import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import { CitationLink } from 'src/components/CitationLink';
import { CITATION_TITLE_REGEX } from 'src/constants';
import { Citation } from 'src/types';
import { CustomLink } from '../CustomLink';

interface HTMLMarkdownProps extends ReactMarkdownProps {
  references?: Citation[];
}

export const LinkMarkdown = ({ references, ...props }: HTMLMarkdownProps) => {
  const text = `${props.children[0]}` || '';
  const match = text.match(CITATION_TITLE_REGEX);

  if (match) {
    const referenceIndex = parseInt(match[1], 10);

    return (
      <CitationLink references={references} citationIndex={referenceIndex} />
    );
  }

  return <CustomLink {...props} />;
};

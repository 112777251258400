import { BaseReactPlayerProps } from 'react-player/base';
import ReactPlayer from 'react-player/lazy';

export const LazyReactPlayer = ({
  width = '100%',
  height = '100%',
  ...rest
}: BaseReactPlayerProps) => {
  return <ReactPlayer width={width} height={height} {...rest} />;
};

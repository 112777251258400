import { SearchWebPageReference } from 'src/types';
import { ResearchReference } from '../ResearchReference';
import { CollapsibleReferencesList } from 'src/components/CollapsibleReferencesList';

interface ThreadResearchReferencesProps {
  researchReferences?: SearchWebPageReference[];
}

export const ThreadResearchReferences = ({
  researchReferences,
}: ThreadResearchReferencesProps) => {
  if (!researchReferences || researchReferences.length === 0) {
    return null;
  }

  return (
    <CollapsibleReferencesList
      references={researchReferences}
      renderItem={(props) => <ResearchReference {...props} key={props.index} />}
    />
  );
};

import { X } from '@phosphor-icons/react';

const SVG_SIZE = 20;

interface CloseTaskButtonProps {
  onClick: () => void;
}

export const CloseTaskButton = ({ onClick }: CloseTaskButtonProps) => {
  return (
    <button onClick={onClick} className="nj-task-view-header--button" data-e2e="close-task-details-button">
      <X size={SVG_SIZE} />
    </button>
  );
};

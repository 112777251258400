import { useMemo } from 'react';
import { useAppSelector, interfaceControlState } from 'src/store';

/**
 * @deprecated
 */
export const useRightSettingsPanelState = () => {
  const { isSettingsPanelExpanded } = useAppSelector(interfaceControlState);

  return useMemo(
    () => ({
      isSettingsPanelExpanded,
    }),
    [isSettingsPanelExpanded],
  );
};

import { TextDivider } from 'src/v2/commonComponents/TextDivider';
import { SuperAgentSection } from '../SuperAgentSection';
import styles from './NinjaTab.module.scss';
import InternalSection from '../InternalSection/InternalSection';

export const NinjaTab = () => {
  return (
    <div className={styles.root}>
      <SuperAgentSection />

      <TextDivider />

      <InternalSection />
    </div>
  );
};

import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);

export const convertHoursToSeconds = (hours?: number | string) => {
  return hours ? Number(hours) * 60 * 60 : 0;
};

export const convertSecondsToHours = (seconds?: number | string) => {
  return seconds ? parseFloat((Number(seconds) / 60 / 60).toFixed(2)) : 0;
};

export const convertWeeksToSeconds = (weeks?: number | string) => {
  return weeks ? Number(weeks) * 7 * 24 * 60 * 60 : 0;
};

export const convertSecondsToWeeks = (seconds?: number | string) => {
  return seconds ? Math.ceil(Number(seconds) / 60 / 60 / 24 / 7) : 0;
};

/**
 * Formats a timestamp to a readable time string in "h:mm A" format considering timezone.
 * @param {string} [timestamp] - The timestamp in the format "YYYY-MM-DDTHH:mm:ss.SSSZ".
 * @param {string} [timezone] - The timezone to apply. Defaults to the local timezone.
 * @returns {string | undefined} - The formatted time string, or undefined if no timestamp is provided.
 */
export const formatTimestampToHoursMinutes = (
  timestamp?: string,
  timezone?: string,
): string => {
  if (!timestamp) {
    return '';
  }

  return (timezone ? dayjs(timestamp).tz(timezone) : dayjs(timestamp)).format(
    'h:mm A',
  );
};

/**
 * Formats a timestamp to a readable date and time string considering timezone.
 * @param {string} [timestamp] - The timestamp in the format "YYYY-MM-DDTHH:mm:ss.SSSZ".
 * @param {string} [timezone] - The timezone to apply. Defaults to the local timezone.
 * @returns {string} - The formatted date and time string, or undefined if no timestamp is provided.
 */
export const formatTimestampToDateAndTime = (
  timestamp?: string,
  timezone?: string,
): string => {
  if (!timestamp) {
    return '';
  }

  const date = timezone ? dayjs(timestamp).tz(timezone) : dayjs(timestamp);
  return `${date.format('MMMM D, YYYY')} at ${date.format('h:mm A')}`;
};

export const getTimeZoneShortName = (timeZone: string): string => {
  const date = new Date();
  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    timeZoneName: 'short',
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const parts = formatter.formatToParts(date);
  const timeZonePart = parts.find((part) => part.type === 'timeZoneName');
  return timeZonePart ? timeZonePart.value : 'N/A';
};

/**
 * `adjustDateForTimezone` adjusts a given date by a specified number of hours to account for timezone differences.
 * This function is useful for converting dates to a user's local timezone from the browser's timezone, or vice versa.
 *
 * @param date a `Date` object or a string that can be parsed into a date.
 * @param hoursDifference The number of hours to adjust the date by. Positive values adjust the date forward, negative values adjust it backward.
 * @returns A new `Date` object representing the adjusted date.
 */
export const adjustDateForTimezone = (date: Date, hoursDifference: number) => {
  return dayjs(date).add(hoursDifference, 'hours').toDate();
};

/**
 * `revertDateForLocalTimezone` reverts a given date string by a specified number of hours to align with the local timezone.
 * It's typically used to convert dates from a user's timezone back to the timezone of the browser or server before display or processing.
 *
 * @param dateString The date string to revert. Should be in a format recognizable by `dayjs`.
 * @param hoursDifference The number of hours to revert the date by, usually the difference between the user's timezone and the local/browser's timezone.
 * @returns A new `Date` object that has been adjusted to the local timezone, or `undefined` if the input date string is falsy.
 */
export const revertDateForLocalTimezone = (
  dateString: string,
  hoursDifference: number,
) => {
  return dateString
    ? dayjs(dateString).subtract(hoursDifference, 'hours').toDate()
    : undefined;
};

export const roundToNextHalfHour = (date: Dayjs) => {
  const minutes = date.minute();
  const increment = minutes % 30 === 0 ? 0 : 30 - (minutes % 30);
  return date.add(increment, 'minute').second(0).millisecond(0);
};

export const roundToNearestQuarterHour = (date: Dayjs) => {
  const minutes = date.minute();
  const increment = minutes % 15 === 0 ? 0 : 15 - (minutes % 15);
  return date.add(increment, 'minute').second(0).millisecond(0);
};

/**
 * getDurationInMinutesAndSeconds() presents milliseconds in the format of "Xmin Ysec"
 * @param ms number
 * @returns string
 */
export const getDurationInMinutesAndSeconds = (ms: number): string => {
  const durationTime = dayjs.duration(ms);
  const minutes = Math.floor(durationTime.asMinutes());
  const seconds = durationTime.seconds();
  return `${minutes > 0 ? `${minutes}min` : ''} ${seconds}sec`;
};

/**
 * Formats a timestamp as "Today", "Yesterday", or in "MMM D HH:mm" format considering the timezone.
 * @param {string} timestamp - The timestamp in the format "YYYY-MM-DDTHH:mm:ss.SSSZ".
 * @param {string} [timezone] - The timezone to apply. Defaults to the local timezone.
 * @returns {string} - The formatted date string.
 */
export const formatByDayjsMonthDay = (
  timestamp: string,
  timezone?: string,
): string => {
  const date = timezone ? dayjs(timestamp).tz(timezone) : dayjs(timestamp);

  if (date.isToday()) {
    return 'Today';
  } else if (date.isYesterday()) {
    return 'Yesterday';
  } else {
    return date.format('MMM D h:mm A');
  }
};

/**
 * Formats a timestamp to a readable time string in "HH:mm" format considering locale and timezone.
 * @param {string} [timestamp] - The timestamp to format.
 * @param {string} [timeZone] - The timezone to apply. Defaults to the local timezone.
 * @returns {string} - The formatted time string.
 */
export function formatHours(timestamp?: string, timeZone?: string): string {
  if (!timestamp) {
    return '';
  }

  const date = timeZone ? dayjs(timestamp).tz(timeZone) : dayjs(timestamp);
  return date.format('h:mm A');
}

export const formatDuration = (seconds: number): string => {
  const time = dayjs.duration(seconds, 'seconds');
  const minutes = Math.floor(time.asMinutes());
  const secs = time.seconds();

  return `${minutes}:${secs.toString().padStart(2, '0')}`;
};

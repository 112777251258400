import styles from './ContactNinjaWhenTeamUserDeleteAccount.module.scss';
import { Modal } from 'src/components/Modal';
import { NINJA_SUPPORT_EMAIL } from 'src/constants';

type ContactNinjaWhenTeamUserDeleteAccountProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ContactNinjaWhenTeamUserDeleteAccount = ({
  isOpen,
  onClose,
}: ContactNinjaWhenTeamUserDeleteAccountProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={styles.contactNinjaModal}
      overlayClassName={styles.overlay}
      blockScroll={false}
    >
      <div className={styles.root}>
        <h4 className={styles.title}>Delete account</h4>
        <span className={styles.text}>
          You currently have an active Business subscription associated with
          your account. To proceed with deleting your account, please contact{' '}
          <a className={styles.link} href={`mailto:${NINJA_SUPPORT_EMAIL}`}>
            {NINJA_SUPPORT_EMAIL}
          </a>
          .
        </span>
        <div className={styles.buttonRow}>
          <button type="button" onClick={onClose} className={styles.button}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

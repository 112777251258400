import { DownloadSimple } from '@phosphor-icons/react';
import cn from 'classnames';
import { useDownloadFile } from 'src/hooks/useDownloadFile';

type DownloadButtonProps = {
  url: string;
  className?: string;
};

const SVG_SIZE = 24;

export const DownloadButton = ({
  url,
  className = '',
}: DownloadButtonProps) => {
  const { onDownloadFile } = useDownloadFile();

  return (
    <button
      type="button"
      className={cn('nj-modal-gallery-activity-button', className)}
      title="Press D"
      onClick={(e) => {
        e.stopPropagation();
        onDownloadFile(url);
      }}
    >
      <DownloadSimple size={SVG_SIZE} />
    </button>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import remarkMath from 'remark-math';
import { PluggableList } from 'react-markdown/lib/react-markdown';
import rehypeKatex from 'rehype-katex';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';
import { Citation, isChitChatCard, TaskSkill } from 'src/types';
import { LinkMarkdown } from './components/LinkMarkdown';
import { CodeMarkdown } from './components/CodeMarkdown';
import {
  CITATION_REGEX,
  CODE_LANGUAGE_REGEX,
  CODE_WITHOUT_NEWLINE_REGEX,
} from 'src/constants';
import styles from './Markdown.module.scss';
import { preprocessLaTeX } from 'src/utils/latex';
import { CustomLink } from './components/CustomLink';
import { useCurrentMessage } from 'src/hooks';

interface MarkdownProps {
  children: string;
  messageId?: string;
  references?: Citation[];
  isStreaming?: boolean;
}

/**
 * Markdown handles markdown for the following:
 * - Headings (H1, H2, H3), use hash (# Heading 1,  ## Heading 2,  ### Heading 3)
 * - Bold, use double asterisks (**Bold**)
 * - Italics, use single asterisks (*Italics*)
 * - Tables, use pipe (|) and hyphen (-)
 * - Lists, use hyphen (-) or asterisk (*)
 * - Links, use square brackets and parentheses ([Link](https://www.example.com))
 * - Images, use exclamation mark and square brackets (![Alt text](https://www.example.com/image.jpg))
 * - Blockquotes, use greater than sign (>)
 * - Code, use backticks (`) and triple backticks (```)
 * - Strikethrough, use double tilde (~~Strikethrough~~)
 * - Newlines, use double space and newline (  \n)
 * - Horizontal rules, use three hyphens (---)
 * - Task lists, use hyphen (-) and square brackets ([x] or [ ])
 * - Footnotes, use caret and square brackets (^[Footnote])
 * - Superscript, use caret and parentheses (^Superscript)
 * - Subscript, use tilde and parentheses (~Subscript)
 * - Abbreviations, use square brackets and parentheses ([HTML](https://www.example.com) or HTML)
 * - Definition lists, use colon (:) and hyphen (-)
 * - Math, use dollar sign ($) and backticks (`)
 * - Emoji, use colon (:) and hyphen (-)
 * - HTML, use angle brackets (<HTML>)
 * @param children
 * @returns
 */
export const Markdown = ({
  children,
  messageId,
  references,
  isStreaming,
}: MarkdownProps) => {
  const { currentMessage } = useCurrentMessage(messageId);

  const messageSkill = useMemo(
    () =>
      !!currentMessage && isChitChatCard(currentMessage.payload)
        ? currentMessage.payload.data?.skill
        : undefined,
    [currentMessage],
  );

  // TODO(olha): we need to figure out what should we do with file agent.
  const shouldHasLatex = !!messageSkill && messageSkill !== TaskSkill.CODING;

  const updatedContent = useMemo(() => {
    const cleared = isStreaming
      ? children.replace(CITATION_REGEX, '')
      : children.replace(CITATION_REGEX, (match) => `${match}(#)`);

    if (shouldHasLatex) {
      return cleared + ' ';
    }

    return cleared;
  }, [children, isStreaming, shouldHasLatex]);

  const CustomLinkMarkdown = useMemo(
    () =>
      ({ ...props }: ReactMarkdownProps) => (
        <LinkMarkdown references={references} {...props} />
      ),
    [references],
  );

  const remarkPlugins: PluggableList = useMemo(() => {
    const plugins = [breaks, gfm];
    if (shouldHasLatex) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      plugins.push([remarkMath, { singleDollarTextMath: false }]);
    }
    return plugins;
  }, [shouldHasLatex]);

  const rehypePlugins = useMemo(() => {
    if (shouldHasLatex) {
      return [
        rehypeKatex,
        { output: 'html', errorColor: 'var(--nj-foreground)' },
      ];
    }
    return [];
  }, [shouldHasLatex]);

  const dataForRender = useMemo(
    () =>
      shouldHasLatex
        ? preprocessLaTeX(updatedContent).slice(0, -1)
        : updatedContent,
    [updatedContent, shouldHasLatex],
  );

  return (
    <div className={styles.root}>
      <ReactMarkdown
        children={dataForRender}
        unwrapDisallowed
        remarkPlugins={remarkPlugins}
        rehypePlugins={rehypePlugins}
        components={{
          // TODO(olha): rendering custom components inside Markdown causes a performance bug (the app is freezing). Using "isStreaming" is a temporary, quick solution to fix freezing
          ...(isStreaming
            ? {
                a: CustomLink,
              }
            : {
                a: CustomLinkMarkdown,
              }),
          code: CodeMarkdown,
        }}
      />
    </div>
  );
};

import { useContext } from 'react';
import {
  Modal as ReactResponsiveModal,
  ModalProps as ReactResponsiveModalProps,
} from 'react-responsive-modal';
import classNames from 'classnames';
import { X } from '@phosphor-icons/react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';

const SVG_SIZE = 24;

interface ModalProps extends ReactResponsiveModalProps {
  children: React.ReactNode;
  className?: string;
  overlayClassName?: string;
}

// (olha): iOS has issue with scrolling inside Modal. I recommend adding blockScroll={false} prop if need it

export const Modal = ({
  open,
  onClose,
  children,
  className,
  overlayClassName,
  closeIcon,
  ...rest
}: ModalProps) => {
  const { rootContainerRef } = useContext(ForwardRefContext);

  return (
    <ReactResponsiveModal
      open={open}
      onClose={onClose}
      container={rootContainerRef?.current || document.body}
      classNames={{
        root: 'nj-modal--root',
        overlay: classNames('nj-modal--overlay', overlayClassName),
        modal: classNames('nj-modal--common', className),
        closeButton: 'nj-modal--closeButton',
      }}
      closeIcon={closeIcon || <X size={SVG_SIZE} />}
      center
      {...rest}
    >
      {children}
    </ReactResponsiveModal>
  );
};

/// <reference types="gapi" />

import React, {
  createContext,
  useMemo,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import useLoadScript from 'src/hooks/useLoadScript';
import { env } from 'src/env';
import { isBetaEnv } from 'src/utils';

interface LoadedApis {
  drive?: boolean;
  drivePicker?: boolean;
  gapi?: boolean;
}

interface GoogleApiContextProps {
  loadedApis: LoadedApis;
}

const GoogleApiContext = createContext<GoogleApiContextProps>({
  loadedApis: {},
});

interface GoogleApiProviderProps {
  clientId: string;
  nonce?: string;
  children: ReactNode;
}

const GoogleApiProvider = ({ nonce, children }: GoogleApiProviderProps) => {
  const [loadedApis, setLoadedApis] = useState<LoadedApis>({
    drive: false,
    drivePicker: false,
    gapi: false,
  });

  const onPickerLoaded = useCallback(() => {
    setLoadedApis((prevState) => {
      return {
        ...prevState,
        drivePicker: true,
      };
    });
  }, []);
  const onGApiClientLoaded = useCallback(() => {
    const loadClients = async () => {
      try {
        await gapi.client.init({
          apiKey: env.REACT_APP_GOOGLE_API_KEY,
          discoveryDocs: [
            'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
          ],
        });
        setLoadedApis((prevState) => {
          return {
            ...prevState,
            gapi: true,
            drive: true,
          };
        });
      } catch {
        console.warn('Unable to init gapi client');
      }
    };
    loadClients();
  }, []);

  const onScriptLoadSuccess = useCallback(() => {
    // TODO: enable for all envs after we test it on Beta/Gamma
    if (isBetaEnv) {
      gapi.load('picker', onPickerLoaded);
      gapi.load('client', onGApiClientLoaded);
    }
  }, [onPickerLoaded, onGApiClientLoaded]);

  useLoadScript({
    src: 'https://apis.google.com/js/api.js',
    nonce,
    onScriptLoadSuccess,
  });

  const contextValue = useMemo(
    () => ({
      loadedApis,
    }),
    [loadedApis],
  );

  return (
    <GoogleApiContext.Provider value={contextValue}>
      {children}
    </GoogleApiContext.Provider>
  );
};

export { GoogleApiProvider };
export default GoogleApiContext;

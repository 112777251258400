import { TaskState } from 'src/types';
import { useBreakpoint } from 'src/hooks';
import { StopTaskButton } from 'src/components/StopTaskButton';
import { DownloadVideoButton } from 'src/v2/components/ThreadMessage/components/DownloadVideoButton';

interface ThreadMessageActionsProps {
  taskState: TaskState;
  messageId?: string;
  taskId?: string;
  taskHash?: string;
}

export const ThreadMessageActions = ({
  taskState,
  taskId = '',
  messageId,
  taskHash,
}: ThreadMessageActionsProps) => {
  const { isMobile } = useBreakpoint();

  return (
    <>
      <StopTaskButton
        taskId={taskId}
        taskState={taskState}
        taskHash={taskHash}
        className="nj-thread-message--action-round-button"
        buttonName={!isMobile ? 'Stop' : ''}
      />

      <DownloadVideoButton messageId={messageId} />
    </>
  );
};

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/types';

type SignupForFreeButtonProps = {
  onClick?: () => void;
  dataGTM: string;
  title?: string;
  className?: string;
};

export const SignupForFreeButton = ({
  onClick,
  dataGTM,
  title,
  className,
}: SignupForFreeButtonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(AppRoutes.SIGN_UP);
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      id={dataGTM}
      type="button"
      className={classNames('nj-signup-for-free-button', className)}
      onClick={handleClick}
        data-e2e="signup-button"
    >
      {title || 'Sign Up'}
    </button>
  );
};

import { useMemo } from 'react';
import { useGetSubscriptionPlansQuery } from 'src/store/services';
import { useSession } from 'src/hooks';
import { TeamGroup } from 'src/types';

type UseSubscriptionPlansProps = {
  group?: TeamGroup;
  seat_count?: number;
};

export const useSubscriptionPlans = ({
  group,
  seat_count,
}: UseSubscriptionPlansProps) => {
  const { appUser, isEnterpriseTier } = useSession();

  const { data, isLoading, isFetching } = useGetSubscriptionPlansQuery(
    { user_id: appUser.user_id, group, seat_count },
    { skip: isEnterpriseTier },
  );

  return useMemo(
    () => ({
      plans: data,
      isPlansLoading: isLoading || isFetching,
    }),
    [data, isLoading, isFetching],
  );
};

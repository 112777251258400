import classNames from 'classnames';
import styles from './TextDivider.module.scss';

interface TextDividerProps {
  text?: string;
  withMargin?: boolean;
}

export const TextDivider = ({ text, withMargin = true }: TextDividerProps) => {
  return text ? (
    <span className={styles.root}>{text}</span>
  ) : (
    <hr
      className={classNames(styles.divider, {
        [styles.withMargin]: withMargin,
      })}
    />
  );
};

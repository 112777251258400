import { useMemo } from 'react';
import { useAppSelector, imageMaskingState } from 'src/store';

export const useImageMaskingCurrentImageData = () => {
  const { currentMaskingImageData } = useAppSelector(imageMaskingState);

  return useMemo(
    () => ({
      currentMaskingImageData,
    }),
    [currentMaskingImageData],
  );
};

import { useMemo } from 'react';
import { ImageCard } from 'src/types';
import { prepareGeneratedImages } from 'src/utils';
import { ImageGenData } from 'src/types/models/ImageGenData';
import { useSession } from '../useSession';
import { useExternalModels } from '../userSettingsHooks';

export const useConversationImages = (imageCard: ImageCard) => {
  const { images, watermarked_images } = imageCard;
  const { appUser, isLimitedTier } = useSession();
  const { imageExternalModels } = useExternalModels();

  const isLimitedEdited = useMemo(
    () => !!watermarked_images && isLimitedTier,
    [watermarked_images, isLimitedTier],
  );

  const imagesArray = useMemo(() => {
    if (!appUser.tier_id) {
      return [];
    }
    if (isLimitedEdited && watermarked_images) {
      // TODO(olha): it's a temporary workaround until BE returns proper model in response
      const imagesWithModels = Object.entries(watermarked_images).reduce(
        (acc, [key, item]) => {
          acc[key] = {
            ...item,
            model: 'alias/ninjatech/ninja-pix',
          };
          return acc;
        },
        {} as Record<string, ImageGenData>,
      );
      return prepareGeneratedImages({
        images: imagesWithModels,
        tierId: appUser.tier_id,
        withWatermark: true,
        imageExternalModels,
      });
    }
    if (!!images) {
      return prepareGeneratedImages({
        images,
        tierId: appUser.tier_id,
        imageExternalModels,
      });
    }
    return [];
  }, [
    images,
    watermarked_images,
    appUser.tier_id,
    isLimitedEdited,
    imageExternalModels,
  ]);

  return imagesArray;
};

import { ConversationsList } from '../../../ConversationsList/ConversationsList';
import styles from './ConversationListSection.module.scss';
import { FilterToolbar } from './components/FilterToolbar';
import { UpsellFooter } from './components/UpsellFooter';

// TODO(olha): move to V2
export const ConversationListSection = () => {
  return (
    <div className={styles.root} data-testid="first-panel">
      <FilterToolbar />

      <ConversationsList />

      <UpsellFooter />
    </div>
  );
};

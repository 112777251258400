import { useContext, MouseEvent, useState } from 'react';
import { useCollapse } from 'react-collapsed';
import AudioContext from 'src/contexts/AudioContext';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import {
  useSession,
  useQueryParams,
  useConversationParams,
  useBreakpoint,
  useLeftPanelActions,
  useScrollToTask,
} from 'src/hooks';
import { ApiMinimumTask, Conversation } from 'src/types';
import { TaskItem } from '../TaskItem';
import { DEFAULT_CHAT_NAME } from 'src/constants';
import { convertConversationIdToSlug, interruptMetahuman } from 'src/utils';
import { ConversationTitle } from '../ConversationTitle';

type ConversationThreadProps = {
  conversation: Conversation;
  selected?: boolean;
  toggleOpenRenameModal?: (val: boolean) => void;
};

export const ConversationItem = ({
  conversation,
  selected = false,
  toggleOpenRenameModal,
}: ConversationThreadProps) => {
  const { appUser } = useSession();
  const { isMobile } = useBreakpoint();
  const { setSearchParams } = useQueryParams();
  const { currentConversationId } = useConversationParams();
  const { toggleLeftPanelExpanded } = useLeftPanelActions();
  const { activeThreadAnchor, setActiveThreadAnchor } = useScrollToTask();

  const { metaHumanTalking } = useContext(AudioContext);
  const { showTranscript, toggleTranscript } = useContext(AvatarModeContext);

  const { conversation_id, tasks = [] } = conversation;

  const [isExpanded, setExpanded] = useState<boolean>(true);

  const { getCollapseProps, getToggleProps } = useCollapse({
    defaultExpanded: true,
    isExpanded,
  });

  const handleConversationTitleClick = async (e: MouseEvent) => {
    e.stopPropagation();
    setActiveThreadAnchor(null);

    if (threadId === currentConversationId) {
      setExpanded((prev) => !prev);
      return;
    }

    if (isMobile) {
      toggleLeftPanelExpanded(false);
    }

    setSearchParams({ conv: convertConversationIdToSlug(threadId) });

    if (metaHumanTalking) {
      await interruptMetahuman(appUser.user_id);
    }

    if (!showTranscript) {
      toggleTranscript(true);
    }
  };

  const handleTaskClick = async (taskId: string) => {
    setActiveThreadAnchor(taskId);

    if (isMobile) {
      toggleLeftPanelExpanded(false);
    }

    if (conversation_id !== currentConversationId) {
      if (conversation_id) {
        setSearchParams({ conv: convertConversationIdToSlug(conversation_id) });
      }
    }

    if (!showTranscript) {
      toggleTranscript(true);
    }
  };

  const threadId = conversation_id || DEFAULT_CHAT_NAME;
  const conversationTitleToggleProps = getToggleProps({
    onClick: handleConversationTitleClick,
  });

  return (
    <>
      <ConversationTitle
        isSelected={selected}
        isExpanded={isExpanded}
        hasTasks={!!tasks.length}
        toggleOpenRenameModal={toggleOpenRenameModal}
        conversation={conversation}
        toggleProps={conversationTitleToggleProps}
        handleConversationTitleClick={handleConversationTitleClick}
        activeThreadAnchor={activeThreadAnchor}
      />

      <div {...getCollapseProps()}>
        {tasks.map((task: ApiMinimumTask, index: number) => (
          <TaskItem
            key={`task-${task.task_id}-${index}`}
            task={task}
            selected={activeThreadAnchor === task.task_id && selected}
            selectedConversation={selected}
            shouldOpenRenameModal={toggleOpenRenameModal}
            onClick={handleTaskClick}
          />
        ))}
      </div>
    </>
  );
};

import { useCallback, useMemo } from 'react';
import {
  useAppDispatch,
  setIsAttachedImageTooltipVisible,
  setIsAttachedImageEditingMode,
} from 'src/store';

export const useAttachedImageTooltipActions = () => {
  const dispatch = useAppDispatch();

  const handleToggleImageEditTooltipVisible = useCallback(
    (data: boolean) => {
      dispatch(setIsAttachedImageTooltipVisible(data));
    },
    [dispatch],
  );

  const handleChangeIsAttachedImageEditingMode = useCallback(
    (data: boolean) => {
      dispatch(setIsAttachedImageEditingMode(data));
    },
    [dispatch],
  );

  return useMemo(
    () => ({
      onToggleImageEditTooltipVisible: handleToggleImageEditTooltipVisible,
      onChangeIsAttachedImageEditingMode:
        handleChangeIsAttachedImageEditingMode,
    }),
    [
      handleToggleImageEditTooltipVisible,
      handleChangeIsAttachedImageEditingMode,
    ],
  );
};

import styles from './BasePublicPage.module.scss';
import { Header } from 'src/v2/pages/auth/components/Header';
import { Footer } from 'src/v2/pages/auth/components/Footer';
import { useRandomSignupBackgroundStyle } from 'src/hooks/useRandomSignupBackgroundStyle';
import { isEnterprise } from 'src/utils';
import { EnterpriseFooter } from 'src/v2/pages/auth/components/EnterpriseFooter';
import classNames from 'classnames';
import { Appearance } from 'src/types/models/Appearance';

interface BasePublicPageProps {
  seoTitle?: string;
  seoDescription?: string;
  alignContainer?: 'center' | 'top'; // where should the main panel reside
  imageUrl?: string; // use specific image instead of random background
  appearance?: Appearance; // use light for light backgrounds
  blurBackground?: boolean; // apply "darken" background effect
  showFooter?: boolean;
  children?: React.ReactNode;
}

export const BasePublicPage = ({
  seoTitle,
  seoDescription,
  children,
  alignContainer = 'top',
  appearance = Appearance.DARK,
  imageUrl,
  blurBackground = true,
  showFooter = true,
}: BasePublicPageProps) => {
  const { backgroundImageStyle } = useRandomSignupBackgroundStyle(imageUrl);

  return (
    <div
      className={classNames(styles.root, {
        [styles.blurred]: blurBackground,
      })}
      style={backgroundImageStyle}
    >
      <Header
        seoTitle={seoTitle}
        seoDescription={seoDescription}
        appearance={appearance}
      />
      <main className={classNames(styles.container, styles[alignContainer])}>
        {children}
      </main>
      {showFooter && (isEnterprise ? <EnterpriseFooter /> : <Footer />)}
    </div>
  );
};

import cn from 'classnames';
import { Circle, Trash } from '@phosphor-icons/react';
import styles from './BrushSizeTool.module.scss';
import { SVG_SIZE_M } from 'src/constants';

const MIN_BRUSH_SIZE = 24;
const MAX_BRUSH_SIZE = 88;

interface BrushSizeToolProps {
  brushSize: number;
  setBrushSize: (brushSize: number) => void;
  handleDeleteSelections: () => void;
  isRedoButtonDisabled: boolean;
  isUndoButtonDisabled: boolean;
  isBrushSizeToolVisible: boolean;
}

export const BrushSizeTool = ({
  brushSize,
  setBrushSize,
  isBrushSizeToolVisible,
  handleDeleteSelections,
  isRedoButtonDisabled,
  isUndoButtonDisabled,
}: BrushSizeToolProps) => {
  const handleBrushSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBrushSize(Number(e.target.value));
  };

  const setBrushMaxSize = () => {
    setBrushSize(MAX_BRUSH_SIZE);
  };

  const circleIconSize = Math.min(brushSize / 2, 32);
  const progress =
    ((brushSize - MIN_BRUSH_SIZE) / (MAX_BRUSH_SIZE - MIN_BRUSH_SIZE)) * 100;

  return (
    <div
      className={cn(styles.brushSettings, {
        [styles.active]: isBrushSizeToolVisible,
      })}
    >
      <input
        type="range"
        min={MIN_BRUSH_SIZE}
        max={MAX_BRUSH_SIZE}
        value={brushSize}
        onChange={handleBrushSizeChange}
        className={styles.rangeInput}
        style={{
          background: `linear-gradient(to right, rgba(29, 40, 45, 1) ${progress}%, rgba(255, 255, 255, 1) ${progress}%)`,
        }}
      />
      <button onClick={setBrushMaxSize} className={styles.brushSizeIcon}>
        <Circle size={circleIconSize} weight="duotone" color="currentColor" />
      </button>
      <div className={styles.verticalLine} />
      <button
        onClick={handleDeleteSelections}
        disabled={isUndoButtonDisabled && isRedoButtonDisabled}
      >
        <Trash size={SVG_SIZE_M} />
      </button>
    </div>
  );
};

import { useMemo } from 'react';
import { useAppSelector, imageMaskingState } from 'src/store';

export const useImageMaskingShouldResetCanvas = () => {
  const { shouldResetCanvas } = useAppSelector(imageMaskingState);

  return useMemo(
    () => ({
      shouldResetCanvas,
    }),
    [shouldResetCanvas],
  );
};

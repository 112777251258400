import styles from './CustomCursor.module.scss';

interface CursorCircleProps {
  customCursorRef: React.RefObject<HTMLDivElement>;
  brushSize: number;
}

export const CustomCursor = ({
  customCursorRef,
  brushSize,
}: CursorCircleProps) => {
  return (
    <div
      ref={customCursorRef}
      className={styles.customCursor}
      style={{
        width: brushSize,
        height: brushSize,
      }}
    />
  );
};

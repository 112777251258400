import styles from './UpgradeDowngradeSummarySection.module.scss';
import { SubscriptionPlan } from 'src/types';
import { CollapsedItem } from 'src/components/CollapsedItem';
import { SummaryHeader } from '../SummaryHeader';
import { SummaryContent } from '../SummaryContent';
import { useSession, useTeamInfo } from 'src/hooks';
import cn from 'classnames';
import { getNextBillingCycleDate } from 'src/utils';

type UpgradeDowngradeSummarySectionProps = {
  selectedPlan?: SubscriptionPlan;
  numberOfSeats?: number;
  isPlanLoading?: boolean;
};

export const UpgradeDowngradeSummarySection = ({
  selectedPlan,
  numberOfSeats,
  isPlanLoading = false,
}: UpgradeDowngradeSummarySectionProps) => {
  const { isTeamsTier } = useSession();
  const { teamInfo } = useTeamInfo();
  const isDowngrade = selectedPlan?.total_due_amount === 0;
  const isUpgradeToTeams = isTeamsTier || !!numberOfSeats;

  const header = (
    <SummaryHeader
      selectedPlan={selectedPlan}
      originalPrice={selectedPlan?.total_recurring_amount}
      dueToday={selectedPlan?.total_due_amount}
      additionalDescription={getNextBillingCycleDate(
        selectedPlan?.next_billing_cycle_start_timestamp,
      )}
      isLoading={isPlanLoading}
    />
  );

  const content = (
    <SummaryContent
      selectedPlan={selectedPlan}
      totalOriginalAmount={selectedPlan?.total_recurring_amount || 0}
      subtotal={selectedPlan?.total_recurring_amount || 0}
      taxes={0}
      dueToday={selectedPlan?.total_due_amount || 0}
      discount={selectedPlan?.prorated_discount_amount}
      isTeams={isUpgradeToTeams}
      seats={isTeamsTier ? teamInfo?.num_seats_total : undefined}
      isLoading={isPlanLoading}
    />
  );

  if (isTeamsTier) {
    return (
      <div className={cn(styles.root, styles.rootTeamTier)}>
        <div>{content}</div>
        <hr className="divider" />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      {isDowngrade ? (
        <div className={styles.header}>{header}</div>
      ) : (
        <CollapsedItem
          title=""
          header={header}
          content={content}
          isExpanded={false}
          headerClassName={styles.collapsedItem}
        />
      )}
    </div>
  );
};

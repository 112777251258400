import { DownloadSimple } from '@phosphor-icons/react';
import cn from 'classnames';
import { useDownloadFile } from 'src/hooks/useDownloadFile';
import { SVG_SIZE_L } from 'src/constants';
import styles from './DownloadButton.module.scss';

type DownloadButtonProps = {
  url: string;
  className?: string;
};

export const DownloadButton = ({
  url,
  className = '',
}: DownloadButtonProps) => {
  const { onDownloadFile } = useDownloadFile();

  return (
    <button
      type="button"
      className={cn(styles.root, className)}
      title="Press D"
      onClick={(e) => {
        e.stopPropagation();
        onDownloadFile(url);
      }}
    >
      <DownloadSimple size={SVG_SIZE_L} />
    </button>
  );
};

import { sessionState, useAppSelector } from 'src/store';
import {
  useAcceptInviteMutation,
  useGetTeamByInvitationTokenQuery,
} from 'src/store/services';
import { useCallback, useMemo } from 'react';
import { INVITATION_TOKEN } from 'src/constants';
import log from 'src/utils/logger';
import { toast } from 'react-toastify';

type InvitationProps = {
  token?: string | null;
};

export const useInvitationInfo = ({ token }: InvitationProps) => {
  const {
    appUser: { user_id },
  } = useAppSelector(sessionState);

  const [acceptInvite, { isLoading: isAcceptInviteLoading }] =
    useAcceptInviteMutation();

  const {
    data: invitationInfo,
    isLoading,
    isFetching,
    error,
  } = useGetTeamByInvitationTokenQuery(
    { token: token || '', user_id: user_id },
    {
      skip: !token || !user_id,
    },
  );

  const handleAcceptInvite = useCallback(async () => {
    try {
      await acceptInvite({
        user_id: user_id,
        token: token || '',
        team_id: invitationInfo?.team_id || '',
        seat_id: invitationInfo?.seat_id || '',
      }).unwrap();
    } catch (e) {
      log.error(e);
      toast.error('Something went wrong');
    } finally {
      localStorage.removeItem(INVITATION_TOKEN);
    }
  }, [user_id, token, invitationInfo, acceptInvite]);

  return useMemo(
    () => ({
      invitationInfo,
      isInvitationInfoLoading: isLoading || isFetching,
      onAcceptInvite: handleAcceptInvite,
      invitationError: error,
      isAcceptInviteLoading,
    }),
    [
      invitationInfo,
      isLoading,
      handleAcceptInvite,
      isFetching,
      error,
      isAcceptInviteLoading,
    ],
  );
};

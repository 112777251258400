/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum NinjaEventTypes {
  NEW_MESSAGE = 'new_message',
  NEW_TASK = 'new_task',
  UPDATE_TASK = 'update_task',
  UPDATE_USER = 'update_user',
  UPDATE_USER_V2 = 'update_user_v2',
  UPDATE_TEAM = 'update_team',
  UPDATE_TEAM_SEAT = 'update_team_seat',
  TASK_COST = 'task_cost',
  NOTIFY_USER = 'notify_user',
  NOTIFICATION = 'notification',
  NEW_STREAMABLE_MESSAGE_HEADER = 'new_streamable_message_header',
  NEW_STREAMABLE_MESSAGE_CHUNK = 'new_streamable_message_chunk',
  NEW_STREAMABLE_MESSAGE_FOOTER = 'new_streamable_message_footer',
  NEW_STREAMABLE_MESSAGE_CHECKPOINT = 'new_streamable_message_checkpoint',
  NEW_CONVERSATION = 'new_conversation',
  UPDATE_CONVERSATION = 'update_conversation',
  DENIED_MESSAGE = 'denied_message',
  UPDATE_USER_STATUS = 'update_user_status',
  UPDATE_APP_VERSION = 'update_app_version',
  CREDITS_AUTO_REPLENISH = 'credits_auto_replenish',
  PAYMENT_STATUS = 'payment_status',
  UPDATE_SUBSCRIPTION = 'update_subscription',
}

import mime from 'mime';
import { toast } from 'react-toastify';
import {
  AVAILABLE_TO_UPLOAD_FILE_TYPES_LIST,
  MAX_FILE_SIZE,
} from 'src/constants';

const FILENAME_REGEX = /^[a-zA-Z0-9._-]+[.][a-zA-Z0-9]+$/;
const GOOGLE_PROPRIETARY_MIME_TYPE = 'application/vnd.google-apps';

const isValidFilename = (filename: string) => FILENAME_REGEX.test(filename);

// supported proprietary Google mime types
const googleDriveMimeTypeMapping: Record<string, string> = {
  'application/vnd.google-apps.document': 'text/plain',
  'application/vnd.google-apps.drawing': 'image/jpeg',
  'application/vnd.google-apps.fusiontable': 'text/csv',
  'application/vnd.google-apps.spreadsheet': 'text/csv',
  'application/vnd.google-apps.photo': 'image/jpeg',
  'application/vnd.google-apps.presentation': 'text/plain',
};

export async function googleDocsToFiles(
  pickerPayload: google.picker.DocumentObject[],
): Promise<File[] | undefined> {
  try {
    const downloadUrlPromises = pickerPayload.map(async (doc) => {
      if (!validateGoogleDoc(doc)) {
        return;
      }
      const fileName = doc.name;
      // proprietary Google type, we should use drive.export API
      if (doc.mimeType?.startsWith(GOOGLE_PROPRIETARY_MIME_TYPE)) {
        const convertedMimeType = googleDriveMimeTypeMapping[doc.mimeType];
        const response = await gapi.client.drive.files.export({
          fileId: doc.id,
          mimeType: convertedMimeType,
        });
        const blob = new Blob([response.body], { type: convertedMimeType });
        return new File(
          [blob],
          `${fileName}.${mime.getExtension(convertedMimeType)}`,
          { type: blob.type },
        );
        // for non-google files we use get API and provide alt:media param to fetch the content
      } else {
        const response = await gapi.client.drive.files.get({
          fileId: doc.id,
          fields: 'id',
          alt: 'media', // tell gapi to return the content in the response
        });
        const blob = new Blob([response.body], {
          type: response.result.mimeType,
        });
        return new File([blob], fileName as string, { type: blob.type });
      }
    });

    return (await Promise.all(downloadUrlPromises)).filter(
      (file) => file !== undefined,
    ) as File[];
  } catch (error) {
    console.error('Error fetching files:', error);
    return;
  }
}

// For proprietary (Google private formats) files Google Drive does not provide us with the file extension
const buildUnsupportedFileExtensionMessage = (filename?: string) => {
  return filename && isValidFilename(filename)
    ? `.${filename.split('.').pop()?.toLowerCase()} file type is not supported. Please try again with a compatible file format.`
    : 'This file type is not supported. Please try again with a compatible file format.';
};

const buildFileToBigMessage = (fileName?: string) => {
  return `Sorry, ${fileName || 'the attached file'} is too large to process. Please upload a smaller file and try again.`;
};

const validateGoogleDoc = (doc: google.picker.DocumentObject) => {
  if (doc.mimeType?.startsWith(GOOGLE_PROPRIETARY_MIME_TYPE)) {
    const mimeType = googleDriveMimeTypeMapping[doc.mimeType];
    if (!mimeType) {
      toast.error(buildUnsupportedFileExtensionMessage());
      return false;
    }
  } else if (
    doc.name &&
    doc.mimeType &&
    !AVAILABLE_TO_UPLOAD_FILE_TYPES_LIST.includes(doc.mimeType)
  ) {
    toast.error(buildUnsupportedFileExtensionMessage(doc.name));
    return false;
  }
  if ((doc.sizeBytes || 0) > MAX_FILE_SIZE) {
    toast.error(buildFileToBigMessage(doc.name));
    return false;
  }
  return true;
};

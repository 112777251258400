import styles from './PeriodSwitch.module.scss';
import { PaymentPlanInterval, NinjaSubscriptionPlan } from 'src/types';
import { useSubscriptionPlans } from 'src/hooks';
import { SwitchSimple } from 'src/v2/commonComponents/SwitchSimple';

type PeriodSwitchProps = {
  selectedPeriod: PaymentPlanInterval;
  onChangePeriod: (value: PaymentPlanInterval) => void;
  isTeams?: boolean;
};

export type PaymentPeriod = 'Monthly' | 'Annually';

export const PeriodSwitch = ({
  selectedPeriod,
  onChangePeriod,
  isTeams,
}: PeriodSwitchProps) => {
  const { plans, isPlansLoading } = useSubscriptionPlans({
    group: isTeams ? 'teams' : 'individual',
  });

  const { recurring_discount_percentage } =
    plans?.find(
      ({ period, plan_tier }) =>
        period === PaymentPlanInterval.ANNUALLY &&
        (plan_tier === NinjaSubscriptionPlan.ULTRA ||
          plan_tier === NinjaSubscriptionPlan.TEAMS_ULTRA),
    ) || {};

  const handleSwitchChange = (value: boolean) => {
    onChangePeriod(
      value ? PaymentPlanInterval.ANNUALLY : PaymentPlanInterval.MONTHLY,
    );
  };

  return (
    <div className={styles.root}>
      {!isPlansLoading && recurring_discount_percentage && (
        <div className={styles.saleLabelWrapper}>
          <p>Billed annually</p>
          <div className={styles.saleLabel}>
            {recurring_discount_percentage}% OFF
          </div>
        </div>
      )}

      <SwitchSimple
        isChecked={selectedPeriod === PaymentPlanInterval.ANNUALLY}
        onChange={handleSwitchChange}
      />
    </div>
  );
};

import { useState, useEffect } from 'react';
import log from 'src/utils/logger';

const backgroundIds = [
  'e3cd8765-3edd-4729-aeee-0f001dd63c33_3.jpeg',
  '0d952f33-3cfb-4632-b699-a514a2805fb4_1.jpeg',
  '79c08c34-bc85-4047-bf10-f581373a8346_2.jpeg',
  'd943303d-8ae5-4254-af67-ed10f1a7d8d1_0.jpeg',
  'bcf7ec03-e2ab-419d-9de5-34a90d90c35a_1.jpeg',
  '88a36874-4894-4bb7-a304-5a887a087337_3.jpeg',
  'd6d9e394-7bb1-4038-8834-6411a0b88e36_1.jpeg',
  'eb7d92ab-6bd2-47f0-ae68-d2c310e68169_1.jpeg',
  '6ba292e4-5ab4-427d-b3ab-cc0d1a3843d5_2.jpeg',
  '0cc7339d-866c-43d6-af67-08d8c2757f26_3.jpeg',
];

const getRandomBackgroundImageUrl = async () => {
  const randomBackgroundId =
    backgroundIds[Math.floor(Math.random() * backgroundIds.length)];
  const background = await import(
    `src/images/backgrounds/signup/${randomBackgroundId}`
  );
  return `url("${background.default}")`;
};

const getRandomBackgroundImageStyle = async (imageUrl?: string) => {
  try {
    return {
      backgroundImage: imageUrl
        ? imageUrl
        : await getRandomBackgroundImageUrl(),
    };
  } catch (error) {
    log.error(error);
    return {
      backgroundColor: '#000000',
    };
  }
};

export const useRandomSignupBackgroundStyle = (imageUrl?: string) => {
  const [backgroundImageStyle, setBackgroundImageStyle] = useState({});
  useEffect(() => {
    getRandomBackgroundImageStyle(imageUrl).then((style) => {
      setBackgroundImageStyle(style);
    });
  }, [imageUrl]);

  return { backgroundImageStyle };
};

import { forwardRef } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import {
  Cube,
  Wallet,
  ArrowLeft,
  UserCircle,
  CirclesThreePlus,
  Gear,
  Users,
} from '@phosphor-icons/react';
import {
  AppRoutes,
  BillingSettingsNestedRoutes,
  UserSettingsNestedRoutes,
  Navigation,
  ManageUsersNestedRoutes,
} from 'src/types';
import { useSession, useSubscriptionInfo, useTeamInfo } from 'src/hooks';
import styles from './ManageAccountNavigation.module.scss';

const SVG_SIZE_L = 24;
const SVG_SIZE_M = 20;

const SVG_PROPS = {
  size: SVG_SIZE_M,
};

interface ManageAccountNavigationProps {
  onClose?: () => void;
}

export const ManageAccountNavigation = forwardRef<
  HTMLDivElement,
  ManageAccountNavigationProps
>(({ onClose }, ref) => {
  const { pathname } = useLocation();
  const { isSubscribedTier, isEnterpriseTier, isTeamsTier } = useSession();
  const { seatInfo, isOwner, isAdmin } = useTeamInfo();

  const { isAppleOrAmpPayment } = useSubscriptionInfo();

  const isManageUsersDisabled =
    !isTeamsTier || !seatInfo || (!!seatInfo && !isOwner && !isAdmin);

  const navigation: Array<Navigation> = [
    {
      category: 'User Settings',
      links: [
        {
          route: UserSettingsNestedRoutes.PROFILE,
          icon: <UserCircle {...SVG_PROPS} weight="fill" />,
          title: 'Profile',
          defaultRoute: false,
          disabled: false,
          datae2e: 'settings-profile-link',
        },
        {
          route: UserSettingsNestedRoutes.INTEGRATIONS,
          icon: <CirclesThreePlus {...SVG_PROPS} />,
          title: 'Integrations',
          defaultRoute: false,
          disabled: false,
          datae2e: 'settings-integrations-link',
        },
        {
          route: UserSettingsNestedRoutes.GENERAL,
          icon: <Gear {...SVG_PROPS} />,
          title: 'General',
          defaultRoute: false,
          disabled: false,
          datae2e: 'settings-general-link',
        },
      ],
    },
    {
      category: 'Billing Settings',
      links: [
        {
          route: BillingSettingsNestedRoutes.SUBSCRIPTION,
          icon: <Cube {...SVG_PROPS} />,
          title: 'Subscription',
          defaultRoute: true,
          disabled: false,
          datae2e: 'settings-billing-subscription-link',
        },
        ...(!isEnterpriseTier
          ? [
              {
                route: BillingSettingsNestedRoutes.BILLING,
                icon: <Wallet {...SVG_PROPS} />,
                title: 'Billing',
                defaultRoute: false,
                disabled:
                  !isSubscribedTier ||
                  (isTeamsTier && !isOwner) ||
                  isAppleOrAmpPayment,
                datae2e: 'settings-billing-billing-link'
              },
            ]
          : []),
      ],
    },
    ...(isTeamsTier
      ? [
          {
            category: 'Workspace management',
            links: [
              {
                route: ManageUsersNestedRoutes.MANAGE_USERS,
                icon: <Users {...SVG_PROPS} />,
                title: 'Manage users',
                defaultRoute: true,
                disabled: isManageUsersDisabled,
                datae2e: 'settings-workspace-link',
              },
            ],
          },
        ]
      : []),
  ];

  return (
    <div className={styles.root} ref={ref}>
      <Link to={AppRoutes.HOME} className={styles.goBackLink}>
        <ArrowLeft size={SVG_SIZE_L} />
        Back to Ninja
      </Link>

      <div className={styles.menu}>
        {navigation.map(({ category, links }) => (
          <div className={styles.navGroup} key={category}>
            <span className={styles.category}>{category}</span>
            <>
              {links.map(({ route, icon, title, defaultRoute, disabled, datae2e }) => (
                <div key={title}>
                  <NavLink
                    onClick={(e) => {
                      if (disabled) {
                        e.preventDefault();
                      } else if (onClose) {
                        onClose();
                      }
                    }}
                    className={({ isActive }) =>
                      classNames(styles.menuLink, {
                        active:
                          isActive ||
                          (defaultRoute &&
                            pathname === AppRoutes.MANAGE_ACCOUNT),
                        disabled,
                      })
                    }
                    key={route}
                    to={route}
                    data-e2e={datae2e}
                  >
                    {icon}
                    {title}
                  </NavLink>
                </div>
              ))}
            </>
          </div>
        ))}
      </div>
    </div>
  );
});

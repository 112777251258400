import { useCallback, useMemo } from 'react';
import {
  useAppSelector,
  teamsState,
  useAppDispatch,
  setSelectedNumberOfSeatsToBuy,
} from 'src/store';

export const useSelectedNumberOfSeats = () => {
  const { selectedNumberOfSeatsToBuy } = useAppSelector(teamsState);
  const dispatch = useAppDispatch();

  const onChangeSelectedNumberOfSeatsToBuy = useCallback(
    (value?: number) => {
      dispatch(setSelectedNumberOfSeatsToBuy(value));
    },
    [dispatch],
  );

  return useMemo(
    () => ({
      selectedNumberOfSeatsToBuy,
      onChangeSelectedNumberOfSeatsToBuy,
    }),
    [selectedNumberOfSeatsToBuy, onChangeSelectedNumberOfSeatsToBuy],
  );
};

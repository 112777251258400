import { useMemo, forwardRef } from 'react';
import { ActionStatue, Action, isVideoGenerationCard } from 'src/types';
import styles from './LatestProgressAction.module.scss';
import { useCurrentMessage } from 'src/hooks';

interface LatestProgressActionProps {
  actions?: Action[];
  messageId?: string;
}
export const LatestProgressAction = forwardRef<
  HTMLParagraphElement,
  LatestProgressActionProps
>(({ actions = [], messageId }, ref) => {
  const { currentMessage } = useCurrentMessage(messageId);

  const videoCardDimension = isVideoGenerationCard(currentMessage?.payload)
    ? currentMessage?.payload?.videos?.[1]?.dimension
    : undefined;

  const allCompleted = useMemo(
    () => actions.every((action) => action.status === ActionStatue.COMPLETED),
    [actions],
  );

  if (allCompleted && videoCardDimension) {
    return (
      <p ref={ref} className={styles.root}>
        {videoCardDimension}
      </p>
    );
  }

  if (allCompleted) {
    return <p ref={ref} className={styles.root} />;
  }

  const firstInprogressAction =
    actions.find((item) => item.status !== ActionStatue.COMPLETED)?.action ||
    'Thinking...';

  return (
    <p ref={ref} className={styles.root}>
      {firstInprogressAction}
    </p>
  );
});

import { useMemo } from 'react';
import { Hash } from '@phosphor-icons/react';
import {
  useBreakpoint,
  useCurrentConversation,
  useLeftPanelState,
} from 'src/hooks';
import { AddConversationButton } from 'src/pages/ManageTasksChatPage/components/MultiConversationsListPanel/components/AddConversationButton';
import { MainNavToggleButton } from 'src/pages/ManageTasksChatPage/components/ConversationsHeader/components/MainNavToggleButton';
import styles from './ConversationsHeader.module.scss';
import { SVG_SIZE_M } from 'src/constants';

export const ConversationsHeader = () => {
  const { isMobileOrTablet, isDesktopAndUp } = useBreakpoint();
  const { currentConversation } = useCurrentConversation();
  const { isLeftPanelExpanded } = useLeftPanelState();

  const headerTitle = useMemo(
    () => currentConversation?.conversation_hash?.replace('#', '') || '',
    [currentConversation],
  );

  return (
    <header className={styles.root}>
      <div className={styles.actionWrapper}>
        {!isLeftPanelExpanded && isMobileOrTablet && <MainNavToggleButton />}
      </div>

      {headerTitle && isDesktopAndUp && (
        <h4 className={styles.desktopTitle}>
          <Hash size={SVG_SIZE_M} />
          <span className={styles.center}>{headerTitle}</span>
        </h4>
      )}

      <div className={styles.actionWrapper}>
        {isMobileOrTablet && (
          <AddConversationButton className="nj-thread-header--mobile-create-new-chat" />
        )}
      </div>
    </header>
  );
};

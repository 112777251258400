export const TEAM_ACTIVE_MEMBERS_COLUMNS: { header: string; key: string }[] = [
  {
    header: 'Name',
    key: 'name',
  },
  {
    header: 'Email',
    key: 'email',
  },
  {
    header: 'Role',
    key: 'role',
  },
  {
    header: '',
    key: 'actions',
  },
];

export const TEAM_PENDING_MEMBERS_COLUMNS: { header: string; key: string }[] = [
  {
    header: 'Email',
    key: 'email',
  },
  {
    header: '',
    key: 'actions',
  },
];

export const SEATS_PAGE_LIMIT = 15;

export const InviteUsersFileInputId = 'inviteEmailsFile';

export const LIMIT_NUMBER_OF_INVITED_EMAILS = 1000;

export const ACCEPTED_INVITE_MESSAGE_TEXT =
  'You have already accepted this invitation';

import { useMemo } from 'react';
import { useAppSelector, interfaceControlState } from 'src/store';

export const useLeftPanelState = () => {
  const { isLeftPanelExpanded, leftPanelSections } = useAppSelector(
    interfaceControlState,
  );

  return useMemo(
    () => ({
      isLeftPanelExpanded,
      leftPanelSections,
    }),
    [isLeftPanelExpanded, leftPanelSections],
  );
};

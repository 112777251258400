import { useEffect, useContext } from 'react';
import AudioContext from 'src/contexts/AudioContext';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { useThreadRegistry } from 'src/hooks';
import { SendMessageButton } from '../SendMessageButton';
import { StopResponseButton } from '../StopResponseButton';
import { useImageMaskingIsLoading } from 'src/hooks/imageMaskingPanelHooks/useImageMaskingIsLoading';

interface SubmitTriggerProps {
  isSubmitDisabled: boolean;
}

export const SubmitTrigger = ({ isSubmitDisabled }: SubmitTriggerProps) => {
  const { stopResponseTriggerRef } = useContext(AudioContext);
  const { showReplaceTooltip } = useContext(ThreadInputBoxContext);
  const { isSubmitHappened } = useThreadRegistry();
  const { imageMaskingIsLoading } = useImageMaskingIsLoading();

  useEffect(() => {
    stopResponseTriggerRef?.current?.stopResponse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isSubmitHappened || imageMaskingIsLoading ? (
        <StopResponseButton />
      ) : (
        <SendMessageButton
          isDisabled={isSubmitDisabled || showReplaceTooltip}
        />
      )}
    </>
  );
};

import { useEffect, useRef, useState } from 'react';
import { useColorTheme, useSubscriptionPlanById, useVisible } from 'src/hooks';
import styles from './CheckoutSection.module.scss';
import { Elements } from '@stripe/react-stripe-js';
import { PanelHeader } from '../PanelHeader';
import { StripeElements } from '../StripeElements';
import { SubscriptionPlan } from 'src/types';
import { LoadingPanel } from '../LoadingPanel';
import { SummarySection } from '../SummarySection';
import { AppearingFromRight } from 'src/animations/AppearingFromRight';
import { PayPalElements } from '../PayPalElements';
import { stripePromise } from 'src/utils';
import { CheckoutSeatsSection } from 'src/v2/pages/payment/components/CheckoutSeatsSection';

type CheckoutSectionProps = {
  isOpen: boolean;
  isPaymentProcessing: boolean;
  onClose: () => void;
  selectedPlan?: SubscriptionPlan;
  onPaymentProcessing: (value: boolean) => void;
  isTeams?: boolean;
};

export const CheckoutSection = ({
  isOpen,
  isPaymentProcessing,
  onClose,
  selectedPlan,
  onPaymentProcessing,
  isTeams,
}: CheckoutSectionProps) => {
  const { isDarkTheme } = useColorTheme();
  const checkoutContentRef = useRef<HTMLDivElement>(null);
  const [numberOfSeats, setNumberOfSeats] = useState(1);

  const { plan, isPlanLoading } = useSubscriptionPlanById({
    plan_id: selectedPlan?.plan_id,
    seat_count: numberOfSeats,
    shouldSkip: !isTeams,
  });

  const {
    isVisible: isElementsLoading,
    onVisibilityRemove: onElementsLoadingRemove,
    onVisibilitySet: onElementsLoadingSet,
  } = useVisible(true);

  const [isReadyPaypalElements, setReadyPaypalElements] =
    useState<boolean>(false);
  const [isReadyStripeElements, setReadyStripeElements] =
    useState<boolean>(false);

  useEffect(() => {
    if (isReadyPaypalElements && isReadyStripeElements) {
      onElementsLoadingRemove();
    } else {
      onElementsLoadingSet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyPaypalElements, isReadyStripeElements]);

  const handleResetState = () => {
    onElementsLoadingSet();
    setReadyPaypalElements(false);
    setReadyStripeElements(false);
  };

  const handlePaymentProcessingChange = (value: boolean) => {
    onPaymentProcessing(value);

    //AB testing using VWO start
    // if (!value) {
    //   ABTesting_SubscribeExecuted();
    // }
    //AB testing using VWO end
  };

  const handleChangeNumberOfSeats = (number: number) => {
    if (number <= 0) {
      setNumberOfSeats(1);
    } else {
      setNumberOfSeats(number);
    }
  };

  const resultPlan = isTeams ? plan : selectedPlan;

  return (
    <AppearingFromRight
      inProp={isOpen}
      nodeRef={checkoutContentRef}
      onExited={handleResetState}
      width={360}
      enableTabletMode={false}
    >
      <div className={styles.root}>
        {isElementsLoading && <LoadingPanel onClose={onClose} />}

        <PanelHeader onClose={onClose} />

        <div className={styles.mainContainer}>
          {isTeams && (
            <CheckoutSeatsSection
              numberOfSeats={numberOfSeats}
              onChangeNumberOfSeats={handleChangeNumberOfSeats}
            />
          )}
          <SummarySection
            currentPlan={resultPlan}
            isTeams={isTeams}
            isLoading={isPlanLoading}
          />

          <div className={styles.paymentContainer}>
            <PayPalElements
              selectedPlan={selectedPlan}
              onReady={() => setReadyPaypalElements(true)}
              onPaymentProcessing={handlePaymentProcessingChange}
              numberOfSeats={numberOfSeats}
            />

            {stripePromise && (
              <Elements
                stripe={stripePromise}
                options={{
                  mode: 'subscription',
                  appearance: {
                    theme: isDarkTheme ? 'night' : 'stripe',
                    variables: {
                      borderRadius: '12px',
                    },
                  },
                  currency: 'usd',
                  amount:
                    resultPlan?.total_due_amount ||
                    (isTeams
                      ? (selectedPlan?.total_due_amount || 1) * numberOfSeats
                      : selectedPlan?.total_due_amount),
                }}
              >
                <StripeElements
                  selectedPlan={selectedPlan}
                  onReady={() => setReadyStripeElements(true)}
                  onPaymentProcessing={handlePaymentProcessingChange}
                  numberOfSeats={numberOfSeats}
                />
              </Elements>
            )}
          </div>
        </div>
      </div>
    </AppearingFromRight>
  );
};

import { useResendVerificationEmailMutation } from 'src/store/services';
import { toast } from 'react-toastify';
import { TokenApiHttpError } from 'src/types';
import styles from './EmailVerificationBlock.module.scss';
import { COMMON_ERROR_TEXT } from 'src/constants';

type EmailVerificationBlockProps = {
  email?: string;
};

export const EmailVerificationBlock = ({
  email,
}: EmailVerificationBlockProps) => {
  const [resendVerificationEmail] = useResendVerificationEmailMutation();

  const handleResendLink = async () => {
    try {
      if (email) {
        await resendVerificationEmail(email).unwrap();
        toast('Verification email was resent');
      }
    } catch (error) {
      toast.error(
        (error as TokenApiHttpError)?.data?.detail
          ? (error as TokenApiHttpError).data.detail
          : COMMON_ERROR_TEXT,
      );
    }
  };

  return (
    <div className={styles.verifiedBlock}>
      <h2>Verify your email</h2>
      <p className={styles.verifiedText}>
        A verification email has been sent to your email address. The
        verification email will expire after 24 hours.
      </p>
      <p className={styles.verifiedText}>
        Didn’t receive an email?&nbsp;
        <button className={styles.verifiedButton} onClick={handleResendLink}>
          Resend the verification email
        </button>
        <p className={styles.spamNote}>Please check your spam folder</p>
      </p>
    </div>
  );
};

import { useState, ReactNode, useMemo, useEffect } from 'react';
import { Slider } from 'src/components/Slider';
import styles from './ResolutionSlider.module.scss';
import {
  ImageGenSettingsDimensionGroups,
  ImageGenSettingsDimensionOptionTypes,
  ImageGenSettingsDimensionResolutionTypes,
  ImageGenSettingsResolutions,
  ImageGenSettingsSelectedDimension,
} from 'src/types';
import { ImageGenSettingsDimensionResolution } from 'src/types/models/ImageGenSettingsDimensionResolution';
import classNames from 'classnames';
import { LabelPro } from 'src/components/LabelPro';
import { LabelUltra } from 'src/components/LabelUltra';

const STEP = 1;
const DEFAULT_STEP_VALUE = 0;

interface ResolutionSliderProps {
  selectedData: ImageGenSettingsSelectedDimension;
  dimensionGroups: ImageGenSettingsDimensionGroups;
  onChange: (value: ImageGenSettingsDimensionResolutionTypes) => void;
}

export const ResolutionSlider = ({
  selectedData,
  dimensionGroups,
  onChange,
}: ResolutionSliderProps) => {
  const { option, group, resolution } = selectedData;
  const currentGroup = dimensionGroups[group] || {};

  const { resolutions } =
    (
      currentGroup as unknown as Record<
        ImageGenSettingsDimensionOptionTypes,
        ImageGenSettingsResolutions
      >
    )[option] || {};

  const preparedResolutions = useMemo(
    () =>
      Object.entries(resolutions || {})
        .map(([key, data]) => ({
          ...data,
          resolutionValue: key,
        }))
        .sort((a, b) => a.width - b.width)
        .reduce(
          (acc, item, index) => {
            acc[index] = { ...item, sliderValue: index };
            return acc;
          },
          {} as Record<
            number,
            ImageGenSettingsDimensionResolution & {
              resolutionValue: string;
              sliderValue: number;
            }
          >,
        ),
    [resolutions],
  );

  const resolutionLabels: Record<number, ReactNode> = useMemo(
    () =>
      Object.values(preparedResolutions).reduce(
        (
          acc,
          { sliderValue, name, width, height, resolutionValue, is_capable },
        ) => {
          acc[sliderValue] = (
            <label
              key={sliderValue}
              className={classNames(styles.label, {
                [styles.disabled]: !is_capable,
                [styles.active]: resolutionValue === resolution,
              })}
              data-e2e={`image-resolution-slider-label-${resolutionValue}`}
            >
              <span className={styles.title}>{name}</span>
              <span className={styles.subtitle}>{`${width}x${height}`}</span>
              {!is_capable &&
                (resolutionValue ===
                ImageGenSettingsDimensionResolutionTypes._1080P ? (
                  <LabelPro />
                ) : (
                  <LabelUltra />
                ))}
            </label>
          );
          return acc;
        },
        {} as Record<number, ReactNode>,
      ),
    [preparedResolutions, resolution],
  );

  const [currentResolution, setCurrentResolution] = useState<number | number[]>(
    DEFAULT_STEP_VALUE,
  );

  useEffect(() => {
    const newSliderValue = Object.values(preparedResolutions).find(
      (item) => item.resolutionValue === resolution,
    )?.sliderValue;
    setCurrentResolution(newSliderValue || 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolution]);

  // TODO(olha): use the value from BE
  const getCapability = (value: number) => {
    return preparedResolutions[value].is_capable;
  };

  const handleChange = (value: number | number[]) => {
    if (typeof value !== 'number') {
      return;
    }

    const isCapable = getCapability(value);

    if (isCapable) {
      setCurrentResolution(value);
    }
  };

  const handleChangeComplete = (value: number | number[]) => {
    if (typeof value !== 'number') {
      return;
    }

    const isCapable = getCapability(value);

    if (!isCapable) {
      return;
    }

    const resolutionValue = preparedResolutions[value].resolutionValue;
    // trigger onChange only if the value was changed
    if (resolutionValue !== resolution) {
      onChange(resolutionValue as ImageGenSettingsDimensionResolutionTypes);
    }
  };

  return (
    <div className={styles.root} data-e2e="image-resolution-slider">
      <Slider
        step={STEP}
        max={Object.keys(preparedResolutions).length - 1}
        min={DEFAULT_STEP_VALUE}
        marks={resolutionLabels}
        value={currentResolution}
        onChange={handleChange}
        onChangeComplete={handleChangeComplete}
      />
    </div>
  );
};

import { createApi } from '@reduxjs/toolkit/query/react';
import { baseContentQuery } from './config';
import { ApiContext } from 'src/types';
import {
  X_NINJA_CONVERSATION_ID_HEADER,
  X_NINJA_USER_ID_HEADER,
} from 'src/constants/api';

export enum ContentTags {
  UploadFileURL = 'UploadFileURL',
  GetFileURL = 'GetFileURL',
}

export const contentApi = createApi({
  reducerPath: 'contentApi',
  tagTypes: [ContentTags.UploadFileURL, ContentTags.GetFileURL],
  baseQuery: baseContentQuery,
  endpoints: (builder) => ({
    getURLForFileUpload: builder.mutation<
      {
        filename: string;
        form_data: {
          url: string;
          fields: { [key: string]: string };
        };
        original_filename: string;
      },
      {
        user_id: string;
        file_name: string;
        content_type: string;
        apiContext: ApiContext;
      }
    >({
      query: ({ user_id, file_name, content_type, apiContext }) => ({
        url: `/users/${user_id}/upload-form`,
        method: 'POST',
        body: {
          filename: file_name,
          content_type: content_type,
        },
        headers: {
          ...(apiContext?.user_id && {
            [X_NINJA_USER_ID_HEADER]: apiContext.user_id,
          }),
          ...(apiContext?.conversation_id && {
            [X_NINJA_CONVERSATION_ID_HEADER]: apiContext.conversation_id,
          }),
        },
      }),
    }),
    getFileURL: builder.query<
      {
        url: string;
      },
      { user_id: string; file_name: string }
    >({
      query: ({ user_id, file_name }) => ({
        url: `/users/${user_id}/content-url/${file_name}`,
        params: {
          format: 'original',
        },
      }),
      providesTags: () => [ContentTags.GetFileURL],
    }),
    deleteFile: builder.mutation<void, { user_id: string; file_name: string }>({
      query: ({ user_id, file_name }) => ({
        url: `/users/${user_id}/content/${file_name}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetURLForFileUploadMutation,
  useDeleteFileMutation,
  useGetFileURLQuery,
} = contentApi;

import { SVGProps } from 'react';

export const NinjaMainIcon = ({
  width = 24,
  height = 24,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M20.0001 10.0004C15.8418 10.0004 12.1293 11.1671 9.68345 13.4921C9.57511 13.3504 9.48345 13.2254 9.42511 13.1337C9.05428 12.5462 8.62511 11.8587 8.31261 11.1629C8.25428 11.0337 8.20428 10.9129 8.16261 10.8004C8.43345 10.8671 8.75428 10.9129 9.10428 10.8921C9.43761 10.8712 9.79178 10.7587 10.1126 10.5171C10.4168 10.2879 10.5959 10.0129 10.6959 9.79622C10.8834 9.39622 10.9001 9.00039 10.9001 8.75872C10.9001 8.23372 10.7709 7.61706 10.6251 7.05872C10.3251 5.90039 9.73345 1.71706 9.37095 0.837891L6.72928 4.91289C7.02511 5.63372 7.40845 6.66289 7.67095 7.57539C7.60845 7.56289 7.54595 7.55039 7.47928 7.54622C7.23345 7.51706 6.87095 7.50872 6.46678 7.65456C5.48345 8.00872 5.16261 8.87956 5.06261 9.42122C4.85845 10.5337 5.24178 11.6546 5.61261 12.4796C6.00011 13.3421 6.39178 13.6754 6.39178 13.6754L7.46261 14.9129L8.10845 15.4879C7.59595 16.3379 7.20011 17.2837 6.96261 18.3337C6.41678 18.0546 5.77511 17.7254 5.21678 17.3587C5.11261 17.2921 5.01678 17.2254 4.92928 17.1587C5.15011 17.1171 5.40011 17.0462 5.62928 16.9171C5.85011 16.7962 6.04178 16.6046 6.14595 16.3379C6.24595 16.0837 6.23345 15.8421 6.19595 15.6587C6.12928 15.3254 5.94595 15.0504 5.82511 14.8879C5.56261 14.5337 5.17095 14.1546 4.79595 13.8254C4.01678 13.1379 1.53345 10.4879 0.845947 10.0087L1.02095 13.6254C1.58345 14.0212 2.35845 14.5921 2.99178 15.1296C2.94178 15.1421 2.89178 15.1546 2.84178 15.1712C2.65428 15.2296 2.40011 15.3421 2.19178 15.5712C1.68345 16.1296 1.89178 16.8254 2.08761 17.2212C2.49595 18.0421 3.31678 18.6796 3.98345 19.1171C4.67928 19.5754 5.11678 19.6754 5.11678 19.6754L6.47511 20.1671L6.71678 20.2254C6.70428 20.4671 6.67928 20.7046 6.67928 20.9587C6.67928 28.2629 12.6501 35.8379 20.0126 35.8379C27.3751 35.8379 33.3459 28.2629 33.3459 20.9587C33.3459 13.6546 27.3751 10.0046 20.0126 10.0046L20.0001 10.0004Z"
        fill="url(#paint0_linear_4567_7671)"
      />
      <path
        opacity="0.12"
        d="M10.7333 29.583C9.0625 27.2122 8.0625 24.3205 8.0625 21.4747C8.0625 14.8747 13.4375 11.5788 20.0667 11.5788C24.1958 11.5788 27.8417 12.858 30 15.4205C27.95 12.3622 24.0167 10.833 19.5042 10.833C12.875 10.833 7.5 14.1288 7.5 20.7288C7.5 23.883 8.72917 27.0913 10.7333 29.583Z"
        fill="#E8EAEA"
      />
      <path
        d="M31.0413 21.3123C31.0413 26.0873 25.6163 30.8331 19.9997 30.8331C14.383 30.8331 8.95801 26.0873 8.95801 21.3123C8.95801 16.5373 14.383 17.5998 19.9997 17.5998C25.6163 17.5998 31.0413 16.5415 31.0413 21.3123Z"
        fill="url(#paint1_linear_4567_7671)"
        fillOpacity="0.4"
      />
      <path
        d="M29.0333 21.6C29.5 22.7917 28.5167 24.2125 26.8375 24.7708C25.1583 25.3333 23.4167 24.8208 22.9458 23.6292C22.4792 22.4375 23.4208 21.9458 25.1 21.3833C26.7792 20.8208 28.5625 20.4083 29.0333 21.6ZM10.9667 21.6C10.5 22.7917 11.4833 24.2125 13.1625 24.7708C14.8417 25.3333 16.5833 24.8208 17.0542 23.6292C17.5208 22.4375 16.5792 21.9458 14.9 21.3833C13.2208 20.8208 11.4375 20.4083 10.9667 21.6Z"
        fill="url(#paint2_linear_4567_7671)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4567_7671"
          x1="30.3793"
          y1="32.2754"
          x2="4.72511"
          y2="6.62122"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#11181B" />
          <stop offset="0.2" stopColor="#1E282D" />
          <stop offset="0.5" stopColor="#343E42" />
          <stop offset="0.8" stopColor="#8E9496" />
          <stop offset="1" stopColor="#E8EAEA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4567_7671"
          x1="19.1163"
          y1="17.1581"
          x2="21.958"
          y2="30.7331"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.22" stopColor="#7E9EA9" />
          <stop offset="1" stopColor="#919EA0" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4567_7671"
          x1="11.011"
          y1="21.1537"
          x2="28.8114"
          y2="24.2924"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0CC5FF" />
          <stop offset="1" stopColor="#0A74FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

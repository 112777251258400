import { useContext } from 'react';
import cn from 'classnames';
import {
  ArrowLeft,
  ArrowUUpLeft,
  ArrowUUpRight,
  CircleDashed,
  MagicWand,
  X,
} from '@phosphor-icons/react';
import styles from './Toolbar.module.scss';
import { SVG_SIZE_M, SVG_SIZE_L } from 'src/constants';
import { AiGenerationStatus, ImageGenRenderData } from 'src/types';
import { Icon } from 'src/components/Icon';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import {
  useImageMaskingPanelActions,
  useImageMaskingBrushSizeToolVisible,
  useCurrentImagesList,
  useSelectedImageIndex,
  useAttachedImageTooltipState,
  useAttachedImageTooltipActions,
} from 'src/hooks/imageMaskingPanelHooks';
import { DownloadButton } from 'src/v2/components/DownloadButton';
import { BrushSizeTool } from '../BrushSizeTool';
import { useRightSidePanelActions } from 'src/hooks';

interface ImageEditorToolbarProps {
  brushSize: number;
  setBrushSize: (brushSize: number) => void;
  currentMaskingImageData: ImageGenRenderData | null;
  sendReceivedFile: (currentMaskingImageData: string) => Promise<void>;
  handleUndo: () => void;
  handleRedo: () => void;
  handleDeleteSelections: () => void;
  isRedoButtonDisabled: boolean;
  isUndoButtonDisabled: boolean;
  isEditingMode: boolean;
}

export const ImageEditorToolbar = ({
  brushSize,
  setBrushSize,
  currentMaskingImageData,
  sendReceivedFile,
  handleDeleteSelections,
  handleUndo,
  handleRedo,
  isRedoButtonDisabled,
  isUndoButtonDisabled,
  isEditingMode,
}: ImageEditorToolbarProps) => {
  const {
    onChangeImageMaskingRightPanelData,
    resetImageMaskingPanelInfo,
    toggleVisibleBrushSizePanel,
    setEditingMode,
  } = useImageMaskingPanelActions();
  const { onClearAttachments } = useContext(ThreadInputBoxContext);
  const { isBrushSizeToolVisible } = useImageMaskingBrushSizeToolVisible();
  const { toggleRightSidePanelExpanded } = useRightSidePanelActions();
  const { isAttachedImageEditingMode } = useAttachedImageTooltipState();
  const {
    onToggleImageEditTooltipVisible,
    onChangeIsAttachedImageEditingMode,
  } = useAttachedImageTooltipActions();
  const { selectedImageIndex } = useSelectedImageIndex();
  const { currentImagesList } = useCurrentImagesList();

  const handleEdit = () => {
    toggleVisibleBrushSizePanel(!isBrushSizeToolVisible);
  };

  const handleCloseEditingModal = () => {
    resetImageMaskingPanelInfo();
    onChangeImageMaskingRightPanelData(null);
    toggleRightSidePanelExpanded(false);

    if (!isAttachedImageEditingMode) {
      onClearAttachments();
    } else {
      onToggleImageEditTooltipVisible(true);
      onChangeIsAttachedImageEditingMode(false);
    }
  };

  const handleEditClick = async () => {
    if (currentMaskingImageData?.url) {
      setEditingMode(true);

      if (!isAttachedImageEditingMode) {
        await sendReceivedFile(currentMaskingImageData?.url);
      }
    }
  };

  const handleBackClick = () => {
    setEditingMode(false);
    handleDeleteSelections();

    if (!isAttachedImageEditingMode) {
      onClearAttachments();
    }
  };

  const withControlButtons =
    !isEditingMode &&
    !currentMaskingImageData?.isDisabled &&
    !currentMaskingImageData?.withWatermark &&
    (selectedImageIndex === null ||
      currentImagesList[selectedImageIndex]?.status ===
        AiGenerationStatus.SUCCESS);

  return (
    <div className={styles.root}>
      {!isEditingMode ? (
        <div className={styles.controls}>
          <div className={styles.modelName}>
            {currentMaskingImageData?.icon && (
              <span className={styles.icon}>
                <Icon size={SVG_SIZE_M} type={currentMaskingImageData.icon} />
              </span>
            )}
            <span>{currentMaskingImageData?.title}</span>
          </div>
          <div className={styles.controlsButtons}>
            {withControlButtons && (
              <button
                onClick={handleEditClick}
                className={cn(
                  styles.inActive,
                  styles.controlBtn,
                  styles.editBtn,
                )}
              >
                <MagicWand size={SVG_SIZE_M} />
                <span>Edit</span>
              </button>
            )}
            {currentMaskingImageData?.url && withControlButtons && (
              <DownloadButton
                url={currentMaskingImageData?.url}
                className={cn(
                  styles.rounded,
                  styles.inActive,
                  styles.controlBtn,
                )}
              />
            )}
            <button
              onClick={handleCloseEditingModal}
              className={cn(styles.rounded, styles.inActive, styles.controlBtn)}
            >
              <X size={SVG_SIZE_L} />
            </button>
          </div>
        </div>
      ) : (
        <div className={styles.controlsButtonsWrapper}>
          <div className={styles.toolbarActions}>
            <button
              onClick={handleBackClick}
              className={cn(styles.controlBtn, styles.inActive)}
            >
              <ArrowLeft size={SVG_SIZE_M} />
            </button>
            <button
              onClick={handleUndo}
              disabled={isUndoButtonDisabled}
              className={styles.controlBtn}
            >
              <ArrowUUpLeft size={SVG_SIZE_M} />
            </button>
            <button
              onClick={handleRedo}
              disabled={isRedoButtonDisabled}
              className={styles.controlBtn}
            >
              <ArrowUUpRight size={SVG_SIZE_M} />
            </button>
            <button onClick={handleEdit} className={styles.controlBtn}>
              <CircleDashed
                size={SVG_SIZE_M}
                className={cn(styles.brush, {
                  [styles.active]: isBrushSizeToolVisible,
                })}
              />
            </button>
          </div>
          <div className={styles.brushSizeTool}>
            {isBrushSizeToolVisible && (
              <BrushSizeTool
                brushSize={brushSize}
                setBrushSize={setBrushSize}
                isBrushSizeToolVisible={isBrushSizeToolVisible}
                handleDeleteSelections={handleDeleteSelections}
                isRedoButtonDisabled={isRedoButtonDisabled}
                isUndoButtonDisabled={isUndoButtonDisabled}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

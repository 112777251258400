import { useContext, useRef, useState, useCallback } from 'react';
import styles from './TranslatePromptButton.module.scss';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { ArrowUUpLeft, Translate } from '@phosphor-icons/react';
import { PromptLoadingReason, SVG_SIZE_M } from 'src/constants';
import {
  useBreakpoint,
  useSession,
  useOutsideClick,
  useConversationParams,
} from 'src/hooks';
import log from 'loglevel';
import { getBaseHeaders } from 'src/store/services/config';
import { bffTasksBaseUrl } from 'src/store/constants';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { BottomDrawer } from 'src/components/BottomDrawer';
import { useGetTranslationLanguagesJSONQuery } from 'src/store/services';
import { IconButton } from 'src/v2/components/IconButton';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { TranslatePromptLanguageSelector } from './components/TranslatePromptLanguageSelector/TranslatePromptLanguageSelector';
import { Spinner } from 'src/components/Loading';
import { useRightSidePanelState } from 'src/hooks';

type TranslatePromptButtonProps = {
  isDisabled?: boolean;
};

const textDecoder = new TextDecoder();
const TOOLTIP_ANCHOR_ID = 'prompt-translate-trigger';

export const TranslatePromptButton = ({
  isDisabled = false,
}: TranslatePromptButtonProps) => {
  const { threadInputBoxRef } = useContext(ForwardRefContext);

  const { shouldCompressThread } = useRightSidePanelState();
  const { appUser } = useSession();
  const { isMobile, isMobileOrTablet } = useBreakpoint();
  const { isLandingPage } = useConversationParams();

  const containerRef = useRef(null);

  const {
    threadInputBoxValue,
    setTemporaryInputValue,
    setPromptLoading,
    setPromptModificationReason,
    temporaryInputValue,
    setThreadInputBoxValue,
    promptLoading,
    promptModificationReason,
  } = useContext(ThreadInputBoxContext);

  const undoTriggerRef = useRef(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [translatedText, setTranslatedText] = useState('');
  const defaultLanguage = 'en';
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);
  const lastUsedLanguage = useRef(defaultLanguage);

  const { data: languagesData = {}, isLoading } =
    useGetTranslationLanguagesJSONQuery();

  const handleReturnInputToFocus = useCallback(() => {
    threadInputBoxRef?.current?.focus();
  }, [threadInputBoxRef]);

  const handleOutsideClick = () => {
    if (isMobileOrTablet || shouldCompressThread) {
      return;
    }
    setIsModalOpen(false);
  };
  useOutsideClick(containerRef, handleOutsideClick);

  const updatePrompt = useCallback(
    async (response: Response, stream: boolean) => {
      let value = '';
      if (stream) {
        if (response.body) {
          const reader = response.body.getReader();
          while (true) {
            const { done, value: chunk } = await reader.read();
            if (done) break;
            value += textDecoder.decode(chunk, { stream: true });
            if (undoTriggerRef.current) {
              undoTriggerRef.current = false;
              return;
            }
            setThreadInputBoxValue(value);
          }
          setTranslatedText(value);
        }
      } else {
        const result = await response.json();
        setThreadInputBoxValue(result.translated_prompt);
        setTranslatedText(result.translated_prompt);
      }
    },
    [setThreadInputBoxValue],
  );

  const handleSendPrompt = useCallback(
    async (language = selectedLanguage) => {
      try {
        setPromptLoading(true);
        setPromptModificationReason(PromptLoadingReason.TRANSLATING);
        setTemporaryInputValue(threadInputBoxValue);
        setThreadInputBoxValue('');

        await fetch(
          `${bffTasksBaseUrl}/users/${appUser.user_id}/prompts/translate`,
          {
            method: 'POST',
            headers: await getBaseHeaders(
              new Headers({ 'Content-Type': 'application/json' }),
            ),
            body: JSON.stringify({
              prompt: threadInputBoxValue,
              target_language: language,
              stream: true,
            }),
          },
        )
          .then((response) => updatePrompt(response, true))
          .catch((err) => log.error(err));

        setPromptLoading(false);
      } catch (e) {
        setPromptLoading(false);
        setThreadInputBoxValue(temporaryInputValue);
        setTemporaryInputValue('');
        log.error(e);
      }
      handleReturnInputToFocus();
    },
    [
      appUser.user_id,
      selectedLanguage,
      threadInputBoxValue,
      setPromptLoading,
      setPromptModificationReason,
      setTemporaryInputValue,
      setThreadInputBoxValue,
      temporaryInputValue,
      handleReturnInputToFocus,
      updatePrompt,
    ],
  );

  const handleUndo = useCallback(() => {
    if (promptLoading) undoTriggerRef.current = true;
    setPromptLoading(false);
    setThreadInputBoxValue(temporaryInputValue);
    setTemporaryInputValue('');
    handleReturnInputToFocus();
  }, [
    promptLoading,
    temporaryInputValue,
    setPromptLoading,
    setThreadInputBoxValue,
    setTemporaryInputValue,
    handleReturnInputToFocus,
  ]);

  const handleLanguageSelect = useCallback((id: string) => {
    setSelectedLanguage(id);
    lastUsedLanguage.current = id;
  }, []);

  const renderBottomDrawer = () => (
    <BottomDrawer
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      className={styles.drawer}
    >
      <TranslatePromptLanguageSelector
        isLoading={isLoading}
        languages={languagesData}
        selectedLanguage={selectedLanguage}
        defaultLanguage={defaultLanguage}
        handleLanguageSelect={handleLanguageSelect}
        handleSendPrompt={handleSendPrompt}
        setIsModalOpen={setIsModalOpen}
      />
    </BottomDrawer>
  );

  const renderTranslationModal = () => (
    <ReactTooltip
      isOpen={isModalOpen}
      place={isLandingPage ? 'bottom-end' : 'left'}
      clickable
      className={styles.tooltip}
      noArrow
      anchorSelect={`#${TOOLTIP_ANCHOR_ID}`}
      openOnClick
      offset={isLandingPage ? -250 : -50}
      opacity={1}
    >
      <TranslatePromptLanguageSelector
        isLoading={isLoading}
        languages={languagesData}
        selectedLanguage={selectedLanguage}
        defaultLanguage={defaultLanguage}
        handleLanguageSelect={handleLanguageSelect}
        handleSendPrompt={handleSendPrompt}
        setIsModalOpen={setIsModalOpen}
      />
    </ReactTooltip>
  );

  const renderLanguageList = () =>
    isMobileOrTablet || shouldCompressThread
      ? renderBottomDrawer()
      : renderTranslationModal();

  if (
    promptModificationReason === PromptLoadingReason.TRANSLATING &&
    promptLoading
  ) {
    return (
      <IconButton
        type="button"
        onClick={handleUndo}
        customContent={<Spinner size={SVG_SIZE_M} inline />}
        size={SVG_SIZE_M}
        isMobile={isMobile || shouldCompressThread}
        label="Cancel"
      />
    );
  }

  if (
    promptModificationReason === PromptLoadingReason.TRANSLATING &&
    !!temporaryInputValue &&
    threadInputBoxValue.trim() === translatedText.trim()
  ) {
    return (
      <IconButton
        type="button"
        onClick={handleUndo}
        customContent={<ArrowUUpLeft size={SVG_SIZE_M} />}
        size={SVG_SIZE_M}
        isMobile={isMobile || shouldCompressThread}
        label="Undo"
      />
    );
  }

  return (
    <div className={styles.root} ref={containerRef}>
      <IconButton
        id={TOOLTIP_ANCHOR_ID}
        className={styles.translatePromptButton}
        icon={Translate}
        label="Translate"
        size={SVG_SIZE_M}
        onClick={() => {
          setSelectedLanguage(lastUsedLanguage.current);
          setIsModalOpen(true);
        }}
        disabled={isDisabled || !threadInputBoxValue.trim()}
        isMobile={isMobile || shouldCompressThread}
      />
      {renderLanguageList()}
    </div>
  );
};

import { RefObject, useCallback, useState } from 'react';

export const useDrawing = (
  maskCanvasRef: RefObject<HTMLCanvasElement>,
  mainCanvasRef: RefObject<HTMLCanvasElement>,
  scale: number,
  brushSize: number,
  isEditingMode: boolean,
) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [lastX, setLastX] = useState(0);
  const [lastY, setLastY] = useState(0);
  const [hasCanvasChanged, setHasCanvasChanged] = useState(false);

  const startDrawing = useCallback(
    (x: number, y: number) => {
      if (!isEditingMode) {
        return;
      }

      setIsDrawing(true);
      setLastX(x);
      setLastY(y);
      setHasCanvasChanged(false);
    },
    [isEditingMode],
  );

  const draw = useCallback(
    (x: number, y: number) => {
      if (
        !isDrawing ||
        !isEditingMode ||
        !mainCanvasRef.current ||
        !maskCanvasRef.current
      ) {
        return;
      }

      const ctx = mainCanvasRef.current.getContext('2d', {
        willReadFrequently: true,
      });
      const maskCtx = maskCanvasRef.current.getContext('2d', {
        willReadFrequently: true,
      });

      if (!ctx || !maskCtx) {
        return;
      }

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(lastX, lastY);
      ctx.lineTo(x, y);
      ctx.strokeStyle = 'white';
      ctx.lineWidth = brushSize;
      ctx.lineCap = 'round';
      ctx.lineJoin = 'round';
      ctx.stroke();
      ctx.restore();

      maskCtx.save();
      maskCtx.beginPath();
      maskCtx.moveTo(lastX / scale, lastY / scale);
      maskCtx.lineTo(x / scale, y / scale);
      maskCtx.strokeStyle = 'white';
      maskCtx.lineWidth = brushSize / scale;
      maskCtx.lineCap = 'round';
      maskCtx.lineJoin = 'round';
      maskCtx.stroke();
      maskCtx.restore();

      setLastX(x);
      setLastY(y);
      setHasCanvasChanged(true);
    },
    [
      brushSize,
      isDrawing,
      isEditingMode,
      lastX,
      lastY,
      mainCanvasRef,
      maskCanvasRef,
      scale,
      setHasCanvasChanged,
    ],
  );

  return {
    startDrawing,
    draw,
    isDrawing,
    setIsDrawing,
    hasCanvasChanged,
    setLastX,
    setLastY,
    setHasCanvasChanged,
  };
};

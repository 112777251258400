import { useState, useRef, useMemo } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import {
  useAttachedImageTooltipState,
  useConversationParams,
  useSession,
} from 'src/hooks';
import { RandomPillars } from 'src/pages/ManageTasksChatPage/components/SkillPillars';
import { ThreadMessageList } from '../ThreadMessageList';
import { ThreadInputBox } from 'src/v2/components/ThreadInputBox';
import { GreetingTitle } from 'src/pages/ManageTasksChatPage/components/GreetingTitle';
import { ANIMATION_TIMEOUT } from 'src/constants';
import { ScrollToButtonMessageListButton } from 'src/components/ScrollToButtonMessageListButton';
import { ThreadViewHooks } from './ThreadViewHooks';
import { useRightSidePanelState, useIsOpenModalImagesList } from 'src/hooks';
interface ThreadViewProps {
  startSpeechRecognizing: () => void;
  stopSpeechRecognizing: () => void;
  unMuteMicrophone: () => void;
}

export const ThreadView = ({
  startSpeechRecognizing,
  stopSpeechRecognizing,
  unMuteMicrophone,
}: ThreadViewProps) => {
  const { isLandingPage } = useConversationParams();
  const { shouldCompressThread } = useRightSidePanelState();
  const { isOpenModalImagesList } = useIsOpenModalImagesList();
  const { isAttachedImageEditingMode } = useAttachedImageTooltipState();
  const { isEnterpriseTier } = useSession();

  const containerRef = useRef<HTMLDivElement>(null);
  const inputBoxRef = useRef<HTMLDivElement>(null);

  const [animationInProgress, setAnimationInProgress] = useState(false);

  const disclaimer = useMemo(() => {
    if (isEnterpriseTier) {
      return 'Please do not post or share any sensitive company information';
    }
    return 'Ninja can make mistakes. Please double check responses.';
  }, [isEnterpriseTier]);

  const handleAnimationStart = () => {
    setAnimationInProgress(true);
  };

  const handleAnimationEnd = () => {
    setAnimationInProgress(false);
  };

  return (
    <div className="nj-thread-view" ref={containerRef}>
      {!isLandingPage && (
        <div className="nj-thread-view--chat">
          <ThreadMessageList />
        </div>
      )}

      <CSSTransition
        in={!isLandingPage || isAttachedImageEditingMode}
        timeout={ANIMATION_TIMEOUT}
        classNames="nj-animate-input-box-transition"
        onEnter={handleAnimationStart}
        onEntered={handleAnimationEnd}
        nodeRef={inputBoxRef}
      >
        <div
          className={classNames('nj-thread-view--landing-page-wrapper', {
            'align-bottom': !isLandingPage && !animationInProgress,
            'z-index-high': shouldCompressThread && !isOpenModalImagesList,
            'align-center': isLandingPage && !isAttachedImageEditingMode,
          })}
          ref={inputBoxRef}
        >
          {isLandingPage && !isAttachedImageEditingMode && <GreetingTitle />}

          <ThreadInputBox
            startSpeechRecognizing={startSpeechRecognizing}
            stopSpeechRecognizing={stopSpeechRecognizing}
            unMuteMicrophone={unMuteMicrophone}
          />

          {isLandingPage && !isAttachedImageEditingMode && <RandomPillars />}
        </div>
      </CSSTransition>

      <p className="nj-thread-view--description">{disclaimer}</p>

      <ScrollToButtonMessageListButton />
      <ThreadViewHooks />
    </div>
  );
};

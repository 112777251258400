import { useMemo, useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { useGetConversationsQuery } from 'src/store/services';
import { useAppSelector, sessionState } from 'src/store';
import { ChatsFilter } from 'src/types';

// TODO(olha): divide into two separate hooks: useConversationsList, useCurrentConversation, useConversationActions
export const useConversations = () => {
  const {
    appUser: { user_id },
  } = useAppSelector(sessionState);

  const { chatsFilter, pageToken } = useContext(SessionContext);

  const includeArchived = useMemo(
    () => chatsFilter === ChatsFilter.ALL_AND_ARCHIVED,
    [chatsFilter],
  );

  const {
    data: conversationListData,
    isLoading: isConversationsLoading,
    isFetching: isConversationsFetching,
  } = useGetConversationsQuery(
    {
      user_id,
      pageToken,
      includeArchived,
    },
    { skip: !user_id },
  );

  const hasUnreadMessage = useMemo(
    () =>
      conversationListData?.data.some(
        (item) => !!item.number_tasks_require_attention,
      ),
    [conversationListData],
  );

  return useMemo(
    () => ({
      conversations: conversationListData?.data,
      newPageToken: conversationListData?.page_token || '',
      isConversationsLoading,
      isConversationsFetching,
      hasUnreadMessage,
    }),
    [
      conversationListData,
      isConversationsLoading,
      isConversationsFetching,
      hasUnreadMessage,
    ],
  );
};

import { useMemo, useRef } from 'react';
import { AppearingFromRight } from 'src/animations/AppearingFromRight';
import { RightPanelSections } from 'src/types';
import {
  useRightSidePanelActions,
  useRightSidePanelState,
  useBreakpoint,
} from 'src/hooks';
import { MaskImageEditor } from '../MaskImageEditor';

// TODO(olha): check design for Tablet. Add outside click if needed
export const RightSidePanel = () => {
  const { isRightPanelExpanded, rightPanelSections } = useRightSidePanelState();
  const { resetAndCloseRightSidePanelData } = useRightSidePanelActions();
  const { isTablet, isDesktopLAndUp, isLaptopLAndUp } = useBreakpoint();
  const ref = useRef<HTMLDivElement>(null);

  // TODO(olha): temporary remove it because of console warnings
  // const Component = useMemo(() => {
  //   switch (rightPanelSections) {
  //     case RightPanelSections.IMAGE:
  //       return MaskImageEditor;
  //     default:
  //       return null;
  //   }
  // }, [rightPanelSections]);

  const panelWidth = useMemo(() => {
    switch (rightPanelSections) {
      case RightPanelSections.IMAGE:
        if (isTablet) {
          return 'calc(100vw - 60px)';
        }
        if (isLaptopLAndUp) {
          return 680;
        }
        if (isDesktopLAndUp) {
          return 976;
        }
        return '100vw';
      // default not used for now
      default:
        if (isTablet) {
          return 'calc(100vw - 60px)';
        }
        if (isDesktopLAndUp) {
          return 820;
        }
        return '100vw';
    }
  }, [rightPanelSections, isTablet, isLaptopLAndUp, isDesktopLAndUp]);

  return (
    <AppearingFromRight
      inProp={isRightPanelExpanded}
      nodeRef={ref}
      onExited={resetAndCloseRightSidePanelData}
      width={panelWidth}
    >
      <MaskImageEditor />
    </AppearingFromRight>
  );
};

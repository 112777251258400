import { useMemo } from 'react';
import { useAppSelector, imageMaskingState } from 'src/store';

export const useSelectedImageIndex = () => {
  const { selectedImageIndex } = useAppSelector(imageMaskingState);

  return useMemo(
    () => ({
      selectedImageIndex,
    }),
    [selectedImageIndex],
  );
};

import { useMemo } from 'react';
import { sanitizeIncomingContent } from 'src/utils';
import { useSession } from 'src/hooks';
import { ErrorCode } from 'src/types/models/ErrorCode';
import classNames from 'classnames';
import { TaskSkill } from 'src/types';
import { UpsellMessageBlock } from 'src/components/FlatAppearance/components/UpsellMessageBlock';
import { ThreadErrorVideoMessage } from '../ThreadErrorVideoMessage';

interface ThreadErrorMessageContentProps {
  content: string;
  className?: string;
  errorCode: ErrorCode;
  unsupportedSkills?: Array<TaskSkill>;
}

export const ThreadErrorMessageContent = ({
  content,
  className,
  errorCode,
  unsupportedSkills = [],
}: ThreadErrorMessageContentProps) => {
  const { isOpenTier, isSubscribedTier } = useSession();
  const contentHTML = useMemo(
    () => sanitizeIncomingContent(content),
    [content],
  );

  if (!contentHTML) {
    return null;
  }

  if (unsupportedSkills.includes(TaskSkill.VIDEO)) {
    return <ThreadErrorVideoMessage />;
  }

  const renderErrorMessage = () => {
    switch (errorCode) {
      case ErrorCode.QUOTA_EXCEPTION:
        if (isSubscribedTier) {
          return 'Sorry, message unavailable';
        }

        return 'Sorry you have reached your daily task limit.';

      case ErrorCode.SKILL_NOT_SUPPORTED:
        if (isOpenTier) {
          if (unsupportedSkills?.includes(TaskSkill.SCHEDULING)) {
            return 'Scheduler Agent requires a signed-in account.';
          } else if (unsupportedSkills?.includes(TaskSkill.IMAGE)) {
            return 'Image Agent requires a signed-in account.';
          } else {
            return 'Access more features and higher limits by signing up.';
          }
        }
        return contentHTML;
      default:
        return contentHTML;
    }
  };

  return (
    <div className={classNames('nj-thread-error-message-content', className)}>
      <p>{renderErrorMessage()}</p>
      {!isSubscribedTier && <UpsellMessageBlock />}
    </div>
  );
};

import { useSession } from 'src/hooks';

interface LabelProProps {
  labelType?: 'short' | 'full';
}

export const LabelPro = ({ labelType = 'short' }: LabelProProps) => {
  const { isProTier } = useSession();

  return (
    <label className="nj-label-pro" data-e2e="label-tier-pro">
      {isProTier ? (labelType === 'short' ? 'Pro' : 'Pro subscription') : 'Pro'}
    </label>
  );
};

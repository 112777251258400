import styles from 'src/v2/pages/subscription/components/UpdateMonthlyToAnnuallySection/components/UpdateToAnnuallySummarySection/UpdateToAnnuallySummarySection.module.scss';
import { PaymentPlanInterval, SubscriptionPlan } from 'src/types';
import { getRoundedPriceWithCurrency, uppercaseFirstLetter } from 'src/utils';
import { SummaryRow } from 'src/pages/PaymentBetaPage/components/SummaryRow';
import dayjs from 'dayjs';
import { PAYMENTS_DATE_FORMAT } from 'src/constants';
import { useSession } from 'src/hooks';

type UpdateToAnnuallySummarySectionProps = {
  selectedPlan?: SubscriptionPlan;
};

export const UpdateToAnnuallySummarySection = ({
  selectedPlan,
}: UpdateToAnnuallySummarySectionProps) => {
  const { isTeamsTier } = useSession();
  const subtotalPrice = selectedPlan?.total_due_amount || 0;
  const subscriptionPrice = selectedPlan?.total_recurring_amount || 0;
  const date = dayjs().format(PAYMENTS_DATE_FORMAT);
  const planName = isTeamsTier
    ? selectedPlan?.plan_tier.split('_')[1] || ''
    : selectedPlan?.plan_tier || '';

  return (
    <div className={styles.content}>
      <div className={styles.checkoutResultSection}>
        <div className={styles.checkoutResultSectionRow}>
          <span className={styles.subscriptionRow}>
            Ninja {isTeamsTier ? 'Business ' : ''}
            {uppercaseFirstLetter(planName)} annual subscription
          </span>
          <span>
            {getRoundedPriceWithCurrency(subscriptionPrice)}/
            {selectedPlan?.period === PaymentPlanInterval.MONTHLY ? 'mo' : 'yr'}
          </span>
        </div>
      </div>

      <hr className="divider" />

      <div className={styles.checkoutRowWrapper}>
        <SummaryRow
          label="Subtotal"
          value={getRoundedPriceWithCurrency(subtotalPrice)}
        />

        <SummaryRow label="Tax & fees" value={getRoundedPriceWithCurrency(0)} />

        <div className={styles.checkoutResultSectionRow}>
          <span>Due on {date} (USD)</span>

          <span>{getRoundedPriceWithCurrency(subtotalPrice)}</span>
        </div>
      </div>
    </div>
  );
};

import styles from './InvitationAccessDeniedModal.module.scss';
import { Modal } from 'src/components/Modal';
import { useInvitationAccessDeniedModal, useSession } from 'src/hooks';
import { convertTierName } from 'src/utils/user';
import { capitalizeEachFirstLetter } from 'src/utils';

export const InvitationAccessDeniedModal = () => {
  const { tierId } = useSession();
  const {
    isInvitationAccessDeniedOpen,
    onCloseInvitationDeniedAccessModal,
    invitationAccessDeniedModalTeamName,
  } = useInvitationAccessDeniedModal();
  return (
    <Modal
      open={isInvitationAccessDeniedOpen}
      onClose={onCloseInvitationDeniedAccessModal}
      className={styles.invitationAccessDeniedModal}
      overlayClassName={styles.overlay}
      blockScroll={false}
    >
      <div className={styles.root}>
        <h4 className={styles.title}>Access denied</h4>
        <span className={styles.text}>
          It seems you were removed from the{' '}
          <span className={styles.teamName}>
            {invitationAccessDeniedModalTeamName}
          </span>
          ’s workspace. Please contact your workspace admin to resolve this
          issue.
        </span>
        <div className={styles.buttonRow}>
          <button
            type="button"
            onClick={onCloseInvitationDeniedAccessModal}
            className={styles.button}
          >
            Continue with {capitalizeEachFirstLetter(convertTierName(tierId))}{' '}
            plan
          </button>
        </div>
      </div>
    </Modal>
  );
};

import { createApi } from '@reduxjs/toolkit/query/react';
import { DEFAULT_PAGE_LIMIT } from 'src/constants';
import { SearchResponse } from 'src/types/search/SearchResponse';
import { chatSeekerQuery } from './config';

interface SearchedTasksRequest {
  user_id: string;
  query?: string;
  page?: number;
  skills?: string[];
  states?: string[];
}

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: chatSeekerQuery,
  endpoints: (builder) => ({
    getSearchedTasks: builder.query<SearchResponse, SearchedTasksRequest>({
      query: ({ user_id, query, page, skills, states }) => ({
        url: `search/user/${user_id}`,
        params: {
          query,
          skills,
          states,
          page,
          size: DEFAULT_PAGE_LIMIT,
        },
      }),
      transformResponse: (response: SearchResponse) => {
        return {
          ...response,
          data: [...response.results].sort(
            (a, b) =>
              Date.parse(b.timestamp || '') - Date.parse(a.timestamp || ''),
          ),
        };
      },
      merge: (currentCache, newData) => {
        if (currentCache?.results && newData?.results) {
          currentCache.results.push(...newData.results);
        }
      },
    }),
  }),
});

export const { useGetSearchedTasksQuery } = searchApi;

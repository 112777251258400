import styles from './Components.module.scss';
import React, { useCallback } from 'react';
import { Button } from 'src/v2/commonComponents/Button';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/types';

export const SignUpSection = () => {
  const navigate = useNavigate();
  const signInCallback = useCallback(() => {
    navigate(AppRoutes.LOGIN);
  }, [navigate]);
  return (
    <div className={styles.root}>
      <h2 className={styles.heading}>Create a new account</h2>
      <p>Sign up to start managing your Business plan.</p>
      <Button
        className={styles.loginButton}
        type="button"
        onClick={signInCallback}
      >
        Sign up
      </Button>
    </div>
  );
};

import { MaskSad, SmileySad } from '@phosphor-icons/react';
import styles from './ResearchMediaError.module.scss';
import { AiGenerationStatus } from 'src/types';
import { SVG_SIZE_XL } from 'src/constants';

interface ResearchMediaErrorProps {
  status?: string;
}

export const ResearchMediaError = ({ status }: ResearchMediaErrorProps) => {
  return (
    <div className={styles.root}>
      {status === AiGenerationStatus.CONTENT_POLICY_VIOLATION ? (
        <MaskSad size={SVG_SIZE_XL} />
      ) : (
        <SmileySad size={SVG_SIZE_XL} />
      )}
    </div>
  );
};

import { useMemo } from 'react';
import { useAppSelector, imageMaskingState } from 'src/store';

export const useCurrentImagesList = () => {
  const { currentImagesList } = useAppSelector(imageMaskingState);

  return useMemo(
    () => ({
      currentImagesList,
    }),
    [currentImagesList],
  );
};

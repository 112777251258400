import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { Modal } from 'src/components/Modal';
import styles from './CancelPaypalSubscriptionModal.module.scss';
import { Button } from 'src/v2/commonComponents/Button';
import { NINJA_SUPPORT_EMAIL } from 'src/constants';

export const CancelPaypalSubscriptionModal = () => {
  const {
    isOpenCancelPaypalSubscriptionModal,
    onToggleVisibleCancelPaypalSubscriptionModal,
  } = useContext(SessionContext);

  const handleCloseModal = () => {
    onToggleVisibleCancelPaypalSubscriptionModal(false);
  };

  return (
    <Modal
      open={isOpenCancelPaypalSubscriptionModal}
      onClose={handleCloseModal}
      className={styles.cancelPaypalModal}
      overlayClassName={styles.overlay}
      blockScroll={false}
    >
      <div className={styles.root}>
        <h4 className={styles.title}>Cancel subscription</h4>
        <span className={styles.text}>
          Please contact Ninja at{' '}
          <a className={styles.link} href={`mailto:${NINJA_SUPPORT_EMAIL}`}>
            {NINJA_SUPPORT_EMAIL}
          </a>{' '}
          to cancel your subscription.
        </span>
        <div className={styles.buttonRow}>
          <Button onClick={handleCloseModal}>Close</Button>
        </div>
      </div>
    </Modal>
  );
};

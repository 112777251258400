import { useMemo } from 'react';
import { RightPanelSections } from 'src/types';
import { useAppSelector, interfaceControlState } from 'src/store';

// TODO(olha): rename to useRightPanelState
export const useRightSidePanelState = () => {
  const { isRightPanelExpanded, rightPanelSections } = useAppSelector(
    interfaceControlState,
  );

  const shouldCompressThread =
    isRightPanelExpanded && rightPanelSections === RightPanelSections.IMAGE;

  return useMemo(
    () => ({
      shouldCompressThread,
      isRightPanelExpanded,
      rightPanelSections,
    }),
    [shouldCompressThread, isRightPanelExpanded, rightPanelSections],
  );
};

import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import {
  useBreakpoint,
  useCurrentImagesList,
  useSelectedImageIndex,
} from 'src/hooks';
import { SVG_SIZE_L, SVG_SIZE_XL } from 'src/constants';
import { MobileListButton } from 'src/components/ModalWithGallery/components/MobileListButton';
import styles from './NavigationButtons.module.scss';

interface NavigationButtonsProps {
  handleSliderNavigate: (index: number) => void;
  handleToggleModalImagesList: () => void;
}

export const NavigationButtons = ({
  handleSliderNavigate,
  handleToggleModalImagesList,
}: NavigationButtonsProps) => {
  const { isMobileOrTablet } = useBreakpoint();
  const { currentImagesList } = useCurrentImagesList();
  const { selectedImageIndex } = useSelectedImageIndex();

  return (
    <>
      <button
        className={styles.swiperPrevButton}
        title="Press Left"
        disabled={selectedImageIndex === 0}
        onClick={(e) => {
          e.stopPropagation();
          handleSliderNavigate(selectedImageIndex - 1);
        }}
      >
        <CaretLeft size={isMobileOrTablet ? SVG_SIZE_L : SVG_SIZE_XL} />
      </button>

      {isMobileOrTablet && (
        <MobileListButton onClick={handleToggleModalImagesList} />
      )}

      <button
        className={styles.swiperNextButton}
        title="Press Right"
        disabled={selectedImageIndex + 1 === currentImagesList.length}
        onClick={(e) => {
          e.stopPropagation();
          handleSliderNavigate(selectedImageIndex + 1);
        }}
      >
        <CaretRight size={isMobileOrTablet ? SVG_SIZE_L : SVG_SIZE_XL} />
      </button>
    </>
  );
};

import { AppRoutes, GTMEvent, TeamsTabs } from 'src/types';
import { sendGTMEvent } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import {
  useRequestUpgradeModal,
  useSession,
  useSubscriptionInfo,
  useTeamInfo,
} from 'src/hooks';
import { toast } from 'react-toastify';
import {
  APPLE_PAYMENT_UPGRADE_ERROR_TEXT,
  SUBSCRIPTION_PENDING_CHANGES_NOTIFICATION_MESSAGE,
} from 'src/constants';
import classNames from 'classnames';

type UpgradeButtonProps = {
  onClick?: () => void;
  title?: string;
  dataGTM: string;
  className?: string;
};

export const UpgradeButton = ({
  onClick,
  title = 'Upgrade',
  dataGTM,
  className,
}: UpgradeButtonProps) => {
  const { isTeamsTier } = useSession();
  const navigate = useNavigate();
  const { subscriptionInfo, isSubscriptionInfoLoading, isAppleOrAmpPayment } =
    useSubscriptionInfo();
  const { onOpenRequestUpgradeModal } = useRequestUpgradeModal();
  const { isOwner } = useTeamInfo();

  const handleUpgradeClick = () => {
    if (
      !!subscriptionInfo?.is_cancelled ||
      !!subscriptionInfo?.has_scheduled_downgrade ||
      !!subscriptionInfo?.has_scheduled_plan_change ||
      !!subscriptionInfo?.processing_plan_change ||
      isAppleOrAmpPayment
    ) {
      toast.error(
        isAppleOrAmpPayment
          ? APPLE_PAYMENT_UPGRADE_ERROR_TEXT
          : SUBSCRIPTION_PENDING_CHANGES_NOTIFICATION_MESSAGE,
      );
    } else {
      if (isTeamsTier) {
        if (isOwner) {
          navigate(`${AppRoutes.PAYMENT}?payment_tab=${TeamsTabs.BUSINESS}`);
        } else {
          onOpenRequestUpgradeModal();
        }
      } else {
        navigate(AppRoutes.PAYMENT);
      }
    }
    if (onClick) {
      onClick();
    }
    sendGTMEvent(GTMEvent.UPGRADE_BUTTON_CLICKS);
  };

  return (
    <button
      id={dataGTM}
      className={classNames('nj-upgrade-button', className)}
      onClick={handleUpgradeClick}
      disabled={isSubscriptionInfoLoading}
    >
      {title}
    </button>
  );
};

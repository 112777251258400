import { MaskSad, SmileySad } from '@phosphor-icons/react';
import { SVG_SIZE_XXXL } from 'src/constants';
import { AiGenerationStatus, MediaType } from 'src/types';
import styles from './MediaModalError.module.scss';
import { uppercaseFirstLetter } from 'src/utils';

interface MediaModalErrorProps {
  status?: AiGenerationStatus;
  mediaType: MediaType;
  classNames?: string;
  width?: string;
  height?: string;
}

export const MediaModalError = ({
  status,
  mediaType,
  width,
  height,
}: MediaModalErrorProps) => {
  return (
    <div className={styles.root} style={{ width, height }}>
      <div className={styles.errorContainer}>
        {status === AiGenerationStatus.CONTENT_POLICY_VIOLATION ? (
          <>
            <MaskSad size={SVG_SIZE_XXXL} />
            <span>
              {uppercaseFirstLetter(mediaType)} violates content policies{' '}
            </span>
          </>
        ) : (
          <>
            <SmileySad size={SVG_SIZE_XXXL} />
            <span>Unable to generate {mediaType}</span>
          </>
        )}
      </div>
    </div>
  );
};

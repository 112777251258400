import { useMemo } from 'react';
import { useAppSelector, imageMaskingState } from 'src/store';

export const useImageMaskingBrushSizeToolVisible = () => {
  const { isBrushSizeToolVisible } = useAppSelector(imageMaskingState);

  return useMemo(
    () => ({
      isBrushSizeToolVisible,
    }),
    [isBrushSizeToolVisible],
  );
};

interface LabelTeamsUltraProps {
  labelType?: 'short' | 'full';
}

export const LabelTeamsUltra = ({
  labelType = 'full',
}: LabelTeamsUltraProps) => {
  return (
    <label className="nj-label-ultra" data-e2e="label-tier-teams-ultra">
      {labelType === 'short' ? 'Business Ultra' : 'Business Ultra subscription'}
    </label>
  );
};

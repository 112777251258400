import { RefObject, useRef } from 'react';
import { FadeTransition } from 'src/animations/FadeTransition';
import classNames from 'classnames';
import styles from './ScrollToEdgeButton.module.scss';
import { ArrowUp, ArrowDown } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';

interface ScrollToEdgeButtonProps {
  scrollableContainerRef: RefObject<HTMLElement> | null;
  direction: 'up' | 'down';
  isVisible: boolean;
  className?: string;
}

export const ScrollToEdgeButton = ({
  scrollableContainerRef,
  direction,
  className,
  isVisible,
}: ScrollToEdgeButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleScroll = () => {
    if (!scrollableContainerRef?.current) {
      return;
    }

    if (direction === 'up') {
      scrollableContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      const { scrollHeight, clientHeight } = scrollableContainerRef.current;

      scrollableContainerRef.current.scrollTo({
        top: scrollHeight - clientHeight,
        behavior: 'smooth',
      });
    }
  };

  return (
    <FadeTransition inProp={isVisible} nodeRef={buttonRef} unmountOnExit>
      <Button
        ref={buttonRef}
        className={classNames(styles.button, className)}
        onClick={handleScroll}
        shape="round"
        color="transparent"
      >
        {direction === 'up' ? (
          <ArrowUp size={SVG_SIZE_M} />
        ) : (
          <ArrowDown size={SVG_SIZE_M} />
        )}
      </Button>
    </FadeTransition>
  );
};

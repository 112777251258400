import { X } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import styles from 'src/v2/pages/subscription/components/UpdateMonthlyToAnnuallySection/components/PanelHeader/PanelHeader.module.scss';

type PanelHeaderProps = {
  onClose: () => void;
};

export const PanelHeader = ({ onClose }: PanelHeaderProps) => {
  return (
    <div className={styles.backButtonRow}>
      <button type="button" onClick={onClose} className={styles.backButton}>
        <span>Confirm order</span>
        <X size={SVG_SIZE_M} />
      </button>
    </div>
  );
};

import { useRef } from 'react';
import styles from './Tooltip.module.scss';
import { XCircle } from '@phosphor-icons/react';
import {
  useLeftPanelState,
  useBreakpoint,
  useRightSidePanelState,
  useTierConfig,
} from 'src/hooks';
import { useVisible, useConversationParams } from 'src/hooks';
import { CSSTransition } from 'react-transition-group';
import { ANIMATION_TIMEOUT } from 'src/constants';

interface TooltipProps {
  children: React.ReactNode;
}

// TODO(olha): Needs refactoring to avoid code duplicate from TiersAuthFooter. And rename it!
// todo and check if all logic is needed - currently it is under condition isOpenTier || isFreeTier
export const Tooltip = ({ children }: TooltipProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const SVG_SIZE = 24;
  const { isVisible, onVisibilityRemove } = useVisible(true);
  const { isRightPanelExpanded } = useRightSidePanelState();

  const { isLeftPanelExpanded } = useLeftPanelState();
  const { isLandingPage } = useConversationParams();

  const { isMobile } = useBreakpoint();

  const { upsellFooterTitle, upsellFooterDescription } = useTierConfig();

  const handleClick = () => {
    onVisibilityRemove();
  };

  const display =
    isLandingPage && !isLeftPanelExpanded && isVisible && !isRightPanelExpanded;

  return (
    <CSSTransition
      in={display}
      timeout={ANIMATION_TIMEOUT}
      classNames={isMobile ? 'nj-animate-vertical-appearance' : ''}
      unmountOnExit
      appear={display}
      nodeRef={containerRef}
    >
      <div ref={containerRef} className={styles.root}>
        <div className={styles.content}>
          <XCircle
            size={SVG_SIZE}
            weight="fill"
            className={styles.close}
            onClick={handleClick}
          />
          <div className={styles.titleContainer}>
            <h4 className={styles.title}>{upsellFooterTitle}</h4>
          </div>
          <p className={styles.description}>{upsellFooterDescription}</p>

          {children}
        </div>
      </div>
    </CSSTransition>
  );
};

import { useQueryParams } from 'src/hooks/useQueryParams';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { batch } from 'react-redux';
import log from 'src/utils/logger';
import {
  setWelcomeUpsellModalType,
  toggleWelcomeModal,
  useAppDispatch,
} from 'src/store';
import { updateUserTier } from 'src/store/slices/sessionSlice';
import {
  NinjaSubscriptionPlan,
  UserTierStatus,
  WelcomeUpsellModalType,
} from 'src/types';
import { usersApi, UsersTags } from 'src/store/services';
import { UserTier } from 'src/types/models/UserTier';
import { capitalizeEachFirstLetter } from 'src/utils';
import { convertTierName } from 'src/utils/user';

export const useUpgradeDowngradeParams = () => {
  const { searchParams, removeAllSearchParams } = useQueryParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!!searchParams.operation_type) {
      log.debug('useUpgradeDowngradeParams->searchParams', searchParams);
      const tierName = searchParams.to_tier
        ? capitalizeEachFirstLetter(convertTierName(searchParams.to_tier))
        : searchParams.to_tier;
      if (searchParams.status === 'rejected') {
        toast.error(
          'Consent approval is needed in the PayPal popup. Please try again and provide your consent.',
        );
        removeAllSearchParams();
        return;
      }
      if (searchParams.operation_type === 'no_change') {
        if (
          searchParams.status === 'scheduled' ||
          searchParams.status === 'successful'
        ) {
          toast.success('Subscription updated');
        } else {
          toast.error('Subscription update failed');
        }
        removeAllSearchParams();
        return;
      }
      if (searchParams.operation_type === 'upgrade') {
        if (searchParams.status === 'successful') {
          toast(`Upgrade to ${tierName} successful`);
          batch(() => {
            const modalType: WelcomeUpsellModalType | null =
              searchParams.to_tier === NinjaSubscriptionPlan.ULTRA
                ? 'ultra-welcome-modal'
                : searchParams.to_tier === NinjaSubscriptionPlan.PRO
                  ? 'pro-welcome-modal'
                  : null;
            if (modalType) {
              dispatch(setWelcomeUpsellModalType(modalType));
              dispatch(toggleWelcomeModal(true));
            }

            dispatch(
              updateUserTier({
                new_tier: searchParams.to_tier as UserTier,
                new_tier_status: UserTierStatus.OK,
              }),
            );
            dispatch(usersApi.util.invalidateTags([UsersTags.Users]));
          });
        } else if (searchParams.status === 'scheduled') {
          toast('Account upgrade started');
        } else {
          toast.error(`Upgrade to ${tierName} failed`);
        }
        removeAllSearchParams();
        return;
      }
      if (searchParams.operation_type === 'downgrade') {
        if (searchParams.status === 'scheduled') {
          toast('Account downgrade started');
        } else {
          toast.error('Account downgrade failed');
        }
        removeAllSearchParams();
        return;
      }
      removeAllSearchParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.status, searchParams.to_tier, searchParams.operation_type]);
};

import classNames from 'classnames';
import styles from './DimensionPrimaryItem.module.scss';
import { ImageGenSettingsResolutions } from 'src/types';

interface DimensionPrimaryItemProps {
  data: ImageGenSettingsResolutions;
  isSelected: boolean;
  isDisabled?: boolean;
  onClick: () => void;
}

export const DimensionPrimaryItem = ({
  data,
  isSelected,
  isDisabled,
  onClick,
}: DimensionPrimaryItemProps) => {
  const { name, aspect_ratio } = data;

  return (
    <button
      onClick={onClick}
      className={classNames(styles.root, {
        [styles.active]: isSelected,
        [styles.disabled]: isDisabled,
      })}
      disabled={isDisabled}
      data-e2e={`image-dimension-${name}-button`}
    >
      <span className={styles.title}>{name}</span>
      <span className={styles.subtitle}>{aspect_ratio}</span>
    </button>
  );
};

export const b64ToFileFormat = (b64Data: string, filename: string): File => {
  const [header, base64] = b64Data.split(',');
  const mime = header.match(/:(.*?);/)?.[1] || '';
  const binary = atob(base64);
  const u8arr = new Uint8Array(binary.length);

  for (let i = 0; i < binary.length; i++) {
    u8arr[i] = binary.charCodeAt(i);
  }

  return new File([u8arr], filename, { type: mime });
};

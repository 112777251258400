import { LazyReactPlayer } from 'src/components/LazyReactPlayer';
import { VideoUpsell } from 'src/v2/components/ThreadMessage/components/VideoUpsell';
import { getRandomVideo } from 'src/utils';
import styles from './ThreadErrorVideoMessage.module.scss';

export const ThreadErrorVideoMessage = () => {
  const video = getRandomVideo();

  return (
    <div className={styles.root}>
      <LazyReactPlayer
        url={video}
        controls
        playing
        className={styles.videoWrapper}
      />

      <VideoUpsell />
    </div>
  );
};

import type { TeamResponse } from 'src/types/models/TeamResponse';

export const getAvailableSeatsCount = (
  teamInfo?: TeamResponse | null,
): number => {
  return (
    (teamInfo?.num_seats_total || 0) -
    ((teamInfo?.num_seats_assigned || 0) +
      (teamInfo?.num_seats_invite_pending || 0))
  );
};

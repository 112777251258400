import { useEffect, useRef } from 'react';
import { LeftPanelSection } from './components/LeftPanelSection';
import { NavSidebar } from './components/NavSidebar';
import { OverlayBackground } from 'src/components/OverlayBackground';
import {
  useLeftPanelActions,
  useBreakpoint,
  useLeftPanelState,
  useOutsideClick,
} from 'src/hooks';
import { ConversationControl } from 'src/pages/ManageTasksChatPage/components/MultiConversationsListPanel/components/ConversationControl';
import styles from './LeftSidePanel.module.scss';

export const LeftSidePanel = () => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { isMobileOrTablet, isMobile, isTablet } = useBreakpoint();

  const { toggleLeftPanelExpanded } = useLeftPanelActions();
  const { isLeftPanelExpanded } = useLeftPanelState();

  const handleClose = () => {
    toggleLeftPanelExpanded(false);
  };

  const handleTabletClose = () => {
    if (isTablet) {
      handleClose();
    }
  };

  useOutsideClick(containerRef, handleTabletClose);

  useEffect(() => {
    if (isMobileOrTablet) {
      toggleLeftPanelExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileOrTablet]);

  return (
    <>
      {isMobile && (
        <OverlayBackground
          showOverlay={isLeftPanelExpanded}
          onClick={handleClose}
          zIndex={1000}
          withTransparentTablet
          ref={overlayRef}
        />
      )}

      <div className={styles.root} ref={containerRef}>
        {!isMobile && <NavSidebar />}

        <LeftPanelSection />
      </div>

      {isMobile && <ConversationControl type="panel" />}
    </>
  );
};

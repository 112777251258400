import styles from './AmpOnboardingPage.module.scss';
import { BasePublicPage } from 'src/v2/pages/auth/BasePublicPage';
import React, { useContext, useEffect, useState } from 'react';
import { AmpOnboardingPageStep } from 'src/v2/pages/auth/AmpOnboardingPage/common';
import { OnboardEmailSection } from 'src/v2/pages/auth/AmpOnboardingPage/components/OnboardEmailSection';
import { ExistingPaidUserSection } from 'src/v2/pages/auth/AmpOnboardingPage/components/ExistingPaidUserSection';
import { AlreadySigninSection } from 'src/v2/pages/auth/AmpOnboardingPage/components/AlreadySigninSection';
import { SignUpSection } from 'src/v2/pages/auth/AmpOnboardingPage/components/SignUpSection';
import { ContactSupportSection } from 'src/v2/pages/auth/AmpOnboardingPage/components/ContactSupportSection';
import { ContactSupportTicketSent } from 'src/v2/pages/auth/AmpOnboardingPage/components/ContactSupportTicketSent';
import { useLogout, useQueryParams } from 'src/hooks';
import AuthContext from 'src/contexts/AuthContext';
import ampOnboardBg from 'src/images/backgrounds/amp_onboard_bg.png';
import { Appearance } from 'src/types/models/Appearance';
import { NinjaLoader } from 'src/components/Loading';
import { usePreOnboardMutation } from 'src/store/services';

export const AmpOnboardingPage = () => {
  const [step, setStep] = useState(AmpOnboardingPageStep.ONBOARD);
  const { isAuthenticated } = useContext(AuthContext);
  const { logout } = useLogout();
  const { searchParams } = useQueryParams();
  const { amp_customer_id, amp_product_code, amp_fulfill_success } =
    searchParams;
  const [preOnboard, { isLoading: isPreOnboardLoading, data: preOnboardData }] =
    usePreOnboardMutation();

  useEffect(() => {
    // It's not ideal but to make UX predictable, logout the current user, preserving all the query params.
    if (isAuthenticated) {
      const curLocation = window.location.href;
      logout().then(() => {
        window.location.href = curLocation;
      });
    }
  }, [isAuthenticated, logout]);

  useEffect(() => {
    if (amp_fulfill_success === 'False') {
      setStep(AmpOnboardingPageStep.CONTACT_SUPPORT);
      return;
    }
    if (amp_customer_id && amp_product_code) {
      preOnboard({
        amp_customer_id: amp_customer_id,
        amp_product_code: amp_product_code,
      }).catch(() => {
        setStep(AmpOnboardingPageStep.CONTACT_SUPPORT);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preOnboard, amp_customer_id, amp_product_code, amp_fulfill_success]);

  return (
    <BasePublicPage
      alignContainer="center"
      imageUrl={`url('${ampOnboardBg}')`}
      blurBackground={false}
      appearance={Appearance.LIGHT}
      showFooter={false}
    >
      {isPreOnboardLoading ? (
        <NinjaLoader content="Loading your data..." />
      ) : (
        <div className={styles.mainSection}>
          {(() => {
            switch (step) {
              case AmpOnboardingPageStep.ONBOARD:
                return (
                  <OnboardEmailSection
                    setStep={setStep}
                    preOnboardData={preOnboardData}
                  />
                );
              case AmpOnboardingPageStep.EXISTING_PLAN:
                return <ExistingPaidUserSection setStep={setStep} />;
              case AmpOnboardingPageStep.SIGN_IN:
                return <AlreadySigninSection />;
              case AmpOnboardingPageStep.SIGN_UP:
                return <SignUpSection />;
              case AmpOnboardingPageStep.CONTACT_SUPPORT:
                return <ContactSupportSection setStep={setStep} />;
              case AmpOnboardingPageStep.ZENDESK_REQUEST_SENT:
                return <ContactSupportTicketSent />;
              default:
                return null;
            }
          })()}
        </div>
      )}
    </BasePublicPage>
  );
};

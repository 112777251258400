import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { ImageGenRenderData } from 'src/types';

interface ImageMaskingState {
  currentMaskingImageData: ImageGenRenderData | null;
  imageMaskingSelectionsCount: number;
  selectedImageMask: File | null;
  isEditingMode: boolean;
  shouldResetCanvas: boolean;
  isBrushSizeToolVisible: boolean;
  imageMaskingIsLoading: boolean;
  currentImagesList: ImageGenRenderData[];
  selectedImageIndex: number;
  isOpenModalImagesList: boolean;
  isAttachedImageTooltipVisible: boolean;
  isAttachedImageEditingMode: boolean;
  messageId: string;
}

const initialState: ImageMaskingState = {
  currentMaskingImageData: null,
  imageMaskingSelectionsCount: 0,
  selectedImageMask: null,
  isEditingMode: false,
  shouldResetCanvas: false,
  isBrushSizeToolVisible: false,
  imageMaskingIsLoading: false,
  currentImagesList: [],
  selectedImageIndex: 0,
  isOpenModalImagesList: false,
  isAttachedImageTooltipVisible: false,
  isAttachedImageEditingMode: false,
  messageId: '',
};

export const imageMaskingSlice = createSlice({
  name: 'imageMasking',
  initialState,
  reducers: {
    setImageMaskingSelectionsCount: (state, action: PayloadAction<number>) => {
      state.imageMaskingSelectionsCount = action.payload;
    },
    setSelectedImageMask: (state, action: PayloadAction<File | null>) => {
      state.selectedImageMask = action.payload;
    },
    setImageMaskingRightPanelData: (
      state,
      action: PayloadAction<ImageGenRenderData | null>,
    ) => {
      state.currentMaskingImageData = action.payload;
    },
    setEditingMode: (state, action: PayloadAction<boolean>) => {
      state.isEditingMode = action.payload;
    },
    triggerCanvasReset: (state) => {
      state.shouldResetCanvas = !state.shouldResetCanvas;
    },
    toggleVisibleBrushSizePanel: (state, action: PayloadAction<boolean>) => {
      state.isBrushSizeToolVisible = action.payload;
    },
    setImageMaskingIsLoading: (state, action: PayloadAction<boolean>) => {
      state.imageMaskingIsLoading = action.payload;
    },
    setSelectedImageIndex: (state, action: PayloadAction<number>) => {
      state.selectedImageIndex = action.payload;
    },
    setCurrentImagesList: (
      state,
      action: PayloadAction<ImageGenRenderData[]>,
    ) => {
      state.currentImagesList = action.payload;
    },
    toggleIsOpenModalImagesList: (state) => {
      state.isOpenModalImagesList = !state.isOpenModalImagesList;
    },
    setIsAttachedImageTooltipVisible: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isAttachedImageTooltipVisible = action.payload;
    },
    setIsAttachedImageEditingMode: (state, action: PayloadAction<boolean>) => {
      state.isAttachedImageEditingMode = action.payload;
    },
    setMessageId: (state, action: PayloadAction<string>) => {
      state.messageId = action.payload;
    },
  },
});

export const imageMaskingState = (state: RootState) =>
  state.imageMasking as ImageMaskingState;

export const {
  setSelectedImageMask,
  setCurrentImagesList,
  setSelectedImageIndex,
  toggleIsOpenModalImagesList,
  setImageMaskingSelectionsCount,
  setImageMaskingRightPanelData,
  setEditingMode,
  toggleVisibleBrushSizePanel,
  setImageMaskingIsLoading,
  setMessageId,
  triggerCanvasReset,
  setIsAttachedImageTooltipVisible,
  setIsAttachedImageEditingMode,
} = imageMaskingSlice.actions;

export default imageMaskingSlice.reducer;

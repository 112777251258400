import { useContext, useMemo } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { escapeRegExp } from 'src/utils';
import ExamplesData from 'src/data/examples.json';
import { ExampleType } from 'src/types';

type CommandsParams = { shouldSearch: boolean };

const EXAMPLES_DATA: ExampleType[] = JSON.parse(JSON.stringify(ExamplesData));

export const useCommands = ({ shouldSearch }: CommandsParams) => {
  const { threadInputBoxValue } = useContext(ThreadInputBoxContext);

  const matchingCommands = useMemo(() => {
    if (!shouldSearch) {
      return [];
    }

    const commands = EXAMPLES_DATA.slice().sort((a, b) =>
      a.title.localeCompare(b.title),
    );

    if (!threadInputBoxValue) {
      return commands;
    }

    const regExp = new RegExp(`^${escapeRegExp(threadInputBoxValue)}`, 'gi');

    return commands.filter((item) => item.title.match(regExp));
  }, [threadInputBoxValue, shouldSearch]);

  return {
    matchingCommands,
  };
};

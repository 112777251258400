import { ForwardedRef, ReactNode, RefObject } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './FadeTransition.module.scss';
import { ANIMATION_TIMEOUT } from 'src/constants';

interface FadeTransitionProps {
  inProp: boolean;
  nodeRef: RefObject<HTMLElement> | ForwardedRef<HTMLDivElement>;
  children: ReactNode;
  unmountOnExit?: boolean;
  onExited?: () => void;
}

export const FadeTransition = ({
  inProp,
  nodeRef,
  children,
  unmountOnExit = true,
  onExited,
}: FadeTransitionProps) => (
  <CSSTransition
    in={inProp}
    timeout={ANIMATION_TIMEOUT}
    classNames={{
      enter: styles.fadeEnter,
      enterActive: styles.fadeEnterActive,
      exit: styles.fadeExit,
      exitActive: styles.fadeExitActive,
    }}
    nodeRef={nodeRef}
    unmountOnExit={unmountOnExit}
    onExited={onExited}
  >
    {children}
  </CSSTransition>
);

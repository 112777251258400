import { useRef, useState } from 'react';
import { AppearingFromRight } from 'src/animations/AppearingFromRight';
import styles from './UpgradeDowngradeSection.module.scss';
import { SubscriptionPlan } from 'src/types';
import { PanelHeader } from '../PanelHeader';
import { UpgradeDowngradeSummarySection } from '../UpgradeDowngradeSummarySection';
import { UpgradeDowngradeButton } from '../UpgradeDowngradeButton';
import { CheckoutSeatsSection } from 'src/v2/pages/payment/components/CheckoutSeatsSection';
import { useSubscriptionPlanById } from 'src/hooks';

interface UpgradeDowngradeSectionProps {
  isOpen: boolean;
  onClose: () => void;
  selectedPlan?: SubscriptionPlan;
  isUpgradeToTeam?: boolean;
}

export const UpgradeDowngradeSection = ({
  isOpen,
  onClose,
  selectedPlan,
  isUpgradeToTeam,
}: UpgradeDowngradeSectionProps) => {
  const checkoutContentRef = useRef<HTMLDivElement>(null);
  const [numberOfSeats, setNumberOfSeats] = useState<number>(1);

  const { plan, isPlanLoading } = useSubscriptionPlanById({
    plan_id: selectedPlan?.plan_id,
    seat_count: numberOfSeats,
    shouldSkip: !isUpgradeToTeam,
  });

  const handleChangeNumberOfSeats = (number: number) => {
    if (number <= 0) {
      setNumberOfSeats(1);
    } else {
      setNumberOfSeats(number);
    }
  };

  const handleCloseSection = () => {
    setNumberOfSeats(1);
    onClose();
  };

  const resultPlan = isUpgradeToTeam ? plan : selectedPlan;
  const resultNumberOfSeats = isUpgradeToTeam ? numberOfSeats : undefined;

  return (
    <AppearingFromRight
      width={360}
      inProp={isOpen}
      nodeRef={checkoutContentRef}
    >
      <div className={styles.root}>
        <PanelHeader onClose={handleCloseSection} />

        <div className={styles.mainContainer}>
          {isUpgradeToTeam && (
            <CheckoutSeatsSection
              numberOfSeats={numberOfSeats}
              onChangeNumberOfSeats={handleChangeNumberOfSeats}
            />
          )}

          <UpgradeDowngradeSummarySection
            selectedPlan={resultPlan}
            numberOfSeats={resultNumberOfSeats}
            isPlanLoading={isPlanLoading}
          />

          <div className={styles.paymentContainer}>
            <UpgradeDowngradeButton
              selectedPlan={resultPlan}
              numberOfSeats={resultNumberOfSeats}
              isLoading={isUpgradeToTeam ? isPlanLoading : false}
            />
          </div>
        </div>
      </div>
    </AppearingFromRight>
  );
};

import { Message } from 'src/types';
import { UserMessageContent } from 'src/v2/components/UserMessage/components/UserMessageContent';
import { FileImageThumbnail } from 'src/components/FlatAppearance/components/FileImageThumbnail';
import { ImageEditRequestPayload } from 'src/types/models/ImageEditRequestPayload';
import { useMemo } from 'react';

interface UserContentWithEditFileProps {
  message: Message;
  imageFromPayload: ImageEditRequestPayload;
}

export const UserContentWithEditFile = ({
  message,
  imageFromPayload,
}: UserContentWithEditFileProps) => {
  const [url, filename] = useMemo(() => {
    const imageUrl = imageFromPayload?.image_url;
    const filename = imageUrl.split('/').pop();

    return [imageUrl, filename];
  }, [imageFromPayload]);

  return (
    <div className="nj-thread-message--content-with-file-wrapper">
      <UserMessageContent content={message.content} />

      <FileImageThumbnail fileName={filename || 'Edited file'} url={url} />
    </div>
  );
};

/* eslint-disable react/jsx-no-undef */
import { File, Warning, Image, X } from '@phosphor-icons/react';
import cn from 'classnames';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'src/components/Loading';
import {
  SVG_SIZE_M,
  CONVERTED_TEXT_TO_FILE_NAME,
  CONVERTED_PASTED_TEXT_TO_FILE_NAME,
} from 'src/constants';
import { FileObject, ModalGalleryType } from 'src/types';
import SessionContext from 'src/contexts/SessionContext';
import { useDeleteAttachment, useRightSidePanelActions } from 'src/hooks';
import styles from './AttachedFileItem.module.scss';
import classNames from 'classnames';
import { ConvertedTextFile } from 'src/components/UserMessage/components/ConvertedTextFile';
import {
  useAttachedImageTooltipActions,
  useAttachedImageTooltipState,
  useImageMaskingPanelActions,
  useImageMaskingSelectionsCount,
} from 'src/hooks/imageMaskingPanelHooks';
import { SpinnerSimple } from 'src/v2/commonComponents/SpinnerSimple';

type AttachedFileItemProps = {
  fileObject: FileObject;
  fileId: string;
};

export const AttachedFileItem = ({
  fileObject,
  fileId,
}: AttachedFileItemProps) => {
  const { onChangeModalGalleryData } = useContext(SessionContext);
  const { imageMaskingSelectionsCount } = useImageMaskingSelectionsCount();
  const { isAttachedImageEditingMode } = useAttachedImageTooltipState();
  const {
    onToggleImageEditTooltipVisible,
    onChangeIsAttachedImageEditingMode,
  } = useAttachedImageTooltipActions();
  const { toggleRightSidePanelExpanded } = useRightSidePanelActions();
  const { resetImageMaskingPanelInfo } = useImageMaskingPanelActions();
  const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(null);

  const { onDeleteAttachment } = useDeleteAttachment();

  const file = fileObject.file as File;

  const isImageFile = useMemo(() => {
    if (!!file) {
      const fileTypeParts = file.type.split('/');
      if (fileTypeParts.length < 2) {
        return false;
      }
      const firstPart = fileTypeParts[0];
      return firstPart.toLowerCase() === 'image';
    }
    return false;
  }, [file]);

  useEffect(() => {
    if (isImageFile && file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader?.result);
      };
      reader.readAsDataURL(file);
    }
  }, [file, isImageFile, imageUrl]);

  const renderExceptionIcon = (type: string) => {
    switch (type) {
      case 'image/apng':
      case 'image/bmp':
      case 'image/dib':
      case 'image/drle':
      case 'image/dpx':
      case 'image/fits':
      case 'image/g3':
      case 'image/gif':
      case 'image/ico':
      case 'image/jp2':
      case 'image/jpg2':
      case 'image/jfif':
      case 'image/pjpeg':
      case 'image/pjp':
      case 'image/webp':
      case 'image/jpm':
      case 'image/jpgm':
      case 'image/svg':
      case 'image/tif':
      case 'image/tfx':
      case 'image/psd':
      case 'image/wbmp':
      case 'image/xif':
      case 'image/pcx':
      case 'image/wmf':
      case 'image/arw':
      case 'image/cr2':
      case 'image/crw':
      case 'image/dcr':
      case 'image/dng':
      case 'image/erf':
      case 'image/k25':
      case 'image/kdc':
      case 'image/mrw':
      case 'image/nef':
      case 'image/nrw':
      case 'image/orf':
      case 'image/pef':
      case 'image/raf':
      case 'image/raw':
      case 'image/sr2':
      case 'image/srf':
      case 'image/x3f':
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
        return <Image size={SVG_SIZE_M} />;
      default:
        return <File size={SVG_SIZE_M} />;
    }
  };

  const handleDeleteAttachment = async () => {
    if (isAttachedImageEditingMode) {
      toggleRightSidePanelExpanded(false);
      onChangeIsAttachedImageEditingMode(false);
      onToggleImageEditTooltipVisible(false);
    }

    resetImageMaskingPanelInfo();

    await onDeleteAttachment(fileId, fileObject?.convertedFileName);
  };

  const getImageMaskingSelectionsLabel = () =>
    imageMaskingSelectionsCount
      ? `${imageMaskingSelectionsCount} selection${imageMaskingSelectionsCount > 1 ? 's' : ''}`
      : '';

  const handleImageClick = (url: string, fileName: string) => {
    onChangeModalGalleryData(
      true,
      ModalGalleryType.IMAGES_VIDEOS,
      [
        {
          url: url,
          title: fileName,
          height: 400,
          width: 400,
          source: '',
        },
      ],
      0,
    );
  };

  if (!fileObject) {
    return null;
  }

  if (
    fileObject.file?.name &&
    (fileObject.file?.name === CONVERTED_TEXT_TO_FILE_NAME ||
      fileObject.file?.name.includes(CONVERTED_PASTED_TEXT_TO_FILE_NAME))
  ) {
    return (
      <ConvertedTextFile
        filename={fileObject.file.name}
        convertedFilename={fileObject.convertedFileName || ''}
        key={fileObject.file?.name}
        isLoading={!!fileObject.isLoading}
        hasError={!!fileObject.errorMessage}
        onDelete={handleDeleteAttachment}
      />
    );
  }

  if (isImageFile && imageUrl) {
    return (
      <div
        className={cn(styles.fileImageWrapper, {
          [styles.withSelections]: imageMaskingSelectionsCount > 0,
        })}
      >
        {fileObject.isLoading ? (
          <SpinnerSimple size={SVG_SIZE_M} />
        ) : !!fileObject.errorMessage ? (
          <Warning
            size={SVG_SIZE_M}
            weight="fill"
            className={styles.errorIcon}
          />
        ) : (
          <img
            id="attachedImage"
            src={imageUrl as string}
            alt={file?.name}
            className={styles.fileImage}
            onClick={() => handleImageClick(imageUrl as string, file?.name)}
          />
        )}
        <div
          className={cn(styles.imageMaskSelectionsCount, {
            [styles.visible]: imageMaskingSelectionsCount > 0,
          })}
        >
          <span>{getImageMaskingSelectionsLabel()}</span>
        </div>
        <button
          type="button"
          onClick={handleDeleteAttachment}
          className={styles.fileLabelClose}
        >
          <X size={12} weight="bold" />
        </button>
      </div>
    );
  }

  return (
    <div className={styles.fileLabel}>
      <div className={styles.fileLabelText}>
        {fileObject.isLoading ? (
          <Spinner size={SVG_SIZE_M} inline />
        ) : !!fileObject.errorMessage ? (
          <Warning
            size={SVG_SIZE_M}
            weight="fill"
            className={styles.errorIcon}
          />
        ) : (
          renderExceptionIcon(file.type)
        )}
        <span className={classNames(styles.fileName, 'ellipsis')}>
          {file?.name}
        </span>
      </div>
      <button
        type="button"
        onClick={handleDeleteAttachment}
        className={styles.fileLabelClose}
      >
        <X size={12} weight="bold" />
      </button>
    </div>
  );
};

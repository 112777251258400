import {
  PaymentPlanInterval,
  PlanSectionType,
  SubscriptionPlan,
} from 'src/types';
import { UpgradeForm } from '../UpgradeForm';
import './PlanSection.scss';
import { useMemo } from 'react';

interface PlanSectionProps {
  type: PlanSectionType;
  onOpenCheckout: (type: PlanSectionType, period: PaymentPlanInterval) => void;
  handleBuyWithAws: (
    type: PlanSectionType,
    period: PaymentPlanInterval,
  ) => void;
  period: PaymentPlanInterval;
  selected?: PlanSectionType;
  plans?: SubscriptionPlan[];
  isTeams?: boolean;
}

export const PlanSection = ({
  type,
  onOpenCheckout,
  handleBuyWithAws,
  period,
  selected,
  plans,
  isTeams,
}: PlanSectionProps) => {
  const displayedPlan = useMemo(
    () =>
      plans?.find(
        (plan) =>
          plan.period === period && plan.plan_tier === type.toLowerCase(),
      ),
    [plans, type, period],
  );

  return (
    <div className="nj-plan-section">
      <UpgradeForm
        onOpenCheckout={onOpenCheckout}
        handleBuyWithAws={handleBuyWithAws}
        period={period}
        type={type}
        isSelected={selected === type}
        selectedPlan={displayedPlan}
        isTeams={isTeams}
      />
    </div>
  );
};

import { useContext, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ANIMATION_TIMEOUT, DEFAULT_GUEST_TAG } from 'src/constants';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import styles from './ReplaceTooltip.module.scss';

// TODO(olha): should be deprecated
export const ReplaceTooltip = () => {
  const { showReplaceTooltip } = useContext(ThreadInputBoxContext);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      in={showReplaceTooltip}
      timeout={ANIMATION_TIMEOUT}
      classNames="nj-animate-opacity"
      unmountOnExit
      nodeRef={containerRef}
    >
      <div ref={containerRef} className={styles.root}>
        {`Replace ${DEFAULT_GUEST_TAG} with a contact (e.g., @Jane) or an email address.`}
      </div>
    </CSSTransition>
  );
};

import { useRef, useContext } from 'react';
import {
  useThemeActions,
  useBreakpoint,
  useOutsideClick,
  useRightSettingsPanelState,
} from 'src/hooks';
import { SideSettingsPanel } from '../SidePanel/components/SideSettingsPanel';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { AppearingFromRight } from 'src/animations/AppearingFromRight';

/* TODO(olha): will be deprecated soon */
export const MobileSidePanel = () => {
  const { avatarFrameRef } = useContext(ForwardRefContext);

  const settingsRef = useRef<HTMLDivElement>(null);

  const { isMobile, isDesktopAndUp } = useBreakpoint();

  const { toggleSettingsPanelExpanded } = useThemeActions();
  const { isSettingsPanelExpanded } = useRightSettingsPanelState();

  const handleOverlayClick = () => {
    if (isDesktopAndUp || isMobile) {
      return;
    }
    if (isSettingsPanelExpanded) {
      toggleSettingsPanelExpanded(false);
    }
  };

  useOutsideClick(settingsRef, handleOverlayClick, avatarFrameRef);

  return (
    <AppearingFromRight
      inProp={isSettingsPanelExpanded}
      nodeRef={settingsRef}
      width={316}
    >
      <SideSettingsPanel />
    </AppearingFromRight>
  );
};

import { ChatCircleDots, Sliders } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { AddConversationButton } from 'src/pages/ManageTasksChatPage/components/MultiConversationsListPanel/components/AddConversationButton';
import { Button } from 'src/v2/commonComponents/Button';
import {
  useLeftPanelState,
  useRightSidePanelActions,
  useRightSidePanelState,
  useLeftPanelActions,
  useConversations,
  useAttachedImageTooltipState,
  useAttachedImageTooltipActions,
} from 'src/hooks';
import classNames from 'classnames';
import styles from './NavSidebar.module.scss';
import { LeftPanelSections } from 'src/types';
import { UserImage } from 'src/v2/components/UserImage';

const ACTION_LIST = [
  {
    section: LeftPanelSections.CONVERSATION_LIST,
    Icon: ChatCircleDots,
    tooltipContent: 'Chat history',
  },
  {
    section: LeftPanelSections.AGENTS_SETTINGS,
    Icon: Sliders,
    tooltipContent: 'Ninja settings',
  },
];

export const NavSidebar = () => {
  const { toggleRightSidePanelExpanded } = useRightSidePanelActions();
  const { isRightPanelExpanded } = useRightSidePanelState();
  const { toggleLeftPanelExpanded, setLeftPanelData } = useLeftPanelActions();
  const { isLeftPanelExpanded, leftPanelSections } = useLeftPanelState();
  const { isAttachedImageEditingMode } = useAttachedImageTooltipState();
  const {
    onChangeIsAttachedImageEditingMode,
    onToggleImageEditTooltipVisible,
  } = useAttachedImageTooltipActions();
  const { hasUnreadMessage } = useConversations();

  const handleButtonClick = (panelType: LeftPanelSections) => {
    if (leftPanelSections === panelType && isLeftPanelExpanded) {
      toggleLeftPanelExpanded(false);
      return;
    }

    setLeftPanelData({
      panelType,
      isExpanded: true,
    });

    if (isRightPanelExpanded) {
      toggleRightSidePanelExpanded(false);
    }

    if (isAttachedImageEditingMode) {
      onChangeIsAttachedImageEditingMode(false);
      onToggleImageEditTooltipVisible(true);
    }
  };

  return (
    <div
      className={classNames(styles.root, {
        [styles.withBorder]: isLeftPanelExpanded,
        [styles.withShadow]: !isLeftPanelExpanded,
      })}
    >
      <div className={styles.container}>
        <AddConversationButton />

        {ACTION_LIST.map(({ section, Icon, tooltipContent }) => (
          <Button
            key={section}
            color="transparent"
            shape="round"
            onClick={() => handleButtonClick(section)}
            tooltipContent={tooltipContent}
            className={classNames(styles.button, {
              [styles.active]:
                isLeftPanelExpanded && section === leftPanelSections,
              [styles.withRedDot]:
                section === LeftPanelSections.CONVERSATION_LIST &&
                hasUnreadMessage,
            })}
            data-e2e={`action-${section}-button`}
          >
            <Icon size={SVG_SIZE_M} />
          </Button>
        ))}
      </div>

      <div className={styles.container}>
        <Button
          data-e2e="user-menu-button"
          color="transparent"
          shape="round"
          onClick={() => handleButtonClick(LeftPanelSections.USER_MENU)}
          tooltipContent="Account"
          className={styles.noPuddingButton}
        >
          <UserImage />
        </Button>

        <AddConversationButton withNinjaIcon />
      </div>
    </div>
  );
};

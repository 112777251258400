import { useMemo } from 'react';
import { useAppSelector, imageMaskingState } from 'src/store';

export const useImageMaskingSelectedArea = () => {
  const { selectedImageMask } = useAppSelector(imageMaskingState);

  return useMemo(
    () => ({
      selectedImageMask,
    }),
    [selectedImageMask],
  );
};

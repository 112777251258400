import { useMemo, Fragment, useState } from 'react';
import classNames from 'classnames';
import { Conversation } from 'src/types';
import { getTaskDisplayDate } from 'src/utils';
import { RenameModal } from 'src/components/RenameModal';
import { DEFAULT_CHAT_ID } from 'src/constants';
import styles from './ConversationsGroupedByDate.module.scss';
import { ConversationItem } from '../ConversationItem';

interface ConversationGroupProps {
  date: string;
  conversations: Conversation[];
  selectedConversationId?: string;
}

export const ConversationsGroupedByDate = ({
  date,
  conversations,
  selectedConversationId,
}: ConversationGroupProps) => {
  const [isRenameModalOpen, setIsRenameModalOpen] = useState<boolean>(false);

  const conversationDate = useMemo(() => getTaskDisplayDate(date), [date]);

  const shouldShowDateTitle = useMemo(() => {
    if (
      conversationDate === 'Today' &&
      conversations.length === 1 &&
      conversations[0].conversation_id === DEFAULT_CHAT_ID
    ) {
      return false;
    }
    return true;
  }, [conversations, conversationDate]);

  return (
    <Fragment>
      <div
        className={classNames(styles.root, {
          [styles.hiddenDate]: !shouldShowDateTitle,
        })}
      >
        <div className={styles.date}>
          <span>{conversationDate}</span>
        </div>
        {conversations.map((conversation: Conversation, index: number) => (
          <ConversationItem
            key={`thread-${conversation.conversation_id}-${index}`}
            selected={selectedConversationId === conversation.conversation_id}
            conversation={conversation}
            toggleOpenRenameModal={setIsRenameModalOpen}
          />
        ))}
      </div>
      {isRenameModalOpen && (
        <RenameModal
          isOpen={isRenameModalOpen}
          onClose={() => setIsRenameModalOpen(false)}
        />
      )}
    </Fragment>
  );
};

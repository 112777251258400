import styles from './TeamsUpgradeButtons.module.scss';
import { UpgradeButton } from 'src/components/UpgradeButton';
import {
  useGetEnterpriseModal,
  useInviteUsersModal,
  useSession,
  useTeamInfo,
} from 'src/hooks';
import { Button } from 'src/v2/commonComponents/Button';

type TeamsUpgradeButtonsProps = {
  dataGTM: string;
};

export const TeamsUpgradeButtons = ({ dataGTM }: TeamsUpgradeButtonsProps) => {
  const { isTeamsUltraTier } = useSession();
  const { isOwner, isMember } = useTeamInfo();
  const { onChangeIsOpenInviteUsersModal } = useInviteUsersModal();
  const { onOpenGetEnterpriseModal } = useGetEnterpriseModal();

  const handleInviteUsers = () => {
    onChangeIsOpenInviteUsersModal(true);
  };

  const handleOpenGetEnterpriseModal = () => {
    onOpenGetEnterpriseModal();
  };

  return (
    <div className={styles.buttonsRow}>
      {!isMember && (
        <Button color="tertiary" onClick={handleInviteUsers}>
          Invite Users
        </Button>
      )}
      {isTeamsUltraTier ? (
        isOwner ? (
          <Button onClick={handleOpenGetEnterpriseModal}>Get Enterprise</Button>
        ) : null
      ) : (
        <UpgradeButton
          dataGTM={dataGTM}
          title={isOwner ? 'Upgrade' : 'Request Upgrade'}
        />
      )}
    </div>
  );
};

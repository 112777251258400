import { useCallback, useMemo } from 'react';
import {
  useAppDispatch,
  setLeftPanelSections,
  setIsLeftPanelExpanded,
} from 'src/store';
import { LeftPanelSections } from 'src/types';

interface LeftPanelDataProps {
  panelType: LeftPanelSections;
  isExpanded?: boolean;
}

export const useLeftPanelActions = () => {
  const dispatch = useAppDispatch();

  const toggleLeftPanelExpanded = useCallback(
    (value?: boolean) => {
      dispatch(setIsLeftPanelExpanded(value));
    },
    [dispatch],
  );

  const handleSetLeftPanelType = useCallback(
    (value: LeftPanelSections) => {
      dispatch(setLeftPanelSections(value));
    },
    [dispatch],
  );

  const setLeftPanelData = useCallback(
    ({ panelType, isExpanded }: LeftPanelDataProps) => {
      toggleLeftPanelExpanded(isExpanded);
      handleSetLeftPanelType(panelType);
    },
    [handleSetLeftPanelType, toggleLeftPanelExpanded],
  );

  return useMemo(
    () => ({
      setLeftPanelData,
      toggleLeftPanelExpanded,
    }),
    [setLeftPanelData, toggleLeftPanelExpanded],
  );
};

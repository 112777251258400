import styles from './Components.module.scss';
import React from 'react';

export const ContactSupportTicketSent = () => {
  return (
    <div className={styles.root}>
      <h2 className={styles.heading}>Thanks! We will back to you shortly.</h2>
    </div>
  );
};

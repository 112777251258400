import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { AppRoutes } from 'src/types';

interface GuestAuthLinksProps {
  className?: string;
  dataGTM: string;
}

export const GuestAuthLinks = ({ className, dataGTM }: GuestAuthLinksProps) => {
  return (
    <div
      className={classNames('nj-tier-auth-footer-button-wrapper', className)}
    >
      <Link
        to={AppRoutes.LOGIN}
        className="nj-tier-auth-footer-button login-button"
        data-e2e="login-button"
      >
        Login
      </Link>
      <Link
        id={dataGTM}
        to={AppRoutes.SIGN_UP}
        className="nj-tier-auth-footer-button signup-button"
        data-e2e="signup-button"
      >
        Sign Up
      </Link>
    </div>
  );
};

import {
  setIsOpenPurchaseSeatsModal,
  teamsState,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { useCallback, useMemo } from 'react';

export const usePurchaseSeatsModal = () => {
  const { isOpenPurchaseSeatsModal } = useAppSelector(teamsState);
  const dispatch = useAppDispatch();

  const onClosePurchaseSeatsModal = useCallback(() => {
    dispatch(setIsOpenPurchaseSeatsModal(false));
  }, [dispatch]);

  const onOpenPurchaseSeatsModal = useCallback(() => {
    dispatch(setIsOpenPurchaseSeatsModal(true));
  }, [dispatch]);

  return useMemo(
    () => ({
      isOpenPurchaseSeatsModal,
      onClosePurchaseSeatsModal,
      onOpenPurchaseSeatsModal,
    }),
    [
      isOpenPurchaseSeatsModal,
      onClosePurchaseSeatsModal,
      onOpenPurchaseSeatsModal,
    ],
  );
};

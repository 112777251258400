import styles from './PromptButton.module.scss';
import { useContext, useRef, useState } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { ArrowUUpLeft, Sparkle } from '@phosphor-icons/react';
import { PromptLoadingReason, SVG_SIZE_M } from 'src/constants';
import { useBreakpoint, useSession } from 'src/hooks';
import log from 'loglevel';
import { Spinner } from 'src/components/Loading';
import cn from 'classnames';
import { getBaseHeaders } from 'src/store/services/config';
import { bffTasksBaseUrl } from 'src/store/constants';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { IconButton } from 'src/v2/components/IconButton';
import { useRightSidePanelState } from 'src/hooks';

type PromptButtonProps = {
  isDisabled?: boolean;
};

const textDecoder = new TextDecoder();

export const PromptButton = ({ isDisabled = false }: PromptButtonProps) => {
  const { threadInputBoxRef } = useContext(ForwardRefContext);
  const { appUser } = useSession();
  const { isMobile } = useBreakpoint();
  const { shouldCompressThread } = useRightSidePanelState();
  const {
    threadInputBoxValue,
    setTemporaryInputValue,
    setPromptLoading,
    setPromptModificationReason,
    temporaryInputValue,
    setThreadInputBoxValue,
    promptLoading,
    promptModificationReason,
  } = useContext(ThreadInputBoxContext);
  const undoTriggerRef = useRef(false);

  const [improvedPrompt, setImprovedPrompt] = useState('');

  const handleReturnInputToFocus = () => {
    if (threadInputBoxRef?.current) {
      threadInputBoxRef.current.focus();
    }
  };

  const updatePrompt = async (response: Response, stream: boolean) => {
    let value = '';
    if (stream) {
      if (undoTriggerRef.current) {
        undoTriggerRef.current = false;
        return;
      }
      if (response.body) {
        if (response.body) {
          const reader = response.body.getReader();

          while (true) {
            const { done, value: chunk } = await reader.read();

            if (done) {
              break;
            }

            value += textDecoder.decode(chunk, { stream: true });

            if (undoTriggerRef.current) {
              undoTriggerRef.current = false;
              return;
            }

            setThreadInputBoxValue(value);
          }
          setImprovedPrompt(value);
        }
      }
    } else {
      const result = await response.json();
      setThreadInputBoxValue(result.improved_prompt);
      setImprovedPrompt(result.improved_prompt);
    }
  };

  const handleSendPrompt = async () => {
    try {
      setPromptLoading(true);
      setPromptModificationReason(PromptLoadingReason.IMPROVING);
      setTemporaryInputValue(threadInputBoxValue);
      setThreadInputBoxValue('');

      // TODO(Inna): it's a temporary workaround to handle response as a streaming (needs to investigate RTK ability)
      await fetch(
        `${bffTasksBaseUrl}/users/${appUser.user_id}/prompts/improve`,
        {
          method: 'POST',
          headers: await getBaseHeaders(
            new Headers({
              'Content-Type': 'application/json',
            }),
          ),
          body: JSON.stringify({ prompt: threadInputBoxValue, stream: true }),
        },
      )
        .then((response) => updatePrompt(response, true))
        .catch((err) => log.error(err));
      setPromptLoading(false);
    } catch (e) {
      if (undoTriggerRef.current) {
        undoTriggerRef.current = false;
      }
      setPromptLoading(false);
      setThreadInputBoxValue(temporaryInputValue);
      setTemporaryInputValue('');
      log.error(e);
    }
    handleReturnInputToFocus();
  };

  const handleUndo = () => {
    if (promptLoading) {
      undoTriggerRef.current = true;
    }
    setPromptLoading(false);
    setThreadInputBoxValue(temporaryInputValue);
    setTemporaryInputValue('');
    handleReturnInputToFocus();
  };

  // Cancel
  if (
    promptModificationReason === PromptLoadingReason.IMPROVING &&
    promptLoading
  ) {
    return (
      <>
        <IconButton
          type="button"
          onClick={handleUndo}
          customContent={<Spinner size={SVG_SIZE_M} inline />}
          size={SVG_SIZE_M}
          isMobile={isMobile}
          label="Cancel"
        />
      </>
    );
  }

  // Undo
  if (
    promptModificationReason === PromptLoadingReason.IMPROVING &&
    !!temporaryInputValue &&
    threadInputBoxValue.trim() === improvedPrompt.trim()
  ) {
    return (
      <>
        <IconButton
          type="button"
          onClick={handleUndo}
          customContent={<ArrowUUpLeft size={SVG_SIZE_M} />}
          size={SVG_SIZE_M}
          isMobile={isMobile}
          label="Undo"
        />
      </>
    );
  }

  return (
    <>
      <IconButton
        className={styles.translatePromptButton}
        icon={Sparkle}
        label="Improve prompt"
        size={SVG_SIZE_M}
        onClick={() => {
          handleSendPrompt();
        }}
        disabled={isDisabled || !threadInputBoxValue.trim()}
        isMobile={isMobile || shouldCompressThread}
      />
    </>
  );
  //Behave differently if the input is being handled by this component or by another one
  if (promptModificationReason === PromptLoadingReason.IMPROVING) {
    if (promptLoading || !!temporaryInputValue) {
      return (
        <button
          className={cn(styles.improvePromptButton, {
            [styles.iconButton]: !promptLoading && isMobile,
          })}
          type="button"
          onClick={handleUndo}
        >
          {promptLoading ? (
            <Spinner size={SVG_SIZE_M} inline />
          ) : (
            <ArrowUUpLeft size={SVG_SIZE_M} />
          )}
          {!isMobile && !promptLoading && <span>Undo</span>}
          {promptLoading && <span>Cancel</span>}
        </button>
      );
    } else {
      return (
        <button
          className={styles.improvePromptButton}
          type="button"
          disabled={isDisabled || !threadInputBoxValue.trim()}
          onClick={handleSendPrompt}
        >
          <Sparkle size={SVG_SIZE_M} />
          {!isMobile && <span>Improve Prompt</span>}
        </button>
      );
    }
  }

  //We are not handling the input text box
  return (
    <button
      className={styles.improvePromptButton}
      type="button"
      disabled={isDisabled || !threadInputBoxValue.trim()}
      onClick={handleSendPrompt}
    >
      <Sparkle size={SVG_SIZE_M} />
      {!isMobile && <span>Improve Prompt</span>}
    </button>
  );
};

import styles from './OwnerUnableJoinTeamModal.module.scss';
import { Modal } from 'src/components/Modal';
import { useOwnerUnableJoinTeamModal } from 'src/hooks';
import { Button } from 'src/v2/commonComponents/Button';
import { INVITATION_TOKEN, NINJA_SUPPORT_EMAIL } from 'src/constants';

export const OwnerUnableJoinTeamModal = () => {
  const {
    onChangeIsOpenOwnerUnableJoinTeamModal,
    isOpenOwnerUnableJoinTeamModal,
  } = useOwnerUnableJoinTeamModal();

  const handleClose = () => {
    onChangeIsOpenOwnerUnableJoinTeamModal(false);
    localStorage.removeItem(INVITATION_TOKEN);
  };

  return (
    <Modal
      open={isOpenOwnerUnableJoinTeamModal}
      onClose={handleClose}
      className={styles.ownerUnableJoinTeamModal}
      overlayClassName={styles.overlay}
      blockScroll={false}
    >
      <div className={styles.root}>
        <h4 className={styles.title}>Unable to Join Team</h4>
        <span className={styles.text}>
          It looks like you’re the owner of a team associated with this email.
          Reach out to{' '}
          <a className={styles.link} href={`mailto:${NINJA_SUPPORT_EMAIL}`}>
            {NINJA_SUPPORT_EMAIL}
          </a>{' '}
          to resolve this.
        </span>
        <div className={styles.buttonRow}>
          <Button onClick={handleClose} className={styles.button}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

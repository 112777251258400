import styles from './RequestUpgradeModal.module.scss';
import { Modal } from 'src/components/Modal';
import { useRequestUpgradeModal, useTeamInfo } from 'src/hooks';

export const RequestUpgradeModal = () => {
  const { teamInfo } = useTeamInfo();
  const { isOpenRequestUpgradeModal, onCloseRequestUpgradeModal } =
    useRequestUpgradeModal();

  return (
    <Modal
      open={isOpenRequestUpgradeModal}
      onClose={onCloseRequestUpgradeModal}
      className={styles.requestUpgradeModal}
      overlayClassName={styles.overlay}
      blockScroll={false}
    >
      <div className={styles.root}>
        <h4 className={styles.title}>Request upgrade</h4>
        <span className={styles.text}>
          <span className={styles.text}>
            To upgrade your business plan, please contact your workspace owner
            at{' '}
            <a
              className={styles.link}
              href={`mailto:${teamInfo?.owner_email || ''}`}
            >
              {teamInfo?.owner_email}
            </a>
          </span>
        </span>
        <div className={styles.buttonRow}>
          <button
            type="button"
            onClick={onCloseRequestUpgradeModal}
            className={styles.button}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

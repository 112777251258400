import { useContext, useRef } from 'react';
import { Notebook } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { useVisible, useBreakpoint } from 'src/hooks';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { PromptsModal } from './components/PromptsModal';
import { useGetPromptTemplatesQuery } from 'src/store/services';
import { useRightSidePanelState } from 'src/hooks';
import { Button } from 'src/v2/commonComponents/Button';

export const PromptTemplatesButton = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { shouldCompressThread } = useRightSidePanelState();
  const { isMobile } = useBreakpoint();
  const {
    isVisible: isModalVisible,
    onVisibilityRemove: onModalClose,
    onVisibilitySet: onModalOpen,
  } = useVisible();

  const {
    promptLoading,
    temporaryInputValue,
    setThreadFromPromptTemplate,
    setThreadInputBoxValue,
    setTemporaryInputValue,
  } = useContext(ThreadInputBoxContext);

  const { data: promptTemplates, isLoading } = useGetPromptTemplatesQuery();

  const handleButtonClick = () => {
    buttonRef.current?.blur();
    onModalOpen();
  };

  const setPrompt = (prompt: string) => {
    if (temporaryInputValue) {
      setTemporaryInputValue('');
    }
    setThreadFromPromptTemplate(prompt);
    setThreadInputBoxValue(prompt);
  };

  return (
    <div>
      <Button
        ref={buttonRef}
        color="transparent"
        onClick={handleButtonClick}
        disabled={isLoading || promptLoading}
      >
        <Notebook size={SVG_SIZE_M} />
        {!isMobile && !shouldCompressThread && <span>Prompt Library</span>}
      </Button>

      <PromptsModal
        isOpen={isModalVisible}
        onClose={onModalClose}
        data={promptTemplates}
        setPrompt={setPrompt}
      />
    </div>
  );
};

import styles from './Components.module.scss';
import React, { useCallback } from 'react';
import { Button } from 'src/v2/commonComponents/Button';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/types';

export const AlreadySigninSection = () => {
  const navigate = useNavigate();
  const signInCallback = useCallback(() => {
    navigate(AppRoutes.LOGIN);
  }, [navigate]);
  return (
    <div className={styles.root}>
      <h2 className={styles.heading}>You already have an account with Ninja</h2>
      <p>Please log in to start managing your new Business plan.</p>
      <Button
        className={styles.loginButton}
        type="button"
        onClick={signInCallback}
      >
        Login
      </Button>
    </div>
  );
};

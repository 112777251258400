import { useContext, useCallback } from 'react';
import dayjs from 'dayjs';
import DebugContext from 'src/contexts/DebugContext';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import AudioContext from 'src/contexts/AudioContext';
import {
  useSession,
  useFetchUserInput,
  useUserLocation,
  useConversationParams,
  useBanner,
  useTimeZone,
  useAttachFile,
} from 'src/hooks';
import {
  ConversationRole,
  MessageChannel,
  ChatMode,
  Message,
  Tag,
  AvatarAnimations,
  LocationSource,
  BannerType,
} from 'src/types';
import { animateMetahuman } from 'src/utils';
import { DEFAULT_CHAT_ID } from 'src/constants';
import { FileRequestPayload } from 'src/types/models/FileRequestPayload';
import { ImageEditRequestPayload } from 'src/types/models/ImageEditRequestPayload';
import { DATE_RANGE_STRING_FORMAT } from 'src/pages/ManageTasksChatPage/components/TaskScratchpad/components/CreateMeetingCard/constants/defaultValues';
import { TextRequestPayload } from 'src/types/models/TextRequestPayload';
import {
  useImageMaskingCurrentImageData,
  useImageMaskingPanelActions,
  useImageMaskingSelectedArea,
} from './imageMaskingPanelHooks';
import { useRightSidePanelActions } from './themeHooks/useRightSidePanelActions';

export const useSubmitUserInput = () => {
  const { debugMode } = useContext(DebugContext);
  const {
    onClearInputBox,
    onClearAttachments,
    threadInputBoxFiles,
    fileDataToSend,
    setFileDataToSend,
    convertedFromTextFilesLength,
    setThreadInputBoxTextError,
  } = useContext(ThreadInputBoxContext);
  const { threadMessageListAnchorRef } = useContext(ForwardRefContext);
  const { setMetaHumanTalking } = useContext(AudioContext);
  const { resetAndCloseRightSidePanelData } = useRightSidePanelActions();
  const {
    setImageMaskingIsLoading,
    resetImageMaskingPanelInfo,
    onChangeImageMaskingRightPanelData,
  } = useImageMaskingPanelActions();

  const { agent, chatMode, appUser, isFirstUserQuery, updateFirstUserQuery } =
    useSession();
  const { fetchAiData } = useFetchUserInput();
  const { setLocationInterval } = useUserLocation();
  const { currentConversationId } = useConversationParams();
  const { removeBanner } = useBanner();
  const { userTimeZone } = useTimeZone();
  const { handleUploadedImageMask } = useAttachFile();
  const { selectedImageMask } = useImageMaskingSelectedArea();
  const { currentMaskingImageData } = useImageMaskingCurrentImageData();

  const isAvatarMode = chatMode === ChatMode.AVATAR;

  const handleUpdateUserLocation = useCallback(() => {
    if (!isFirstUserQuery && appUser.location?.source !== LocationSource.GPS) {
      setLocationInterval();
      updateFirstUserQuery();
    }
  }, [isFirstUserQuery, appUser, updateFirstUserQuery, setLocationInterval]);

  const handleValidateSubmit = useCallback(() => {
    if (!appUser.rate_limited_until) {
      return true;
    }

    const currentTime = dayjs();
    const rateLimitedTime = dayjs(appUser.rate_limited_until);

    if (rateLimitedTime.isSameOrBefore(currentTime)) {
      removeBanner(BannerType.USER_RATE_LIMITED_UNTIL);
      setThreadInputBoxTextError('');
      return true;
    }

    const rateLimitedTimeString = rateLimitedTime
      .tz(userTimeZone)
      .format(DATE_RANGE_STRING_FORMAT);

    setThreadInputBoxTextError(
      `You have hit a rate limit until ${rateLimitedTimeString}.`,
    );

    return false;
  }, [
    appUser.rate_limited_until,
    userTimeZone,
    removeBanner,
    setThreadInputBoxTextError,
  ]);

  const onSubmitUserInput = useCallback(
    async (userInput: string, params?: Partial<Message>) => {
      if (!userInput.trim() && convertedFromTextFilesLength === 0) {
        return;
      }

      const isSubmitValidated = handleValidateSubmit();

      if (!isSubmitValidated) {
        return;
      }

      resetAndCloseRightSidePanelData();
      setImageMaskingIsLoading(true);
      handleUpdateUserLocation();

      if (isAvatarMode) {
        animateMetahuman(appUser.user_id, AvatarAnimations.THINKING, debugMode);
      }

      let imageMaskFileName: string | undefined;

      if (selectedImageMask) {
        imageMaskFileName = await handleUploadedImageMask(selectedImageMask);
      }

      const filteredFileList = !!threadInputBoxFiles
        ? Object.keys(threadInputBoxFiles).filter(
            (item) => !threadInputBoxFiles[item]?.errorMessage,
          )
        : [];

      const isEditing =
        !!threadInputBoxFiles &&
        Object.keys(threadInputBoxFiles).filter(
          (item) => threadInputBoxFiles[item]?.isEdit,
        ).length > 0;

      const filesList =
        !!threadInputBoxFiles && filteredFileList.length > 0
          ? filteredFileList.map((key) => {
              if (currentMaskingImageData?.url) {
                return {
                  payload_type: ImageEditRequestPayload.payload_type.IMAGE_EDIT,
                  image_url: currentMaskingImageData.url,
                  mask_url: imageMaskFileName
                    ? `upload://${imageMaskFileName}`
                    : null,
                };
              }

              return {
                payload_type: FileRequestPayload.payload_type.FILE,
                original_filename: threadInputBoxFiles[key]?.file?.name,
                converted_filename: threadInputBoxFiles[key]?.convertedFileName,
              };
            })
          : ([] as FileRequestPayload[]);

      const newMessage = {
        user_id: appUser.user_id,
        from_user_id: appUser.user_id,
        to_user_id: agent.user_id,
        channel: MessageChannel.WEB_APP,
        role: ConversationRole.USER,
        content: isEditing ? `${fileDataToSend} ${userInput}` : userInput,
        is_read: false,
        tag: Tag.CONVERSATION,
        timestamp: new Date().toISOString(),
        conversation_id: currentConversationId || DEFAULT_CHAT_ID,
        persona: appUser.persona,
        payload_list: [
          {
            payload_type: TextRequestPayload.payload_type.TEXT,
            content: userInput,
          },
          ...filesList,
        ],
        ...(params ? params : {}),
      } as Message;

      setFileDataToSend('');
      onClearInputBox();
      onClearAttachments();
      resetImageMaskingPanelInfo();
      onChangeImageMaskingRightPanelData(null);

      threadMessageListAnchorRef?.current?.onScrollToBottomMessageList(0);

      await fetchAiData(newMessage);

      if (isAvatarMode) {
        // TODO(olha): it's a workaround until we have "beginSpeech" event from the Avatar game
        setMetaHumanTalking(true);
      }
    },
    [
      convertedFromTextFilesLength,
      handleValidateSubmit,
      resetAndCloseRightSidePanelData,
      setImageMaskingIsLoading,
      handleUpdateUserLocation,
      isAvatarMode,
      selectedImageMask,
      threadInputBoxFiles,
      appUser.user_id,
      appUser.persona,
      agent.user_id,
      fileDataToSend,
      currentConversationId,
      setFileDataToSend,
      onClearInputBox,
      onClearAttachments,
      resetImageMaskingPanelInfo,
      onChangeImageMaskingRightPanelData,
      threadMessageListAnchorRef,
      fetchAiData,
      debugMode,
      handleUploadedImageMask,
      currentMaskingImageData?.url,
      setMetaHumanTalking,
    ],
  );

  return { onSubmitUserInput };
};

import { EmailProvider } from 'src/types/models/EmailProvider';
import { UserTier } from 'src/types/models/UserTier';

export const isGoogleEmailProvider = (
  emailProvider: EmailProvider | string | undefined,
) =>
  emailProvider === EmailProvider.GOOGLE ||
  emailProvider === EmailProvider.GOOGLE_WORKSPACE;

export const isOpenTier = (tier_id?: UserTier) => {
  return tier_id === UserTier.OPEN || tier_id === undefined;
};

export const isProTier = (tier_id?: UserTier) => {
  return (
    tier_id === UserTier.PRO ||
    tier_id === UserTier.PAID ||
    tier_id === UserTier.TESTING
  );
};

export const isLimitedTier = (tier_id?: UserTier) => {
  return (
    tier_id === UserTier.STANDARD ||
    tier_id === UserTier.FREE ||
    tier_id === UserTier.OPEN ||
    tier_id === UserTier.TEAMS_STANDARD
  );
};

export const isSubscribedTier = (tier_id?: UserTier) => {
  return (
    tier_id === UserTier.PAID ||
    tier_id === UserTier.STANDARD ||
    tier_id === UserTier.PRO ||
    tier_id === UserTier.ULTRA ||
    tier_id === UserTier.TESTING ||
    tier_id === UserTier.ENTERPRISE ||
    tier_id === UserTier.TEAMS_STANDARD ||
    tier_id === UserTier.TEAMS_PRO ||
    tier_id === UserTier.TEAMS_ULTRA
  );
};

export const isTeamsTier = (tier_id?: UserTier) => {
  return (
    tier_id === UserTier.TEAMS_PRO ||
    tier_id === UserTier.TEAMS_ULTRA ||
    tier_id === UserTier.TEAMS_STANDARD
  );
};

export const convertTierName = (name: string) => {
  return name.replace('teams_', 'Business ').replace('_', ' ');
};

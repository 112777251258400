import { WelcomeUpsellModal } from 'src/components/WelcomeUpsellModal';
import { useSession, useTeamInfo } from 'src/hooks';
import {
  useAppDispatch,
  toggleWelcomeModal,
  useAppSelector,
  modalsControlState,
  setWelcomeUpsellModalType,
} from 'src/store';
import { WelcomeUpsellModalType } from 'src/types';
import { batch } from 'react-redux';
import { LOCAL_STORAGE_VALUE, SHOWN_ONBOARDING_MODAL } from 'src/constants';
import { useMemo } from 'react';

export const WelcomeModal = () => {
  const dispatch = useAppDispatch();
  const { isOwner, isTeamInfoLoading, isTeamInfoFetching, teamInfo } =
    useTeamInfo();

  const { isVisibleWelcomeModal, welcomeUpsellModalType } =
    useAppSelector(modalsControlState);

  const {
    isOpenTier,
    isFreeTier,
    isStandardTier,
    isUltraTier,
    isProTier,
    isTeamsStandardTier,
    isTeamsUltraTier,
    isTeamsProTier,
  } = useSession();

  const handleCloseModal = () => {
    if (welcomeUpsellModalType === 'open-tier-welcome-modal' || isOpenTier) {
      localStorage.setItem(SHOWN_ONBOARDING_MODAL, LOCAL_STORAGE_VALUE);
    }
    batch(() => {
      dispatch(toggleWelcomeModal(false));
      dispatch(setWelcomeUpsellModalType(null));
    });
  };

  const modalType: WelcomeUpsellModalType | null = useMemo(() => {
    if (welcomeUpsellModalType) {
      return welcomeUpsellModalType;
    }
    if (isUltraTier) {
      return 'ultra-welcome-modal';
    }
    if (isProTier) {
      return 'pro-welcome-modal';
    }
    if (isStandardTier) {
      return 'standard-welcome-modal';
    }
    if (isFreeTier) {
      return 'free-tier-welcome-modal';
    }
    if (isOpenTier) {
      return 'open-tier-welcome-modal';
    }
    if (isTeamsUltraTier) {
      return 'teams-ultra-welcome-modal';
    }
    if (isTeamsProTier) {
      return 'teams-pro-welcome-modal';
    }
    if (isTeamsStandardTier) {
      return 'teams-standard-welcome-modal';
    }
    return null;
  }, [
    isUltraTier,
    isProTier,
    isStandardTier,
    isFreeTier,
    isOpenTier,
    welcomeUpsellModalType,
    isTeamsStandardTier,
    isTeamsUltraTier,
    isTeamsProTier,
  ]);

  if (!!modalType && modalType.includes('teams') && !teamInfo) {
    return null;
  }

  if (!modalType || isOwner || isTeamInfoLoading || isTeamInfoFetching) {
    return null;
  }

  return (
    <WelcomeUpsellModal
      modalType={modalType}
      isOpen={isVisibleWelcomeModal}
      onClose={handleCloseModal}
    />
  );
};

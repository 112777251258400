import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAttachFile } from 'src/hooks';
import { ImageEditorToolbar } from './components/Toolbar';
import { CanvasEditor } from './components/CanvasEditor';
import styles from './MaskImageEditor.module.scss';
import {
  useAttachedImageTooltipState,
  useImageMaskingCurrentImageData,
  useImageMaskingIsEditingMode,
  useImageMaskingPanelActions,
  useImageMaskingSelectionsCount,
  useImageMaskingShouldResetCanvas,
  useUndoRedo,
} from 'src/hooks/imageMaskingPanelHooks';
import { b64ToFileFormat } from 'src/utils/b64ToFileFormat';
import classNames from 'classnames';

const DEFAULT_BRUSH_SIZE = 44;

export const MaskImageEditor = forwardRef<HTMLDivElement>((_, ref) => {
  const {
    setImageMaskingSelectionsCount,
    setSelectedImageMask,
    triggerCanvasReset,
  } = useImageMaskingPanelActions();
  const { imageMaskingSelectionsCount } = useImageMaskingSelectionsCount();
  const { isEditingMode } = useImageMaskingIsEditingMode();
  const { currentMaskingImageData } = useImageMaskingCurrentImageData();
  const { shouldResetCanvas } = useImageMaskingShouldResetCanvas();
  const { isAttachedImageEditingMode } = useAttachedImageTooltipState();
  const { sendReceivedFile } = useAttachFile();
  const { push, undo, redo, canUndo, canRedo, resetUndoAndRedo } =
    useUndoRedo();

  const mainCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const maskCanvasRef = useRef<HTMLCanvasElement | null>(null);

  const [brushSize, setBrushSize] = useState(DEFAULT_BRUSH_SIZE);
  const [scale, setScale] = useState(1);

  const clearCanvases = useCallback(() => {
    const clearCanvas = (canvasRef: React.RefObject<HTMLCanvasElement>) => {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext('2d');
      if (canvas && ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
    };

    clearCanvas(mainCanvasRef);
    clearCanvas(maskCanvasRef);
  }, []);

  const handleDeleteSelections = useCallback(() => {
    clearCanvases();
    setImageMaskingSelectionsCount(0);
    setSelectedImageMask(null);
    resetUndoAndRedo();
    triggerCanvasReset();

    const maskCanvas = maskCanvasRef.current;
    const maskCtx = maskCanvas?.getContext('2d');

    if (maskCanvas && maskCtx) {
      maskCtx.fillRect(0, 0, maskCanvas.width, maskCanvas.height);
    }

    resetUndoAndRedo();
  }, [
    resetUndoAndRedo,
    clearCanvases,
    setImageMaskingSelectionsCount,
    setSelectedImageMask,
    triggerCanvasReset,
  ]);

  useEffect(() => {
    if (shouldResetCanvas) {
      handleDeleteSelections();
    }
  }, [shouldResetCanvas, handleDeleteSelections]);

  useEffect(() => {
    if (shouldResetCanvas) {
      return;
    }

    if (mainCanvasRef.current && maskCanvasRef.current) {
      const mainDataUrl = mainCanvasRef.current.toDataURL('image/png');
      const maskDataUrl = maskCanvasRef.current.toDataURL('image/png');

      push({ mainCanvas: mainDataUrl, maskCanvas: maskDataUrl });
    }
  }, [push, currentMaskingImageData, shouldResetCanvas]);

  const incrementSelectionsCount = useCallback(() => {
    setImageMaskingSelectionsCount(imageMaskingSelectionsCount + 1);
  }, [setImageMaskingSelectionsCount, imageMaskingSelectionsCount]);

  const decrementSelectionsCount = useCallback(() => {
    setImageMaskingSelectionsCount(
      Math.max(0, imageMaskingSelectionsCount - 1),
    );
  }, [setImageMaskingSelectionsCount, imageMaskingSelectionsCount]);

  const addBlackBgAndSaveMask = useCallback(() => {
    const hiddenCanvas = maskCanvasRef.current;
    if (!hiddenCanvas) {
      return;
    }

    const temporaryCanvas = document.createElement('canvas');
    temporaryCanvas.width = hiddenCanvas.width;
    temporaryCanvas.height = hiddenCanvas.height;
    const tempCtx = temporaryCanvas.getContext('2d');

    if (!tempCtx) {
      return;
    }

    tempCtx.fillStyle = 'black';
    tempCtx.fillRect(0, 0, temporaryCanvas.width, temporaryCanvas.height);

    tempCtx.globalCompositeOperation = 'source-over';
    tempCtx.drawImage(hiddenCanvas, 0, 0);

    const maskPng = temporaryCanvas.toDataURL('image/png');
    const file = b64ToFileFormat(maskPng, 'mask.png');

    setSelectedImageMask(file);
  }, [setSelectedImageMask]);

  const restoreCanvasState = useCallback(
    (mainDataUrl: string, maskDataUrl: string) => {
      const maskCanvas = maskCanvasRef.current;
      const visibleCanvas = mainCanvasRef.current;
      const maskCtx = maskCanvas?.getContext('2d');
      const visibleCtx = visibleCanvas?.getContext('2d');

      if (maskCanvas && maskCtx && visibleCanvas && visibleCtx) {
        const maskImg = new Image();
        maskImg.src = maskDataUrl;
        maskImg.onload = () => {
          maskCtx.clearRect(0, 0, maskCanvas.width, maskCanvas.height);
          maskCtx.drawImage(maskImg, 0, 0, maskCanvas.width, maskCanvas.height);
        };

        const mainImg = new Image();
        mainImg.src = mainDataUrl;
        mainImg.onload = () => {
          visibleCtx.clearRect(0, 0, visibleCanvas.width, visibleCanvas.height);
          visibleCtx.drawImage(
            mainImg,
            0,
            0,
            visibleCanvas.width,
            visibleCanvas.height,
          );
          addBlackBgAndSaveMask();
        };
      }
    },
    [addBlackBgAndSaveMask],
  );

  const handleUndo = useCallback(() => {
    if (!canUndo) {
      return;
    }

    const previousState = undo();

    if (previousState) {
      const { mainCanvas: mainDataUrl, maskCanvas: maskDataUrl } =
        previousState;

      restoreCanvasState(mainDataUrl, maskDataUrl);
      decrementSelectionsCount();
    }
  }, [canUndo, undo, restoreCanvasState, decrementSelectionsCount]);

  const handleRedo = useCallback(() => {
    if (!canRedo) {
      return;
    }

    const restoredState = redo();

    if (restoredState) {
      const { mainCanvas: mainDataUrl, maskCanvas: maskDataUrl } =
        restoredState;
      restoreCanvasState(mainDataUrl, maskDataUrl);
      incrementSelectionsCount();
    }
  }, [canRedo, redo, restoreCanvasState, incrementSelectionsCount]);

  return (
    <div ref={ref} className={styles.root}>
      <div
        className={classNames(styles.wrapper, {
          [styles.withoutNavigationButtons]: isAttachedImageEditingMode,
        })}
      >
        <ImageEditorToolbar
          brushSize={brushSize}
          setBrushSize={setBrushSize}
          currentMaskingImageData={currentMaskingImageData}
          sendReceivedFile={sendReceivedFile}
          handleDeleteSelections={handleDeleteSelections}
          handleUndo={handleUndo}
          handleRedo={handleRedo}
          isRedoButtonDisabled={!canRedo}
          isUndoButtonDisabled={!canUndo}
          isEditingMode={isEditingMode}
        />
        <CanvasEditor
          maskCanvasRef={maskCanvasRef}
          mainCanvasRef={mainCanvasRef}
          brushSize={brushSize}
          currentMaskingImageData={currentMaskingImageData}
          pushToUndoStack={push}
          isEditingMode={isEditingMode}
          incrementSelectionsCount={incrementSelectionsCount}
          addBlackBgAndSaveMask={addBlackBgAndSaveMask}
          scale={scale}
          setScale={setScale}
        />
      </div>
    </div>
  );
});

import { forwardRef } from 'react';
import classNames from 'classnames';
import styles from './OverlayBackground.module.scss';
import { FadeTransition } from 'src/animations/FadeTransition';

interface OverlayBackgroundProps {
  showOverlay: boolean;
  zIndex?: number;
  withTransparentTablet?: boolean;
  onClick?: () => void;
}

export const OverlayBackground = forwardRef<
  HTMLDivElement,
  OverlayBackgroundProps
>(({ showOverlay, zIndex = 0, withTransparentTablet, onClick }, ref) => {
  return (
    <FadeTransition inProp={showOverlay} nodeRef={ref}>
      <div
        className={classNames(styles.root, {
          [styles.transparentTablet]: withTransparentTablet,
        })}
        style={{ zIndex }}
        onClick={onClick}
        ref={ref}
      />
    </FadeTransition>
  );
});

import { useMemo } from 'react';
import {
  useLeftPanelState,
  useLeftPanelActions,
  useBreakpoint,
} from 'src/hooks';
import { ConversationListSection } from 'src/v2/components/LeftSidePanel/components/ConversationListSection';
import { LeftPanelSections } from 'src/types';
import { SectionAnimatedWrapper } from '../SectionAnimatedWrapper';
import { NinjaAgentsSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/NinjaAgentsSection';
import { Button } from 'src/v2/commonComponents/Button';
import { ArrowLineLeft } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import styles from './LeftPanelSection.module.scss';
import classNames from 'classnames';
import { NavSidebar } from '../NavSidebar';
import { UserMenuSection } from '../UserMenuSection';

export const LeftPanelSection = () => {
  const { leftPanelSections } = useLeftPanelState();
  const { toggleLeftPanelExpanded } = useLeftPanelActions();

  const { isMobile } = useBreakpoint();

  const sectionTitle: string = useMemo(() => {
    switch (leftPanelSections) {
      case LeftPanelSections.CONVERSATION_LIST:
        return 'Chat history';
      case LeftPanelSections.AGENTS_SETTINGS:
        return 'Ninja settings';
      case LeftPanelSections.USER_MENU:
        return 'Account';
      default:
        return '';
    }
  }, [leftPanelSections]);

  const section = useMemo(() => {
    switch (leftPanelSections) {
      case LeftPanelSections.CONVERSATION_LIST:
        return <ConversationListSection />;
      case LeftPanelSections.AGENTS_SETTINGS:
        return <NinjaAgentsSection />;
      case LeftPanelSections.USER_MENU:
        return <UserMenuSection />;

      default:
        return <></>;
    }
  }, [leftPanelSections]);

  const onCloseSection = () => {
    toggleLeftPanelExpanded();
  };

  return (
    <SectionAnimatedWrapper>
      <div className={styles.root}>
        {isMobile && <NavSidebar />}

        <div className={styles.container}>
          <div className={styles.header}>
            <p className={styles.title}>{sectionTitle}</p>

            <Button color="transparent" onClick={onCloseSection} data-e2e="hide-menu-button">
              <ArrowLineLeft size={SVG_SIZE_M} />
            </Button>
          </div>

          <div
            className={classNames(styles.sectionWrapper, {
              [styles.withScroll]:
                leftPanelSections !== LeftPanelSections.CONVERSATION_LIST,
            })}
          >
            {section}
          </div>
        </div>
      </div>
    </SectionAnimatedWrapper>
  );
};

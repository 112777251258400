import { useCallback, useContext } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { useSession } from 'src/hooks/useSession';
import { useGetURLForFileUploadMutation } from 'src/store/services';
import { FileObject } from 'src/types';
import {
  DEFAULT_CHAT_ID,
  MAX_FILE_SIZE,
  MAX_NUMBER_OF_FILES,
} from 'src/constants';
import { toast } from 'react-toastify';
import { getRandomFileId } from 'src/utils';
import { useDeleteAttachment } from './inputBoxHooks/useDeleteAttachment';
import { useConversationParams } from './conversationHooks';
import { useAttachedImageTooltipActions } from './imageMaskingPanelHooks';

const COMMON_ERROR_MESSAGE =
  ' File(s) failed to upload. Try again or check the file type and size limits.';
const MAX_SIZE_ERROR_MESSAGE = `Upload multiple files (up to ${MAX_NUMBER_OF_FILES}) at once`;
const MAX_PASTED_SIZE_ERROR_MESSAGE = 'Pasted text limit reached';

export const useAttachFile = () => {
  const {
    setAttachmentLoading,
    setThreadInputBoxTextError,
    setFileInfoToArray,
    setThreadInputBoxFiles,
    threadInputBoxFiles,
    convertedFromTextFilesLength,
  } = useContext(ThreadInputBoxContext);
  const {
    appUser: { user_id },
  } = useSession();
  const { currentConversationId } = useConversationParams();
  const [getFileUploadUrl, { isError }] = useGetURLForFileUploadMutation();
  const { onDeleteAllAttachments } = useDeleteAttachment();
  const { onToggleImageEditTooltipVisible } = useAttachedImageTooltipActions();

  const setError = useCallback(
    (message: string) => {
      setThreadInputBoxTextError(message);
    },
    [setThreadInputBoxTextError],
  );

  const addFile = useCallback(
    async (
      fileId: string,
      filesObject: {
        [key: string]: FileObject | null;
      },
    ) => {
      const file = filesObject[fileId]?.file as File;
      setFileInfoToArray(fileId, {
        ...filesObject[fileId],
        isLoading: true,
      });

      if (file.size > MAX_FILE_SIZE) {
        const errorMessage =
          'Sorry, the attached file is too large to process. Please upload a smaller one and try again.';
        toast.error(errorMessage);
        setFileInfoToArray(fileId, {
          isLoading: false,
          errorMessage: errorMessage,
        });
        return;
      }

      const result = await getFileUploadUrl({
        user_id,
        file_name: file.name.toLocaleLowerCase(),
        content_type: file.type,
        apiContext: {
          user_id,
          conversation_id:
            currentConversationId === DEFAULT_CHAT_ID
              ? ''
              : currentConversationId,
        },
      }).unwrap();

      if (!!result) {
        const formData = new FormData();
        for (const [key, value] of Object.entries(result.form_data.fields)) {
          formData.append(key, value);
        }
        formData.append('file', file);

        const uploadFileUrl = result.form_data?.url;

        await fetch(uploadFileUrl, {
          method: 'POST',
          body: formData,
          mode: 'cors',
        })
          .then((response) => {
            if (response.ok) {
              setFileInfoToArray(fileId, {
                ...filesObject[fileId],
                isLoading: false,
                convertedFileName: result.filename,
              });
            } else {
              setError(COMMON_ERROR_MESSAGE);
              toast.error(COMMON_ERROR_MESSAGE);
              setFileInfoToArray(fileId, {
                isLoading: false,
                errorMessage: COMMON_ERROR_MESSAGE,
              });
            }
          })
          .catch(() => {
            setError(COMMON_ERROR_MESSAGE);
            toast.error(COMMON_ERROR_MESSAGE);
            setFileInfoToArray(fileId, {
              isLoading: false,
              errorMessage: COMMON_ERROR_MESSAGE,
            });
          });
      } else if (isError) {
        setError(COMMON_ERROR_MESSAGE);
        toast.error(COMMON_ERROR_MESSAGE);
        setFileInfoToArray(fileId, {
          isLoading: false,
          errorMessage: COMMON_ERROR_MESSAGE,
        });
      }
    },
    [
      user_id,
      getFileUploadUrl,
      setError,
      setFileInfoToArray,
      isError,
      currentConversationId,
    ],
  );

  const sendReceivedFile = useCallback(
    async (url: string) => {
      const urlParts = new URL(url);
      const filePath = urlParts.pathname;
      const fileName = filePath.split('/').pop()?.toLocaleLowerCase();

      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], fileName || 'image.jpg', {
            type: `image/${fileName?.split('.')[1].toLowerCase()}`,
            lastModified: new Date().getTime(),
          });
          onDeleteAllAttachments();
          setAttachmentLoading(true);
          const fileId = getRandomFileId();
          const fileObject = {
            file: file,
            isLoading: false,
            errorMessage: null,
            isEdit: true,
          };
          setFileInfoToArray(fileId, fileObject);

          if (file.size > MAX_FILE_SIZE) {
            const errorMessage =
              'Sorry, the attached image is too large to process. Please upload a smaller file and try again.';
            setError(errorMessage);
            toast.error(errorMessage);
            setAttachmentLoading(false);
          } else {
            setAttachmentLoading(false);
          }
        });
    },
    [
      setError,
      setAttachmentLoading,
      setFileInfoToArray,
      onDeleteAllAttachments,
    ],
  );

  const handleUploadedFiles = useCallback(
    async (files: FileList | File[]) => {
      const numberOfExistingFiles = !!threadInputBoxFiles
        ? Object.keys(threadInputBoxFiles).length - convertedFromTextFilesLength
        : 0;

      let filesObject = {};
      const fileArray = Array.from(files);
      const totalFilesLength = numberOfExistingFiles + fileArray.length;
      const uploadedFilesArray =
        totalFilesLength > MAX_NUMBER_OF_FILES
          ? fileArray.slice(0, MAX_NUMBER_OF_FILES - numberOfExistingFiles)
          : fileArray;
      if (totalFilesLength > MAX_NUMBER_OF_FILES) {
        toast.error(MAX_SIZE_ERROR_MESSAGE);
      }

      if (uploadedFilesArray && uploadedFilesArray.length > 0) {
        try {
          setAttachmentLoading(true);
          uploadedFilesArray.forEach((file) => {
            const fileId = getRandomFileId();
            filesObject = {
              ...filesObject,
              [fileId]: {
                file: file,
                isLoading: false,
                errorMessage: null,
              },
            };
          });

          const newFiles = { ...threadInputBoxFiles, ...filesObject };
          setThreadInputBoxFiles(newFiles);
          await Promise.allSettled(
            Object.keys(filesObject).map((key) => addFile(key, filesObject)),
          );
          setAttachmentLoading(false);

          if (totalFilesLength === 1) {
            const isImageFileType =
              uploadedFilesArray[0].type.includes('image');

            if (isImageFileType) {
              onToggleImageEditTooltipVisible(true);
            }
          }
        } catch (e) {
          toast.error(COMMON_ERROR_MESSAGE);
          setAttachmentLoading(false);
        }
      }
    },
    [
      setThreadInputBoxFiles,
      threadInputBoxFiles,
      addFile,
      setAttachmentLoading,
      convertedFromTextFilesLength,
      onToggleImageEditTooltipVisible,
    ],
  );

  const handleUploadedImageMask = async (maskFile: File) => {
    if (maskFile) {
      try {
        return await addMaskFile(maskFile);
      } catch (e) {
        toast.error(COMMON_ERROR_MESSAGE);
        setAttachmentLoading(false);
      }
    }
  };

  const addMaskFile = async (file: File) => {
    const result = await getFileUploadUrl({
      user_id,
      file_name: file.name.toLocaleLowerCase(),
      content_type: file.type,
      apiContext: {
        user_id,
        conversation_id:
          currentConversationId === DEFAULT_CHAT_ID
            ? ''
            : currentConversationId,
      },
    }).unwrap();

    if (!!result) {
      const formData = new FormData();
      for (const [key, value] of Object.entries(result.form_data.fields)) {
        formData.append(key, value);
      }
      formData.append('file', file);

      const uploadFileUrl = result.form_data?.url;

      try {
        const response = await fetch(uploadFileUrl, {
          method: 'POST',
          body: formData,
          mode: 'cors',
        });

        if (response.ok) {
          return result.filename;
        } else {
          setError(COMMON_ERROR_MESSAGE);
          toast.error(COMMON_ERROR_MESSAGE);
          return;
        }
      } catch (error) {
        setError(COMMON_ERROR_MESSAGE);
        toast.error(COMMON_ERROR_MESSAGE);
        return;
      }
    } else if (isError) {
      setError(COMMON_ERROR_MESSAGE);
      toast.error(COMMON_ERROR_MESSAGE);
      return;
    }
  };

  const handleUpTextToFiles = useCallback(
    async (file: File) => {
      if (convertedFromTextFilesLength + 1 > MAX_NUMBER_OF_FILES) {
        toast.error(MAX_PASTED_SIZE_ERROR_MESSAGE);
        return;
      }

      if (file) {
        try {
          setAttachmentLoading(true);

          const fileId = getRandomFileId();
          const fileObject = {
            [fileId]: {
              file: file,
              isLoading: false,
              errorMessage: null,
            },
          };

          setThreadInputBoxFiles({ ...threadInputBoxFiles, ...fileObject });
          await addFile(fileId, fileObject);

          setAttachmentLoading(false);
        } catch (e) {
          toast.error(COMMON_ERROR_MESSAGE);
          setAttachmentLoading(false);
        }
      }
    },
    [
      setThreadInputBoxFiles,
      threadInputBoxFiles,
      addFile,
      setAttachmentLoading,
      convertedFromTextFilesLength,
    ],
  );

  return {
    addFile,
    sendReceivedFile,
    handleUploadedFiles,
    handleUpTextToFiles,
    handleUploadedImageMask,
  };
};

import { useMemo } from 'react';
import { useGetUserSubscriptionInfoQuery } from 'src/store/services';
import { useSession, useTeamInfo } from 'src/hooks';
import { isAppleOrAmpSource } from 'src/utils';

export const useSubscriptionInfo = () => {
  const { appUser, isSubscribedTier, isEnterpriseTier, isTeamsTier } =
    useSession();
  const { isOwner } = useTeamInfo();

  const {
    data: subscriptionInfo,
    isLoading: isSubscriptionInfoLoading,
    isFetching: isSubscriptionInfoFetching,
  } = useGetUserSubscriptionInfoQuery(
    {
      user_id: appUser.user_id,
    },
    {
      skip: !isSubscribedTier || isEnterpriseTier || (isTeamsTier && !isOwner),
    },
  );

  const isAppleOrAmpPayment = isAppleOrAmpSource(subscriptionInfo?.source);

  return useMemo(
    () => ({
      subscriptionInfo,
      isSubscriptionInfoLoading,
      isSubscriptionInfoFetching,
      isAppleOrAmpPayment,
    }),
    [
      subscriptionInfo,
      isSubscriptionInfoLoading,
      isSubscriptionInfoFetching,
      isAppleOrAmpPayment,
    ],
  );
};

import { SubscriptionPlan } from 'src/types';
import { getRoundedPriceWithCurrency } from 'src/utils';
import styles from './SummaryHeader.module.scss';
import { ProgressBar } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/ProgressBar';

interface SummaryHeaderProps {
  originalPrice?: number;
  dueToday?: number;
  selectedPlan?: SubscriptionPlan;
  additionalDescription?: string;
  isLoading?: boolean;
}

export const SummaryHeader = ({
  originalPrice = 0,
  dueToday = 0,
  selectedPlan,
  additionalDescription,
  isLoading,
}: SummaryHeaderProps) => {
  return (
    <div className={styles.header}>
      <h4 className={styles.topTitle}>Due today</h4>
      {isLoading ? (
        <ProgressBar
          progressLineClassName={styles.dueTodayLoaderLine}
          className={styles.dueTodayLoader}
        />
      ) : (
        <h3 className={styles.title}>
          {getRoundedPriceWithCurrency(dueToday)}
        </h3>
      )}
      {isLoading ? (
        <ProgressBar
          progressLineClassName={styles.descriptionLoaderLine}
          className={styles.descriptionLoader}
        />
      ) : (
        <p className={styles.subtitle}>
          Then {getRoundedPriceWithCurrency(originalPrice)} per{' '}
          {selectedPlan?.period === 'monthly' ? 'month' : 'year'}
          {additionalDescription}
        </p>
      )}
    </div>
  );
};

import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import { DotsThreeOutlineVertical } from '@phosphor-icons/react';
import React from 'react';
import { SVG_SIZE_M } from 'src/constants';
import { Seat } from 'src/types/models/Seat';
import { useSession, useTeamInfo, useVisible } from 'src/hooks';
import { SeatRole } from 'src/types/models/SeatRole';
import {
  useCancelInviteMutation,
  useResendInviteMutation,
  useUnassignSeatMutation,
  useUpdateSeatMutation,
} from 'src/store/services';
import log from 'src/utils/logger';
import styles from './TeamsTableMenu.module.scss';
import { SeatStatus } from 'src/types/models/SeatStatus';
import { ChangeWorkspaceModal } from 'src/v2/pages/manageUsersInWorkspace/components/ChangeWorkspaceModal';
import { toast } from 'react-toastify';
import { getErrorStatus } from 'src/utils';

type TeamsTableMenuProps = {
  seatInfo: Seat;
  statuses: SeatStatus;
  isActiveUsersTable: boolean;
};

export const TeamsTableMenu = ({
  seatInfo,
  statuses,
  isActiveUsersTable,
}: TeamsTableMenuProps) => {
  const { appUser } = useSession();
  const { seatInfo: currentUserSeatInfo, teamInfo } = useTeamInfo();
  const {
    isVisible: isContactNinjaVisible,
    onVisibilitySet: onOpenContactNinjaVisible,
    onVisibilityRemove: onCloseContactNinjaVisible,
  } = useVisible();

  const [unassignSeat] = useUnassignSeatMutation();
  const [cancelInvite] = useCancelInviteMutation();
  const [resendInvite] = useResendInviteMutation();

  const isOwner =
    !!currentUserSeatInfo && currentUserSeatInfo.role === SeatRole.OWNER;

  const [updateSeat] = useUpdateSeatMutation();

  const handleError = (e: unknown) => {
    if (getErrorStatus(e) === 403) {
      toast.error(
        "You don't have the necessary permissions to perform this action",
      );
    }
  };

  const handleRemoveUserFromWorkspace = async () => {
    try {
      await unassignSeat({
        current_user_id: appUser.user_id,
        user_id: seatInfo.user_id || '',
        team_id: teamInfo?.team_id || '',
        seat_id: seatInfo.seat_id,
        statuses: statuses,
      }).unwrap();
    } catch (e) {
      log.error('error', e);
      handleError(e);
    }
  };

  const handleChangeRole = async (role: SeatRole) => {
    try {
      await updateSeat({
        user_id: appUser.user_id,
        team_id: teamInfo?.team_id || '',
        seat_id: seatInfo.seat_id,
        seat: { role: role },
        statuses: statuses,
      }).unwrap();
    } catch (e) {
      log.error('error', e);
      handleError(e);
    }
  };

  const handleMakeOwner = async () => {
    onOpenContactNinjaVisible();
  };

  const handleResendInvite = async () => {
    try {
      await resendInvite({
        user_id: appUser.user_id,
        team_id: teamInfo?.team_id || '',
        seat_id: seatInfo.seat_id,
      }).unwrap();
      toast.success('Invite was resent');
    } catch (e) {
      log.error('error', e);
      handleError(e);
    }
  };

  const handleCancelInvite = async () => {
    try {
      await cancelInvite({
        user_id: appUser.user_id,
        team_id: teamInfo?.team_id || '',
        seat_id: seatInfo.seat_id,
      }).unwrap();
    } catch (e) {
      log.error('error', e);
      handleError(e);
    }
  };

  return (
    <>
      <Menu
        gap={8}
        menuClassName="nj-multi-menu"
        menuButton={
          <MenuButton className={styles.actionButton}>
            <DotsThreeOutlineVertical size={SVG_SIZE_M} alt="Actions menu" />
          </MenuButton>
        }
        align="end"
        transition
      >
        {isActiveUsersTable ? (
          <>
            {isOwner && seatInfo.role === SeatRole.OWNER ? (
              <MenuItem data-testid="make-owner-btn" onClick={handleMakeOwner}>
                Change owner
              </MenuItem>
            ) : (
              <MenuItem
                data-testid="remove-user-from-team-btn"
                onClick={handleRemoveUserFromWorkspace}
              >
                Remove user from workspace
              </MenuItem>
            )}
            {seatInfo.role === SeatRole.ADMIN && (
              <MenuItem
                data-testid="remove-as-admin-btn"
                onClick={() => handleChangeRole(SeatRole.MEMBER)}
              >
                Remove as admin
              </MenuItem>
            )}
            {seatInfo.role !== SeatRole.ADMIN &&
              seatInfo.role !== SeatRole.OWNER && (
                <MenuItem
                  data-testid="make-an-admin-btn"
                  onClick={() => handleChangeRole(SeatRole.ADMIN)}
                >
                  Make admin
                </MenuItem>
              )}
            {isOwner && seatInfo.role !== SeatRole.OWNER && (
              <MenuItem data-testid="make-owner-btn" onClick={handleMakeOwner}>
                Make workspace owner
              </MenuItem>
            )}
          </>
        ) : (
          <>
            <MenuItem
              data-testid="cancel-invite-btn"
              onClick={handleCancelInvite}
            >
              Cancel invite
            </MenuItem>
            <MenuItem
              data-testid="resend-invite-btn"
              onClick={handleResendInvite}
            >
              Resend invite
            </MenuItem>
          </>
        )}
      </Menu>
      <ChangeWorkspaceModal
        isOpen={isContactNinjaVisible}
        onClose={onCloseContactNinjaVisible}
      />
    </>
  );
};

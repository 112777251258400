import { useForm } from 'react-hook-form';
import cn from 'classnames';
import {
  audioLanguageOptions,
  ccLanguageOptions,
} from 'src/constants/languages';
import { useAvatarPreferences, useSession, useUserData } from 'src/hooks';
import { useLazyGetUserByIdQuery } from 'src/store/services';
import { Select } from 'src/components/Select';
import { AvatarVoiceID } from 'src/types/models/AvatarVoiceID';
import { AvatarLocale } from 'src/types/models/AvatarLocale';
import { FormProvider } from 'src/components/FormProvider';
import { DEFAULT_LANGUAGE } from 'src/constants';

type ExecutiveAssistanceFormData = {
  assistant_audio_language: string;
  close_caption_language: string;
};
interface ExecutiveAssistanceSettingsProps {
  className?: string;
}

/**
 * @deprecated
 */
export const ExecutiveAssistanceSettings = ({
  className,
}: ExecutiveAssistanceSettingsProps) => {
  const {
    appUser: { settings, user_id },
  } = useSession();
  const { updateUserData } = useUserData();
  const { avatarVoiceID, voiceOptions, selectedAvatarGender } =
    useAvatarPreferences();

  const [trigger] = useLazyGetUserByIdQuery();

  const methods = useForm<ExecutiveAssistanceFormData>({
    defaultValues: async () => {
      const result = await trigger(user_id, true);
      const { data } = result;
      return {
        assistant_audio_language:
          data?.settings?.video_language_setting?.avatar_audio_locale ||
          DEFAULT_LANGUAGE,
        close_caption_language:
          data?.settings?.video_language_setting?.avatar_cc_locale ||
          DEFAULT_LANGUAGE,
      };
    },
  });

  const getVoiceId = (avatarAudioLocale: string) => {
    const filteredVoices = voiceOptions.filter(
      ({ gender, languageCode }) =>
        languageCode === avatarAudioLocale && gender === selectedAvatarGender,
    );

    const isCurrentVoiceExist = filteredVoices.find(
      (item) => item.voiceID === avatarVoiceID,
    );

    if (isCurrentVoiceExist) {
      return avatarVoiceID;
    } else {
      return (
        filteredVoices.find((item) => item.isDefault)?.voiceID || avatarVoiceID
      );
    }
  };

  const handleAudioLanguageChange = (avatarAudioLocale: string) => {
    const voiceId = getVoiceId(avatarAudioLocale) as AvatarVoiceID;
    updateUserData({
      settings: {
        ...settings,
        video_language_setting: {
          ...settings?.video_language_setting,
          voice_id: voiceId,
          avatar_audio_locale: avatarAudioLocale as AvatarLocale,
        },
      },
    });
  };

  const handleCCLanguageChange = (avatarCCLocale: string) => {
    updateUserData({
      settings: {
        ...settings,
        video_language_setting: {
          ...settings?.video_language_setting,
          avatar_cc_locale: avatarCCLocale as AvatarLocale,
        },
      },
    });
  };

  return (
    <FormProvider<ExecutiveAssistanceFormData> methods={methods}>
      <form className={cn('nj-settings-tab-content', className)}>
        <div className="nj-settings-tab-content--form-field">
          <label className="nj-settings-tab-content--form-label">
            Audio language
          </label>
          <div className="nj-settings-tab-content--assistance-select-wrapper">
            <Select
              name="assistant_audio_language"
              options={audioLanguageOptions}
              isSearchable={false}
              onChangeHandler={handleAudioLanguageChange}
            />
          </div>
        </div>

        <div className="nj-settings-tab-content--form-field">
          <label className="nj-settings-tab-content--form-label">
            Closed Captions Language
          </label>
          <div className="nj-settings-tab-content--assistance-select-wrapper">
            <Select
              name="close_caption_language"
              options={ccLanguageOptions}
              isSearchable={false}
              onChangeHandler={handleCCLanguageChange}
            />
          </div>
        </div>

        <hr className="divider" />
      </form>
    </FormProvider>
  );
};

import styles from './InviteToTeamPage.module.scss';
import { Loading } from 'src/components/Loading';
import {
  useInvitationAccessDeniedModal,
  useInvitationInfo,
  useLogout,
  useQueryParams,
  useSession,
} from 'src/hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/types';
import { ACCEPTED_INVITE_MESSAGE_TEXT, INVITATION_TOKEN } from 'src/constants';
import { InvitationStatus } from 'src/types/models/InvitationStatus';
import { toast } from 'react-toastify';
import { getErrorStatus } from 'src/utils';

export const InviteToTeamPage = () => {
  const { appUser, isSubscribedTier, isOpenTier } = useSession();
  const { searchParams } = useQueryParams();
  const { logoutWithRedirectToSignUp } = useLogout();
  const navigate = useNavigate();
  const token = searchParams.invitation_token;
  const {
    invitationInfo,
    isInvitationInfoLoading,
    onAcceptInvite,
    invitationError,
  } = useInvitationInfo({
    token,
  });
  const { onOpenInvitationDeniedAccessModal } =
    useInvitationAccessDeniedModal();

  const handleAcceptInvite = async () => {
    await onAcceptInvite();
    navigate(AppRoutes.HOME, { replace: true });
  };

  useEffect(() => {
    if (!token) {
      navigate(AppRoutes.HOME, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (!!token && isOpenTier) {
      localStorage.setItem(INVITATION_TOKEN, token);
      navigate(AppRoutes.SIGN_UP, { replace: true });
    }
    if (!!token && getErrorStatus(invitationError) === 404) {
      localStorage.setItem(INVITATION_TOKEN, token);
      logoutWithRedirectToSignUp();
    }
    if (!!token && !isInvitationInfoLoading && !!invitationInfo) {
      if (invitationInfo.status === InvitationStatus.ACCEPTED) {
        navigate(AppRoutes.HOME, { replace: true });
        toast(ACCEPTED_INVITE_MESSAGE_TEXT);
      }

      if (invitationInfo.status === InvitationStatus.ACTIVE) {
        if (invitationInfo.user_email === appUser.email) {
          if (isSubscribedTier) {
            localStorage.setItem(INVITATION_TOKEN, token);
            navigate(AppRoutes.HOME, { replace: true });
          } else {
            handleAcceptInvite();
          }
        } else {
          localStorage.setItem(INVITATION_TOKEN, token);
          logoutWithRedirectToSignUp();
        }
      }

      if (
        invitationInfo.status === InvitationStatus.CANCELLED ||
        invitationInfo.status === InvitationStatus.EXPIRED
      ) {
        navigate(AppRoutes.HOME, { replace: true });
        onOpenInvitationDeniedAccessModal(
          invitationInfo?.team_name || '',
          invitationInfo?.user_email || '',
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    invitationInfo,
    isInvitationInfoLoading,
    appUser.email,
    token,
    isOpenTier,
    invitationError,
  ]);

  return (
    <div className={styles.root}>
      <Loading />
    </div>
  );
};

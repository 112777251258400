import { useMemo } from 'react';
import { useAppSelector, imageMaskingState } from 'src/store';

export const useMessageIdForSelectedImage = () => {
  const { messageId } = useAppSelector(imageMaskingState);

  return useMemo(
    () => ({
      messageId,
    }),
    [messageId],
  );
};

import { AiGenerationStatus, MediaType } from 'src/types';
import { MaskSad, SmileySad } from '@phosphor-icons/react';
import { SVG_SIZE_XL } from 'src/constants';
import styles from './ThreadMediaError.module.scss';
import { uppercaseFirstLetter } from 'src/utils';

interface ThreadMediaErrorProps {
  status?: AiGenerationStatus;
  mediaType: MediaType;
}

export const ThreadMediaError = ({
  status,
  mediaType,
}: ThreadMediaErrorProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.errorContainer}>
        {status === AiGenerationStatus.CONTENT_POLICY_VIOLATION ? (
          <>
            <MaskSad size={SVG_SIZE_XL} />
            <span>{`${uppercaseFirstLetter(mediaType)} violates content policies`}</span>
          </>
        ) : (
          <>
            <SmileySad size={SVG_SIZE_XL} />
            <span>{`Unable to generate ${mediaType}`}</span>
          </>
        )}
      </div>
    </div>
  );
};

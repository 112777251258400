import { useBreakpoint } from 'src/hooks';
import styles from './ManageAccountContentPage.module.scss';
import { X } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { SVG_SIZE_L } from 'src/constants';
import { AppRoutes } from 'src/types';
import cn from 'classnames';

interface ManageAccountContentPageProps {
  title: string;
  subtitle?: string;
  children: JSX.Element;
  className?: string;
  errorMessage?: string;
}

export const ManageAccountContentPage = ({
  title,
  subtitle,
  children,
  className,
  errorMessage,
}: ManageAccountContentPageProps) => {
  const { isDesktopAndUp } = useBreakpoint();

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        {subtitle && isDesktopAndUp && (
          <h5 className={styles.subtitle}>{subtitle}</h5>
        )}

        {errorMessage && (
          <h5 className={styles.errorMessage}>{errorMessage}</h5>
        )}

        <Link to={AppRoutes.HOME} className={styles.back}>
          <X size={SVG_SIZE_L} />
        </Link>

        {isDesktopAndUp && <hr className="divider" />}
      </div>

      <div className={styles.containerWrapper}>
        <div className={styles.container}>{children}</div>
      </div>
    </div>
  );
};

import { useCallback } from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { CreateSubscriptionActions, OnClickActions } from '@paypal/paypal-js';
import { env } from 'src/env';
import { AppRoutes, GTMEvent, SubscriptionPlan } from 'src/types';
import { useBanner, useSession } from 'src/hooks';
import { setWithExpiry } from 'src/utils/localStorage';
import {
  PAYPAL_PAYMENT_IN_PROGRESS,
  PAYPAL_PAYMENT_IN_PROGRESS_BANNER_TTL_SECONDS,
  PAYMENT_DATADOG_ERROR,
  PAYMENT_ERROR_MESSAGE,
} from 'src/constants';
import { useNavigate } from 'react-router-dom';
import { sendGTMEvent, sendPaymentGTMEvent } from 'src/utils';
import { toast } from 'react-toastify';
import { datadogLogs } from '@datadog/browser-logs';
import { OnApproveActions } from '@paypal/paypal-js/types/components/buttons';
import { useCreatePaypalSubscriptionMutation } from 'src/store/services';

const PAYMENT_PROVIDER = 'paypal';

interface PayPalElementsProps {
  selectedPlan?: SubscriptionPlan;
  onReady: () => void;
  onPaymentProcessing: (value: boolean) => void;
  numberOfSeats?: number;
}

export const PayPalElements = ({
  selectedPlan,
  onReady,
  onPaymentProcessing,
  numberOfSeats,
}: PayPalElementsProps) => {
  const navigate = useNavigate();

  const {
    appUser: { user_id },
  } = useSession();

  const [createPaypalSubscriptionMutation] =
    useCreatePaypalSubscriptionMutation();

  const { removeAllPaymentBanners } = useBanner();

  const createPaypalSubscription = useCallback(
    async (
      data: Record<string, unknown>,
      actions: CreateSubscriptionActions,
    ): Promise<string> => {
      if (selectedPlan?.metadata?.paypal) {
        return actions.subscription.create({
          plan_id: selectedPlan?.metadata?.paypal.plan_id,
          quantity: numberOfSeats ? numberOfSeats.toString() : undefined,
          custom_id: user_id,
        });
      }
      throw new Error(
        "Missing paypal details in the subscription plan's metadata",
      );
    },
    [selectedPlan?.metadata?.paypal, user_id, numberOfSeats],
  );

  const onApprovePaypalSubscription = useCallback(
    async (
      data: Record<string, unknown>,
      actions: OnApproveActions,
    ): Promise<void> => {
      onPaymentProcessing(true);
      try {
        await createPaypalSubscriptionMutation({
          user_id,
          subscription_id: data.subscriptionID as string,
        }).unwrap();
      } catch (e) {
        console.error(e);
        // No need to notify user, this event will be handled by PayPal webhook
        setWithExpiry(
          PAYPAL_PAYMENT_IN_PROGRESS,
          'true',
          PAYPAL_PAYMENT_IN_PROGRESS_BANNER_TTL_SECONDS,
        );
      }
      onPaymentProcessing(false);

      const dataGTM = {
        transaction_id: data.subscriptionID as string,
        subscription_plan: selectedPlan?.plan_tier,
        subscription_period: selectedPlan?.period,
        number_of_seats: numberOfSeats ? numberOfSeats.toString() : undefined,
      };
      sendPaymentGTMEvent(dataGTM);
      removeAllPaymentBanners();
      navigate(AppRoutes.HOME, { replace: true });
    },
    [
      onPaymentProcessing,
      selectedPlan?.plan_tier,
      selectedPlan?.period,
      navigate,
      removeAllPaymentBanners,
      createPaypalSubscriptionMutation,
      user_id,
      numberOfSeats,
    ],
  );

  const onErrorPaypalSubscription = useCallback(
    (error: Record<string, unknown>) => {
      localStorage.removeItem(PAYPAL_PAYMENT_IN_PROGRESS);

      toast(PAYMENT_ERROR_MESSAGE);

      datadogLogs.logger.error(PAYMENT_DATADOG_ERROR, {
        user_id,
        payment_provider: PAYMENT_PROVIDER,
        plan: selectedPlan?.plan_id,
        error: JSON.stringify(error),
      });
    },
    [selectedPlan?.plan_id, user_id],
  );

  const handlePaypalClick = (
    data: Record<string, unknown>,
    actions: OnClickActions,
  ) => {
    sendGTMEvent(GTMEvent.PAYMENT_METHOD_CLICK, {
      payment_method_type: data.fundingSource as string,
    });
  };

  return (
    <PayPalScriptProvider
      options={{
        clientId: env.REACT_APP_PAYPAL_CLIENT_ID,
        vault: true,
        intent: 'subscription',
      }}
    >
      <PayPalButtons
        createSubscription={createPaypalSubscription}
        onApprove={onApprovePaypalSubscription}
        onError={onErrorPaypalSubscription}
        onInit={onReady}
        fundingSource={'paypal'}
        onClick={handlePaypalClick}
        style={{
          layout: 'vertical',
          color: 'gold',
          label: 'paypal',
          borderRadius: 12,
          height: 53,
        }}
        forceReRender={[selectedPlan, numberOfSeats]}
      />
    </PayPalScriptProvider>
  );
};

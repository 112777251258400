import cn from 'classnames';
import { Icon, IconName } from 'src/components/Icon';
import styles from './ExternalModelThumbnail.module.scss';

const SVG_SIZE = 20;

interface ExternalModelThumbnailProps {
  isActive: boolean;
  title: string;
  icon_name: IconName | null;
  icon?: ({ size }: { size?: number }) => JSX.Element;
  disabled?: boolean;
  onClick: () => void;
  isCostPending?: boolean;
  price?: string;
}

export const ExternalModelThumbnail = ({
  isActive,
  title,
  icon: LogoIcon,
  icon_name,
  disabled,
  onClick,
  isCostPending = true,
  price,
}: ExternalModelThumbnailProps) => {
  return (
    <button
      className={cn(styles.root, { [styles.active]: isActive })}
      disabled={disabled}
      onClick={onClick}
    >
      <div className={styles.leftSideWrapper}>
        {LogoIcon ? (
          <LogoIcon size={SVG_SIZE} />
        ) : icon_name ? (
          <Icon size={SVG_SIZE} type={icon_name} />
        ) : null}
        <span className={cn(styles.title, 'ellipsis')}>{title}</span>
      </div>
      {/* {!isOpenTier && !isFreeTier && (
        <>
          {isCostPending ? (
            <LoadingLabel
              text="Calculating costs"
              isSelected={isActive}
              isBiggerShift
            />
          ) : (
            <span className={styles.costLabel}>{price}</span>
          )}
        </>
      )} */}
    </button>
  );
};

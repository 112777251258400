import { Tooltip, PlacesType } from 'react-tooltip';
import { isDesktop } from 'react-device-detect';
import styles from './HoverTooltip.module.scss';
import { HOVER_TOOLTIP_ID } from 'src/constants';

interface HoverTooltipProps {
  place?: PlacesType;
  noArrow?: boolean;
  offset?: number;
}

/**
 * For passing content we should use data-tooltip-content in the trigger, and data-tooltip-id for link tooltip ID with trigger.
 */
export const HoverTooltip = ({ place, noArrow, offset }: HoverTooltipProps) => {
  if (!isDesktop) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Tooltip
        id={HOVER_TOOLTIP_ID}
        noArrow={noArrow}
        className={styles.tooltip}
        imperativeModeOnly
        place={place}
        opacity={1}
        offset={offset}
      />
    </div>
  );
};

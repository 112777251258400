import React, { useCallback, MouseEvent, TouchEvent } from 'react';
import cn from 'classnames';
import { AiGenerationStatus, ImageGenRenderData } from 'src/types';
import { useBreakpoint, useDrawing } from 'src/hooks';
import styles from './Canvas.module.scss';
import { CustomCursor } from '../CustomCursor';

interface CanvasProps {
  mainCanvasRef: React.RefObject<HTMLCanvasElement>;
  maskCanvasRef: React.RefObject<HTMLCanvasElement>;
  imgRef: React.RefObject<HTMLImageElement>;
  customCursorRef: React.RefObject<HTMLDivElement>;
  isEditingMode: boolean;
  scale: number;
  brushSize: number;
  addBlackBgAndSaveMask: () => void;
  pushToUndoStack: (data: { mainCanvas: string; maskCanvas: string }) => void;
  incrementSelectionsCount: () => void;
  displayedHeight: number;
  currentMaskingImageData?: ImageGenRenderData | null;
  offsetLeft: number;
  displayedWidth: number;
  isCanvasVisible: boolean;
}

export const Canvas: React.FC<CanvasProps> = ({
  currentMaskingImageData,
  mainCanvasRef,
  maskCanvasRef,
  imgRef,
  customCursorRef,
  isEditingMode,
  scale,
  brushSize,
  offsetLeft,
  displayedWidth,
  isCanvasVisible,
  addBlackBgAndSaveMask,
  pushToUndoStack,
  incrementSelectionsCount,
  displayedHeight,
}) => {
  const { isMobileOrTablet } = useBreakpoint();
  const {
    startDrawing,
    draw,
    isDrawing,
    setIsDrawing,
    hasCanvasChanged,
    setLastX,
    setLastY,
  } = useDrawing(maskCanvasRef, mainCanvasRef, scale, brushSize, isEditingMode);

  const handleMouseUp = useCallback(() => {
    if (!isDrawing) {
      return;
    }

    setIsDrawing(false);
    setLastX(-1);
    setLastY(-1);

    if (hasCanvasChanged && maskCanvasRef.current && mainCanvasRef.current) {
      const maskDataUrl = maskCanvasRef.current.toDataURL('image/png');
      const mainDataUrl = mainCanvasRef.current.toDataURL('image/png');

      pushToUndoStack({ mainCanvas: mainDataUrl, maskCanvas: maskDataUrl });
      addBlackBgAndSaveMask();
      incrementSelectionsCount();
    }
  }, [
    hasCanvasChanged,
    incrementSelectionsCount,
    isDrawing,
    mainCanvasRef,
    maskCanvasRef,
    pushToUndoStack,
    addBlackBgAndSaveMask,
    setIsDrawing,
    setLastX,
    setLastY,
  ]);

  const handleMouseDown = useCallback(
    (e: MouseEvent<HTMLCanvasElement>) => {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      startDrawing(x, y);
    },
    [startDrawing],
  );

  const handleMouseMove = useCallback(
    (e: MouseEvent<HTMLCanvasElement>) => {
      if (customCursorRef.current && isEditingMode) {
        const xCursor = e.nativeEvent.offsetX;
        const yCursor = e.nativeEvent.offsetY;
        const halfBrush = brushSize / 3;

        if (
          xCursor < halfBrush ||
          yCursor < halfBrush ||
          xCursor > displayedWidth - halfBrush ||
          yCursor > displayedHeight - halfBrush
        ) {
          customCursorRef.current.style.display = 'none';
        } else {
          customCursorRef.current.style.display = 'block';
          customCursorRef.current.style.transform = `translate(${xCursor + offsetLeft - brushSize / 2}px, ${yCursor - brushSize / 2}px)`;
        }
      }

      const xD = e.nativeEvent.offsetX;
      const yD = e.nativeEvent.offsetY;
      draw(xD, yD);
    },
    [
      customCursorRef,
      isEditingMode,
      draw,
      displayedWidth,
      displayedHeight,
      offsetLeft,
      brushSize,
    ],
  );

  const handleTouchStart = useCallback(
    (e: TouchEvent<HTMLCanvasElement>) => {
      const rect = e.currentTarget.getBoundingClientRect();
      const touch = e.touches[0];
      const x = touch.clientX - rect.left;
      const y = touch.clientY - rect.top;
      startDrawing(x, y);
    },
    [startDrawing],
  );

  const handleTouchMove = useCallback(
    (e: TouchEvent<HTMLCanvasElement>) => {
      const rect = e.currentTarget.getBoundingClientRect();
      const t = e.touches[0];
      const xCursor = t.clientX - rect.left;
      const yCursor = t.clientY - rect.top;

      if (customCursorRef.current && isEditingMode) {
        if (
          xCursor < 0 ||
          yCursor < 0 ||
          xCursor > displayedWidth ||
          yCursor > displayedHeight
        ) {
          customCursorRef.current.style.display = 'none';
        } else {
          customCursorRef.current.style.display = 'block';
          customCursorRef.current.style.transform = `translate(${xCursor - brushSize / 2}px, ${yCursor - brushSize / 2}px)`;
        }
      }

      draw(xCursor, yCursor);
    },
    [
      customCursorRef,
      isEditingMode,
      draw,
      brushSize,
      displayedWidth,
      displayedHeight,
    ],
  );

  return (
    <>
      <img
        ref={imgRef}
        src={currentMaskingImageData?.url || ''}
        alt="background-image"
        className={cn(styles.backgroundImage, {
          [styles.nonVisible]:
            currentMaskingImageData?.status !== AiGenerationStatus.SUCCESS ||
            !isCanvasVisible,
        })}
        style={{
          left: `${offsetLeft}px`,
          top: 0,
          width: displayedWidth,
          height: displayedHeight,
        }}
      />

      <canvas
        ref={mainCanvasRef}
        className={cn(styles.mainCanvas, {
          [styles.nonVisible]:
            currentMaskingImageData?.status !== AiGenerationStatus.SUCCESS ||
            !isCanvasVisible,
        })}
        style={{
          left: `${offsetLeft}px`,
          top: 0,
          width: displayedWidth,
          height: displayedHeight,
          pointerEvents: isEditingMode ? 'auto' : 'none',
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleMouseUp}
      />

      <canvas ref={maskCanvasRef} className={styles.maskCanvas} />

      {!isMobileOrTablet && isEditingMode && (
        <CustomCursor customCursorRef={customCursorRef} brushSize={brushSize} />
      )}
    </>
  );
};

import { Modal } from 'src/components/Modal';
import styles from './ChangeWorkspaceModal.module.scss';
import { NINJA_SUPPORT_EMAIL } from 'src/constants';

type ChangeWorkspaceModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ChangeWorkspaceModal = ({
  isOpen,
  onClose,
}: ChangeWorkspaceModalProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={styles.changeWorkspaceModal}
      overlayClassName={styles.overlay}
      blockScroll={false}
    >
      <div className={styles.root}>
        <h4 className={styles.title}>Change workspace owner</h4>
        <span className={styles.text}>
          Each workspace can have one workspace owner, in order to change owner
          please contact{' '}
          <a className={styles.link} href={`mailto:${NINJA_SUPPORT_EMAIL}`}>
            {NINJA_SUPPORT_EMAIL}
          </a>
        </span>
        <div className={styles.buttonRow}>
          <button type="button" onClick={onClose} className={styles.button}>
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
};

import { ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useBreakpoint, useLeftPanelState } from 'src/hooks';
import { ANIMATION_TIMEOUT_MIDDLE } from 'src/constants';
import styles from './SectionAnimatedWrapper.module.scss';

interface SectionAnimatedWrapperProps {
  children: ReactNode;
}

// TODO(olha): WIP: use .module.scss
export const SectionAnimatedWrapper = ({
  children,
}: SectionAnimatedWrapperProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { isMobileOrTablet } = useBreakpoint();

  const { isLeftPanelExpanded } = useLeftPanelState();

  return (
    <CSSTransition
      in={isLeftPanelExpanded}
      timeout={ANIMATION_TIMEOUT_MIDDLE}
      classNames={
        isMobileOrTablet
          ? 'nj-animate-horizontal-appearance-ltr'
          : 'nj-animate-left-panel-width-reduce'
      }
      unmountOnExit
      nodeRef={containerRef}
    >
      <div ref={containerRef} className={styles.root}>
        {children}
      </div>
    </CSSTransition>
  );
};

import { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Check } from '@phosphor-icons/react';
import {
  useAvatarPreferences,
  useVisible,
  useSession,
  useUserData,
  useRightSettingsPanelState,
} from 'src/hooks';
import { Button } from 'src/components/Button';
import { ColorPicker } from 'src/components/ColorPicker';
import {
  hexColorToMetahumanFormat,
  changeMetahumanAppearance,
} from 'src/utils';
import { EnvironmentBackgroundID } from 'src/types/models/EnvironmentBackgroundID';

const SVG_SIZE = 40;

/**
 * @deprecated
 */
export const EnvironmentSelector = () => {
  const {
    environmentOptions,
    avatarEnvironment,
    avatarBackgroundHexColor,
    avatarBackgroundRgbColor,
    avatarCameraIndex,
  } = useAvatarPreferences();
  const { updateUserData } = useUserData();
  const { isVisible, onVisibilitySet, onVisibilityRemove } = useVisible();
  const { appUser } = useSession();
  const { isSettingsPanelExpanded } = useRightSettingsPanelState();

  const shouldShowColorPicker = useMemo(
    () =>
      !!environmentOptions.find(({ id }) => id === avatarEnvironment)
        ?.hasDynamicBackground,
    [avatarEnvironment, environmentOptions],
  );

  useEffect(() => {
    if (!isSettingsPanelExpanded && isVisible) {
      onVisibilityRemove();
    }
  }, [isSettingsPanelExpanded, isVisible, onVisibilityRemove]);

  const handleEnvironmentChange = (environment: string) => {
    changeMetahumanAppearance(
      appUser.user_id,
      environment,
      avatarCameraIndex || 0,
      avatarBackgroundRgbColor,
    );
    updateUserData({
      settings: {
        ...appUser.settings,
        theme_setting: {
          ...appUser.settings?.theme_setting,
          environment: environment as EnvironmentBackgroundID,
        },
      },
    });
  };

  const handleColorChange = (hexColor: string) => {
    const metahumanColor = hexColorToMetahumanFormat(hexColor);

    changeMetahumanAppearance(
      appUser.user_id,
      avatarEnvironment,
      avatarCameraIndex || 0,
      metahumanColor,
    );
    updateUserData({
      settings: {
        ...appUser.settings,
        theme_setting: {
          ...appUser.settings?.theme_setting,
          background_hex_color: hexColor,
        },
      },
    });
  };

  return (
    <div className="nj-section--main-container with-padding">
      <p className="nj-section--main-container-title">Environment background</p>

      <div className="nj-section--thumbnail-list">
        {environmentOptions.map(({ url, id }) => (
          <Button
            key={id}
            className="nj-section--thumbnail-item"
            onClick={() => handleEnvironmentChange(id)}
          >
            <img
              className={classNames('nj-section--thumbnail-image', {
                active: avatarEnvironment === id,
              })}
              src={url}
              alt="Ninja Environment"
            />

            {avatarEnvironment === id && (
              <span className="nj-section--thumbnail-mask">
                <Check size={SVG_SIZE} />
              </span>
            )}
          </Button>
        ))}
      </div>

      {shouldShowColorPicker && (
        <>
          <p className="nj-section--main-container-title">Lighting</p>

          {!isVisible ? (
            <div className="nj-section--thumbnail-list">
              <button
                className="nj-section--thumbnail-item"
                onClick={onVisibilitySet}
                style={{ backgroundColor: avatarBackgroundHexColor }}
              />
            </div>
          ) : (
            <ColorPicker
              className="nj-section--color-picker"
              hexColor={avatarBackgroundHexColor}
              onChange={handleColorChange}
            />
          )}
        </>
      )}
    </div>
  );
};

import { SeatStatus, UserTier } from 'src/types';

export enum TeamsUsersTabs {
  ACTIVE = 'Active',
  PENDING = 'Pending',
}

export type SeatsParams = {
  user_id: string;
  team_id: string;
  statuses: SeatStatus;
  page_token?: string;
};

export enum SeatsOptions {
  ADD = 'add',
  REMOVE = 'remove',
}

export type TierConfigItem = {
  labelShort: React.ReactNode | null;
  labelLong: React.ReactNode | null;
  upsellFooterTitle: string;
  upsellFooterDescription: string;
  upsellFooterSecondaryButtonTitle: string | null;
  upsellFooterPrimaryButtonTitle: string | null;
};

export type TierConfigType = Record<UserTier, TierConfigItem>;

export type TeamUserTier =
  | UserTier.TEAMS_STANDARD
  | UserTier.TEAMS_PRO
  | UserTier.TEAMS_ULTRA;

export type NonTeamUserTier = Exclude<UserTier, TeamUserTier>;

export type NonTeamTierConfigType = Record<NonTeamUserTier, TierConfigItem>;

export type TeamTierConfigType = Record<TeamUserTier, TierConfigItem>;

export const isNonTeamUserTier = (
  tierId: UserTier,
): tierId is NonTeamUserTier => {
  return ![
    UserTier.TEAMS_STANDARD,
    UserTier.TEAMS_PRO,
    UserTier.TEAMS_ULTRA,
  ].includes(tierId);
};

export const isTeamUserTier = (tierId: UserTier): tierId is TeamUserTier => {
  return [
    UserTier.TEAMS_STANDARD,
    UserTier.TEAMS_PRO,
    UserTier.TEAMS_ULTRA,
  ].includes(tierId);
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageTabs } from 'src/types';
import { onScrollToMessageByTask } from 'src/utils';

interface useMessageSwitchTabsProps {
  taskId: string | undefined;
  handleTabClick: (tab: string) => void;
  superAgentWithExternalModelName?: string;
}

export const useMessageSwitchTabs = ({
  taskId,
  handleTabClick,
  superAgentWithExternalModelName,
}: useMessageSwitchTabsProps) => {
  const [activeTab, setActiveTab] = useState<string>(MessageTabs.NINJA_AGENT);
  const [tabsWasChangedFromBody, setTabsWasChangedFromBody] =
    useState<boolean>(false);

  const superAgentTabName = superAgentWithExternalModelName
    ? superAgentWithExternalModelName
    : MessageTabs.SUPER_AGENT;

  const handleSeeSuperAgentAnswerButton = useCallback(() => {
    handleTabClick(superAgentTabName);
    setTabsWasChangedFromBody(true);
  }, [handleTabClick, superAgentTabName]);

  useEffect(() => {
    if (taskId && tabsWasChangedFromBody) {
      onScrollToMessageByTask(taskId);
      setTabsWasChangedFromBody(false);
    }
  }, [taskId, tabsWasChangedFromBody]);

  return useMemo(
    () => ({
      activeTab,
      setActiveTab,
      handleSeeSuperAgentAnswerButton,
    }),
    [activeTab, setActiveTab, handleSeeSuperAgentAnswerButton],
  );
};

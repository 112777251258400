import { Link } from 'react-router-dom';
import { AppRoutes } from 'src/types';

export const UpsellChatsMessage = () => {
  return (
    <div className="nj-upsell-message-chats" data-e2e="upsell-message-chat-history-label">
      <span>To save your chat history </span>
      <div>
        <Link to={AppRoutes.SIGN_UP} data-e2e="signup-button">Sign up</Link> or{' '}
        <Link to={AppRoutes.LOGIN} data-e2e="login-button">Login</Link>
      </div>
    </div>
  );
};

import { useSession, useSubscriptionInfo, useTeamInfo } from 'src/hooks';
import { useChangePlanMutation } from 'src/store/services';
import { useState } from 'react';
import { AppRoutes, SubscriptionPlan } from 'src/types';
import { toast } from 'react-toastify';
import log from 'src/utils/logger';
import styles from 'src/v2/pages/subscription/components/UpdateMonthlyToAnnuallySection/components/UpdateToAnnuallyButton/UpdateToAnnuallyButton.module.scss';
import { useNavigate } from 'react-router-dom';
import { APPLE_PAYMENT_ERROR_TEXT } from 'src/constants';

type UpdateToAnnuallyButtonProps = {
  selectedPlan?: SubscriptionPlan;
  onClosePanel: () => void;
};

export const UpdateToAnnuallyButton = ({
  selectedPlan,
  onClosePanel,
}: UpdateToAnnuallyButtonProps) => {
  const { appUser, isTeamsTier } = useSession();
  const { teamInfo } = useTeamInfo();
  const navigate = useNavigate();
  const [changePlan] = useChangePlanMutation();
  const { isAppleOrAmpPayment } = useSubscriptionInfo();

  const [isDisabledSubmit, setDisabledSubmit] = useState<boolean>(false);

  const handleConfirmAndPayClick = async () => {
    setDisabledSubmit(true);

    try {
      if (!!selectedPlan) {
        const data = await changePlan({
          user_id: appUser.user_id,
          product_key: selectedPlan.metadata?.stripe?.lookup_key || '',
          price_timestamp: selectedPlan.timestamp,
          seat_count: isTeamsTier ? teamInfo?.num_seats_total : undefined,
        }).unwrap();

        const redirectUrl = data?.approve_url;

        if (!!redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          navigate(AppRoutes.HOME);
          toast.success('Payment processing in progress');
        }
      }
    } catch (e) {
      toast.error('Subscription update failed');
      onClosePanel();
      log.error();
    }
  };

  return (
    <button
      type="button"
      className={styles.confirmAndPayButton}
      onClick={handleConfirmAndPayClick}
      disabled={isDisabledSubmit || isAppleOrAmpPayment}
      data-e2e="confirm-and-pay-button"
      title={isAppleOrAmpPayment ? APPLE_PAYMENT_ERROR_TEXT : ''}
    >
      Confirm & pay
    </button>
  );
};

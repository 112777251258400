import {
  FacebookLogo,
  InstagramLogo,
  XLogo,
  TiktokLogo,
  Desktop,
  DeviceMobile,
  Monitor,
  FilmStrip,
  Icon,
} from '@phosphor-icons/react';
import {
  ImageGenSettingsDimensionGroups,
  ImageGenSettingsDimensionGroupTypes,
  ImageGenSettingsDimensionOptionTypes,
  ImageGenSettingsResolutions,
  ImageGenSettingsSelectedDimension,
} from 'src/types';
import styles from './DimensionMoreOptions.module.scss';
import { SVG_SIZE_M } from 'src/constants';
import classNames from 'classnames';
import { uppercaseFirstLetter } from 'src/utils';

const IconsSet: Record<string, Icon> = {
  [ImageGenSettingsDimensionOptionTypes.FACEBOOK]: FacebookLogo,
  [ImageGenSettingsDimensionOptionTypes.INSTAGRAM]: InstagramLogo,
  [ImageGenSettingsDimensionOptionTypes.X]: XLogo,
  [ImageGenSettingsDimensionOptionTypes.TIKTOK]: TiktokLogo,
  [ImageGenSettingsDimensionOptionTypes.DESKTOP]: Desktop,
  [ImageGenSettingsDimensionOptionTypes.MOBILE]: DeviceMobile,
  [ImageGenSettingsDimensionOptionTypes.TV]: Monitor,
  [ImageGenSettingsDimensionOptionTypes.CINEMA]: FilmStrip,
};

interface DimensionMoreOptionsProps {
  dimensionGroups: ImageGenSettingsDimensionGroups;
  selectedData: ImageGenSettingsSelectedDimension;
  onClick: ({
    group,
    option,
  }: {
    group: ImageGenSettingsDimensionGroupTypes;
    option: ImageGenSettingsDimensionOptionTypes;
  }) => void;
}

export const DimensionMoreOptions = ({
  dimensionGroups,
  selectedData,
  onClick,
}: DimensionMoreOptionsProps) => {
  // TODO(olha): use the value from BE
  const handleDisablingCheck = (data: ImageGenSettingsResolutions) => {
    return data.resolutions && Object.values(data.resolutions).length > 0
      ? Object.values(data.resolutions || {}).every(
          ({ is_capable }) => !is_capable,
        )
      : true;
  };

  return (
    <div>
      {Object.entries(dimensionGroups)
        .filter(
          ([group]) => group !== ImageGenSettingsDimensionGroupTypes.PRIMARY,
        )
        .map(([group, data], index) => (
          <div key={group}>
            <p className={styles.groupTitle}>
              {uppercaseFirstLetter(group).replace('_', ' ')}
            </p>

            {Object.entries(data).map(
              ([option, resolutionData]: [
                option: string,
                resolutionData: ImageGenSettingsResolutions,
              ]) => {
                const CurrentIcon = IconsSet[option];

                return (
                  <button
                    className={classNames(styles.menuItem, {
                      [styles.active]: selectedData.option === option,
                      [styles.disabled]: handleDisablingCheck(resolutionData),
                    })}
                    key={option}
                    disabled={handleDisablingCheck(resolutionData)}
                    onClick={() =>
                      onClick({
                        group: group as ImageGenSettingsDimensionGroupTypes,
                        option: option as ImageGenSettingsDimensionOptionTypes,
                      })
                    }
                    data-e2e={`image-dimension-more-options-${option}`}
                  >
                    <span className={styles.title}>
                      <CurrentIcon size={SVG_SIZE_M} />

                      {resolutionData.name}
                    </span>
                    <span className={styles.subtitle}>
                      {resolutionData.aspect_ratio}
                    </span>
                  </button>
                );
              },
            )}

            {index < Object.keys(dimensionGroups).length - 2 && (
              <hr className={styles.divider} />
            )}
          </div>
        ))}
    </div>
  );
};

import logoHorizontalLight from 'src/images/logos/logo-light.svg';
import logoHorizontalDark from 'src/images/logos/logo-dark.svg';
import { useColorTheme } from 'src/hooks';
import styles from './CompanyLinks.module.scss';
import {
  Info,
  Article,
  Question,
  Files,
  ShieldCheck,
  Icon as IconType,
  ArrowSquareOut,
} from '@phosphor-icons/react';
import {
  DEFAULT_NINJATECH_BLOG_LINK,
  DEFAULT_NINJATECH_LINK,
  DEFAULT_NINJATECH_FAQ_LINK,
  DEFAULT_TERMS_OF_SERVICE_LINK,
  DEFAULT_ACCEPTABLE_USE_POLICY,
} from 'src/constants/externalLinks';
import { SVG_SIZE_M } from 'src/constants';

const COMPANY_LINKS: { Icon: IconType; title: string; link: string }[] = [
  {
    Icon: Info,
    title: 'Company',
    link: DEFAULT_NINJATECH_LINK,
  },
  {
    Icon: Article,
    title: 'Blog',
    link: DEFAULT_NINJATECH_BLOG_LINK,
  },
  {
    Icon: Question,
    title: 'FAQ',
    link: DEFAULT_NINJATECH_FAQ_LINK,
  },
  {
    Icon: Files,
    title: 'Terms',
    link: DEFAULT_TERMS_OF_SERVICE_LINK,
  },
  {
    Icon: ShieldCheck,
    title: 'Privacy policy',
    link: DEFAULT_ACCEPTABLE_USE_POLICY,
  },
];

export const CompanyLinks = () => {
  const { isDarkTheme } = useColorTheme();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {COMPANY_LINKS.map(({ Icon, title, link }) => (
          <a
            key={title}
            href={link}
            className={styles.link}
            target="_blank"
            rel="noreferrer"
          >
            <span className={styles.linkContainer}>
              <Icon size={SVG_SIZE_M} />
              {title}
            </span>

            <ArrowSquareOut size={SVG_SIZE_M} />
          </a>
        ))}
      </div>

      <a href={DEFAULT_NINJATECH_LINK} target="_blank" rel="noreferrer">
        <img
          src={isDarkTheme ? logoHorizontalLight : logoHorizontalDark}
          alt="NinjaTech AI"
          className={styles.logo}
        />
      </a>
    </div>
  );
};

import {
  useSession,
  useTeamInfo,
  useTierConfig,
  useInviteUsersModal,
  useSubscriptionInfo,
  useRequestUpgradeModal,
  useGetEnterpriseModal,
} from 'src/hooks';
import styles from './UpsellFooter.module.scss';
import { Button } from 'src/v2/commonComponents/Button';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { useNavigate } from 'react-router-dom';
import { AppRoutes, TeamsTabs } from 'src/types';
import {
  SUBSCRIPTION_PENDING_CHANGES_NOTIFICATION_MESSAGE,
  APPLE_PAYMENT_UPGRADE_ERROR_TEXT,
} from 'src/constants';
import { toast } from 'react-toastify';

export const UpsellFooter = () => {
  const navigate = useNavigate();

  const {
    isOpenTier,
    isEnterpriseTier,
    isEnterpriseTrialTier,
    isTeamsTier,
    isTeamsUltraTier,
  } = useSession();

  const {
    upsellFooterTitle,
    upsellFooterDescription,
    upsellFooterPrimaryButtonTitle,
    upsellFooterSecondaryButtonTitle,
  } = useTierConfig();

  const { onChangeIsOpenInviteUsersModal } = useInviteUsersModal();
  const { onOpenRequestUpgradeModal } = useRequestUpgradeModal();
  const { onOpenGetEnterpriseModal } = useGetEnterpriseModal();

  const { isOwner, isAdmin, isMember } = useTeamInfo();

  const { subscriptionInfo, isAppleOrAmpPayment } = useSubscriptionInfo();

  const isDisabledPayment =
    !!subscriptionInfo?.is_cancelled ||
    !!subscriptionInfo?.has_scheduled_downgrade ||
    !!subscriptionInfo?.has_scheduled_plan_change ||
    !!subscriptionInfo?.processing_plan_change ||
    isAppleOrAmpPayment;

  const handleSecondaryButtonClick = () => {
    if (isOwner || isAdmin) {
      onChangeIsOpenInviteUsersModal(true);
      return;
    }

    if (!isTeamsTier) {
      if (isDisabledPayment) {
        toast.error(
          isAppleOrAmpPayment
            ? APPLE_PAYMENT_UPGRADE_ERROR_TEXT
            : SUBSCRIPTION_PENDING_CHANGES_NOTIFICATION_MESSAGE,
        );
      } else {
        navigate(`${AppRoutes.PAYMENT}?payment_tab=${TeamsTabs.BUSINESS}`);
      }
    }
  };

  const handlePrimaryButtonClick = () => {
    if (isAdmin || isMember) {
      onOpenRequestUpgradeModal();
      return;
    }

    if (isDisabledPayment) {
      toast.error(
        isAppleOrAmpPayment
          ? APPLE_PAYMENT_UPGRADE_ERROR_TEXT
          : SUBSCRIPTION_PENDING_CHANGES_NOTIFICATION_MESSAGE,
      );
      return;
    }

    if (!isTeamsTier) {
      navigate(AppRoutes.PAYMENT);
      return;
    }

    if (isOwner && isTeamsUltraTier) {
      onOpenGetEnterpriseModal();
      return;
    }

    if (isOwner) {
      navigate(`${AppRoutes.PAYMENT}?payment_tab=${TeamsTabs.BUSINESS}`);
      return;
    }
  };

  if (isEnterpriseTier || isEnterpriseTrialTier) {
    return null;
  }

  if (
    !upsellFooterSecondaryButtonTitle &&
    !upsellFooterPrimaryButtonTitle &&
    !isOpenTier
  ) {
    return null;
  }

  return (
    <div className={styles.root}>
      <p className={styles.title}>{upsellFooterTitle}</p>
      <p className={styles.description}>{upsellFooterDescription}</p>

      {isOpenTier ? (
        <GuestAuthLinks dataGTM="Left-panel-signup" />
      ) : (
        <div className={styles.actions}>
          {upsellFooterSecondaryButtonTitle && (
            <Button
              onClick={handleSecondaryButtonClick}
              color="tertiary"
              className={styles.secondaryButton}
            >
              {upsellFooterSecondaryButtonTitle}
            </Button>
          )}

          {upsellFooterPrimaryButtonTitle && (
            <Button id="Left-panel-upgrade" onClick={handlePrimaryButtonClick}>
              {upsellFooterPrimaryButtonTitle}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

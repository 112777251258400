import styles from './UpdateMonthlyToAnnuallySection.module.scss';
import { useRef } from 'react';
import { AppearingFromRight } from 'src/animations/AppearingFromRight';
import { SubscriptionPlan } from 'src/types';
import { PanelHeader } from './components/PanelHeader';
import { UpdateToAnnuallyButton } from './components/UpdateToAnnuallyButton';
import { UpdateToAnnuallySummarySection } from './components/UpdateToAnnuallySummarySection';

interface UpdateMonthlyToAnnuallySectionProps {
  isOpen: boolean;
  onClose: () => void;
  selectedPlan?: SubscriptionPlan;
}

export const UpdateMonthlyToAnnuallySection = ({
  isOpen,
  onClose,
  selectedPlan,
}: UpdateMonthlyToAnnuallySectionProps) => {
  const checkoutContentRef = useRef<HTMLDivElement>(null);

  return (
    <AppearingFromRight
      width={320}
      inProp={isOpen}
      nodeRef={checkoutContentRef}
      enableTabletMode={false}
    >
      <div className={styles.root}>
        <PanelHeader onClose={onClose} />

        <div className={styles.mainContainer}>
          <UpdateToAnnuallySummarySection selectedPlan={selectedPlan} />
          <div className={styles.paymentContainer}>
            <UpdateToAnnuallyButton
              selectedPlan={selectedPlan}
              onClosePanel={onClose}
            />
          </div>
        </div>
      </div>
    </AppearingFromRight>
  );
};

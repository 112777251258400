import { Icon as PhosphorIconType } from '@phosphor-icons/react';
import {
  AiGenerationStatus,
  Message,
  ResearchDataReferenceCode,
  ResearchDataReferenceImage,
  ResearchDataReferenceLLM,
  ResearchDataReferenceVideo,
  ResearchDataReferenceWebPage,
  SearchImageReference,
  SearchVideoReference,
  SearchWebPageReference,
} from './api';
import {
  NINJA_AGENT_AS_EXTERNAL_MODEL,
  SUPER_AGENT_AS_EXTERNAL_MODEL,
} from 'src/constants/llmResources';
import { IconName } from 'src/components/Icon';

export type MediaReferences =
  | ResearchDataReferenceCode
  | ResearchDataReferenceImage
  | ResearchDataReferenceLLM
  | ResearchDataReferenceVideo
  | ResearchDataReferenceWebPage;

export enum MessageSkill {
  CODER = 'coder',
  RESEARCHER = 'researcher',
  SCHEDULER = 'scheduler',
  WRITER = 'writer',
  IMAGE_GENERATION = 'image',
  VIDEO_GENERATION = 'video',
  FILE_AGENT = 'file agent',
}

export enum RecommendationSkill {
  CODER = 'coder',
  RESEARCHER = 'researcher',
  SCHEDULER = 'scheduler',
  WRITER = 'writer',
  COPILOT = 'copilot',
  ASSISTANT = 'assistant',
}

export interface CreateMessageSharedLink {
  userId: string;
  messageId: string;
}

export enum ModalGalleryType {
  IMAGES_VIDEOS = 'IMAGES_VIDEOS',
  REFERENCES = 'REFERENCES',
  GENERATED_IMAGES = 'GENERATED_IMAGES',
  EXTERNAL_MODELS = 'EXTERNAL_MODELS',
  TEXT_FILE = 'TEXT_FILE',
}

export interface ExternalModelReference {
  url: string;
  title: string;
  icon_name: IconName | null;
  content: string;
  modelKey: string;
  originalQuery?: string;
}

export interface ExternalModelTabContent {
  tabTitle: string;
  iconName?: IconName;
  content: string;
  tabName: string;
  status?: AiGenerationStatus;
}

export interface MessageTabData extends ExternalModelTabContent {
  references?: SearchWebPageReference[];
  IconComponent?: PhosphorIconType;
}

export interface TextFileItem {
  url: string;
  title: string;
  content: string;
}

export interface ImageGenerationItem {
  url: string;
  title: string;
  icon: IconName | null;
  source?: string;
  isDisabled?: boolean;
  withWatermark?: boolean;
}

export type ModalGalleryItemType =
  | SearchVideoReference
  | SearchImageReference
  | SearchWebPageReference
  | ExternalModelReference
  | ImageGenerationItem
  | TextFileItem;

export type ModalGalleryList = ModalGalleryItemType[];

export type Citation = SearchWebPageReference | ExternalModelReference;

export interface ExtendedMessage extends Message {
  isBlinking?: boolean;
}

export const MessageTabs = {
  NINJA_AGENT: NINJA_AGENT_AS_EXTERNAL_MODEL,
  SUPER_AGENT: SUPER_AGENT_AS_EXTERNAL_MODEL,
} as const;

export type TabRegistryState = {
  isRead: boolean | null;
};

export type TabRegistry = {
  [tabName: string]: TabRegistryState;
};

export type MessageTabRegistry = {
  [messageId: string]: TabRegistry;
};

export type FeedbackOperationType = 'models' | 'message-level';

export type MediaType = 'image' | 'video';

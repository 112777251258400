import { Icon } from 'src/components/Icon';
import styles from './SeeSuperAgentAnswerButton.module.scss';
import { SVG_SIZE_M } from 'src/constants';
import { MessageTabs } from 'src/types';
import { useCallback } from 'react';
import classNames from 'classnames';

interface SeeSuperAgentAnswerButtonProps {
  onClick: (tab: string) => void;
}

export const SeeSuperAgentAnswerButton = ({
  onClick,
}: SeeSuperAgentAnswerButtonProps) => {
  const handleClick = useCallback(() => {
    onClick(MessageTabs.SUPER_AGENT);
  }, [onClick]);

  return (
    <div className={classNames(styles.root, styles.withGradient)}>
      <button className={styles.container} onClick={handleClick}>
        <Icon type="ninjaPixProLogo" size={SVG_SIZE_M} />

        <span className={styles.text}>
          See improved Multi-AI SuperAgent Answers
        </span>
      </button>
    </div>
  );
};

import styles from './UpsellMessageBlock.module.scss';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { UpgradeLearnMoreButtons } from 'src/pages/AuthPages/components/UpgradeLearnMoreButtons';
import { useSession, useTeamInfo } from 'src/hooks';
import { useMemo } from 'react';
import { TeamsUpgradeButtons } from 'src/v2/components/TeamsUpgradeButtons';

// todo review this component - it shows only with condition !isSubscribed now, maybe need refactoring
export const UpsellMessageBlock = () => {
  const {
    isOpenTier,
    isProTrialTier,
    isFreeTier,
    isStandardTier,
    isLimitedTier,
    isTeamsStandardTier,
  } = useSession();

  const { isMember } = useTeamInfo();

  const title = useMemo(() => {
    if (isOpenTier) {
      return 'Sign Up For Free';
    }
    if (isProTrialTier || isFreeTier) {
      return 'Unlimited Tasks, Starting at $5/mo';
    }

    if (isStandardTier) {
      return 'Unlimited external AI models';
    }

    if (isTeamsStandardTier) {
      return 'Get more with Ninja';
    }

    return '';
  }, [
    isOpenTier,
    isProTrialTier,
    isFreeTier,
    isStandardTier,
    isTeamsStandardTier,
  ]);

  const description = useMemo(() => {
    if (isOpenTier) {
      return 'Get access to your own AI assistant for research, writing, coding, image generation, scheduling and so much more. No credit card required.';
    }
    if (isProTrialTier || isFreeTier) {
      return 'Enjoy unlimited daily tasks, custom image settings, and access to the best AI models';
    }

    if (isStandardTier) {
      return 'Get answers from external models from companies like OpenAI, Google, and Anthropic';
    }

    if (isTeamsStandardTier && isMember) {
      return 'Ask your workspace owner to unlock more features.';
    }
    if (isTeamsStandardTier && !isMember) {
      return 'Expand your team & unlock advanced features';
    }

    return '';
  }, [
    isOpenTier,
    isProTrialTier,
    isFreeTier,
    isStandardTier,
    isTeamsStandardTier,
    isMember,
  ]);

  if (!isLimitedTier) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div>
          <div className={styles.titleContainer}>
            <h4 className={styles.title}>{title}</h4>
            {isProTrialTier && <span className={styles.tag}>Trial Offer</span>}
            {isOpenTier && <span className={styles.tag}>Limited time</span>}
          </div>
          <p className={styles.description}>{description}</p>
        </div>
        <div className={styles.buttonWrapper}>
          {isOpenTier && <GuestAuthLinks dataGTM="Chat-thread-signup" />}
          {(isProTrialTier || isFreeTier || isStandardTier) && (
            <UpgradeLearnMoreButtons dataGTM="Chat-thread-upgrade" />
          )}
          {isTeamsStandardTier && (
            <TeamsUpgradeButtons dataGTM="Chat-thread-upgrade" />
          )}
        </div>
      </div>
    </div>
  );
};

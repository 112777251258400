import { useCallback, useMemo } from 'react';
import {
  useAppSelector,
  teamsState,
  useAppDispatch,
  setIsOpenInviteUsersModal,
  setIsFirstInviteUsersRequest,
} from 'src/store';
import { batch } from 'react-redux';

export const useInviteUsersModal = () => {
  const { isOpenInviteUsersModal, isFirstInviteUsersRequest } =
    useAppSelector(teamsState);
  const dispatch = useAppDispatch();

  const onChangeIsOpenInviteUsersModal = useCallback(
    (value: boolean, isFirst?: boolean) => {
      batch(() => {
        dispatch(setIsOpenInviteUsersModal(value));
        dispatch(setIsFirstInviteUsersRequest(isFirst || false));
      });
    },
    [dispatch],
  );

  return useMemo(
    () => ({
      isFirstInviteUsersRequest,
      isOpenInviteUsersModal,
      onChangeIsOpenInviteUsersModal,
    }),
    [
      isFirstInviteUsersRequest,
      isOpenInviteUsersModal,
      onChangeIsOpenInviteUsersModal,
    ],
  );
};

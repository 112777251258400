import { ReactNode } from 'react';
import { Tooltip, PlacesType } from 'react-tooltip';
import classNames from 'classnames';
import styles from './ReactTooltip.module.scss';

interface ReactTooltipProps {
  id: string;
  isOpen: boolean;
  place?: PlacesType;
  offset?: number;
  className?: string;
  children: ReactNode;
  onClick?: () => void;
}

export const ReactTooltip = ({
  id,
  isOpen,
  place,
  offset,
  className,
  children,
  onClick,
}: ReactTooltipProps) => {
  return (
    <div className={styles.root} onClick={onClick}>
      <Tooltip
        anchorSelect={`#${id}`}
        isOpen={isOpen}
        place={place}
        clickable
        className={classNames(className, styles.tooltip)}
        noArrow
        openOnClick
        offset={offset}
        opacity={1}
        imperativeModeOnly
      >
        {children}
      </Tooltip>
    </div>
  );
};

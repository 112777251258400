import {
  MouseEvent,
  MouseEventHandler,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { CaretUp, CaretDown } from '@phosphor-icons/react';
import classNames from 'classnames';
import { Counter } from 'src/v2/commonComponents/Counter';
import SessionContext from 'src/contexts/SessionContext';
import { DEFAULT_CHAT_NAME, SVG_SIZE_M } from 'src/constants';
import { Conversation } from 'src/types';
import { ConversationMenu } from '../ConversationMenu';
import styles from './ConversationTitle.module.scss';

interface ConversationTitleProps {
  isSelected: boolean;
  isExpanded: boolean;
  hasTasks: boolean;
  toggleOpenRenameModal?: (val: boolean) => void;
  activeThreadAnchor: string | null;
  conversation: Conversation;
  toggleProps: {
    onClick?: React.MouseEventHandler<Element>;
    disabled?: boolean;
    [k: string]: unknown;
  };
  handleConversationTitleClick: MouseEventHandler<HTMLDivElement>;
}

export const ConversationTitle = ({
  isSelected,
  toggleOpenRenameModal,
  handleConversationTitleClick,
  conversation,
  isExpanded,
  hasTasks,
  activeThreadAnchor,
  toggleProps,
}: ConversationTitleProps) => {
  const threadRef = useRef<HTMLDivElement>(null);
  const { isVisibleTaskViewModal } = useContext(SessionContext);

  const {
    conversation_hash,
    number_tasks_require_attention,
    conversation_id,
    messages,
  } = conversation;

  const titleWithoutHash = useMemo(
    () => conversation_hash?.replace('#', '') || '',
    [conversation_hash],
  );

  const threadId = conversation_id || DEFAULT_CHAT_NAME;

  const handleMenuClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <div
      className={classNames(styles.root, {
        [styles.default]: threadId === DEFAULT_CHAT_NAME,
        [styles.hasMessages]: !!messages?.length,
        [styles.selected]: isSelected,
      })}
      onClick={handleConversationTitleClick}
      ref={threadRef}
      data-conversation-id={conversation_id}
      data-conversation-hash={conversation_hash}
    >
      <div
        className={classNames(styles.container, {
          [styles.withIndicator]: isSelected && !activeThreadAnchor,
        })}
      >
        {hasTasks && (
          <button className={styles.collapsedIcon} {...toggleProps}>
            {isExpanded ? (
              <CaretUp size={SVG_SIZE_M} />
            ) : (
              <CaretDown size={SVG_SIZE_M} />
            )}
          </button>
        )}

        <h3
          className={classNames(styles.conversationTitle, {
            [styles.hasNotification]: !!number_tasks_require_attention,
          })}
          data-e2e="conversation-title"
        >
          {titleWithoutHash}
        </h3>

        {!!number_tasks_require_attention && (
          <Counter
            number={number_tasks_require_attention}
            className={styles.counter}
          />
        )}

        <div className={styles.threeDotsMenu} onClick={handleMenuClick}>
          {isSelected && !isVisibleTaskViewModal && (
            <ConversationMenu
              conversation={conversation}
              toggleOpenRenameModal={toggleOpenRenameModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};

import {
  setIsDarkTheme,
  useAppDispatch,
  setIsSettingsPanelExpanded,
  setChatModeSidePanel,
} from 'src/store';
import { ChatModeSidePanel } from 'src/types';

// TODO(olha): after refactoring we can move updateIsDarkTheme to useColorTheme
export const useThemeActions = () => {
  const dispatch = useAppDispatch();

  /**
   * @deprecated
   */
  const toggleSettingsPanelExpanded = (value?: boolean) => {
    dispatch(setIsSettingsPanelExpanded(value));
  };

  const updateIsDarkTheme = (isDark: boolean) => {
    dispatch(setIsDarkTheme(isDark));
  };

  /**
   * @deprecated
   */
  const updateChatModeSidePanel = (panel: ChatModeSidePanel) => {
    dispatch(setChatModeSidePanel(panel));
  };

  return {
    setIsDarkTheme: updateIsDarkTheme,
    toggleSettingsPanelExpanded,
    updateChatModeSidePanel,
  };
};

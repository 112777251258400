import { imageGeneratorsModelsSet } from 'src/constants';
import { getDefaultModelIconName } from 'src/utils';
import { LazyReactPlayer } from 'src/components/LazyReactPlayer';
import styles from './ThreadVideoPayer.module.scss';
import { AiGenerationStatus, VideoGenData } from 'src/types';
import { ThreadMediaSkeleton } from '../ThreadMessage/components/ThreadMediaSkeleton';
import { ThreadMediaError } from '../ThreadMessage/components/ThreadMediaError';

interface ThreadVideoPayerProps {
  data: VideoGenData;
  isStreaming?: boolean;
}

export const ThreadVideoPayer = ({
  data,
  isStreaming,
}: ThreadVideoPayerProps) => {
  const { model, video_url, status } = data;

  const icon =
    model && imageGeneratorsModelsSet[model]
      ? imageGeneratorsModelsSet[model]?.icon_name || ''
      : getDefaultModelIconName(model || '');

  if (
    status === AiGenerationStatus.CONTENT_POLICY_VIOLATION ||
    status === AiGenerationStatus.FAILURE ||
    (!isStreaming && !video_url)
  ) {
    return (
      <div className={styles.root}>
        <ThreadMediaError status={status} mediaType="video" />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      {!video_url ? (
        <ThreadMediaSkeleton icon={icon || null} />
      ) : (
        <LazyReactPlayer
          url={video_url}
          controls
          playing
          style={{ borderRadius: '12px' }}
        />
      )}
    </div>
  );
};

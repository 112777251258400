import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import styles from './CustomLink.module.scss';

export const CustomLink = (props: ReactMarkdownProps) => {
  return (
    <a className={styles.root} target="_blank" rel="noreferrer" {...props}>
      {props.children}
    </a>
  );
};

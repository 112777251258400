import { useMemo } from 'react';
import { sessionState, useAppSelector } from 'src/store';
import { useGetSearchedTasksQuery } from 'src/store/services/searchApi';

interface UseConversationSearchParams {
  query: string;
  page: number;
  skills?: string[];
  states?: string[];
  isSearchedConversationsList?: boolean;
}

export const useConversationSearch = ({
  query,
  page,
  skills,
  states,
  isSearchedConversationsList = true,
}: UseConversationSearchParams) => {
  const {
    appUser: { user_id },
  } = useAppSelector(sessionState);

  const { data, isLoading, isSuccess, isError } = useGetSearchedTasksQuery(
    { user_id, query, page, skills, states },
    { skip: !user_id || !query },
  );

  return useMemo(
    () => ({
      isLoading,
      isSuccess,
      isError,
      data: data?.results || [],
      hasSearchResults: isSearchedConversationsList,
    }),
    [data, isLoading, isSuccess, isError, isSearchedConversationsList],
  );
};

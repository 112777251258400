import {
  setIsOpenRequestUpgradeModal,
  teamsState,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { useCallback, useMemo } from 'react';

export const useRequestUpgradeModal = () => {
  const { isOpenRequestUpgradeModal } = useAppSelector(teamsState);
  const dispatch = useAppDispatch();

  const onCloseRequestUpgradeModal = useCallback(() => {
    dispatch(setIsOpenRequestUpgradeModal(false));
  }, [dispatch]);

  const onOpenRequestUpgradeModal = useCallback(() => {
    dispatch(setIsOpenRequestUpgradeModal(true));
  }, [dispatch]);

  return useMemo(
    () => ({
      isOpenRequestUpgradeModal,
      onCloseRequestUpgradeModal,
      onOpenRequestUpgradeModal,
    }),
    [
      isOpenRequestUpgradeModal,
      onCloseRequestUpgradeModal,
      onOpenRequestUpgradeModal,
    ],
  );
};

import styles from './ChangeSeatsSection.module.scss';
import {
  useSelectedNumberOfSeats,
  useSubscriptionInfo,
  useTeamInfo,
} from 'src/hooks';
import { Button } from 'src/v2/commonComponents/Button';
import { PaymentsSubscriptionInfo } from 'src/types';
import { getAvailableSeatsCount } from 'src/utils';
import { APPLE_PAYMENT_ERROR_TEXT } from 'src/constants';

type ChangeSeatsSectionProps = {
  onOpenChangeSeatsCheckout: () => void;
  subscriptionInfo?: PaymentsSubscriptionInfo;
};

export const ChangeSeatsSection = ({
  onOpenChangeSeatsCheckout,
  subscriptionInfo,
}: ChangeSeatsSectionProps) => {
  const { teamInfo, isOwner } = useTeamInfo();
  const { onChangeSelectedNumberOfSeatsToBuy, selectedNumberOfSeatsToBuy } =
    useSelectedNumberOfSeats();
  const { isAppleOrAmpPayment } = useSubscriptionInfo();

  const isDisabled =
    (isOwner &&
      (subscriptionInfo?.has_scheduled_downgrade ||
        subscriptionInfo?.is_cancelled ||
        subscriptionInfo?.has_scheduled_plan_change ||
        subscriptionInfo?.processing_plan_change)) ||
    !isOwner ||
    isAppleOrAmpPayment;

  const availableNumberOfSeats = getAvailableSeatsCount(teamInfo);

  const handleChangeSeatsClick = () => {
    if (selectedNumberOfSeatsToBuy) {
      onChangeSelectedNumberOfSeatsToBuy(undefined);
    }
    onOpenChangeSeatsCheckout();
  };

  return (
    <div className={styles.root}>
      <h4 className={styles.title}>Seats</h4>
      <span className={styles.description}>
        Add or remove seats to match your teams current needs.
      </span>
      <div className={styles.valueRow}>
        <span className={styles.label}>Total seats</span>
        <span className={styles.value}>{teamInfo?.num_seats_total || 1}</span>
      </div>
      <div className={styles.valueRow}>
        <span className={styles.label}>Available seats</span>
        <span className={styles.value}>{availableNumberOfSeats}</span>
      </div>
      <Button
        className={styles.button}
        onClick={handleChangeSeatsClick}
        color="secondary"
        title={
          !isOwner
            ? 'Available for workspace owner'
            : isAppleOrAmpPayment
              ? APPLE_PAYMENT_ERROR_TEXT
              : ''
        }
        disabled={isDisabled}
      >
        Change seats
      </Button>
    </div>
  );
};

import { useContext, useEffect, useMemo } from 'react';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import {
  useRightSettingsPanelState,
  useBreakpoint,
  useSession,
  useLeftPanelState,
} from 'src/hooks';
import { ChatMode } from 'src/types';

const FULL_PICTURE_WIDTH = 960;
const LEFT_PANEL_WIDTH = 360;
const RIGHT_PANEL_WIDTH = 316;

export const usePictureInPicture = () => {
  const { showTranscript, toggleTranscript } = useContext(AvatarModeContext);

  const { isSettingsPanelExpanded } = useRightSettingsPanelState();
  const { isLeftPanelExpanded } = useLeftPanelState();

  const { chatMode } = useSession();
  const { isDesktopAndUp } = useBreakpoint();

  const leftOffset = useMemo(
    () => (isLeftPanelExpanded ? LEFT_PANEL_WIDTH : 0),
    [isLeftPanelExpanded],
  );
  const rightOffset = useMemo(
    () => (isSettingsPanelExpanded ? RIGHT_PANEL_WIDTH : 0),
    [isSettingsPanelExpanded],
  );

  const { innerWidth } = window;

  const containerWidth = useMemo(
    () => (isDesktopAndUp ? innerWidth - leftOffset - rightOffset : innerWidth),
    [innerWidth, isDesktopAndUp, leftOffset, rightOffset],
  );

  const isPictureInPicture = useMemo(() => {
    if (chatMode !== ChatMode.AVATAR) {
      return false;
    }
    if (!isDesktopAndUp) {
      return true;
    }

    if (containerWidth < FULL_PICTURE_WIDTH) {
      return true;
    }

    return false;
  }, [isDesktopAndUp, containerWidth, chatMode]);

  useEffect(() => {
    if (isPictureInPicture && !showTranscript) {
      toggleTranscript(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPictureInPicture]);

  return useMemo(
    () => ({
      isPictureInPicture,
    }),
    [isPictureInPicture],
  );
};

import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Icon, IconName } from 'src/components/Icon';
import { SVG_SIZE_XL } from 'src/constants';
import { useColorTheme } from 'src/hooks';
import styles from './ThreadMediaSkeleton.module.scss';

const { darkTheme: baseDark, lightTheme: baseLight } = {
  darkTheme: '#1d282d',
  lightTheme: '#e8eaea',
};

const { darkTheme: highlightDark, lightTheme: highlightLight } = {
  darkTheme: '#11181b',
  lightTheme: '#ffffff',
};

const { darkTheme: basePixProDark, lightTheme: basePixProLight } = {
  darkTheme: '#021733',
  lightTheme: '#CEE3FF',
};

const { darkTheme: highlightPixProDark, lightTheme: highlightPixProLight } = {
  darkTheme: '#0880A6',
  lightTheme: '#E7F9FF',
};

interface ThreadMediaSkeletonProps {
  isPixPro?: boolean;
  icon?: IconName | null;
}

export const ThreadMediaSkeleton = ({
  icon,
  isPixPro,
}: ThreadMediaSkeletonProps) => {
  const { isDarkTheme } = useColorTheme();

  const currentBaseColor = useMemo(() => {
    if (isPixPro) {
      return isDarkTheme ? basePixProDark : basePixProLight;
    }
    return isDarkTheme ? baseDark : baseLight;
  }, [isDarkTheme, isPixPro]);

  const currentHighlightColor = useMemo(() => {
    if (isPixPro) {
      return isDarkTheme ? highlightPixProDark : highlightPixProLight;
    }
    return isDarkTheme ? highlightDark : highlightLight;
  }, [isDarkTheme, isPixPro]);

  return (
    <div className={styles.root}>
      {icon && (
        <span className={styles.iconWrapper}>
          <Icon size={SVG_SIZE_XL} type={icon} />
        </span>
      )}

      <Skeleton
        baseColor={currentBaseColor}
        highlightColor={currentHighlightColor}
        duration={2}
        width="100%"
        height="100%"
        count={1}
      />
    </div>
  );
};

import { Fragment } from 'react';
import { PlayCircle } from '@phosphor-icons/react';
import { QuickTourModal } from 'src/pages/AuthPages/components/QuickTourModal';
import { useBreakpoint } from 'src/hooks';
import {
  useAppDispatch,
  useAppSelector,
  modalsControlState,
  toggleQuickTourModal,
} from 'src/store';
import { Button } from 'src/v2/commonComponents/Button';

const SVG_SIZE = 20;
const SVG_SIZE_MOBILE = 24;

export const QuickTour = () => {
  const dispatch = useAppDispatch();

  const { isVisibleQuickTourModal } = useAppSelector(modalsControlState);

  const { isMobileOrTablet } = useBreakpoint();

  const handleToggle = () => {
    dispatch(toggleQuickTourModal());
  };
  return (
    <Fragment>
      <Button
        onClick={handleToggle}
        shape="round"
        color="transparent"
        tooltipContent="How to use Ninja AI"
      >
        <PlayCircle
          size={isMobileOrTablet ? SVG_SIZE_MOBILE : SVG_SIZE}
          weight="fill"
        />
      </Button>

      <QuickTourModal isOpen={isVisibleQuickTourModal} onClose={handleToggle} />
    </Fragment>
  );
};

import { imageNumberOptions } from 'src/constants';
import { useSession } from 'src/hooks';
import styles from './ImagesPerModelToggle.module.scss';
import classNames from 'classnames';

interface ImagesPerModelToggleProps {
  selectedValue?: number;
  onChange: (value: number) => Promise<void>;
}

export const ImagesPerModelToggle = ({
  selectedValue,
  onChange,
}: ImagesPerModelToggleProps) => {
  const { isOpenTier, isStandardTier } = useSession();

  return (
    <div className={styles.root}>
      <label className={styles.label}>Images per model</label>

      <div className={styles.toggleContainer}>
        {imageNumberOptions.map(({ value }) => {
          const isDisabled = isOpenTier || (isStandardTier && value > 2);
          return (
            <button
              className={classNames(styles.button, {
                [styles.active]: selectedValue === value,
                [styles.disabled]: isDisabled,
              })}
              disabled={isDisabled}
              onClick={() => onChange(value)}
              key={value}
              data-e2e={`image-per-model-${value}-button`}
            >
              {value}
            </button>
          );
        })}
      </div>
    </div>
  );
};

import { useCallback, useMemo } from 'react';
import {
  useAppSelector,
  teamsState,
  useAppDispatch,
  setIsOpenOwnerUnableJoinTeamModal,
} from 'src/store';

export const useOwnerUnableJoinTeamModal = () => {
  const { isOpenOwnerUnableJoinTeamModal } = useAppSelector(teamsState);
  const dispatch = useAppDispatch();

  const onChangeIsOpenOwnerUnableJoinTeamModal = useCallback(
    (value: boolean) => {
      dispatch(setIsOpenOwnerUnableJoinTeamModal(value));
    },
    [dispatch],
  );

  return useMemo(
    () => ({
      isOpenOwnerUnableJoinTeamModal,
      onChangeIsOpenOwnerUnableJoinTeamModal,
    }),
    [isOpenOwnerUnableJoinTeamModal, onChangeIsOpenOwnerUnableJoinTeamModal],
  );
};

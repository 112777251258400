import {
  setInvitationAccessDeniedModalState,
  teamsState,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { useCallback, useMemo } from 'react';

export const useInvitationAccessDeniedModal = () => {
  const { invitationAccessDeniedModal } = useAppSelector(teamsState);
  const dispatch = useAppDispatch();

  const onCloseInvitationDeniedAccessModal = useCallback(() => {
    dispatch(
      setInvitationAccessDeniedModalState({
        isOpen: false,
        teamName: '',
        teamOwner: '',
      }),
    );
  }, [dispatch]);

  const onOpenInvitationDeniedAccessModal = useCallback(
    (teamName: string, teamOwner: string) => {
      dispatch(
        setInvitationAccessDeniedModalState({
          isOpen: true,
          teamName,
          teamOwner,
        }),
      );
    },
    [dispatch],
  );

  return useMemo(
    () => ({
      isInvitationAccessDeniedOpen: invitationAccessDeniedModal.isOpen,
      invitationAccessDeniedModalTeamName: invitationAccessDeniedModal.teamName,
      invitationAccessDeniedModalTeamOwner:
        invitationAccessDeniedModal.teamOwner,
      onCloseInvitationDeniedAccessModal,
      onOpenInvitationDeniedAccessModal,
    }),
    [
      invitationAccessDeniedModal,
      onCloseInvitationDeniedAccessModal,
      onOpenInvitationDeniedAccessModal,
    ],
  );
};

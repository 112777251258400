import { useSetState } from 'react-use';
import { useSession } from 'src/hooks';
import { DimensionToggle } from '../DimensionToggle';
import { ResolutionSlider } from '../ResolutionSlider';
import styles from './DimensionForm.module.scss';
import {
  ImageGenSettingsSelectedDimension,
  ImageGenSettingsDimensionGroupTypes,
  ImageGenSettingsDimensionOptionTypes,
  ImageGenSettingsDimensionResolutionTypes,
} from 'src/types';
import { useEffect } from 'react';
import { LabelPro } from 'src/components/LabelPro';
import { LabelUltra } from 'src/components/LabelUltra';
import cn from 'classnames';

const defaultDimensionState: ImageGenSettingsSelectedDimension = {
  group: ImageGenSettingsDimensionGroupTypes.PRIMARY,
  option: ImageGenSettingsDimensionOptionTypes.SQUARE,
  resolution: ImageGenSettingsDimensionResolutionTypes._720P,
};

interface DimensionFormProps {
  selectedData?: ImageGenSettingsSelectedDimension;
  onChange: (value: ImageGenSettingsSelectedDimension) => void;
}

export const DimensionForm = ({
  selectedData = defaultDimensionState,
  onChange,
}: DimensionFormProps) => {
  const {
    appUser: { settings },
    isOpenTier,
    isFreeTier,
  } = useSession();

  const isDisabled = isOpenTier || isFreeTier;

  const { groups } = settings?.image_gen_settings?.dimensions || {};

  const [selectedDimension, setSelectedDimension] =
    useSetState<ImageGenSettingsSelectedDimension>(selectedData);

  useEffect(() => {
    setSelectedDimension(selectedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  const handleDimensionChange = (
    value: Partial<ImageGenSettingsSelectedDimension>,
  ) => {
    setSelectedDimension(value);
    onChange({ ...selectedDimension, ...value });
  };

  const handleResolutionChange = (
    value: ImageGenSettingsDimensionResolutionTypes,
  ) => {
    setSelectedDimension({ resolution: value });
    onChange({ ...selectedDimension, resolution: value });
  };

  if (!groups) {
    return null;
  }

  return (
    <div className={cn(styles.root, { [styles.isDisabled]: isDisabled })} data-e2e="image-dimension-form">
      <label className={styles.label}>
        <span>Dimension</span>
        {isDisabled && (
          <>
            <LabelPro /> <LabelUltra />
          </>
        )}
      </label>

      <DimensionToggle
        dimensionGroups={groups}
        selectedData={selectedData}
        onChange={handleDimensionChange}
      />

      <ResolutionSlider
        dimensionGroups={groups}
        selectedData={selectedData}
        onChange={handleResolutionChange}
      />
    </div>
  );
};

type LabelNumberOfSeatsProps = {
  numberOfSeats: number;
};

export const LabelNumberOfSeats = ({
  numberOfSeats,
}: LabelNumberOfSeatsProps) => {
  return (
    <label
      className="nj-label-number-of-seats"
      data-e2e="label-number-of-seats"
    >
      {numberOfSeats} seat(s)
    </label>
  );
};

import { useCallback } from 'react';
import {
  avatarState,
  clearAvatarQueue,
  clearNotification,
  sessionState,
  setAppUser,
  setAvatarQueue,
  setChatMode,
  setFirstUserQuery,
  setIsAccessModalShown,
  setOnboardingHintStep,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { AppUser, AvatarQueue, ChatMode } from 'src/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserTier } from 'src/types/models/UserTier';
import { updateRenewEarlyStatus } from 'src/store/slices/sessionSlice';
import {
  isLimitedTier,
  isOpenTier,
  isProTier,
  isSubscribedTier,
  isTeamsTier,
} from 'src/utils/user';

export const useSession = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    agent,
    appUser,
    notification,
    isSocketConnected,
    onboardingHintsStep,
    isAccessModalShown,
    isFirstSchedulerTask,
    isFirstUserQuery,
  } = useAppSelector(sessionState);
  const { chatMode, avatarQueue } = useAppSelector(avatarState);

  const dispatch = useAppDispatch();

  const updateChatMode = (mode: ChatMode) => {
    dispatch(setChatMode(mode));

    if (pathname !== '/' && mode !== ChatMode.CALENDAR) {
      navigate('/');
    }
  };

  const removeNotification = () => {
    dispatch(clearNotification());
  };

  const changeOnboardingHintStep = (value: number) => {
    dispatch(setOnboardingHintStep(value));
  };

  const updateAppUser = (user: AppUser) => {
    dispatch(setAppUser(user));
  };

  const updateAvatarQueue = (value?: AvatarQueue) => {
    if (!value) {
      dispatch(clearAvatarQueue());
    } else {
      dispatch(setAvatarQueue(value));
    }
  };

  const setRenewEarlyPending = () => {
    dispatch(
      updateRenewEarlyStatus({
        renew_early_pending_subscription: {
          subscription_id: '',
          period_start: '',
          period_end: '',
        },
      }),
    );
  };

  const updateIsAccessModalShown = (value: boolean) => {
    dispatch(setIsAccessModalShown(value));
  };

  const updateFirstUserQuery = useCallback(() => {
    dispatch(setFirstUserQuery());
  }, [dispatch]);

  return {
    notification,
    removeNotification,
    agent,
    chatMode,
    updateChatMode,
    appUser,
    updateAppUser,
    isSocketConnected,
    onboardingHintsStep,
    changeOnboardingHintStep,
    avatarQueue,
    updateAvatarQueue,
    isAccessModalShown,
    isFirstSchedulerTask,
    updateIsAccessModalShown,
    isFirstUserQuery,
    updateFirstUserQuery,
    isOpenTier: isOpenTier(appUser?.tier_id),
    isFreeTier: appUser?.tier_id === UserTier.FREE,
    isStandardTier: appUser?.tier_id === UserTier.STANDARD,
    isProTier: isProTier(appUser?.tier_id),
    isUltraTier: appUser?.tier_id === UserTier.ULTRA,
    isSubscribedTier: isSubscribedTier(appUser?.tier_id),
    // TODO(olha): experimental variable.
    isLimitedTier: isLimitedTier(appUser?.tier_id),
    isProTrialTier: appUser?.tier_id === UserTier.PRO_TRIAL,
    isTestTier: appUser?.tier_id === UserTier.TESTING,
    isEnterpriseTrialTier: appUser?.tier_id === UserTier.ENTERPRISE_TRIAL,
    isEnterpriseTier: appUser?.tier_id === UserTier.ENTERPRISE,
    isTeamsTier: isTeamsTier(appUser?.tier_id),
    isTeamsProTier: appUser.tier_id === UserTier.TEAMS_PRO,
    isTeamsStandardTier: appUser?.tier_id === UserTier.TEAMS_STANDARD,
    isTeamsUltraTier: appUser?.tier_id === UserTier.TEAMS_ULTRA,
    tierId: appUser?.tier_id || UserTier.OPEN,
    setRenewEarlyPending,
  };
};

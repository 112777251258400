import { SVG_SIZE_M } from 'src/constants';
import { Warning } from '@phosphor-icons/react';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { ModalGalleryType } from 'src/types';
import styles from './FileImageThumbnail.module.scss';
import { useFileUrl } from 'src/hooks/useFileUrl';
import { SpinnerSimple } from 'src/v2/commonComponents/SpinnerSimple';

type FileImageThumbnailProp = {
  fileName: string;
  originalFileName?: string;
  url?: string;
};
export const FileImageThumbnail = ({
  fileName,
  originalFileName,
  url,
}: FileImageThumbnailProp) => {
  const { onChangeModalGalleryData } = useContext(SessionContext);
  const { url: fileUrl, isError } = useFileUrl(fileName, !!url);

  if (!fileUrl && !url) {
    return (
      <div className={styles.fileImageWrapper}>
        {isError ? (
          <Warning
            size={SVG_SIZE_M}
            weight="fill"
            className={styles.errorIcon}
          />
        ) : (
          <SpinnerSimple size={SVG_SIZE_M} />
        )}
      </div>
    );
  }

  const handleImageClick = () => {
    onChangeModalGalleryData(
      true,
      ModalGalleryType.IMAGES_VIDEOS,
      [
        {
          url: fileUrl || url || '',
          title: originalFileName || fileName,
          height: 400,
          width: 400,
          source: '',
        },
      ],
      0,
    );
  };

  return (
    <img
      src={fileUrl || url || ''}
      alt={fileName}
      className={styles.fileImage}
      onClick={handleImageClick}
    />
  );
};

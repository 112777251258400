import { SVG_SIZE_S } from 'src/constants';
import { useDownloadFile } from 'src/hooks';
import { Icon, IconName } from 'src/components/Icon';
import styles from './ThreadMediaHeader.module.scss';
import { DownloadSimple } from '@phosphor-icons/react';
import { Button } from 'src/v2/commonComponents/Button';
import classNames from 'classnames';
import { formatDuration } from 'src/utils';
import { MediaType } from 'src/types';

interface ThreadMediaHeaderProps {
  mediaType: MediaType;
  thumbnail: string;
  isEditDisabled?: boolean;
  icon?: IconName;
  videoDuration?: number;
  videoUrl?: string;
}

export const ThreadMediaHeader = ({
  mediaType,
  thumbnail,
  icon,
  videoDuration,
  videoUrl,
}: ThreadMediaHeaderProps) => {
  const { onDownloadFile } = useDownloadFile();

  const handleDownloadClick = () => {
    if (!videoUrl) {
      return;
    }
    onDownloadFile(videoUrl);
  };

  if (mediaType === 'video') {
    return (
      <div className={styles.root}>
        <span className={styles.videoWrapper}>
          <span className={styles.caption}>
            {formatDuration(videoDuration || 0)}
          </span>
        </span>

        {videoUrl && (
          <Button
            type="button"
            className={styles.button}
            onClick={handleDownloadClick}
            shape="round"
            color="secondary"
          >
            <DownloadSimple size={SVG_SIZE_S} />
          </Button>
        )}
      </div>
    );
  }

  return (
    <div className={classNames(styles.root, { [styles.leftAlign]: !icon })}>
      {icon && (
        <span className={styles.icon}>
          <Icon size={SVG_SIZE_S} type={icon} />
        </span>
      )}
    </div>
  );
};

import { useEffect, useState } from 'react';
import { AiGenerationStatus, ImageGenRenderData } from 'src/types';
import { useImageMaskingPanelActions } from './useImageMaskingPanelActions';
import { useSelectedImageIndex } from './useSelectedImageIndex';
import { useRightSidePanelState } from '../themeHooks';
import { useMessageIdForSelectedImage } from './useMessageIdForSelectedImage';
import { useAttachedImageTooltipState } from './useAttachedImageTooltipState';

export const useUpdateImagesForEditor = (
  images: ImageGenRenderData[],
  messageId: string | undefined,
) => {
  const { onChangeCurrentImagesList, onChangeImageMaskingRightPanelData } =
    useImageMaskingPanelActions();
  const { selectedImageIndex } = useSelectedImageIndex();
  const { isRightPanelExpanded } = useRightSidePanelState();
  const { isAttachedImageEditingMode } = useAttachedImageTooltipState();
  const { messageId: currentMessageIdForSelectedImage } =
    useMessageIdForSelectedImage();

  const [isImagesLoaded, setImagesLoaded] = useState(false);
  const wasSelectedImagesFromAnotherMessage =
    messageId !== currentMessageIdForSelectedImage;

  useEffect(() => {
    if (!wasSelectedImagesFromAnotherMessage) {
      const hasLoadingImages = images.some(
        (image) => image.status === AiGenerationStatus.SUCCESS && !image.url,
      );

      if (!hasLoadingImages) {
        setImagesLoaded(true);
      }
    }
  }, [images, wasSelectedImagesFromAnotherMessage]);

  useEffect(() => {
    if (
      isRightPanelExpanded &&
      !isAttachedImageEditingMode &&
      !wasSelectedImagesFromAnotherMessage &&
      isImagesLoaded
    ) {
      onChangeCurrentImagesList(images);
      onChangeImageMaskingRightPanelData(images[selectedImageIndex]);
    }
  }, [
    images,
    isImagesLoaded,
    isRightPanelExpanded,
    selectedImageIndex,
    isAttachedImageEditingMode,
    onChangeCurrentImagesList,
    onChangeImageMaskingRightPanelData,
    wasSelectedImagesFromAnotherMessage,
  ]);

  return null;
};

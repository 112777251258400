import { ArrowCounterClockwise } from '@phosphor-icons/react';
import { useBreakpoint } from 'src/hooks';
import { Button } from 'src/v2/commonComponents/Button';

const SVG_SIZE = 20;
const SVG_SIZE_MOBILE = 24;

type RefreshButtonProps = {
  label?: string;
  onClick?: () => void;
};

export const RefreshButton = ({
  label = 'Refresh',
  onClick,
}: RefreshButtonProps) => {
  const { isMobileOrTablet } = useBreakpoint();

  const handleClick = () => {
    onClick && onClick();
  };
  return (
    <Button
      onClick={handleClick}
      shape="round"
      color="transparent"
      tooltipContent="Give me new ideas!"
    >
      <ArrowCounterClockwise
        size={isMobileOrTablet ? SVG_SIZE_MOBILE : SVG_SIZE}
      />
    </Button>
  );
};

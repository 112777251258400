import {
  useAddSeatsCheckout,
  useSession,
  useSubscriptionInfo,
  useSubscriptionPlans,
  useTeamInfo,
  useVisible,
} from 'src/hooks';
import { ManageAccountContentPage } from '../ManageAccountContentPage/ManageAccountContentPage';
import { SubscriptionPlansForm } from '../SubscriptionPlansForm';
import { CancelSubscription } from '../CancelSubscription';
import './SubscriptionPlans.scss';
import { PaymentPlanInterval } from 'src/types';
import { UpdateMonthlyToAnnuallySection } from 'src/v2/pages/subscription/components/UpdateMonthlyToAnnuallySection';
import { LeaveWorkspaceSection } from 'src/v2/pages/subscription/components/LeaveWorkspaceSection';
import { ChangeSeatsSection } from 'src/v2/pages/manageUsersInWorkspace/components/ChangeSeatsSection';
import { ChangeSeatsCheckout } from 'src/v2/pages/subscription/components/ChangeSeatsCheckout';
import { LabelEnterprise } from 'src/components/LabelEnterprise';

export const SubscriptionPlans = () => {
  const { isSubscribedTier, appUser, isEnterpriseTier, isTeamsTier } =
    useSession();
  const { isMember, isAdmin, isOwner, teamInfo } = useTeamInfo();
  const {
    isVisible: IsCheckoutOpen,
    onVisibilitySet: onOpenCheckout,
    onVisibilityRemove: onCloseCheckout,
  } = useVisible(false);

  const {
    isOpenAddSeatsCheckout,
    onCloseAddSeatsCheckout,
    onOpenAddSeatsCheckout,
  } = useAddSeatsCheckout();

  const { plans } = useSubscriptionPlans({
    group: isTeamsTier ? 'teams' : 'individual',
    seat_count: isTeamsTier ? teamInfo?.num_seats_total : undefined,
  });

  const { subscriptionInfo } = useSubscriptionInfo();

  const annuallyPlanInfo = plans
    ? plans.filter(
        (item) =>
          item.plan_tier === (appUser.tier_id as string) &&
          item.period === PaymentPlanInterval.ANNUALLY,
      )[0]
    : undefined;

  const handleOpenChangeSeatsCheckout = () => {
    if (IsCheckoutOpen) {
      onCloseCheckout();
    }
    onOpenAddSeatsCheckout();
  };

  const handleOpenChangePlanCheckout = () => {
    if (isOpenAddSeatsCheckout) {
      onCloseAddSeatsCheckout();
    }
    onOpenCheckout();
  };

  return !isEnterpriseTier ? (
    <>
      <ManageAccountContentPage
        title="Subscription"
        errorMessage={
          subscriptionInfo?.processing_plan_change
            ? 'Payment processing in progress'
            : undefined
        }
      >
        <div className="nj-subscription-plans">
          <SubscriptionPlansForm
            onOpenCheckout={handleOpenChangePlanCheckout}
          />

          {isTeamsTier && !isMember && (
            <>
              <hr className="divider" />

              <ChangeSeatsSection
                onOpenChangeSeatsCheckout={handleOpenChangeSeatsCheckout}
                subscriptionInfo={subscriptionInfo}
              />
            </>
          )}
          {isTeamsTier && !isOwner && (
            <>
              <hr className="divider" />

              <LeaveWorkspaceSection />
            </>
          )}

          {isSubscribedTier && !isMember && !isAdmin && (
            <>
              <hr className="divider" />

              <CancelSubscription />
            </>
          )}
        </div>
      </ManageAccountContentPage>
      {!isOpenAddSeatsCheckout && (
        <UpdateMonthlyToAnnuallySection
          isOpen={IsCheckoutOpen}
          onClose={onCloseCheckout}
          selectedPlan={annuallyPlanInfo}
        />
      )}
      {!IsCheckoutOpen && (
        <ChangeSeatsCheckout
          isOpen={isOpenAddSeatsCheckout}
          onClose={onCloseAddSeatsCheckout}
          subscriptionInfo={subscriptionInfo}
        />
      )}
    </>
  ) : (
    <ManageAccountContentPage title="Subscription">
      <div className="enterprise-root">
        <h4 className="subtitle">Your subscription</h4>
        <div className="plan">
          <p>Plan</p>
          <p className="nj-subscription-plans-form--value">
            <LabelEnterprise />
          </p>
        </div>
      </div>
    </ManageAccountContentPage>
  );
};

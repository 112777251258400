import { useMemo, useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import { useCollapse } from 'react-collapsed';
import { DotsThreeOutlineVertical } from '@phosphor-icons/react';
import { isChitChatCard, Message, MessageType, TaskSkill } from 'src/types';
import { SVG_SIZE_M } from 'src/constants';
import {
  useBreakpoint,
  useOutsideClick,
  useSession,
  useVisible,
} from 'src/hooks';
import styles from './FooterMenu.module.scss';
import classNames from 'classnames';
import { RedoSkillActions } from '../../../RedoSkillActions';
import { BottomDrawer } from 'src/components/BottomDrawer';
import { sanitizeHTMLId } from 'src/utils';

type GetToggleProps = ReturnType<typeof useCollapse>['getToggleProps'];

interface FooterMenuProps {
  message: Message;
  messageId?: string;
  isShowRedoSkillActions?: boolean;
  onToggleRedoSkillActions: GetToggleProps;
}

// TODO(olha): component needs refactoring
export const FooterMenu = ({
  message,
  messageId,
  isShowRedoSkillActions,
  onToggleRedoSkillActions,
}: FooterMenuProps) => {
  const buttonRef = useRef(null);

  const { isMobileOrTablet } = useBreakpoint();

  const { isOpenTier, isStandardTier, isFreeTier, isTeamsStandardTier } =
    useSession();

  const { isVisible, onVisibilityRemove, onVisibilityToggle } = useVisible();

  const handleCloseWithDelay = () => {
    setTimeout(() => {
      onVisibilityRemove();
    }, 100);
  };

  useOutsideClick(buttonRef, handleCloseWithDelay);

  const tooltipAnchorId = `footer-tooltip-${sanitizeHTMLId(messageId || '')}`;

  const isFileAttached = useMemo(() => {
    return (
      isChitChatCard(message.payload) &&
      message.payload.data?.skill === TaskSkill.DOCUMENT_QA
    );
  }, [message.payload]);

  const menu =
    message.message_type !== MessageType.IMAGE_CARD &&
    message.message_type !== MessageType.VIDEO_CARD &&
    !isFileAttached ? (
      <RedoSkillActions
        message={message}
        isShowRedoSkillActions={isShowRedoSkillActions}
        onToggleRedoSkillActions={onToggleRedoSkillActions}
      />
    ) : null;

  if (!menu) {
    return null;
  }

  return (
    <>
      <button
        className={classNames(styles.trigger, {
          [styles.active]: isVisible,
        })}
        ref={buttonRef}
        onClick={onVisibilityToggle}
        id={tooltipAnchorId}
        disabled={
          (isOpenTier || isFreeTier || isStandardTier || isTeamsStandardTier) &&
          isChitChatCard(message.payload) &&
          message.payload.data?.skill === TaskSkill.DOCUMENT_QA
        }
      >
        <DotsThreeOutlineVertical
          weight={isVisible ? 'fill' : 'regular'}
          size={SVG_SIZE_M}
        />
      </button>

      {isMobileOrTablet ? (
        <BottomDrawer isOpen={isVisible} onClose={onVisibilityRemove}>
          {menu}
        </BottomDrawer>
      ) : (
        <Tooltip
          isOpen={isVisible}
          place="top-start"
          clickable
          className="nj-message-footer-tooltip"
          noArrow
          anchorSelect={`#${tooltipAnchorId}`}
          openOnClick
        >
          {menu}
        </Tooltip>
      )}
    </>
  );
};

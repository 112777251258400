import styles from './Components.module.scss';
import React, { useCallback } from 'react';
import { Button } from 'src/v2/commonComponents/Button';
import { AmpOnboardingPageStep } from 'src/v2/pages/auth/AmpOnboardingPage/common';

interface ExistingPaidUserSectionProps {
  setStep: (step: AmpOnboardingPageStep) => void;
}

export const ExistingPaidUserSection = ({
  setStep,
}: ExistingPaidUserSectionProps) => {
  const toSelectOwnerEmail = useCallback(() => {
    setStep(AmpOnboardingPageStep.ONBOARD);
  }, [setStep]);

  return (
    <div className={styles.root}>
      <h2 className={styles.heading}>You currently have a different plan</h2>
      <p>
        Do you want to cancel your existing plan and become owner of this new
        Business plan?
      </p>
      <div className={styles.row}>
        <Button onClick={toSelectOwnerEmail}>Keep my existing plan</Button>
        <Button disabled>Cancel my existing plan</Button>
      </div>
    </div>
  );
};

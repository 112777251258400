import { NinjaSearchTypes, WebSearchTypes } from 'src/types';
import {
  AvailableImageGenerationModels,
  AvailableFluxImageModels,
  imageStyleOptions,
  webSearchTypesList,
} from 'src/constants/llmResources';
import { Checkbox } from 'src/components/Checkbox';
import { FormProvider } from 'src/components/FormProvider';
import { useSession, useUserData } from 'src/hooks';
import { useLazyGetUserByIdQuery } from 'src/store/services';
import { useForm } from 'react-hook-form';
import log from 'src/utils/logger';
import {
  ListMagnifyingGlass,
  Images,
  Code,
  ChatCircleText,
  Calendar,
  ArrowSquareOut,
} from '@phosphor-icons/react';
import { CollapsedItem } from 'src/components/CollapsedItem';
import { Link } from 'react-router-dom';
import { SVG_SIZE_M, SVG_SIZE_L } from 'src/constants';
import styles from './NinjaAgentsSection.module.scss';
import {
  DEEP_RESEARCHER,
  IMAGE_GEN,
  CODER,
  WRITER,
  SCHEDULER,
} from 'src/constants/externalLinks';
import { ImageGenStyles } from 'src/types/models/ImageGenStyles';
import type {
  ImageGenSettings,
  ImageGenSettingsSelectedDimension,
} from 'src/types';
import { ImagesPerModelToggle } from 'src/components/ImageGenSettings/components/ImagesPerModelToggle';
import { ImageStyleSelect } from 'src/components/ImageGenSettings/components/ImageStyleSelect';
import { DimensionForm } from 'src/components/ImageGenSettings/components/DimensionForm';
import { useMemo } from 'react';
import { UpsellMessage } from 'src/components/UpsellMessage';

interface FormData {
  [NinjaSearchTypes.DEEP_RESEARCH]: boolean;
  [WebSearchTypes.IMAGE_SEARCH]: boolean;
  [WebSearchTypes.VIDEO_SEARCH]: boolean;
}

// TODO(olha): component needs a complete refactoring. Let's try to remove form
export const NinjaAgentsSection = () => {
  const MENU_HEIGHT = 410;

  const {
    appUser: { settings, user_id },
    isFreeTier,
    isOpenTier,
    isStandardTier,
  } = useSession();

  const styleValue =
    settings?.image_gen_settings?.style?.value || ImageGenStyles.NONE;
  const styleSelected = imageStyleOptions.find(
    (option) => option.value === styleValue,
  );

  const { updateUserSettings } = useUserData();

  const [trigger] = useLazyGetUserByIdQuery();

  // TODO: duplicated code in ImageGenModifyPanel
  const imagesPerModel = useMemo(() => {
    const numberImages = (settings?.image_gen_settings?.models || []).find(
      (item) =>
        (AvailableFluxImageModels as string[]).includes(item.model) &&
        item.enabled,
    )?.num_images;

    return numberImages || 1;
  }, [settings?.image_gen_settings?.models]);

  const methods = useForm<FormData>({
    defaultValues: async () => {
      const result = await trigger(user_id, true);
      const { data } = result;

      return {
        [NinjaSearchTypes.DEEP_RESEARCH]:
          data?.settings?.research?.deep_research?.enabled || false,
        [WebSearchTypes.IMAGE_SEARCH]:
          data?.settings?.research?.web_search?.search_images || false,
        [WebSearchTypes.VIDEO_SEARCH]:
          data?.settings?.research?.web_search?.search_videos || false,
      };
    },
  });

  const { setValue } = methods;

  const handleWebSearchSwitcher = async (
    checked: boolean,
    value: keyof WebSearchTypes,
  ) => {
    const research = {
      research: {
        ...(settings?.research || {}),
        web_search: {
          ...(settings?.research?.web_search || {}),
          [value]: checked,
        },
      },
    };

    try {
      await updateUserSettings(research);
    } catch (e) {
      setValue(
        value as WebSearchTypes.IMAGE_SEARCH | WebSearchTypes.VIDEO_SEARCH,
        !checked,
      );
      log.error(e);
    }
  };

  const researcherContent = (
    <>
      <div className="nj-web-research-content-form-wrapper">
        {webSearchTypesList.map(({ value, label }) => {
          return (
            <div
              key={value}
              className="nj-section--field-wrapper nj-web-search-checkboxes-wrapper"
            >
              <Checkbox
                label={
                  <p className="nj-section--field-title">
                    <span>{label}</span>
                  </p>
                }
                name={value}
                onChangeHandler={(checked: boolean) =>
                  handleWebSearchSwitcher(
                    checked,
                    value as unknown as keyof WebSearchTypes,
                  )
                }
                disabled={!settings?.research?.web_search?.is_capable}
              />
            </div>
          );
        })}

        <Link
          to={DEEP_RESEARCHER}
          target="_blank"
          className={styles.link}
          data-e2e="learn-more-deep-link"
        >
          Learn more <ArrowSquareOut size={SVG_SIZE_M} />
        </Link>
      </div>
    </>
  );

  const handleImageStyleSelect = async (value: ImageGenStyles) => {
    const image_gen_settings = {
      image_gen_settings: {
        ...(settings?.image_gen_settings || {}),
        style: {
          value,
        },
      },
    };

    try {
      await updateUserSettings(image_gen_settings);
    } catch (e) {
      log.error(e);
    }
  };

  const handleDimensionChange = async (
    dimension: ImageGenSettingsSelectedDimension,
  ) => {
    const image_gen_settings: ImageGenSettings = {
      ...(settings?.image_gen_settings || {}),
      dimensions: {
        ...(settings?.image_gen_settings?.dimensions || {}),
        selected: dimension,
      },
    };

    try {
      await updateUserSettings({ image_gen_settings });
    } catch (e) {
      // TODO(olha): check if FE needs to reset value
      log.error(e);
    }
  };

  const handleImageNumberChange = async (value: number) => {
    const updatedModels = (settings?.image_gen_settings?.models || []).map(
      (item) => {
        if ((AvailableImageGenerationModels as string[]).includes(item.model)) {
          return { ...item, num_images: value };
        }
        return item;
      },
    );

    const image_gen_settings = {
      image_gen_settings: {
        ...(settings?.image_gen_settings || {}),
        models: updatedModels,
      },
    };

    try {
      await updateUserSettings(image_gen_settings);
    } catch (e) {
      // No need to reset the values in case we fall into an exception, UI is handling gracefully
      log.error(e);
    }
  };

  const imageGeneratorContent = (
    <div>
      <p className={styles.description}>
        Ninja’s proprietary model trained using PixArt-Sigma as a base.
      </p>

      {(isOpenTier || isFreeTier || isStandardTier) && (
        <UpsellMessage
          title="Customize your images"
          description="Unlock high-resolution images with 11 aspect ratios and the ability to select from multiple image styles"
          dataGTMNinjaTier="Customize-images-upgrade"
          dataGTMOpenTier="Customize-images-signup"
          buttonStyle="with-background"
          buttonLabel="Upgrade to unlock"
          className={styles.upsellMessage}
        />
      )}

      <DimensionForm
        selectedData={settings?.image_gen_settings?.dimensions?.selected}
        onChange={handleDimensionChange}
      />

      <ImageStyleSelect
        selectedValue={styleValue}
        onChange={handleImageStyleSelect}
        maxMenuHeight={MENU_HEIGHT}
      />

      {/* <ImagesModelSelector
        selectedValue={imageModel}
        onChange={handleImageModelSelect}
      /> */}

      <ImagesPerModelToggle
        selectedValue={imagesPerModel}
        onChange={handleImageNumberChange}
      />

      <Link
        to={IMAGE_GEN}
        target="_blank"
        className={styles.link}
        data-e2e="learn-more-image-link"
      >
        Learn more <ArrowSquareOut size={SVG_SIZE_M} />
      </Link>
    </div>
  );

  const images = settings?.research?.web_search?.search_images ? 1 : 0;
  const videos = settings?.research?.web_search?.search_videos ? 1 : 0;

  const selectedResearcher = images + videos;
  const selectedImageGenerator = 0;

  const agents = [
    {
      id: 'image-generator',
      title: 'Image Generator',
      selection: false,
      icon: <Images size={SVG_SIZE_L} />,
      content: imageGeneratorContent,
      selected: selectedImageGenerator,
      expanded: true,
      style: true,
    },
    {
      id: 'researcher',
      title: 'Researcher',
      selection: true,
      icon: <ListMagnifyingGlass size={SVG_SIZE_L} />,
      content: researcherContent,
      selected: selectedResearcher,
      expanded: true,
      style: false,
    },
    {
      id: 'coder',
      title: 'Coder',
      selection: false,
      icon: <Code size={SVG_SIZE_L} />,
      content: (
        <Link
          to={CODER}
          target="_blank"
          className={styles.link}
          data-e2e="learn-more-coder-link"
        >
          Learn more <ArrowSquareOut size={SVG_SIZE_M} />
        </Link>
      ),
      expanded: false,
      style: false,
    },
    {
      id: 'writer',
      title: 'Writer',
      selection: false,
      icon: <ChatCircleText size={SVG_SIZE_L} />,
      content: (
        <Link
          to={WRITER}
          target="_blank"
          className={styles.link}
          data-e2e="learn-more-writer-link"
        >
          Learn more <ArrowSquareOut size={SVG_SIZE_M} />
        </Link>
      ),
      expanded: false,
      style: false,
    },
    {
      id: 'scheduler',
      title: 'Scheduler',
      selection: false,
      icon: <Calendar size={SVG_SIZE_L} />,
      content: (
        <Link
          to={SCHEDULER}
          target="_blank"
          className={styles.link}
          data-e2e="learn-more-scheduler-link"
        >
          Learn more <ArrowSquareOut size={SVG_SIZE_M} />
        </Link>
      ),
      expanded: false,
      style: false,
    },
  ];

  return (
    <div className="nj-section--main-container with-padding nj-ninja-agents-section-wrapper">
      <h6 className="nj-section--main-container-subtitle-secondary">
        Ninja's models are proprietary, agentic, and fine-tuned, utilizing the
        advanced capabilities of Llama 3.3 70B
      </h6>
      <div>
        <FormProvider<FormData> methods={methods}>
          <div className="nj-researcher-form-wrapper">
            <div className="nj-accordion nj-accordion-external-models">
              {agents.map(
                ({
                  id,
                  title,
                  selection,
                  icon,
                  content,
                  selected,
                  expanded,
                  style,
                }) => {
                  let rightSideComponent = null;

                  if (selection) {
                    rightSideComponent = (
                      <span className="nj-accordion--label-selected-items">
                        {selected} selected
                      </span>
                    );
                  }

                  if (style) {
                    rightSideComponent = (
                      <span className="nj-accordion--label-selected-items">
                        {styleSelected?.label}
                      </span>
                    );
                  }

                  const header = (
                    <div className="nj-accordion--label-content-wrapper">
                      {icon}
                      <span className="nj-accordion--label-content-text">
                        {title}
                      </span>
                    </div>
                  );

                  const contentWrapper = (
                    <div className="nj-accordion-external-models-content-wrapper">
                      <div className="nj-accordion-external-models-checkboxes-wrapper">
                        {content}
                      </div>
                    </div>
                  );

                  return (
                    <CollapsedItem
                      key={id}
                      header={header}
                      title={'Ninja Agent'}
                      content={contentWrapper}
                      isExpanded={expanded}
                      rightSideComponent={rightSideComponent}
                    />
                  );
                },
              )}
            </div>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};

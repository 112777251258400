import { X } from '@phosphor-icons/react';
import { Button } from 'src/v2/commonComponents/Button';
import { ReactTooltip } from 'src/v2/commonComponents/ReactTooltip';
import { SVG_SIZE_M } from 'src/constants';
import styles from './ImageEditTooltip.module.scss';
import {
  useAttachedImageTooltipActions,
  useAttachedImageTooltipState,
  useImageMaskingPanelActions,
  useOutsideClick,
  useRightSidePanelActions,
  useLeftPanelActions,
} from 'src/hooks';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { AiGenerationStatus, RightPanelSections } from 'src/types';
import { useFileUrl } from 'src/hooks/useFileUrl';

interface ImageEditTooltipProps {
  id: string;
}

export const ImageEditTooltip = ({ id }: ImageEditTooltipProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { threadInputBoxFiles } = useContext(ThreadInputBoxContext);
  const attachedImage = Object.values(threadInputBoxFiles || {})?.[0];
  const imageName = attachedImage?.convertedFileName || '';

  const { url, isError } = useFileUrl(imageName);

  const currentImage = useMemo(
    () => ({
      url: url ?? '',
      isDisabled: undefined,
      id: '',
      icon: undefined,
      title: '',
      height: 0,
      width: 0,
      source: '',
      status: AiGenerationStatus.SUCCESS,
    }),
    [url],
  );

  const { isAttachedImageTooltipVisible } = useAttachedImageTooltipState();

  const { toggleLeftPanelExpanded } = useLeftPanelActions();
  const {
    onToggleImageEditTooltipVisible,
    onChangeIsAttachedImageEditingMode,
  } = useAttachedImageTooltipActions();
  const { setRightSidePanelData } = useRightSidePanelActions();
  const {
    onChangeImageMaskingRightPanelData,
    resetImageMaskingPanelInfo,
    onChangeCurrentImagesList,
    onChangeSelectedImageIndex,
  } = useImageMaskingPanelActions();

  const handleClose = useCallback(() => {
    onToggleImageEditTooltipVisible(false);
    onChangeIsAttachedImageEditingMode(false);
  }, [onToggleImageEditTooltipVisible, onChangeIsAttachedImageEditingMode]);

  const handleOutsideClick = useCallback(() => {
    if (isAttachedImageTooltipVisible) {
      handleClose();
    }
  }, [handleClose, isAttachedImageTooltipVisible]);

  useOutsideClick(containerRef, handleOutsideClick);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);

  const handleEditClick = useCallback(() => {
    handleClose();
    resetImageMaskingPanelInfo();
    toggleLeftPanelExpanded(false);
    onChangeImageMaskingRightPanelData(currentImage);
    onChangeCurrentImagesList([currentImage]);
    onChangeIsAttachedImageEditingMode(true);
    onChangeSelectedImageIndex(0);
    setRightSidePanelData({
      panelType: RightPanelSections.IMAGE,
      isExpanded: true,
    });
  }, [
    currentImage,
    setRightSidePanelData,
    resetImageMaskingPanelInfo,
    toggleLeftPanelExpanded,
    onChangeImageMaskingRightPanelData,
    onChangeCurrentImagesList,
    onChangeIsAttachedImageEditingMode,
    onChangeSelectedImageIndex,
    handleClose,
  ]);

  if (isError) {
    return null;
  }

  return (
    <div className={styles.root} ref={containerRef}>
      <ReactTooltip
        place="top-start"
        id={id}
        isOpen={isAttachedImageTooltipVisible}
        className={styles.tooltip}
        offset={8}
      >
        <span className={styles.tooltipText}>
          Edit uploaded image with Ninja
        </span>
        <Button
          className={styles.editImageButton}
          shape="round"
          color="transparent"
          onClick={handleEditClick}
          disabled={!url}
        >
          Edit image
        </Button>

        <button type="button" onClick={handleClose}>
          <X size={SVG_SIZE_M} />
        </button>
      </ReactTooltip>
    </div>
  );
};

import { useMemo } from 'react';
import {
  ConversationRole,
  isChitChatCard,
  isImageGenerationCard,
  Message,
  MessageSkill,
  MessageType,
  TaskSkill,
  TaskState,
} from 'src/types';
import {
  useCurrentConversationTasks,
  useSession,
  useSubmitUserInput,
} from 'src/hooks';
import {
  getSkillFromMessage,
  getTaskIdFromMessage,
  uppercaseFirstLetter,
} from 'src/utils';
import { RegenerateMessageButton } from '../RegenerateMessageButton';
import { CopyMessageButton } from 'src/pages/ManageTasksChatPage/components/ChatMessage/components/CopyMessageButton';
import { ShareButton } from 'src/components/ShareButton';
import { MessageModal } from '../MessageModal';
import { RedoSkillButtons, SVG_SIZE_M } from 'src/constants';
import styles from './MessageFooter.module.scss';
import { useCollapse } from 'react-collapsed';
import { FooterMenu } from './components/FooterMenu';

interface MessageFooterPops {
  message: Message;
  isFinalMessageChunk?: boolean;
}

export const MessageFooter = ({
  message,
  isFinalMessageChunk,
}: MessageFooterPops) => {
  const {
    isOpenTier,
    isFreeTier,
    isStandardTier,
    isLimitedTier,
    isTeamsStandardTier,
  } = useSession();
  const { role, message_id, message_type } = message;

  const {
    getToggleProps: onToggleRedoSkillActions,
    isExpanded: isShowRedoSkillActions,
    setExpanded: setExpandedRedoSkillActions,
    getCollapseProps: getCollapsePropsRedoSkillActions,
  } = useCollapse({
    defaultExpanded: false,
  });

  const userInput = message?.refined_query || message?.clean_query || '';

  const { getTaskFromConversationById } = useCurrentConversationTasks();

  const taskId = getTaskIdFromMessage(message);
  const taskState = taskId ? getTaskFromConversationById(taskId)?.state : null;
  const { onSubmitUserInput } = useSubmitUserInput();

  const currentSkill = useMemo(() => {
    return message.message_type
      ? getSkillFromMessage(
          message.message_type,
          isChitChatCard(message.payload)
            ? message.payload.data?.skill
            : undefined,
        )
      : '';
  }, [message.message_type, message.payload]);

  const skillsForRedo = useMemo(() => {
    return RedoSkillButtons.filter(({ skill }) => skill !== currentSkill);
  }, [currentSkill]);

  const shouldShowSharingButtons = useMemo(() => {
    if (
      (isOpenTier || isFreeTier || isStandardTier || isTeamsStandardTier) &&
      isChitChatCard(message.payload) &&
      message.payload.data?.skill === TaskSkill.DOCUMENT_QA
    ) {
      return false;
    }
    if (isImageGenerationCard(message.payload) && (isOpenTier || isFreeTier)) {
      return false;
    }
    if (
      isImageGenerationCard(message.payload) &&
      !!message.payload.watermarked_images &&
      isLimitedTier
    ) {
      return false;
    }
    if (message_type === MessageType.VIDEO_CARD) {
      return false;
    }
    if (message_type === MessageType.CONVERSATION && isFinalMessageChunk) {
      return true;
    }

    if (taskState === TaskState.DONE && isFinalMessageChunk) {
      return true;
    }

    if (!taskId && isFinalMessageChunk) {
      return true;
    }

    return false;
  }, [
    message_type,
    isFinalMessageChunk,
    taskState,
    taskId,
    isOpenTier,
    isFreeTier,
    isStandardTier,
    message,
    isLimitedTier,
    isTeamsStandardTier,
  ]);

  if (role === ConversationRole.USER || !message_id) {
    return null;
  }

  const handleClick = (skill: MessageSkill) => {
    const preparedUserInput = `/${skill} ${userInput}`;
    onSubmitUserInput(preparedUserInput);

    setExpandedRedoSkillActions(false);
  };

  const hideAllOptions = () => {
    setExpandedRedoSkillActions(false);
  };

  return (
    <div>
      <div className="nj-message-footer">
        <div className="nj-message-footer--wrapper">
          <RegenerateMessageButton
            message={message}
            hideAllOptions={hideAllOptions}
          />

          <CopyMessageButton
            item={message}
            withTitle
            disabled={
              !shouldShowSharingButtons ||
              message_type === MessageType.IMAGE_CARD ||
              message_type === MessageType.VIDEO_CARD ||
              message_type === MessageType.ERROR_MESSAGE
            }
          />

          <FooterMenu
            message={message}
            messageId={message_id}
            isShowRedoSkillActions={isShowRedoSkillActions}
            onToggleRedoSkillActions={onToggleRedoSkillActions}
          />
        </div>

        <div className="nj-message-footer--wrapper">
          {message.message_type !==
            MessageType.SCHEDULER_TASK_CREATION_CARD && (
            <ShareButton
              withTitle
              messageId={message_id || ''}
              buttonIconSize={SVG_SIZE_M}
              disabled={
                !shouldShowSharingButtons ||
                message_type === MessageType.ERROR_MESSAGE
              }
            />
          )}
        </div>
      </div>

      <div className={styles.small} {...getCollapsePropsRedoSkillActions()}>
        <MessageModal
          title="Regenerate with different Agent"
          onClick={() => {
            setExpandedRedoSkillActions(false);
          }}
        >
          <div className={styles.agents}>
            {skillsForRedo.map(({ skill, icon: SkillIcon }) => {
              if (
                (skill === MessageSkill.CODER &&
                  currentSkill === MessageSkill.WRITER) ||
                (skill === MessageSkill.WRITER &&
                  currentSkill === MessageSkill.CODER)
              ) {
                return null;
              }
              return (
                <button
                  className={styles.row}
                  onClick={() => handleClick(skill)}
                  key={skill}
                  // todo enabled for now
                  // disabled={
                  //   skill === MessageSkill.SCHEDULER &&
                  //   !appUser?.settings?.features?.redo_as_scheduler?.is_capable
                  // }
                >
                  <SkillIcon size={SVG_SIZE_M} />
                  <span>{uppercaseFirstLetter(skill)}</span>
                </button>
              );
            })}
          </div>
        </MessageModal>
      </div>
    </div>
  );
};

import styles from './WelcomeTeamOwnerScreen.module.scss';
import mobileBackground from 'src/images/backgrounds/maintenance-mobile-bg.png';
import desktopBackground from 'src/images/backgrounds/maintenance-desktop-bg.png';
import {
  useBreakpoint,
  useInviteUsersModal,
  usePurchaseSeatsModal,
  useSession,
  useTeamInfo,
} from 'src/hooks';
import { Modal } from 'src/components/Modal';
import { Button } from 'src/v2/commonComponents/Button';
import { ChangeEvent, useState } from 'react';
import log from 'src/utils/logger';
import { useSetTeamNameMutation } from 'src/store/services';

export const WelcomeTeamOwnerScreen = () => {
  const { isMobile } = useBreakpoint();
  const { appUser } = useSession();
  const [workspaceName, setWorkspaceName] = useState<string>('');
  const [setTeamName, { isLoading }] = useSetTeamNameMutation();
  const { onChangeIsOpenInviteUsersModal } = useInviteUsersModal();
  const { onOpenPurchaseSeatsModal } = usePurchaseSeatsModal();

  const { teamInfo } = useTeamInfo();

  const handleContinueClick = async () => {
    try {
      await setTeamName({
        user_id: appUser.user_id,
        team_id: teamInfo?.team_id || '',
        team_name: workspaceName,
      }).unwrap();
      if ((teamInfo?.num_seats_total || 1) > 1) {
        onChangeIsOpenInviteUsersModal(true, true);
      } else {
        onOpenPurchaseSeatsModal();
      }
    } catch (e) {
      // todo handle errors
      log.error('error', e);
    }
  };

  const handleChangeWorkspaceName = (e: ChangeEvent<HTMLInputElement>) => {
    setWorkspaceName(e.target.value);
  };

  return (
    <div
      className={styles.root}
      style={{
        backgroundImage: isMobile
          ? `url(${mobileBackground})`
          : `url(${desktopBackground})`,
        backgroundSize: 'cover',
      }}
    >
      <Modal
        open={true}
        onClose={() => {
          // do nothing
        }}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        overlayClassName={styles.overlay}
        className={styles.modal}
      >
        <div className={styles.modalContent}>
          <h3 className={styles.title}>Welcome to your team plan</h3>
          <span className={styles.description}>
            Enjoy unlimited access to Ninja.{' '}
          </span>
          <hr className={styles.divider} />
          <div className={styles.workspaceNameInputWrapper}>
            <div className={styles.labelWrapper}>
              <h5 className={styles.labelTitle}>Workspace name</h5>
              <span className={styles.labelDescription}>
                Choose a name for your team's workspace. You can change it
                anytime.
              </span>
            </div>
          </div>
          <div className={styles.inputWrapper}>
            <input
              value={workspaceName}
              placeholder="Workspace name (e,g. Acme Inc)"
              className={styles.input}
              onChange={handleChangeWorkspaceName}
            />
          </div>
          <div className={styles.buttonRow}>
            <Button
              onClick={handleContinueClick}
              disabled={workspaceName.length === 0 || isLoading}
            >
              Continue
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

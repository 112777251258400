import styles from './SummaryRow.module.scss';
import { ProgressBar } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/ProgressBar';

interface SummaryRowProps {
  label: string;
  value: string;
  isLoading?: boolean;
}

export const SummaryRow = ({ label, value, isLoading }: SummaryRowProps) => {
  return (
    <div className={styles.root}>
      <span className={styles.label}>{label}</span>
      {isLoading ? (
        <ProgressBar
          progressLineClassName={styles.loaderLine}
          className={styles.loader}
        />
      ) : (
        <span className={styles.value}>{value}</span>
      )}
    </div>
  );
};

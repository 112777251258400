export const useDownloadFile = () => {
  const onDownloadFile = (url: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const fileUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = url.split('/').pop() || 'file';
        link.click();
      });
  };

  return { onDownloadFile };
};

import { useContext } from 'react';
import { FunnelSimple, X } from '@phosphor-icons/react';
import SessionContext from 'src/contexts/SessionContext';
import { ChatsFilter } from 'src/types';
import { useBreakpoint } from 'src/hooks';
import styles from './FilterToolbar.module.scss';
import { Button } from 'src/v2/commonComponents/Button';
import { SVG_SIZE_M, SVG_SIZE_S } from 'src/constants';
import { ConversationControl } from 'src/pages/ManageTasksChatPage/components/MultiConversationsListPanel/components/ConversationControl';

export const FilterToolbar = () => {
  const { isMobile } = useBreakpoint();

  // TODO(olha): we need to move this handling from Context to Slice
  const { chatsFilter, setChatsFilter, onOpenTaskListControlsPanel } =
    useContext(SessionContext);

  const handleResetFilters = () => {
    setChatsFilter(ChatsFilter.ALL);
  };

  return (
    <div className={styles.root}>
      {isMobile ? (
        <Button
          color="secondary"
          shape="round"
          onClick={onOpenTaskListControlsPanel}
          className={styles.filterTrigger}
        >
          <FunnelSimple size={SVG_SIZE_M} />
        </Button>
      ) : (
        <ConversationControl type="menu" />
      )}

      {chatsFilter !== ChatsFilter.ALL && (
        <Button color="tertiary-outline" onClick={handleResetFilters}>
          {chatsFilter}

          <span className={styles.closeIcon}>
            <X size={SVG_SIZE_S} />
          </span>
        </Button>
      )}
    </div>
  );
};

import {
  useEffect,
  useRef,
  useState,
  useContext,
  useImperativeHandle,
  forwardRef,
  useMemo,
} from 'react';
import classNames from 'classnames';
import ForwardRefContext, {
  ThreadMessageListHandlers,
} from 'src/contexts/ForwardRefContext';
import { ChatMode, Message } from 'src/types';
import {
  addEmptyWaitingMessage,
  formatByDayjsMonthDay,
  prepareMessagesToRender,
  getErrorStatus,
} from 'src/utils';
import {
  useSession,
  useAvatarPreferences,
  useScrollToBottomThread,
  useThreadRegistry,
  useTranslateMessages,
  useTimeZone,
  useRightSettingsPanelState,
  useCurrentConversation,
  useCurrentConversationMessages,
} from 'src/hooks';
import { ThreadMessage } from 'src/v2/components/ThreadMessage';
import { ThreadDividerLine } from '../ThreadDividerLine';
import { NinjaLoader } from 'src/components/Loading';
import { UserMessage } from 'src/v2/components/UserMessage';
import { useRightSidePanelState } from 'src/hooks';

export const ThreadMessageList = forwardRef<ThreadMessageListHandlers>(() => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { threadMessageListAnchorRef, threadMessageListRef, endOfMessagesRef } =
    useContext(ForwardRefContext);

  const { avatarCCLocale, avatarAudioLocale } = useAvatarPreferences();
  const { ignoreMessages } = useThreadRegistry();
  const { chatMode } = useSession();
  const { isSettingsPanelExpanded } = useRightSettingsPanelState();
  const { isRightPanelExpanded } = useRightSidePanelState();
  const {
    isCurrentConversationLoading,
    isConversationError,
    conversationError,
  } = useCurrentConversation();
  const { conversationMessages } = useCurrentConversationMessages();

  const { userTimeZone } = useTimeZone();

  useScrollToBottomThread(endOfMessagesRef);

  const [translatedMessages, setTranslatedMessages] = useState<Message[]>([]);

  const { onTranslateMessages } = useTranslateMessages();

  const handleScrollToBottom = (delay = 1000) => {
    setTimeout(() => {
      endOfMessagesRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, delay);
  };

  useImperativeHandle(threadMessageListAnchorRef, () => ({
    onScrollToBottomMessageList: handleScrollToBottom,
  }));

  const isAvatarMode = chatMode === ChatMode.AVATAR;

  // TODO(olha): to avoid "window.location.replace" multiple calls it should be moved to one of components
  useEffect(() => {
    // Redirect to the main page if the conversation is not found
    if (isConversationError && getErrorStatus(conversationError) === 404) {
      window.location.replace('/');
    }
  }, [isConversationError, conversationError]);
  // TODO(olha): moved from MultiConversationsMessagesList. Try to call translation by clicking to the avatar mode
  useEffect(() => {
    if (isAvatarMode) {
      const translateMessages = async () => {
        const result = await onTranslateMessages();
        setTranslatedMessages(result);
      };

      translateMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    conversationMessages,
    avatarCCLocale,
    avatarAudioLocale,
    isAvatarMode,
    onTranslateMessages,
  ]);

  const messagesToRender = useMemo(
    () =>
      prepareMessagesToRender(
        !isAvatarMode
          ? addEmptyWaitingMessage(conversationMessages, ignoreMessages)
          : translatedMessages,
      ),
    [isAvatarMode, conversationMessages, ignoreMessages, translatedMessages],
  );

  if (isCurrentConversationLoading) {
    return <NinjaLoader content="Ninja is loading the content" />;
  }

  return (
    <div
      ref={threadMessageListRef}
      className={classNames('nj-thread-message-list', {
        'avatar-mode': isAvatarMode,
        'is-settings-panel-expanded': isSettingsPanelExpanded,
        'is-right-side-panel-expanded': isRightPanelExpanded,
        'with-min-height': conversationMessages.length !== 0,
      })}
    >
      <div ref={containerRef} className="nj-thread-message-list-wrapper">
        {messagesToRender.map(({ userMessage, agentMessage }, index) => {
          const { showDate, anchorId, ...restUserMessage } = userMessage;
          return (
            <div
              key={userMessage.message_id}
              className={classNames(
                'nj-thread-message-list--message-pair-wrapper',
                {
                  'with-min-height': index === messagesToRender.length - 1,
                },
              )}
            >
              {showDate && userMessage.timestamp && (
                <p className="nj-thread-message-list--date-line">
                  {formatByDayjsMonthDay(userMessage.timestamp, userTimeZone)}
                </p>
              )}

              <ThreadDividerLine anchorId={anchorId} />

              <UserMessage message={restUserMessage} />

              <ThreadMessage
                message={agentMessage}
                canShowProgress={index === messagesToRender.length - 1}
              />
            </div>
          );
        })}

        <span
          // anchor for scrolling to the bottom
          className="nj-thread-message-list--anchor"
          ref={endOfMessagesRef}
        />
      </div>
    </div>
  );
});

import { SpinnerSimple } from 'src/v2/commonComponents/SpinnerSimple';
import { PanelHeader } from '../PanelHeader';
import styles from './LoadingPanel.module.scss';

interface LoadingPanelProps {
  onClose: () => void;
}

export const LoadingPanel = ({ onClose }: LoadingPanelProps) => {
  return (
    <div className={styles.root}>
      <PanelHeader onClose={onClose} />

      <div className={styles.loadingContainer}>
        <div>
          <SpinnerSimple className={styles.loading} />
          <span className={styles.loadingTitle} data-e2e="loading-payment-spinner">Loading payment options…</span>
        </div>
      </div>
    </div>
  );
};

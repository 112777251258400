import Skeleton from 'react-loading-skeleton';
import { useColorTheme } from 'src/hooks';
import classNames from 'classnames';

export type Color = { darkTheme: string; lightTheme: string };

export type ColorScheme = {
  baseColor?: Color;
  highlightColor?: Color;
};

type ProgressBarProps = ColorScheme & {
  className?: string;
  count?: number;
  withColor?: boolean;
  progressLineClassName?: string;
};

export const ProgressBar = ({
  className,
  progressLineClassName,
  count = 1,
  baseColor,
  highlightColor,
  withColor,
}: ProgressBarProps) => {
  const { isDarkTheme } = useColorTheme();

  const { darkTheme: baseDark, lightTheme: baseLight } = baseColor || {
    darkTheme: 'var(--nj-regular-button--foreground)',
    lightTheme: withColor ? 'var(--nj-mode-button-background)' : '#e8eaea',
  };

  const { darkTheme: highlightDark, lightTheme: highlightLight } =
    highlightColor || {
      darkTheme: 'var(--nj-loading)',
      lightTheme: 'var(--nj-background)',
    };

  return (
    <div
      className={classNames('nj-thread-combined-card--progress-bar', className)}
    >
      <Skeleton
        baseColor={isDarkTheme ? baseDark : baseLight}
        highlightColor={isDarkTheme ? highlightDark : highlightLight}
        className={classNames(
          'nj-thread-combined-card--progress-bar-skeleton',
          progressLineClassName,
        )}
        duration={3}
        width="100%"
        count={count}
      />
    </div>
  );
};

import { useGetTeamByUserIdQuery } from 'src/store/services';
import { useMemo } from 'react';
import { useSession } from 'src/hooks';
import { SeatRole } from 'src/types/models/SeatRole';

export const useTeamInfo = () => {
  const {
    appUser: { user_id },
    isTeamsTier,
  } = useSession();

  const {
    data: teamInfo,
    isLoading: isTeamInfoLoading,
    isFetching: isTeamInfoFetching,
  } = useGetTeamByUserIdQuery(
    {
      user_id,
    },
    { skip: !user_id || !isTeamsTier },
  );

  return useMemo(
    () => ({
      teamInfo: teamInfo?.team || null,
      seatInfo: teamInfo?.seat || null,
      isTeamInfoLoading,
      isTeamInfoFetching,
      isOwner: teamInfo?.seat?.role === SeatRole.OWNER,
      isAdmin: teamInfo?.seat?.role === SeatRole.ADMIN,
      isMember: teamInfo?.seat?.role === SeatRole.MEMBER,
    }),
    [teamInfo, isTeamInfoLoading, isTeamInfoFetching],
  );
};

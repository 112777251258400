import classNames from 'classnames';
import {
  useBreakpoint,
  useTaskById,
  useQueryParams,
  useLeftPanelState,
  useLeftPanelActions,
  useDeleteAttachment,
  useRightSidePanelActions,
  useRightSidePanelState,
  useAttachedImageTooltipState,
  useAttachedImageTooltipActions,
} from 'src/hooks';
import { PencilSimpleLine } from '@phosphor-icons/react';
import { GTMEvent } from 'src/types';
import { HOVER_TOOLTIP_ID, SVG_SIZE_M, SVG_SIZE_XXL } from 'src/constants';
import { sendGTMEvent } from 'src/utils';
import './AddConversationButton.scss';
import { useContext } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { Icon } from 'src/components/Icon';
import { Button } from 'src/v2/commonComponents/Button';

interface AddConversationButtonProps {
  withNinjaIcon?: boolean;
  className?: string;
}

export const AddConversationButton = ({
  withNinjaIcon,
  className,
}: AddConversationButtonProps) => {
  const { searchParams, removeSearchParam } = useQueryParams();
  const { onClearInputBox } = useContext(ThreadInputBoxContext);

  const { onDeleteAllAttachments } = useDeleteAttachment();
  const { toggleLeftPanelExpanded } = useLeftPanelActions();
  const { isLeftPanelExpanded } = useLeftPanelState();

  const { removeCurrentTaskId } = useTaskById();
  const { isMobileOrTablet } = useBreakpoint();
  const { shouldCompressThread } = useRightSidePanelState();
  const { isAttachedImageEditingMode } = useAttachedImageTooltipState();
  const { toggleRightSidePanelExpanded } = useRightSidePanelActions();
  const {
    onChangeIsAttachedImageEditingMode,
    onToggleImageEditTooltipVisible,
  } = useAttachedImageTooltipActions();

  const handleCreateNewChat = async () => {
    if (!!searchParams.conv) {
      removeCurrentTaskId();
      removeSearchParam('conv');
      sendGTMEvent(GTMEvent.NEW_CHAT_INITIATION);
      toggleRightSidePanelExpanded(false);
    }

    if (isAttachedImageEditingMode) {
      toggleRightSidePanelExpanded(false);
      onChangeIsAttachedImageEditingMode(false);
      onToggleImageEditTooltipVisible(true);
    }

    if ((isMobileOrTablet || shouldCompressThread) && isLeftPanelExpanded) {
      toggleLeftPanelExpanded();
    }

    onClearInputBox();
    await onDeleteAllAttachments();

    // do pillars refresh from this button click
    const event = new CustomEvent('refresh-pillars', {
      detail: { message: 'Refresh random pillars' },
    });
    dispatchEvent(event);
  };

  if (withNinjaIcon) {
    return (
      <Button
        color="transparent"
        shape="round"
        className="nj-create-new-chat--ninja-icon"
        onClick={handleCreateNewChat}
      >
        <Icon type="ninjaMainIcon" size={SVG_SIZE_XXL} />
      </Button>
    );
  }

  return (
    <div className={classNames('nj-create-new-chat', className)}>
      <div className="nj-create-new-chat--container" role="button" tabIndex={0}>
        <div className="nj-create-new-chat--gradient-shadow" />

        <div className="nj-create-new-chat--gradient-border" />

        <button
          data-e2e="new-chat-button"
          onClick={handleCreateNewChat}
          className="nj-create-new-chat--button"
          tabIndex={-1}
          data-tooltip-id={HOVER_TOOLTIP_ID}
          data-tooltip-content="New chat"
        >
          <div className="nj-create-new-chat--button-container">
            <PencilSimpleLine size={SVG_SIZE_M} />
          </div>
        </button>
      </div>
    </div>
  );
};

import {
  setIsGetEnterpriseModalOpen,
  teamsState,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { useCallback, useMemo } from 'react';

export const useGetEnterpriseModal = () => {
  const { isGetEnterpriseModalOpen } = useAppSelector(teamsState);
  const dispatch = useAppDispatch();

  const onCloseGetEnterpriseModal = useCallback(() => {
    dispatch(setIsGetEnterpriseModalOpen(false));
  }, [dispatch]);

  const onOpenGetEnterpriseModal = useCallback(() => {
    dispatch(setIsGetEnterpriseModalOpen(true));
  }, [dispatch]);

  return useMemo(
    () => ({
      isGetEnterpriseModalOpen,
      onCloseGetEnterpriseModal,
      onOpenGetEnterpriseModal,
    }),
    [
      isGetEnterpriseModalOpen,
      onCloseGetEnterpriseModal,
      onOpenGetEnterpriseModal,
    ],
  );
};

import styles from './PaymentPageTeamsTabs.module.scss';
import { TeamsTabs } from 'src/types';
import cn from 'classnames';
import { BuildingOffice, Icon, User } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';

type PaymentPageTeamsTabsProps = {
  activeTab: TeamsTabs;
  onChange: (value: TeamsTabs) => void;
};

const TAB_LIST: { tab: TeamsTabs; Icon: Icon }[] = [
  { tab: TeamsTabs.INDIVIDUAL, Icon: User },
  { tab: TeamsTabs.BUSINESS, Icon: BuildingOffice },
];

export const PaymentPageTeamsTabs = ({
  activeTab,
  onChange,
}: PaymentPageTeamsTabsProps) => {
  return (
    <div className={styles.root}>
      {TAB_LIST.map(({ tab, Icon }) => {
        const handleTabClick = () => {
          onChange(tab);
        };
        return (
          <button
            type="button"
            key={tab}
            className={cn(styles.tab, { [styles.active]: activeTab === tab })}
            onClick={handleTabClick}
            data-e2e={`account-type-${tab}-button`}
          >
            <Icon size={SVG_SIZE_M} />
            {tab}
          </button>
        );
      })}
    </div>
  );
};

import cn from 'classnames';
import { Link } from 'react-router-dom';
import { CaretRight, Gear } from '@phosphor-icons/react';
import { AppRoutes, BillingSettingsNestedRoutes } from 'src/types';
import { SVG_SIZE_M } from 'src/constants';
import styles from './SettingsLink.module.scss';
import {
  useSession,
  useRightSidePanelState,
  useRightSidePanelActions,
} from 'src/hooks';

export const SettingsLink = () => {
  const { isOpenTier } = useSession();
  const { isRightPanelExpanded } = useRightSidePanelState();
  const { resetAndCloseRightSidePanelData } = useRightSidePanelActions();

  return (
    <Link
      to={`${AppRoutes.MANAGE_ACCOUNT}/${BillingSettingsNestedRoutes.SUBSCRIPTION}`}
      className={cn(styles.root, { [styles.disabled]: isOpenTier })}
      onClick={(e) => {
        isOpenTier && e.preventDefault();
        if (isRightPanelExpanded) {
          resetAndCloseRightSidePanelData();
        }
      }}
      data-e2e="manage-settings-button"
    >
      <span className={styles.container}>
        <Gear size={SVG_SIZE_M} />
        Manage account & settings
      </span>

      <CaretRight size={SVG_SIZE_M} />
    </Link>
  );
};

import { useCallback, useMemo } from 'react';
import {
  useAppDispatch,
  setRightPanelSections,
  setIsRightPanelExpanded,
} from 'src/store';
import { RightPanelSections } from 'src/types';

interface RightSidePanelDataProps {
  panelType: RightPanelSections | null;
  isExpanded?: boolean;
}

// TODO(olha): rename to useRightPanelActions
export const useRightSidePanelActions = () => {
  const dispatch = useAppDispatch();

  const toggleRightSidePanelExpanded = useCallback(
    (value?: boolean) => {
      dispatch(setIsRightPanelExpanded(value));
    },
    [dispatch],
  );

  const handleSetRightSidePanelType = useCallback(
    (value: RightPanelSections | null) => {
      dispatch(setRightPanelSections(value));
    },
    [dispatch],
  );

  const setRightSidePanelData = useCallback(
    ({ panelType, isExpanded }: RightSidePanelDataProps) => {
      toggleRightSidePanelExpanded(isExpanded);
      handleSetRightSidePanelType(panelType);
    },
    [handleSetRightSidePanelType, toggleRightSidePanelExpanded],
  );

  const resetAndCloseRightSidePanelData = useCallback(() => {
    setRightSidePanelData({
      // TODO(olha): we should keep panelType for smooth animation
      panelType: null,
      isExpanded: false,
    });
  }, [setRightSidePanelData]);

  return useMemo(
    () => ({
      setRightSidePanelData,
      resetAndCloseRightSidePanelData,
      toggleRightSidePanelExpanded,
    }),
    [
      setRightSidePanelData,
      resetAndCloseRightSidePanelData,
      toggleRightSidePanelExpanded,
    ],
  );
};

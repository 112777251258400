import {
  ChitChatCard,
  type ImageCard,
  Message,
  MessageType,
  type ResearchTaskCreationCard,
  TaskSkill,
} from 'src/types';
import { logAction } from 'src/utils/analytics';
import { datadogLogs } from '@datadog/browser-logs';

const outliersConfig: Record<string, number> = {
  [TaskSkill.CHITCHAT]: 10,
  [TaskSkill.CODING]: 15,
  [TaskSkill.IMAGE]: 25,
  [TaskSkill.DOCUMENT_QA]: 30,
  [TaskSkill.RESEARCH]: 30,
};

export interface TaskMetric {
  conversation_id?: string;
  user_id?: string;
  start_time: number;
  end_time?: number;
  location: string;
}

export interface LogTaskMetricRequest {
  conversationId: string;
  skill: TaskSkill;
  message?: Message;
}

export const taskMetricsMap: Record<string, TaskMetric> = {};

export const initTaskMetric = (taskMetric: TaskMetric) => {
  if (!taskMetric.conversation_id) {
    return;
  }
  taskMetricsMap[taskMetric.conversation_id] = taskMetric;
};

export const maybeLogTaskMetric = (message: Message) => {
  if (!message.conversation_id) {
    return;
  }

  const taskMetric = taskMetricsMap[message.conversation_id];
  if (!taskMetric) {
    return;
  }

  if (
    message?.message_type === MessageType.CHAT_CARD &&
    message.conversation_id &&
    (message?.payload as ChitChatCard)?.data
  ) {
    // no skill data yet... set end_time but don't log yet
    const skill = (message.payload as ChitChatCard)?.data?.skill;
    if (!skill && !taskMetric.end_time) {
      taskMetric.end_time = Date.now();
      return;
    } else if (skill) {
      logTaskMetric({
        conversationId: message.conversation_id,
        skill: skill as TaskSkill,
        message: message,
      });
    }
  }
  if (
    message?.message_type === MessageType.RESEARCH_TASK_CREATION_CARD &&
    message.conversation_id &&
    (message?.payload as ResearchTaskCreationCard)?.research_card?.data
      ?.search_result?.summary
  ) {
    logTaskMetric({
      conversationId: message.conversation_id,
      skill: TaskSkill.RESEARCH,
      message: message,
    });
  }
  if (
    message?.message_type === MessageType.IMAGE_CARD &&
    message.conversation_id &&
    Object.values((message?.payload as ImageCard)?.images || {}).find(
      (item) => !!item.image_url,
    )
  ) {
    logTaskMetric({
      conversationId: message.conversation_id,
      skill: TaskSkill.IMAGE,
      message: message,
    });
  }
};

export const logTaskMetric = (request: LogTaskMetricRequest) => {
  const { conversationId, skill } = request;
  const taskMetric = taskMetricsMap[conversationId];
  const endTime = taskMetric.end_time || Date.now();
  const latency = (endTime - taskMetric.start_time) / 1000;
  logAction('TaskTimeToFirstWord', {
    latency: latency.toFixed(3),
    user_id: taskMetric.user_id,
    skill: skill,
    location: taskMetric.location,
  });
  if (outliersConfig[skill] && latency >= outliersConfig[skill]) {
    datadogLogs.logger.info('High TTD', {
      conversation_id: request.conversationId,
      task_id: request.message?.task_id,
    });
  }
  delete taskMetricsMap[conversationId];
};

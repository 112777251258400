import { useMemo } from 'react';
import { useAppSelector, imageMaskingState } from 'src/store';

export const useImageMaskingSelectionsCount = () => {
  const { imageMaskingSelectionsCount } = useAppSelector(imageMaskingState);

  return useMemo(
    () => ({
      imageMaskingSelectionsCount,
    }),
    [imageMaskingSelectionsCount],
  );
};

import styles from './SummarySection.module.scss';
import { SubscriptionPlan } from 'src/types';
import { CollapsedItem } from 'src/components/CollapsedItem';
import { SummaryHeader } from '../SummaryHeader';
import { SummaryContent } from '../SummaryContent';

interface SummarySectionProps {
  currentPlan?: SubscriptionPlan;
  isTeams?: boolean;
  isLoading?: boolean;
}

export const SummarySection = ({
  currentPlan,
  isTeams,
  isLoading = false,
}: SummarySectionProps) => {
  if (!currentPlan) {
    return null;
  }

  const header = (
    <SummaryHeader
      selectedPlan={currentPlan}
      originalPrice={currentPlan.total_due_amount}
      dueToday={currentPlan.total_due_amount}
      isLoading={isLoading}
    />
  );

  const content = (
    <SummaryContent
      selectedPlan={currentPlan}
      totalOriginalAmount={currentPlan.total_original_amount}
      subtotal={currentPlan.total_original_amount}
      taxes={0}
      dueToday={currentPlan.total_due_amount}
      discount={currentPlan.total_discount_amount}
      isTeams={isTeams}
      isLoading={isLoading}
    />
  );

  return (
    <div className={styles.root}>
      <CollapsedItem
        title=""
        header={header}
        content={content}
        isExpanded={false}
        headerClassName={styles.collapsedItem}
      />
    </div>
  );
};

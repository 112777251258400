import {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ChatsCircle, Broom } from '@phosphor-icons/react';
import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT, SVG_SIZE_L } from 'src/constants';
import SessionContext from 'src/contexts/SessionContext';
import {
  useSession,
  useLeftPanelState,
  useConversations,
  useConversationParams,
  useTasks,
} from 'src/hooks';
import { Conversation, ChatsFilter } from 'src/types';
import { SpinnerSimple } from 'src/v2/commonComponents/SpinnerSimple';
import { ScrollToEdgeButton } from 'src/components/ScrollToEdgeButton';
import styles from './ConversationsList.module.scss';
import { ConversationsGroupedByDate } from './components/ConversationsGroupedByDate';
import { UpsellChatsMessage } from 'src/components/UpsellChatsMessage';

const SVG_SIZE_LG = 48;

enum ConversationsListEmptyStates {
  NO_RESULTS = `There are no matches for your criteria.`,
  DEFAULT = `Starting a chat with Ninja creates new conversation threads. Dive in and chat whenever you are ready.`,
}

export const ConversationsList = () => {
  const { isOpenTier } = useSession();
  const { isLeftPanelExpanded } = useLeftPanelState();

  const {
    conversations,
    isConversationsLoading,
    isConversationsFetching,
    newPageToken,
  } = useConversations();
  const { currentConversationId } = useConversationParams();

  const { filteredTasks, isFilteredTasksList, newFilteredTaskPageToken } =
    useTasks();

  const conversationsForRender = useMemo(
    () => (isFilteredTasksList ? filteredTasks : conversations || []),
    [conversations, filteredTasks, isFilteredTasksList],
  );

  const { setPageToken, chatsFilter, setFilteredTasksPageToken } =
    useContext(SessionContext);

  const [scrolledToBottom, setScrolledToBottom] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const [isButtonScrollUpVisible, setIsButtonScrollUpVisible] = useState(false);

  useEffect(() => {
    const scrollRef = containerRef?.current;

    const onConversationsScroll = () => {
      const {
        scrollTop = 0,
        clientHeight = 0,
        scrollHeight = 0,
      } = scrollRef || {};

      setIsButtonScrollUpVisible(scrollTop > 30);

      if (!scrolledToBottom) {
        if (scrollTop + clientHeight >= scrollHeight) {
          setScrolledToBottom(true);
        }
      }
    };

    scrollRef?.addEventListener('scroll', onConversationsScroll);

    return () => {
      scrollRef?.removeEventListener('scroll', onConversationsScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (conversationsForRender.length === 0) {
      setScrolledToBottom(false);
    }

    if (scrolledToBottom && (newPageToken || newFilteredTaskPageToken)) {
      if (!isFilteredTasksList && newPageToken) {
        setPageToken(newPageToken);
      }

      if (isFilteredTasksList && newFilteredTaskPageToken) {
        setFilteredTasksPageToken(newFilteredTaskPageToken);
      }

      setScrolledToBottom(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrolledToBottom]);

  const groupedConversations: Record<string, Conversation[]> = useMemo(() => {
    const acc: Record<string, Conversation[]> = {};

    if (!conversationsForRender) {
      return acc;
    }

    for (const conversation of conversationsForRender) {
      const groupedDate = dayjs(conversation.timestamp).format(
        DEFAULT_DATE_FORMAT,
      );

      if (!acc[groupedDate]) {
        acc[groupedDate] = [conversation];
      } else {
        acc[groupedDate] = [...acc[groupedDate], conversation];
      }
    }

    return acc;
  }, [conversationsForRender]);

  const isEmpty = useMemo(() => {
    return Object.keys(groupedConversations).length === 0;
  }, [groupedConversations]);

  const emptyStateMessage = useMemo(() => {
    switch (chatsFilter) {
      case ChatsFilter.ALL:
        return (
          <Fragment>
            <span>
              <ChatsCircle size={SVG_SIZE_LG} weight="light" />
            </span>
            <span>{ConversationsListEmptyStates.DEFAULT}</span>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <span>
              <Broom size={SVG_SIZE_LG} weight="light" />
            </span>
            <span>{ConversationsListEmptyStates.NO_RESULTS}</span>
          </Fragment>
        );
    }
  }, [chatsFilter]);

  return (
    <div className={styles.root} ref={containerRef}>
      {isConversationsLoading ? (
        <div className={styles.spinnerWrapper}>
          <SpinnerSimple />
        </div>
      ) : (
        <>
          <div className={styles.container}>
            {Object.entries(groupedConversations).map(([date, group]) => (
              <ConversationsGroupedByDate
                key={date}
                date={date}
                conversations={group}
                selectedConversationId={currentConversationId}
              />
            ))}
          </div>

          {isOpenTier && <UpsellChatsMessage />}

          {!isEmpty && isConversationsFetching && (
            <SpinnerSimple size={SVG_SIZE_L} />
          )}

          {!isConversationsLoading && isEmpty && (
            <div className={styles.emptyContainer}>{emptyStateMessage}</div>
          )}
        </>
      )}

      <ScrollToEdgeButton
        direction="up"
        scrollableContainerRef={containerRef}
        isVisible={isButtonScrollUpVisible && isLeftPanelExpanded}
        className={styles.scrollButton}
      />
    </div>
  );
};

import { useSession } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/types';
import styles from './DisabledImageOverlay.module.scss';
import { Button } from 'src/v2/commonComponents/Button';

export const DisabledImageOverlay = () => {
  const { isOpenTier, isFreeTier } = useSession();
  const navigate = useNavigate();

  const handleDisabledOverlayClick = () => {
    if (isOpenTier) {
      navigate(AppRoutes.SIGN_UP);
    }
    if (isFreeTier) {
      navigate(AppRoutes.PAYMENT);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <span>Unlock to see</span>

        <Button
          type="button"
          color="secondary"
          onClick={handleDisabledOverlayClick}
        >
          {isOpenTier ? 'Sign up' : 'Upgrade'}
        </Button>
      </div>
    </div>
  );
};

import { Modal } from 'src/components/Modal';
import styles from './PurchaseSeatsModal.module.scss';
import { Button } from 'src/v2/commonComponents/Button';
import {
  useAddSeatsCheckout,
  usePurchaseSeatsModal,
  useSubscriptionInfo,
} from 'src/hooks';
import { AppRoutes, BillingSettingsNestedRoutes } from 'src/types';
import { useNavigate } from 'react-router-dom';

export const PurchaseSeatsModal = () => {
  const { isOpenPurchaseSeatsModal, onClosePurchaseSeatsModal } =
    usePurchaseSeatsModal();
  const { onOpenAddSeatsCheckout } = useAddSeatsCheckout();
  const navigate = useNavigate();
  const {
    subscriptionInfo,
    isAppleOrAmpPayment,
    isSubscriptionInfoLoading,
    isSubscriptionInfoFetching,
  } = useSubscriptionInfo();

  const handleAddSeatsClick = () => {
    onClosePurchaseSeatsModal();
    onOpenAddSeatsCheckout();
    navigate(
      `${AppRoutes.MANAGE_ACCOUNT}/${BillingSettingsNestedRoutes.SUBSCRIPTION}`,
    );
  };

  const isDisableAddSeats =
    isSubscriptionInfoLoading ||
    isSubscriptionInfoFetching ||
    subscriptionInfo?.has_scheduled_downgrade ||
    subscriptionInfo?.is_cancelled ||
    subscriptionInfo?.has_scheduled_plan_change ||
    subscriptionInfo?.processing_plan_change ||
    isAppleOrAmpPayment;

  return (
    <Modal
      open={isOpenPurchaseSeatsModal}
      onClose={onClosePurchaseSeatsModal}
      className={styles.purchaseSeatsModal}
      overlayClassName={styles.overlay}
      blockScroll={false}
    >
      <div className={styles.root}>
        <h4 className={styles.title}>Invite Your Team to Collaborate</h4>
        <span className={styles.text}>
          Adding seats will allow you to invite team members and work together
          more effectively.
        </span>
        <div className={styles.buttonRow}>
          <Button onClick={onClosePurchaseSeatsModal} color="link">
            Continue Without Seats
          </Button>
          <Button onClick={handleAddSeatsClick} disabled={isDisableAddSeats}>
            Add Seats
          </Button>
        </div>
      </div>
    </Modal>
  );
};

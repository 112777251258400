/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ImageEditRequestPayload = {
  payload_type?: ImageEditRequestPayload.payload_type;
  image_url: string;
  mask_url?: string;
};

export namespace ImageEditRequestPayload {
  export enum payload_type {
    IMAGE_EDIT = 'image-edit',
  }
}

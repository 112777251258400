import { Moon } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { useThemeActions, useColorTheme } from 'src/hooks';
import styles from './ThemeSelector.module.scss';
import { SwitchSimple } from 'src/v2/commonComponents/SwitchSimple';

export const ThemeSelector = () => {
  const { setIsDarkTheme } = useThemeActions();
  const { isDarkTheme } = useColorTheme();

  const handleChange = (value: boolean) => {
    setIsDarkTheme(value);
  };

  return (
    <div className={styles.root}>
      <span className={styles.container}>
        <Moon size={SVG_SIZE_M} />
        Dark
      </span>

      <SwitchSimple isChecked={isDarkTheme} onChange={handleChange} />
    </div>
  );
};

import { useMemo } from 'react';
import { useAppSelector, imageMaskingState } from 'src/store';

export const useAttachedImageTooltipState = () => {
  const { isAttachedImageTooltipVisible, isAttachedImageEditingMode } =
    useAppSelector(imageMaskingState);

  return useMemo(
    () => ({
      isAttachedImageTooltipVisible,
      isAttachedImageEditingMode,
    }),
    [isAttachedImageTooltipVisible, isAttachedImageEditingMode],
  );
};

import { useContext } from 'react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { ScrollToEdgeButton } from 'src/components/ScrollToEdgeButton';
import styles from './ScrollToButtonMessageListButton.module.scss';
import {
  useCurrentConversationStatuses,
  useConversationParams,
  useIntersectionObserver,
} from 'src/hooks';

export const ScrollToButtonMessageListButton = () => {
  const { threadMessageListRef, endOfMessagesRef } =
    useContext(ForwardRefContext);

  const { isCurrentConversationLoading } = useCurrentConversationStatuses();
  const { isLandingPage } = useConversationParams();

  const isVisibleMessageListBottom = useIntersectionObserver(endOfMessagesRef, {
    threshold: 1.0,
  });

  return (
    <ScrollToEdgeButton
      scrollableContainerRef={threadMessageListRef}
      direction="down"
      isVisible={
        !isVisibleMessageListBottom &&
        !isCurrentConversationLoading &&
        !isLandingPage
      }
      className={styles.root}
    />
  );
};

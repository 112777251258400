import { useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiGenerationStatus, AppRoutes, MediaType } from 'src/types';
import { useSession } from 'src/hooks';
import { ThreadMediaSkeleton } from '../ThreadMediaSkeleton';
import { ThreadMediaError } from '../ThreadMediaError';
import { ThreadMediaHeader } from '../ThreadMediaHeader';
import styles from './ThreadMediaThumbnail.module.scss';
import { AvailableFluxImageModels, SVG_SIZE_M } from 'src/constants';
import classNames from 'classnames';
import { Play } from '@phosphor-icons/react';
import { Button } from 'src/v2/commonComponents/Button';
import { IconName } from 'src/components/Icon';

interface ThreadMediaThumbnailProps {
  //(olha): MediaType === 'video' is not used for now
  mediaType: MediaType;
  thumbnail: string;
  title?: string;
  icon?: IconName;
  withDisabledOverlay?: boolean;
  withWatermark?: boolean;
  status?: AiGenerationStatus;
  source?: string;
  videoDuration?: number;
  videoUrl?: string;
  className?: string;
  onClick: () => void;
}

export const ThreadMediaThumbnail = ({
  mediaType,
  thumbnail,
  withDisabledOverlay,
  withWatermark,
  title,
  icon,
  status,
  source,
  videoDuration,
  videoUrl,
  className,
  onClick,
}: ThreadMediaThumbnailProps) => {
  const navigate = useNavigate();
  const { isOpenTier, isFreeTier } = useSession();
  const [isCropped, setIsCropped] = useState(false);

  const isLoading = thumbnail.length === 0;

  const isPixPro =
    !!source && (AvailableFluxImageModels as string[]).includes(source);

  const handleDisabledOverlayClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (isOpenTier) {
      navigate(AppRoutes.SIGN_UP);
    }
    if (isFreeTier) {
      navigate(AppRoutes.PAYMENT);
    }
  };

  if (
    isCropped ||
    status === AiGenerationStatus.CONTENT_POLICY_VIOLATION ||
    status === AiGenerationStatus.FAILURE
  ) {
    return (
      <div className={styles.root}>
        <ThreadMediaError status={status} mediaType={mediaType} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.root}>
        <ThreadMediaSkeleton isPixPro={isPixPro} icon={icon} />
      </div>
    );
  }

  return (
    <div className={classNames(styles.root, className)}>
      <ThreadMediaHeader
        mediaType={mediaType}
        thumbnail={thumbnail}
        isEditDisabled={withWatermark}
        icon={icon}
        videoDuration={videoDuration}
        videoUrl={videoUrl}
      />

      <img
        className={styles.image}
        alt={title || ''}
        src={thumbnail}
        onClick={onClick}
        onError={() => {
          setIsCropped(true);
        }}
      />

      {withDisabledOverlay && (
        <button
          type="button"
          className={styles.mask}
          onClick={handleDisabledOverlayClick}
        >
          <span>Unlock to see</span>
          <div className={styles.maskButton}>
            {isOpenTier ? 'Sign up' : 'Upgrade'}
          </div>
        </button>
      )}

      {mediaType === 'video' && (
        <Button
          shape="round"
          color="transparent"
          className={styles.playIcon}
          onClick={onClick}
        >
          <Play weight="fill" size={SVG_SIZE_M} />
        </Button>
      )}
    </div>
  );
};

import { useGetFileURLQuery } from 'src/store/services';
import { useSession } from './useSession';

export const useFileUrl = (fileName: string, skipCondition?: boolean) => {
  const { appUser } = useSession();
  const { data, isError } = useGetFileURLQuery(
    { user_id: appUser.user_id, file_name: fileName },
    {
      skip: !fileName || !appUser.user_id || !!skipCondition,
    },
  );

  return { url: data?.url, isError };
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ChatModeSidePanel,
  LeftPanelSections,
  RightPanelSections,
} from 'src/types';
import { RootState } from '../index';
import { BREAKPOINT_DESKTOP } from 'src/constants';

interface InterfaceControl {
  /**
   * @deprecated
   */
  chatModeSidePanel: ChatModeSidePanel;
  /**
   * @deprecated
   */
  isSettingsPanelExpanded: boolean;
  isLeftPanelExpanded: boolean;
  leftPanelSections: LeftPanelSections;
  isRightPanelExpanded: boolean;
  rightPanelSections: RightPanelSections | null;
}

const initialState: InterfaceControl = {
  chatModeSidePanel: ChatModeSidePanel.NONE,
  isSettingsPanelExpanded: false,
  isLeftPanelExpanded: window.innerWidth >= BREAKPOINT_DESKTOP ? true : false,
  leftPanelSections: LeftPanelSections.CONVERSATION_LIST,
  isRightPanelExpanded: false,
  rightPanelSections: null,
};

export const interfaceControlSlice = createSlice({
  name: 'interfaceControl',
  initialState,
  reducers: {
    /**
     * @deprecated
     */
    setChatModeSidePanel: (
      state,
      { payload }: PayloadAction<ChatModeSidePanel>,
    ) => {
      state.chatModeSidePanel = payload;
    },
    /**
     * @deprecated
     */
    setIsSettingsPanelExpanded: (
      state,
      { payload }: PayloadAction<boolean | undefined>,
    ) => {
      state.isSettingsPanelExpanded = payload ?? !state.isSettingsPanelExpanded;
    },
    setIsLeftPanelExpanded: (
      state,
      { payload }: PayloadAction<boolean | undefined>,
    ) => {
      state.isLeftPanelExpanded = payload ?? !state.isLeftPanelExpanded;
    },
    setLeftPanelSections: (
      state,
      { payload }: PayloadAction<LeftPanelSections>,
    ) => {
      state.leftPanelSections = payload;
    },
    setIsRightPanelExpanded: (
      state,
      { payload }: PayloadAction<boolean | undefined>,
    ) => {
      state.isRightPanelExpanded = payload ?? !state.isRightPanelExpanded;
    },
    setRightPanelSections: (
      state,
      { payload }: PayloadAction<RightPanelSections | null>,
    ) => {
      state.rightPanelSections = payload;
    },
  },
});

export const interfaceControlState = (state: RootState) =>
  state.interfaceControl as InterfaceControl;

export const {
  setChatModeSidePanel,
  setIsSettingsPanelExpanded,
  setIsLeftPanelExpanded,
  setLeftPanelSections,
  setRightPanelSections,
  setIsRightPanelExpanded,
} = interfaceControlSlice.actions;

export default interfaceControlSlice.reducer;

import { useTeamInfo } from '../teamsHooks';
import { useSession } from '../useSession';
import { TierConfigItem, isNonTeamUserTier } from 'src/types';
import {
  nonTeamTierConfig,
  teamOwnerTierConfig,
  teamAdminTierConfig,
  teamMemberTierConfig,
  defaultTierConfig,
} from 'src/constants';

// TODO(olha): extend return object by adding a callback
export const useTierConfig = (): TierConfigItem => {
  const {
    appUser: { tier_id },
  } = useSession();

  const { isOwner, isAdmin, isMember } = useTeamInfo();

  if (!tier_id) {
    return defaultTierConfig;
  }

  if (isNonTeamUserTier(tier_id)) {
    return nonTeamTierConfig[tier_id];
  }

  if (isOwner) {
    return teamOwnerTierConfig[tier_id];
  }

  if (isAdmin) {
    return teamAdminTierConfig[tier_id];
  }

  if (isMember) {
    return teamMemberTierConfig[tier_id];
  }

  return defaultTierConfig;
};

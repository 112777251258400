import styles from './CheckoutSeatsSection.module.scss';
import { useDebouncedCallback } from 'use-debounce';
import { useState } from 'react';

type CheckoutSeatsSectionProps = {
  numberOfSeats: number;
  onChangeNumberOfSeats: (value: number) => void;
};

export const CheckoutSeatsSection = ({
  numberOfSeats,
  onChangeNumberOfSeats,
}: CheckoutSeatsSectionProps) => {
  const [inputValue, changeInputValue] = useState(`${numberOfSeats}`);

  const handleDebouncedOnChange = useDebouncedCallback((query: number) => {
    onChangeNumberOfSeats(query);
  }, 600);

  const handleChangeValue = (value: string) => {
    const inputValue = Number(value);
    if (inputValue >= 0) {
      changeInputValue(value);
      if (value.length > 0) {
        handleDebouncedOnChange(inputValue);
      }
      if (Number(value) === 0) {
        handleDebouncedOnChange(1);
      }
    }
  };

  const handleChangeValueOnBlur = () => {
    if (inputValue === '' || inputValue === '0') {
      changeInputValue('1');
      // changeValue(1);
      handleDebouncedOnChange(1);
    }
  };

  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.labelWrapper}>
        <label className={styles.label}>Seats</label>
        <span className={styles.description}>
          Choose the number of seats included in your workspace.
        </span>
      </div>
      <input
        className={styles.input}
        type="number"
        step={1}
        min={1}
        value={inputValue}
        onChange={(e) => handleChangeValue(e.target.value)}
        onBlur={handleChangeValueOnBlur}
      />
    </div>
  );
};

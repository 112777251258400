import { Modal } from 'src/components/Modal';
import styles from './InviteUsersModal.module.scss';
import {
  InviteUsersFileInputId,
  LIMIT_NUMBER_OF_INVITED_EMAILS,
  VALID_EMAIL_PATTERN,
} from 'src/constants';
import { ChangeEvent, useState } from 'react';
import log from 'src/utils/logger';
import { InviteUsersModalStep } from 'src/v2/pages/manageUsersInWorkspace/components/InviteUsersModalStep';
import { FileWithInvitedUsersStep } from 'src/v2/pages/manageUsersInWorkspace/components/FileWithInvitedUsersStep';
import { ConfirmInvitedUsersStep } from 'src/v2/pages/manageUsersInWorkspace/components/ConfirmInvitedUsersStep';
import { useInviteUsersModal } from 'src/hooks';

const errorMissingEmailsText =
  'Input is missing email addresses, please try again';

export const InviteUsersModal = () => {
  const { isOpenInviteUsersModal, onChangeIsOpenInviteUsersModal } =
    useInviteUsersModal();
  const [fileWithEmails, setFileWithEmails] = useState<File | null>(null);
  const [listOfEmails, setListOfEmails] = useState<string[]>([]);
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [isConfirmationStep, setIsConfirmationStep] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [inputError, setInputError] = useState<string>('');
  const [fileUploadError, setFileUploadError] = useState<string>('');
  const [hasLimitError, setHasLimitError] = useState<boolean>(false);

  const handleUploadClick = () => {
    document.getElementById(InviteUsersFileInputId)?.click();
  };

  const handleDeleteFile = () => {
    if (!!fileWithEmails) {
      setFileWithEmails(null);
      setListOfEmails([]);
    }
    setIsFileLoading(false);
  };

  const handleCancelClick = () => {
    handleDeleteFile();
    setListOfEmails([]);
    setInputValue('');
  };

  const handleClose = () => {
    setIsConfirmationStep(false);
    handleCancelClick();
    onChangeIsOpenInviteUsersModal(false);
    setInputError('');
    setFileUploadError('');
    setHasLimitError(false);
  };

  const handleContinueClick = () => {
    setIsConfirmationStep(true);
  };

  const handleChangeInputValue = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (inputError.length > 0) {
      setInputError('');
    }
    if (fileUploadError.length > 0) {
      setFileUploadError('');
    }
    if (hasLimitError) {
      setHasLimitError(false);
    }
    setInputValue(event.currentTarget.value);
  };

  const handleInviteInputEmails = () => {
    const emails = inputValue.split(',');
    const validEmails = emails
      .map((item) => (item || '').trim())
      .filter((email) => VALID_EMAIL_PATTERN.value.test(email));
    if (validEmails.length > 0) {
      if (validEmails.length > LIMIT_NUMBER_OF_INVITED_EMAILS) {
        setListOfEmails(validEmails.slice(0, LIMIT_NUMBER_OF_INVITED_EMAILS));
        setHasLimitError(true);
      } else {
        setListOfEmails(validEmails);
      }
      setIsConfirmationStep(true);
    } else {
      setInputError(errorMissingEmailsText);
    }
  };

  const handleGoBackClick = () => {
    handleDeleteFile();
    setListOfEmails([]);
    setIsConfirmationStep(false);
    setHasLimitError(false);
  };

  const handleFileSelect = (event: ChangeEvent) => {
    if (inputError.length > 0) {
      setInputError('');
    }
    if (hasLimitError) {
      setHasLimitError(false);
    }
    const files = ((event.target as HTMLInputElement)?.files || []) as FileList;
    const file = files[0];
    if (file) {
      setIsFileLoading(true);
      setFileWithEmails(file);
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>): void => {
        try {
          const text = event.target?.result as string;
          const lines = text.split('\n');
          const parsedEmails = lines
            .map((line) => line.replace(/"/g, '').trim())
            .filter(Boolean)
            .filter((email) => VALID_EMAIL_PATTERN.value.test(email));

          if (parsedEmails.length > 0) {
            if (parsedEmails.length > LIMIT_NUMBER_OF_INVITED_EMAILS) {
              setListOfEmails(
                parsedEmails.slice(0, LIMIT_NUMBER_OF_INVITED_EMAILS),
              );
              setHasLimitError(true);
            } else {
              setListOfEmails(parsedEmails);
            }
          } else {
            setFileWithEmails(null);
            setListOfEmails([]);
            setFileUploadError(errorMissingEmailsText);
          }

          setIsFileLoading(false);
          const fileInput = document.getElementById(
            InviteUsersFileInputId,
          ) as HTMLInputElement;
          if (fileInput) {
            fileInput.value = '';
            fileInput.files = null;
          }
        } catch (err) {
          log.error(err, 'Error parsing file');
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <Modal
      open={isOpenInviteUsersModal}
      onClose={handleClose}
      className={styles.inviteUsersModal}
      overlayClassName={styles.overlay}
      blockScroll={false}
    >
      {!listOfEmails.length && (
        <InviteUsersModalStep
          onClose={handleClose}
          onFileSelect={handleFileSelect}
          onUploadFile={handleUploadClick}
          inputValue={inputValue}
          onInputChange={handleChangeInputValue}
          onInviteInputEmails={handleInviteInputEmails}
          inputError={inputError}
          fileUploadError={fileUploadError}
        />
      )}
      {!!fileWithEmails && !isConfirmationStep && (
        <FileWithInvitedUsersStep
          file={fileWithEmails}
          isLoading={isFileLoading}
          onDeleteFile={handleDeleteFile}
          onClose={handleCancelClick}
          onContinue={handleContinueClick}
        />
      )}

      {isConfirmationStep && (
        <ConfirmInvitedUsersStep
          onGoBack={handleGoBackClick}
          onClose={handleClose}
          listOfEmails={listOfEmails}
          hasLimitError={hasLimitError}
        />
      )}
    </Modal>
  );
};

import { createApi } from '@reduxjs/toolkit/query/react';
import { baseTasksQuery } from './config';
import { UserTeamInfoResponse } from 'src/types/models/UserTeamInfoResponse';
import { ListSeatsResponse } from 'src/types/models/ListSeatsResponse';
import { SEATS_PAGE_LIMIT } from 'src/constants';
import { Seat } from 'src/types/models/Seat';
import { SeatStatus } from 'src/types/models/SeatStatus';
import { SeatsParams } from 'src/types';
import { InviteTeamUsersResponse } from 'src/types/models/InviteTeamUsersResponse';
import { SeatRole } from 'src/types/models/SeatRole';
import { TeamResponse } from 'src/types/models/TeamResponse';
import { InvitationResponse } from 'src/types/models/InvitationResponse';

export enum TeamsTags {
  TeamByUserId = 'TeamByUserId',
  SeatsList = 'SeatsList',
  TeamInfoByInviteToken = 'TeamInfoByInviteToken',
}

export const teamsApi = createApi({
  reducerPath: 'teamsApi',
  baseQuery: baseTasksQuery,
  tagTypes: [
    TeamsTags.TeamByUserId,
    TeamsTags.SeatsList,
    TeamsTags.TeamInfoByInviteToken,
  ],
  endpoints: (builder) => ({
    getSeatsList: builder.query<ListSeatsResponse, SeatsParams>({
      query: ({ team_id, user_id, page_token, statuses }) => ({
        url: `/teams/${team_id}/seats`,
        params: {
          user_id,
          records_limit: SEATS_PAGE_LIMIT,
          b64_serialized_last_evaluated_key: page_token,
          include_statuses: statuses,
        },
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        const { team_id, statuses } = queryArgs;
        return { team_id, statuses };
      },
      merge: (currentCache, newData) => {
        currentCache.seats.push(...newData.seats);
        currentCache.b64_serialized_last_evaluated_key =
          newData.b64_serialized_last_evaluated_key;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          !!currentArg?.page_token &&
          currentArg?.page_token !== previousArg?.page_token
        );
      },
      providesTags: (_, __, { team_id, statuses, page_token }) => {
        return [
          {
            type: TeamsTags.SeatsList,
            id: team_id,
            status: statuses,
          },
        ];
      },
    }),
    getTeamByUserId: builder.query<UserTeamInfoResponse, { user_id: string }>({
      query: ({ user_id }) => ({
        url: `/teams?user_id=${user_id}`,
      }),
      providesTags: (_, __, { user_id }) => {
        return [{ type: TeamsTags.TeamByUserId, id: user_id }];
      },
    }),

    getTeamByInvitationToken: builder.query<
      InvitationResponse,
      { token: string; user_id: string }
    >({
      query: ({ token, user_id }) => ({
        url: `/teams/invitation/${token}`,
        params: {
          user_id,
        },
      }),
      providesTags: [TeamsTags.TeamInfoByInviteToken],
    }),

    updateSeat: builder.mutation<
      Seat,
      {
        user_id: string;
        team_id: string;
        seat_id: string;
        seat: Partial<Seat>;
        statuses: SeatStatus;
      }
    >({
      query: ({ user_id, team_id, seat_id, seat }) => ({
        url: `/teams/${team_id}/seats/${seat_id}`,
        method: `PUT`,
        params: {
          user_id,
        },
        body: seat,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          teamsApi.util.updateQueryData(
            'getSeatsList',
            {
              user_id: patch.user_id,
              team_id: patch.team_id,
              page_token: '',
              statuses: patch.statuses,
            },
            (draft) => ({
              ...draft,
              seats: draft.seats.map((item) =>
                item.seat_id === patch.seat_id
                  ? { ...item, ...patch.seat }
                  : item,
              ),
            }),
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    unassignSeat: builder.mutation<
      Seat,
      {
        current_user_id: string;
        user_id: string;
        team_id: string;
        seat_id: string;
        statuses: SeatStatus;
      }
    >({
      query: ({ user_id, team_id, seat_id, current_user_id }) => ({
        url: `/teams/${team_id}/seats/${seat_id}/unassign`,
        method: `PUT`,
        params: {
          user_id: current_user_id,
        },
        body: {
          user_id,
        },
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult1 = dispatch(
          teamsApi.util.updateQueryData(
            'getSeatsList',
            {
              user_id: patch.current_user_id,
              team_id: patch.team_id,
              page_token: '',
              statuses: patch.statuses,
            },
            (draft) => ({
              ...draft,
              seats: draft.seats.filter(
                (item) => item.seat_id !== patch.seat_id,
              ),
            }),
          ),
        );
        const patchResult2 = dispatch(
          teamsApi.util.updateQueryData(
            'getTeamByUserId',
            {
              user_id: patch.current_user_id,
            },
            (draft) => ({
              ...draft,
              team: {
                ...draft.team,
                num_seats_assigned: (draft?.team?.num_seats_assigned || 1) - 1,
              },
            }),
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult1.undo();
          patchResult2.undo();
        }
      },
    }),

    leaveWorkspace: builder.mutation<
      Seat,
      {
        user_id: string;
        team_id: string;
      }
    >({
      query: ({ user_id, team_id }) => ({
        url: `/teams/${team_id}/leave-team`,
        method: `PUT`,
        params: {
          user_id: user_id,
        },
      }),
    }),

    cancelInvite: builder.mutation<
      Seat,
      {
        user_id: string;
        team_id: string;
        seat_id: string;
      }
    >({
      query: ({ user_id, team_id, seat_id }) => ({
        url: `/teams/${team_id}/seats/${seat_id}/cancel-invite`,
        method: `PUT`,
        params: {
          user_id,
        },
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          teamsApi.util.updateQueryData(
            'getSeatsList',
            {
              user_id: patch.user_id,
              team_id: patch.team_id,
              page_token: '',
              statuses: SeatStatus.INVITE_PENDING,
            },
            (draft) => ({
              ...draft,
              seats: draft.seats.filter(
                (item) => item.seat_id !== patch.seat_id,
              ),
            }),
          ),
        );
        const patchResult2 = dispatch(
          teamsApi.util.updateQueryData(
            'getTeamByUserId',
            {
              user_id: patch.user_id,
            },
            (draft) => ({
              ...draft,
              team: {
                ...draft.team,
                num_seats_invite_pending:
                  (draft?.team?.num_seats_invite_pending || 1) - 1,
              },
            }),
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          patchResult2.undo();
        }
      },
    }),

    inviteUsers: builder.mutation<
      InviteTeamUsersResponse,
      {
        user_id: string;
        team_id: string;
        users: { email: string; role: SeatRole }[];
      }
    >({
      query: ({ user_id, team_id, users }) => ({
        url: `/teams/${team_id}/invite`,
        method: `POST`,
        params: {
          user_id,
        },
        body: {
          users,
        },
      }),

      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            teamsApi.util.updateQueryData(
              'getSeatsList',
              {
                user_id: patch.user_id,
                team_id: patch.team_id,
                page_token: '',
                statuses: SeatStatus.INVITE_PENDING,
              },
              () => ({ seats: [], b64_serialized_last_evaluated_key: '' }),
            ),
          );
        } catch {}
      },
    }),

    setTeamName: builder.mutation<
      TeamResponse,
      {
        user_id: string;
        team_id: string;
        team_name: string;
      }
    >({
      query: ({ user_id, team_id, team_name }) => ({
        url: `/teams/${team_id}`,
        method: `PUT`,
        params: {
          user_id,
        },
        body: {
          team_name,
        },
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            teamsApi.util.updateQueryData(
              'getTeamByUserId',
              {
                user_id: patch.user_id,
              },
              (draft) => ({
                ...draft,
                team: { ...draft.team, team_name: patch.team_name },
              }),
            ),
          );
        } catch {}
      },
    }),

    acceptInvite: builder.mutation<
      Seat,
      {
        user_id: string;
        team_id: string;
        seat_id: string;
        token: string;
      }
    >({
      query: ({ user_id, team_id, seat_id, token }) => ({
        url: `/teams/${team_id}/seats/${seat_id}/accept-invite`,
        method: `PUT`,
        params: {
          user_id: user_id,
        },
        body: {
          token,
        },
      }),
    }),

    resendInvite: builder.mutation<
      string,
      {
        user_id: string;
        team_id: string;
        seat_id: string;
      }
    >({
      query: ({ user_id, team_id, seat_id }) => ({
        url: `/teams/${team_id}/seats/${seat_id}/resend-invite`,
        method: `PUT`,
        params: {
          user_id: user_id,
        },
      }),
    }),
  }),
});

export const {
  useGetTeamByUserIdQuery,
  useGetSeatsListQuery,
  useUpdateSeatMutation,
  useUnassignSeatMutation,
  useLeaveWorkspaceMutation,
  useCancelInviteMutation,
  useInviteUsersMutation,
  useSetTeamNameMutation,
  useGetTeamByInvitationTokenQuery,
  useAcceptInviteMutation,
  useResendInviteMutation,
  useLazyGetSeatsListQuery,
} = teamsApi;

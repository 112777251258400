interface LabelStandardProps {
  labelType?: 'short' | 'full';
}

const LabelStandard = ({ labelType = 'short' }: LabelStandardProps) => {
  return (
    <label className="nj-label-free nj-label-standard" data-e2e="label-tier-standard">
      {labelType === 'short' ? 'Standard' : 'Standard subscription'}
    </label>
  );
};

export default LabelStandard;

import { UpsellCard } from 'src/v2/commonComponents/UpsellCard';
import videoIcon from 'src/images/icons/art-researcher-sa.svg';
import videoDarkIcon from 'src/images/icons/art-researcher-sa-dark.svg';

export const VideoUpsell = () => {
  return (
    <UpsellCard
      darkIcon={videoDarkIcon}
      lightIcon={videoIcon}
      description="Video Generation is now available with for Ultra users. Try text to video with Amazon Nova Reel’s video model."
      dataGTM="Video-message"
      shouldShowUpgrade
      upgradeTitle="Upgrade to Ultra"
    />
  );
};

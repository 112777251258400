import {
  setIsOpenAddSeatsCheckout,
  teamsState,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { useCallback, useMemo } from 'react';

export const useAddSeatsCheckout = () => {
  const { isOpenAddSeatsCheckout } = useAppSelector(teamsState);
  const dispatch = useAppDispatch();

  const onCloseAddSeatsCheckout = useCallback(() => {
    dispatch(setIsOpenAddSeatsCheckout(false));
  }, [dispatch]);

  const onOpenAddSeatsCheckout = useCallback(() => {
    dispatch(setIsOpenAddSeatsCheckout(true));
  }, [dispatch]);

  return useMemo(
    () => ({
      isOpenAddSeatsCheckout,
      onCloseAddSeatsCheckout,
      onOpenAddSeatsCheckout,
    }),
    [isOpenAddSeatsCheckout, onCloseAddSeatsCheckout, onOpenAddSeatsCheckout],
  );
};

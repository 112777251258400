import { SignOut } from '@phosphor-icons/react';
import { useLogout } from 'src/hooks';
import { sendGTMEvent } from 'src/utils';
import { GTMEvent } from 'src/types';
import { SVG_SIZE_M } from 'src/constants';
import styles from './SignOutButton.module.scss';

export const SignOutButton = () => {
  const { logout } = useLogout();

  const handleLogout = () => {
    sendGTMEvent(GTMEvent.LOG_OUT);
    logout();
  };

  return (
    <button
      onClick={handleLogout}
      className={styles.root}
      data-e2e="sign-out-button"
    >
      <SignOut size={SVG_SIZE_M} />
      <span>Sign Out</span>
    </button>
  );
};

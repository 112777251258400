import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import { AppRoutes } from 'src/types';
import { SVG_SIZE_L, UI_DOMAIN } from 'src/constants';
import { ArrowLeft } from '@phosphor-icons/react';
import { Helmet } from 'react-helmet-async';
import { isEnterprise } from 'src/utils';
import { Appearance } from 'src/types/models/Appearance';
import logoHorizontal from 'src/images/logos/logo-light.svg';
import logoDark from 'src/images/logos/logo-dark.svg';
import classNames from 'classnames';

interface HeaderProps {
  seoTitle?: string;
  seoDescription?: string;
  appearance?: Appearance;
}

export const Header = ({
  seoTitle,
  seoDescription,
  appearance = Appearance.DARK,
}: HeaderProps) => {
  return (
    <header className={styles.root}>
      {seoTitle && seoDescription && (
        <Helmet prioritizeSeoTags>
          <title>{seoTitle}</title>
          <meta content={seoDescription} name="description" data-rh="true" />
          <meta content={seoTitle} property="og:title" />
          <meta
            content={seoDescription}
            property="og:description"
            data-rh="true"
          />
          <meta content={seoTitle} property="twitter:title" data-rh="true" />
          <meta
            content={seoDescription}
            property="twitter:description"
            data-rh="true"
          />
        </Helmet>
      )}
      <Link to="/" className={styles.logo}>
        <img
          src={appearance === Appearance.DARK ? logoHorizontal : logoDark}
          alt="Logo"
        />
      </Link>
      {!isEnterprise && (
        <Link
          to={AppRoutes.HOME}
          className={classNames(styles.backToHome, {
            [styles.lightMode]: appearance === Appearance.LIGHT,
          })}
        >
          <span className={styles.backToHomeLink}>
            <ArrowLeft size={SVG_SIZE_L} />
            Back to {UI_DOMAIN}
          </span>
        </Link>
      )}
    </header>
  );
};

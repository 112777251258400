import styles from './JoinWorkspaceModal.module.scss';
import { Modal } from 'src/components/Modal';
import robotAvatar from 'src/images/login/robot.png';
import { XCircle } from '@phosphor-icons/react';
import { INVITATION_TOKEN, SVG_SIZE_M } from 'src/constants';
import {
  useInvitationInfo,
  useJoinWorkspaceModal,
  useSession,
  useTeamInfo,
} from 'src/hooks';
import { Button } from 'src/v2/commonComponents/Button';
import log from 'src/utils/logger';

export const JoinWorkspaceModal = () => {
  const { isTeamsTier } = useSession();
  const { isOpenJoinWorkspaceModal, onChangeIsOpenJoinWorkspaceModal } =
    useJoinWorkspaceModal();

  const token = localStorage.getItem(INVITATION_TOKEN);

  const {
    invitationInfo,
    isInvitationInfoLoading,
    onAcceptInvite,
    isAcceptInviteLoading,
  } = useInvitationInfo({
    token,
  });

  const { teamInfo } = useTeamInfo();

  const handleClose = () => {
    onChangeIsOpenJoinWorkspaceModal(false);
    localStorage.removeItem(INVITATION_TOKEN);
  };

  const handleAcceptClick = async () => {
    try {
      if (!!token) {
        await onAcceptInvite();
      }
    } catch (e) {
      log.error('error', e);
    } finally {
      handleClose();
    }
  };

  const isButtonDisabled = isAcceptInviteLoading;

  return (
    <Modal
      open={isOpenJoinWorkspaceModal}
      onClose={handleClose}
      className={styles.joinWorkspaceModal}
      overlayClassName={styles.overlay}
      blockScroll={false}
      closeIcon={<XCircle size={SVG_SIZE_M} weight="fill" />}
    >
      <div className={styles.root}>
        <header className={styles.header}>
          <img className={styles.headerImage} src={robotAvatar} alt="Ninja" />
        </header>
        {!isInvitationInfoLoading && !!invitationInfo && (
          <div className={styles.content}>
            <h4 className={styles.title}>
              Join {invitationInfo.team_name}’s workspace
            </h4>
            <span className={styles.description}>
              {invitationInfo.sender_name} has invited you to join the{' '}
              <span className={styles.descriptionBold}>
                {invitationInfo.team_name}’s
              </span>{' '}
              workspace. Accepting this invitation will add you to the workspace
              and{' '}
              {!isTeamsTier
                ? 'cancel your current individual plan.'
                : `remove you from the ${teamInfo?.team_name}’s workspace.`}
            </span>

            <div className={styles.buttonRow}>
              <Button
                color="transparent"
                onClick={handleClose}
                disabled={isButtonDisabled}
              >
                Decline
              </Button>
              <Button onClick={handleAcceptClick} disabled={isButtonDisabled}>
                Accept
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

import { useMemo } from 'react';
import { useAppSelector, imageMaskingState } from 'src/store';

export const useImageMaskingIsLoading = () => {
  const { imageMaskingIsLoading } = useAppSelector(imageMaskingState);

  return useMemo(
    () => ({
      imageMaskingIsLoading,
    }),
    [imageMaskingIsLoading],
  );
};

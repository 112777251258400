import { useContext } from 'react';
import classNames from 'classnames';
import AudioContext from 'src/contexts/AudioContext';
import { ApiTaskSelectable, TaskState } from 'src/types';
import { useReadMessage } from 'src/hooks';
import { TaskStatusIcon } from 'src/components/TaskStatusIcon';
import { SkillLabel } from 'src/components/SkillLabel';
import styles from './TaskItem.module.scss';
import { SVG_SIZE_S } from 'src/constants';

type ConversationTaskProps = {
  task: Partial<ApiTaskSelectable>;
  selected: boolean;
  selectedConversation: boolean;
  shouldOpenRenameModal?: (val: boolean) => void;
  onClick: (taskId: string) => Promise<void>;
};

export const TaskItem = ({
  task,
  selected,
  selectedConversation,
  onClick,
}: ConversationTaskProps) => {
  const { microphoneTriggerRef } = useContext(AudioContext);
  const {
    task_hash,
    state: taskState,
    conversation,
    requires_attention,
  } = task;
  const { readMessage } = useReadMessage();

  const handleClick = () => {
    const { task_id, parent_conversation_id, requires_attention } = task;

    if (!task_id || !parent_conversation_id) {
      return;
    }

    microphoneTriggerRef?.current?.stopRecording(true);

    if (requires_attention) {
      readMessage(task_id);
    }

    onClick(task_id);
  };

  return (
    <div
      className={classNames(styles.root, {
        [styles.selected]: selected,
        [styles.highlighted]: selectedConversation,
        [styles.bold]: requires_attention,
      })}
      data-conversation-id={conversation?.conversation_id}
      onClick={handleClick}
      data-testid="task"
    >
      <div className={styles.container}>
        <div className={styles.skillWrapper}>
          {task.skill && <SkillLabel skill={task.skill} />}
        </div>

        <h3 className={styles.title}>
          <span>{task_hash?.replace('#', '')}</span>
        </h3>

        {taskState !== TaskState.DONE && (
          <span className={styles.taskStatusIcon}>
            <TaskStatusIcon taskState={taskState} size={SVG_SIZE_S} />
          </span>
        )}
      </div>
    </div>
  );
};

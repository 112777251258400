import { DownloadSimple } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { useCurrentMessage, useDownloadFile } from 'src/hooks';
import { isVideoGenerationCard } from 'src/types';
import { Button } from 'src/v2/commonComponents/Button';

interface DownloadVideoButtonProps {
  messageId?: string;
}

export const DownloadVideoButton = ({
  messageId,
}: DownloadVideoButtonProps) => {
  const { currentMessage } = useCurrentMessage(messageId);
  const { onDownloadFile } = useDownloadFile();

  const payload = isVideoGenerationCard(currentMessage?.payload)
    ? currentMessage?.payload
    : undefined;

  if (!payload) {
    return null;
  }

  // (olha): it will work until we support only one video
  const url = payload.videos?.[1]?.video_url;

  const handleDownloadClick = () => {
    if (url) {
      onDownloadFile(url);
    }
  };

  return (
    <Button disabled={!url} color="transparent" onClick={handleDownloadClick}>
      <DownloadSimple size={SVG_SIZE_M} />
      Download
    </Button>
  );
};

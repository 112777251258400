import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

/**
 * isString checks whether the data is string.
 * @param data unknown
 * @returns boolean
 */
export function isString(data: unknown): data is string {
  return typeof data === 'string';
}

/**
 * isBoolean checks whether the data is boolean.
 * @param data unknown
 * @returns boolean
 */
export function isBoolean(data: unknown): data is boolean {
  return typeof data === 'boolean';
}

/**
 * isNumber typeguards data to be a number.
 */
export function isNumber(data: unknown): data is number {
  return typeof data === 'number';
}

/**
 * isObject typeguards data to be object.
 */
export function isObject(data: unknown): data is object {
  return typeof data === 'object';
}

/**
 * isNull typegards data to be null.
 */
export function isNull(data: unknown): data is null {
  return data === null;
}

/**
 * isError typeguards error objects.
 */
export function isError(data: unknown): data is Error {
  const error = data as Error;
  return error && typeof error === 'object' && 'message' in error;
}

export function isFetchBaseQueryError(
  error: unknown,
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

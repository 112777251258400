import styles from './GetEnterpriseModal.module.scss';
import { Modal } from 'src/components/Modal';
import { Button } from 'src/v2/commonComponents/Button';
import { useGetEnterpriseModal } from 'src/hooks';
import { NINJA_SUPPORT_EMAIL } from 'src/constants';

export const GetEnterpriseModal = () => {
  const { isGetEnterpriseModalOpen, onCloseGetEnterpriseModal } =
    useGetEnterpriseModal();

  return (
    <Modal
      open={isGetEnterpriseModalOpen}
      onClose={onCloseGetEnterpriseModal}
      className={styles.getEnterpriseModal}
      overlayClassName={styles.overlay}
      blockScroll={false}
    >
      <div className={styles.root}>
        <h4 className={styles.title}>Get Enterprise</h4>
        <span className={styles.text}>
          If you're interested in learning more about our Enterprise plans,
          including pricing and implementation, please contact us at{' '}
          <a className={styles.link} href={`mailto:${NINJA_SUPPORT_EMAIL}`}>
            {NINJA_SUPPORT_EMAIL}
          </a>
        </span>
        <div className={styles.buttonRow}>
          <Button onClick={onCloseGetEnterpriseModal}>Close</Button>
        </div>
      </div>
    </Modal>
  );
};

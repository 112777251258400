import { VERSION_NUMBER } from 'src/constants';
import styles from './SectionFooter.module.scss';
import { useSession } from 'src/hooks';
import { Circle } from '@phosphor-icons/react';

export const SectionFooter = () => {
  const { isSocketConnected } = useSession();

  return (
    <div className={styles.root}>
      <p>Version: {VERSION_NUMBER}</p>

      <p className={styles.connection}>
        <span className={isSocketConnected ? styles.greenIcon : styles.redIcon}>
          <Circle size={8} weight="fill" />
        </span>

        <span>{isSocketConnected ? 'Connected' : 'Disconnected'}</span>
      </p>
    </div>
  );
};

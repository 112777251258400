import cn from 'classnames';
import { useLeftPanelActions } from 'src/hooks';
import styles from './MainNavToggleButton.module.scss';
import { SVG_SIZE_M } from 'src/constants';
import { SidebarSimple } from '@phosphor-icons/react';
import { useRightSidePanelActions, useRightSidePanelState } from 'src/hooks';

interface MainNavToggleButtonProps {
  className?: string;
}

export const MainNavToggleButton = ({
  className,
}: MainNavToggleButtonProps) => {
  const { toggleLeftPanelExpanded } = useLeftPanelActions();
  const { toggleRightSidePanelExpanded } = useRightSidePanelActions();
  const { isRightPanelExpanded } = useRightSidePanelState();

  const handleClick = () => {
    toggleLeftPanelExpanded();

    if (isRightPanelExpanded) {
      toggleRightSidePanelExpanded(false);
    }
  };

  return (
    <button
      className={cn(styles.root, {
        className,
      })}
      onClick={handleClick}
      data-tooltip-id="avatar-onboarding-tooltip"
      data-tooltip-place="bottom-end"
    >
      <SidebarSimple size={SVG_SIZE_M} />
    </button>
  );
};

import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import {
  useQueryParams,
  useSubmitUserInput,
  useSession,
  useConversations,
} from 'src/hooks';
import {
  DEFAULT_CHAT_ID,
  REDIRECT_TO_STRIPE_TIMESTAMP,
  TRANSACTION_ID,
  PAYMENT_DATADOG_ERROR,
  PAYMENT_ERROR_MESSAGE,
} from 'src/constants';
import { sendGTMEvent, sendPaymentGTMEvent, stripePromise } from 'src/utils';
import { AppRoutes, BannerType, GTMEvent } from 'src/types';
import { useBanner } from 'src/hooks/useBanner';
import { useLazyGetSessionInfoQuery } from 'src/store/services';
import { toast } from 'react-toastify';
import { datadogLogs } from '@datadog/browser-logs';

const PAYMENT_PROVIDER = 'stripe';

/**
 * useChatPageQueryParams handles all query parameters on the Main chat page.
 */
export const useChatPageQueryParams = () => {
  const navigate = useNavigate();

  const {
    searchParams: {
      session_id,
      query,
      subscription_succeeded_plan,
      subscription_succeeded_period,
      payment_intent_client_secret,
      subscription_succeeded_number_of_seats,
    },
    removeSearchParam,
  } = useQueryParams();

  const { onSubmitUserInput } = useSubmitUserInput();
  const { removeBanners, removeAllPaymentBanners } = useBanner();

  const { appUser } = useSession();

  const [checkSession] = useLazyGetSessionInfoQuery();

  const { isConversationsLoading, isConversationsFetching, conversations } =
    useConversations();

  const { setThreadInputBoxValue } = useContext(ThreadInputBoxContext);

  // TODO(olha): this hook will be deprecated soon (After Payments V2)
  useEffect(() => {
    if (session_id) {
      const handleCheckSession = async () => {
        try {
          await checkSession({
            user_id: appUser.user_id,
            session_id: session_id,
          }).unwrap();

          // !!! (olha): it's an important event we are tracking. We need to make sure it calls properly and only once by the subscription
          sendGTMEvent(GTMEvent.SUCCESSFUL_NEW_PRO_SUBSCRIPTION);
        } finally {
          removeBanners([
            BannerType.INSUFFICIENT_CREDITS,
            BannerType.LOW_CREDITS,
            BannerType.LOW_TASKS,
          ]);

          removeSearchParam('session_id');

          if (!!localStorage.getItem(REDIRECT_TO_STRIPE_TIMESTAMP)) {
            localStorage.removeItem(REDIRECT_TO_STRIPE_TIMESTAMP);
          }
        }
      };

      handleCheckSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session_id]);

  const handleSentQuery = (query: string) => {
    const decodedQuery = decodeURIComponent(query);

    setThreadInputBoxValue(decodedQuery);
    onSubmitUserInput(decodedQuery, {
      conversation_id: DEFAULT_CHAT_ID,
    });
  };

  // Adding the text from the "query" param to the Input box and sending a request.
  useEffect(() => {
    if (
      !appUser.user_id ||
      isConversationsLoading ||
      isConversationsFetching ||
      !conversations
    ) {
      return;
    }

    if (query) {
      removeSearchParam('query');
      handleSentQuery(query);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query,
    appUser.user_id,
    isConversationsLoading,
    isConversationsFetching,
    conversations,
  ]);

  // TODO(olha): includes code-duplication. Needs refactoring
  // handling succeeded payment (with redirect_url)
  useEffect(() => {
    if (!subscription_succeeded_plan || !subscription_succeeded_period) {
      return;
    }
    const transaction_id = localStorage.getItem(TRANSACTION_ID);

    const checkIntent = async () => {
      // TODO(olha): double-check if we can get paymentIntent.status from query params
      const stripeResult = await stripePromise;
      const intentResult = await stripeResult?.retrievePaymentIntent(
        payment_intent_client_secret || '',
      );
      if (
        intentResult?.paymentIntent &&
        intentResult.paymentIntent.status === 'succeeded'
      ) {
        if (transaction_id) {
          // !!! (olha): it's an important event we are tracking. We need to make sure it calls properly and only once by the subscription
          sendPaymentGTMEvent({
            transaction_id: transaction_id,
            subscription_plan: subscription_succeeded_plan,
            subscription_period: subscription_succeeded_period,
            number_of_seats: subscription_succeeded_number_of_seats,
          });

          removeAllPaymentBanners();
          navigate(AppRoutes.HOME, { replace: true });
        } else {
          // TODO(olha): if for some reason transaction_id is undefined (should not normally happened)
          removeAllPaymentBanners();
          navigate(AppRoutes.HOME, { replace: true });
        }
      } else {
        toast(PAYMENT_ERROR_MESSAGE);
        navigate(AppRoutes.HOME, { replace: true });

        datadogLogs.logger.error(PAYMENT_DATADOG_ERROR, {
          user_id: appUser.user_id,
          payment_provider: PAYMENT_PROVIDER,
          plan: subscription_succeeded_plan,
          step: 'final redirect',
          error: JSON.stringify(
            intentResult?.error || intentResult?.paymentIntent,
          ),
        });
      }
    };

    checkIntent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subscription_succeeded_plan,
    subscription_succeeded_period,
    payment_intent_client_secret,
    subscription_succeeded_number_of_seats,
  ]);
};

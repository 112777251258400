import { useSession } from 'src/hooks';
import { Tooltip } from './Tooltip';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';

export const PromoTooltip = () => {
  const { isOpenTier } = useSession();

  if (!isOpenTier) {
    return null;
  }

  return (
    <Tooltip>
      <GuestAuthLinks dataGTM="Toast-signup" />
    </Tooltip>
  );
};

import { UserMenuMainInfo } from 'src/pages/ManageTasksChatPage/components/ConversationsHeader/components/UserMenuMainInfo';
import { SectionFooter } from './components/SectionFooter';
import styles from './UserMenuSection.module.scss';
import { useSession } from 'src/hooks';
import { SupportButton } from './components/SupportButton';
import { SignOutButton } from './components/SignOutButton';
import { UpsellMessage } from 'src/components/UpsellMessage';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { ThemeSelector } from './components/ThemeSelector';
import { SettingsLink } from 'src/pages/ManageTasksChatPage/components/ConversationsHeader/components/SettingsLink';
import { WalletSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/WalletSection';
import { CompanyLinks } from './components/CompanyLinks';

export const UserMenuSection = () => {
  const {
    isOpenTier,
    isSubscribedTier,
    isStandardTier,
    isProTier,
    isTeamsTier,
  } = useSession();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <UserMenuMainInfo />

        {!isSubscribedTier && !isTeamsTier && (
          <div className={styles.walletWrapper}>
            <WalletSection />
          </div>
        )}

        {(isStandardTier || isProTier) && (
          <UpsellMessage
            title="Unlimited external AI Models"
            description="Get answers from external models from companies like OpenAI, Google, and Anthropic"
            className={styles.upsellMessage}
            dataGTMNinjaTier="Settings-upgrade"
            dataGTMOpenTier="Settings-wallet-section-signup"
          />
        )}

        <SettingsLink />

        <ThemeSelector />

        <SupportButton />

        {!isOpenTier && <SignOutButton />}

        <hr className="divider" />

        <CompanyLinks />

        {isOpenTier && (
          <GuestAuthLinks
            dataGTM="Settings-auth-buttons-signup"
            className={styles.authLinks}
          />
        )}
      </div>

      <SectionFooter />
    </div>
  );
};

import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { ManageAccountContentPage } from '../ManageAccountContentPage/ManageAccountContentPage';
import styles from './Profile.module.scss';
import { useGetUserPhoto, useSession } from 'src/hooks';
import { FormInput } from 'src/components/FormInput';
import { FormProvider } from 'src/components/FormProvider';
import { useLazyGetUserByIdQuery } from 'src/store/services';
import { useUserData } from 'src/hooks/useUserData';
import { UserImage } from 'src/v2/components/UserImage';
import {
  USER_NAME_MAX_LENGTH,
  USER_NAME_MIN_LENGTH,
  USER_NAME_PATTERN_1,
  USER_NAME_PATTERN_2,
} from 'src/constants';

interface ProfileFormData {
  first_name?: string;
  last_name?: string;
  department_of_team?: string;
  job_title?: string;
}

export const Profile = () => {
  const {
    appUser: { email, user_id, ...restUserInfo },
  } = useSession();
  const { photoUrl } = useGetUserPhoto();

  const [, setImg] = useState('');
  const [errorTooltip, setErrorTooltip] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { updateUserData } = useUserData();

  useEffect(() => {
    if (photoUrl) {
      setImg(photoUrl);
    }
  }, [photoUrl]);

  const handleChangeUserInfo = async (
    name: 'first_name' | 'last_name',
    value: string,
  ) => {
    const initValue = restUserInfo[name] || '';

    const fieldName = name === 'first_name' ? 'First name' : 'Last name';

    if (value !== '') {
      if (value.length < USER_NAME_MIN_LENGTH) {
        setErrorMessage(`The ${fieldName} is too short`);
        setErrorTooltip(name);
        return;
      }
      if (value.length > USER_NAME_MAX_LENGTH) {
        setErrorMessage(`The ${fieldName} is too long`);
        setErrorTooltip(name);
        return;
      }
      if (!USER_NAME_PATTERN_1.value.test(value)) {
        setErrorMessage(USER_NAME_PATTERN_1.message);
        setErrorTooltip(name);
        return;
      } else if (USER_NAME_PATTERN_2.value.test(value)) {
        setErrorMessage(USER_NAME_PATTERN_2.message);
        setErrorTooltip(name);
        return;
      }
      if (value !== initValue) {
        await updateUserData({ [name]: value });
      }
    } else {
      setErrorMessage(`The ${fieldName} is required`);
      setErrorTooltip(name);
    }
  };

  const [trigger] = useLazyGetUserByIdQuery();

  const methods = useForm<ProfileFormData>({
    defaultValues: async () => {
      const result = await trigger(user_id, true);
      const { data } = result;
      return {
        first_name: data?.first_name || '',
        last_name: data?.last_name || '',
        job_title:
          data?.settings?.profile_setting?.role_information?.job_title || '',
        department_of_team:
          data?.settings?.profile_setting?.role_information
            ?.department_of_team || '',
      };
    },
    mode: 'onBlur',
  });

  const deleteError = () => {
    setErrorTooltip(null);
    setErrorMessage(null);
  };

  return (
    <FormProvider<ProfileFormData> methods={methods}>
      <ManageAccountContentPage
        title="Profile"
        subtitle="Manage your Ninja profile"
      >
        <div className={styles.root}>
          <section className={styles.section}>
            <h4 className={styles.title}>Your photo</h4>

            <div className={styles.userAvatarWrapper}>
              <UserImage size={96} />
            </div>

            <hr className={styles.divider} />
          </section>

          <section className={styles.section}>
            <h4 className={styles.title}>Contact information</h4>
            <h5 className={styles.subtitle}>
              Enter your contact information so Ninja can use it when doing
              outreach.
            </h5>

            <div className={styles.group}>
              <div className={styles.field}>
                <label className={styles.label}>
                  First name
                  <span className={styles.rule}>Required</span>
                </label>
                <FormInput
                  name="first_name"
                  type="text"
                  placeholder="First name (e,g. Adam)"
                  onFocus={() => deleteError()}
                  onBlurHandler={(value) =>
                    handleChangeUserInfo('first_name', value)
                  }
                  errorMessage={
                    errorTooltip === 'first_name' && !!errorMessage
                      ? errorMessage
                      : undefined
                  }
                />
              </div>

              <div className={styles.field}>
                <label className={styles.label}>
                  Last name
                  <span className={styles.rule}>Required</span>
                </label>
                <FormInput
                  name="last_name"
                  type="text"
                  placeholder="Last name (e,g. Smith)"
                  onFocus={() => deleteError()}
                  onBlurHandler={(value) =>
                    handleChangeUserInfo('last_name', value)
                  }
                  errorMessage={
                    errorTooltip === 'last_name' && !!errorMessage
                      ? errorMessage
                      : undefined
                  }
                />
              </div>
            </div>

            <div className={styles.group}>
              <div className={`${styles.field} ${styles.full}`}>
                <label className={styles.label}>Email</label>
                <input disabled value={email} />
              </div>
            </div>

            <hr className={styles.divider} />
          </section>

          <section className={styles.section}>
            <h4 className={styles.title}>Role information</h4>
            <h5 className={styles.subtitle}>Enter your role information.</h5>

            <div className={styles.group}>
              <div className={`${styles.field} ${styles.full}`}>
                <label className={styles.label}>
                  Job title
                  <span className={styles.rule}>Optional</span>
                </label>
                <FormInput
                  name="job_title"
                  type="text"
                  placeholder="Your job title (e,g. Account manager)"
                  onBlurHandler={(value) =>
                    updateUserData({
                      settings: {
                        ...restUserInfo.settings,
                        profile_setting: {
                          ...restUserInfo.settings?.profile_setting,
                          role_information: {
                            ...restUserInfo.settings?.profile_setting
                              ?.role_information,
                            job_title: value,
                          },
                        },
                      },
                    })
                  }
                />
              </div>
            </div>

            <div className={styles.group}>
              <div className={`${styles.field} ${styles.full}`}>
                <label className={styles.label}>
                  Department of team
                  <span className={styles.rule}>Optional</span>
                </label>
                <FormInput
                  name="department_of_team"
                  type="text"
                  placeholder="Your team (e,g. Sales)"
                  onBlurHandler={(value) =>
                    updateUserData({
                      settings: {
                        ...restUserInfo.settings,
                        profile_setting: {
                          ...restUserInfo.settings?.profile_setting,
                          role_information: {
                            ...restUserInfo.settings?.profile_setting
                              ?.role_information,
                            department_of_team: value,
                          },
                        },
                      },
                    })
                  }
                />
              </div>
            </div>
          </section>
        </div>
      </ManageAccountContentPage>
    </FormProvider>
  );
};

import { useContext, useMemo } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import styles from './SuperAgentIsWorkingHardText.module.scss';
import { useCurrentConversationTasks, useTaskById } from 'src/hooks';
import { sendGTMEvent } from 'src/utils';
import { GTMEvent, TaskState } from 'src/types';

interface SuperAgentIsWorkingHardTextProps {
  taskId?: string;
}

export const SuperAgentIsWorkingHardText = ({
  taskId,
}: SuperAgentIsWorkingHardTextProps) => {
  const { onToggleVisibleTaskViewModal } = useContext(SessionContext);
  const { setCurrentTaskId } = useTaskById();
  const { getTaskFromConversationById } = useCurrentConversationTasks();

  const taskState = useMemo(() => {
    return getTaskFromConversationById(taskId)?.state || TaskState.IN_PROGRESS;
  }, [getTaskFromConversationById, taskId]);

  const handleClick = () => {
    if (taskId) {
      setCurrentTaskId(taskId);
      onToggleVisibleTaskViewModal(true);
      sendGTMEvent(GTMEvent.TASK_VIEW_OPENING, {
        taskId,
        task_state: taskState,
      });
    }
  };

  return (
    <p className={styles.root}>
      SuperAgent is working hard.{' '}
      <span className={styles.link} onClick={handleClick}>
        See the progress
      </span>{' '}
    </p>
  );
};

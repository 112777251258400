import classNames from 'classnames';
import styles from './MockedVideoProgress.module.scss';
import { ListBullets } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';

export const MockedVideoProgress = () => {
  return (
    <div className={styles.root}>
      <div className={classNames(styles.progressButton, styles.video)}>
        <span
          className={classNames(styles.progressButtonContainer, styles.video)}
        >
          <span>/video</span>
          <ListBullets size={SVG_SIZE_M} />
        </span>
      </div>

      <span className={styles.caption}>1280x720</span>
    </div>
  );
};

import { useState, useCallback } from 'react';

type CanvasState = {
  mainCanvas: string;
  maskCanvas: string;
};

type UseUndoRedoReturnType = {
  undoStack: CanvasState[];
  redoStack: CanvasState[];
  push: (state: CanvasState) => void;
  undo: () => CanvasState | null;
  redo: () => CanvasState | null;
  resetUndoAndRedo: () => void;
  canUndo: boolean;
  canRedo: boolean;
};

export const useUndoRedo = (
  initialState: CanvasState | null = null,
): UseUndoRedoReturnType => {
  const [undoStack, setUndoStack] = useState<CanvasState[]>(
    initialState ? [initialState] : [],
  );
  const [redoStack, setRedoStack] = useState<CanvasState[]>([]);

  const push = useCallback((state: CanvasState) => {
    setUndoStack((prev) => [...prev, state]);
    setRedoStack([]);
  }, []);

  const undo = useCallback((): CanvasState | null => {
    if (undoStack.length <= 1) {
      return null;
    }

    const newUndo = [...undoStack];
    const lastState = newUndo.pop();

    if (lastState) {
      setRedoStack((prevRedo) => [...prevRedo, lastState]);
      setUndoStack(newUndo);
      return newUndo[newUndo.length - 1] || null;
    }

    return null;
  }, [undoStack]);

  const redo = useCallback((): CanvasState | null => {
    if (redoStack.length === 0) {
      return null;
    }

    const newRedo = [...redoStack];
    const stateToRestore = newRedo.pop();
    if (stateToRestore) {
      setUndoStack((prevUndo) => [...prevUndo, stateToRestore]);
      setRedoStack(newRedo);
      return stateToRestore;
    }

    return null;
  }, [redoStack]);

  const resetUndoAndRedo = useCallback(() => {
    setUndoStack([]);
    setRedoStack([]);
  }, []);

  const canUndo = undoStack.length > 1;
  const canRedo = redoStack.length > 0;

  return {
    undoStack,
    redoStack,
    resetUndoAndRedo,
    push,
    undo,
    redo,
    canUndo,
    canRedo,
  };
};

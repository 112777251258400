import cn from 'classnames';
import { useContext } from 'react';
import { MobileModalOverlayList } from 'src/components/ModalWithGallery/components/MobileModalOverlayList';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import {
  useBreakpoint,
  useCurrentImagesList,
  useImageMaskingPanelActions,
  useSelectedImageIndex,
} from 'src/hooks';
import { ThreadMediaThumbnail } from 'src/v2/components/ThreadMessage/components/ThreadMediaThumbnail';
import styles from './MobileModalImagesList.module.scss';
import { AiGenerationStatus } from 'src/types';

interface MobileModalImagesListProps {
  isOpenMobileListPanel: boolean;
  closeMobileListPanel: () => void;
}

export const MobileModalImagesList = ({
  isOpenMobileListPanel,
  closeMobileListPanel,
}: MobileModalImagesListProps) => {
  const { isMobileOrTablet } = useBreakpoint();
  const { currentImagesList } = useCurrentImagesList();
  const { selectedImageIndex } = useSelectedImageIndex();
  const {
    onChangeSelectedImageIndex,
    onChangeImageMaskingRightPanelData,
    onToggleIsOpenModalImagesList,
    resetImageMaskingPanelInfo,
  } = useImageMaskingPanelActions();
  const { onClearAttachments } = useContext(ThreadInputBoxContext);

  const handleSelectImage = (index: number) => {
    resetImageMaskingPanelInfo();
    onChangeSelectedImageIndex(index);
    onChangeImageMaskingRightPanelData(currentImagesList[index]);
    onClearAttachments();
  };

  const handleChangeCurrentIndex = (index: number) => {
    if (isMobileOrTablet && isOpenMobileListPanel) {
      handleCloseModalImagesList();
    }

    handleSelectImage(index);
  };

  const handleCloseModalImagesList = () => {
    closeMobileListPanel();
    onToggleIsOpenModalImagesList();
  };

  return (
    <MobileModalOverlayList
      isOpen={isOpenMobileListPanel}
      onClose={handleCloseModalImagesList}
    >
      <div className={styles.root}>
        <div className={styles.body}>
          {currentImagesList.map((item, i) => (
            <div
              className={cn('', {
                selected: selectedImageIndex === i,
              })}
              key={`${item.url}_${i}`}
            >
              <ThreadMediaThumbnail
                mediaType="image"
                key={item.id}
                thumbnail={item.url}
                title={item.title}
                onClick={() => handleChangeCurrentIndex(i)}
                icon={item.icon || undefined}
                source={item.source}
                status={item.status as AiGenerationStatus}
                withDisabledOverlay={item.isDisabled}
              />
            </div>
          ))}
        </div>
      </div>
    </MobileModalOverlayList>
  );
};

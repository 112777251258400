import styles from './TeamsUsersTable.module.scss';
import {
  SVG_SIZE_M,
  TEAM_ACTIVE_MEMBERS_COLUMNS,
  TEAM_PENDING_MEMBERS_COLUMNS,
} from 'src/constants';
import { Seat } from 'src/types/models/Seat';
import cn from 'classnames';
import { useSession } from 'src/hooks';
import { Button } from 'src/v2/commonComponents/Button';
import { Spinner } from 'src/components/Loading';
import { TeamsTableMenu } from 'src/v2/pages/manageUsersInWorkspace/components/TeamsTableMenu';
import { SeatStatus } from 'src/types/models/SeatStatus';

type TeamsUsersTableProps = {
  usersList: Seat[];
  isLoading?: boolean;
  isFetching?: boolean;
  onLoadMore: () => void;
  hasMorePages?: boolean;
  statuses: SeatStatus;
  isActiveUsersTable: boolean;
};

export const TeamsUsersTable = ({
  usersList,
  isLoading,
  onLoadMore,
  hasMorePages,
  isFetching,
  statuses,
  isActiveUsersTable,
}: TeamsUsersTableProps) => {
  const { appUser } = useSession();

  return (
    <div className={styles.root}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.row}>
            {(isActiveUsersTable
              ? TEAM_ACTIVE_MEMBERS_COLUMNS
              : TEAM_PENDING_MEMBERS_COLUMNS
            ).map((col) => (
              <th key={col.key} className={styles.headCell}>
                {col.header}
              </th>
            ))}
          </tr>
        </thead>
        {usersList && (
          <tbody>
            {(usersList || []).map((user, rowIndex) => {
              const isCurrentUser = user.user_id === appUser.user_id;
              return (
                <tr key={rowIndex} className={styles.row}>
                  {isActiveUsersTable && (
                    <td className={styles.cell}>
                      <div className={styles.nameCell}>
                        <span>{user.user_full_name}</span>
                        {isCurrentUser && <span>(you)</span>}
                      </div>
                    </td>
                  )}
                  <td className={styles.cell}>{user.user_email}</td>
                  {isActiveUsersTable && (
                    <td className={cn(styles.cell, styles.roleCell)}>
                      {user.role}
                    </td>
                  )}
                  <td className={cn(styles.cell, styles.actionsCell)}>
                    <TeamsTableMenu
                      seatInfo={user}
                      statuses={statuses}
                      isActiveUsersTable={isActiveUsersTable}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
        {(isLoading ||
          !usersList ||
          (!isLoading && usersList && usersList.length === 0)) && (
          <tfoot>
            <tr>
              <td colSpan={4} className={styles.loadingCell}>
                {isLoading
                  ? 'Loading...'
                  : !usersList || (usersList && usersList.length === 0)
                    ? 'No users'
                    : ''}
              </td>
            </tr>
          </tfoot>
        )}
      </table>
      {hasMorePages && (
        <Button
          className={styles.loadMoreButton}
          onClick={onLoadMore}
          color="transparent"
        >
          {isFetching && <Spinner size={SVG_SIZE_M} inline />}
          Load more
        </Button>
      )}
    </div>
  );
};

import { Headset } from '@phosphor-icons/react';
import { useSession } from 'src/hooks';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { Button } from 'src/v2/commonComponents/Button';
import { SVG_SIZE_M } from 'src/constants';
import styles from './SupportButton.module.scss';

export const SupportButton = () => {
  const { appUser } = useSession();
  const { onChangeIsOpenSupportModal } = useContext(SessionContext);

  const handleClick = () => {
    onChangeIsOpenSupportModal(true);
  };

  return (
    <Button
      className={styles.root}
      color="transparent"
      onClick={handleClick}
      disabled={!appUser?.settings?.features?.zendesk_support?.is_capable}
      data-e2e="support-button"
    >
      <Headset size={SVG_SIZE_M} />
      Support
    </Button>
  );
};

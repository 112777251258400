import { useMemo, useRef, useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { useCurrentConversationTasks, useTaskById } from 'src/hooks';
import {
  TaskState,
  ResearchTaskCreationCard,
  SchedulerCreationCard,
  ChitChatCard,
  ImageCard,
  CodeTaskCreationCard,
  MessageType,
  isChitChatCard,
  GTMEvent,
  VideoCard,
} from 'src/types';
import { getSkillFromMessage, sendGTMEvent } from 'src/utils';
import { ProgressStatus } from '../ProgressStatus';
import { LatestProgressAction } from '../LatestProgressAction';
import { ThreadMessageActions } from 'src/components/FlatAppearance/components/ThreadMessageActions';
import styles from './MessageWithTaskProgress.module.scss';
import classNames from 'classnames';

interface MessageWithTaskProgressProps {
  messageId?: string;
  message_type?: MessageType;
  payload:
    | ResearchTaskCreationCard
    | SchedulerCreationCard
    | CodeTaskCreationCard
    | ChitChatCard
    | ImageCard
    | VideoCard;
}

export const MessageWithTaskProgress = ({
  messageId,
  message_type,
  payload,
}: MessageWithTaskProgressProps) => {
  const containerRef = useRef<HTMLParagraphElement>(null);

  const { getTaskFromConversationById } = useCurrentConversationTasks();

  const { task_id, progress } = payload;

  const taskState = useMemo(() => {
    return getTaskFromConversationById(task_id)?.state || TaskState.IN_PROGRESS;
  }, [getTaskFromConversationById, task_id]);

  const { setCurrentTaskId } = useTaskById();

  const { onToggleVisibleTaskViewModal } = useContext(SessionContext);

  const skill = useMemo(
    () =>
      message_type
        ? getSkillFromMessage(
            message_type,
            isChitChatCard(payload) ? payload.data?.skill : undefined,
          )
        : '',
    [message_type, payload],
  );

  const customClassName = useMemo(() => skill.replace(' ', '_'), [skill]);

  if (!task_id) {
    return null;
  }

  const handleClick = () => {
    setCurrentTaskId(task_id);
    onToggleVisibleTaskViewModal(true);
    sendGTMEvent(GTMEvent.TASK_VIEW_OPENING, {
      task_id,
      task_state: taskState,
    });
  };

  return (
    <div className={styles.root}>
      <button
        onClick={handleClick}
        className={classNames(styles.progressButton, styles[customClassName], {
          [styles.animatedBorder]: taskState === TaskState.IN_PROGRESS,
        })}
        data-e2e={`skill-tag-${skill}`}
      >
        <span
          className={classNames(
            styles.progressButtonContainer,
            styles[customClassName],
          )}
        >
          <span>/{skill}</span>

          <ProgressStatus
            temporaryIncreaseProgress={skill === 'video'}
            taskState={taskState}
            actions={progress?.actions}
          />
        </span>
      </button>

      <LatestProgressAction
        messageId={messageId}
        ref={containerRef}
        actions={progress?.actions}
      />

      <ThreadMessageActions
        taskId={task_id}
        taskState={taskState}
        messageId={messageId}
      />
    </div>
  );
};

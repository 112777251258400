import { ReactElement, RefObject, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useBreakpoint } from 'src/hooks';
import styles from './AppearingFromRight.module.scss';
import { ANIMATION_TIMEOUT_MIDDLE } from 'src/constants';
import classNames from 'classnames';

interface AppearingFromRightProps {
  inProp: boolean;
  nodeRef: RefObject<HTMLDivElement>;
  children: ReactElement;
  unmountOnExit?: boolean;
  timeout?: number;
  onExited?: () => void;
  width: number | string;
  enableTabletMode?: boolean;
}

// TODO(olha): rename, since this component implements 3 types of animations related to breakpoint
export const AppearingFromRight = ({
  inProp,
  nodeRef,
  children,
  // TODO(olha): WIP: we need to pass timeout as a CSS variable to CSS file
  timeout = ANIMATION_TIMEOUT_MIDDLE,
  unmountOnExit = true,
  onExited,
  width,
  enableTabletMode = true,
}: AppearingFromRightProps) => {
  const { isMobile, isTablet } = useBreakpoint();

  const animationClassNames = useMemo(() => {
    if (isMobile) {
      return {
        enter: styles.fadeEnter,
        enterActive: styles.fadeEnterActive,
        exit: styles.fadeExit,
        exitActive: styles.fadeExitActive,
      };
    }

    if (isTablet && enableTabletMode) {
      return {
        enter: styles.transformAppearanceEnter,
        enterActive: styles.transformAppearanceEnterActive,
        exit: styles.transformAppearanceExit,
        exitActive: styles.transformAppearanceExitActive,
      };
    }

    return {
      enter: styles.widthReduceEnter,
      enterActive: styles.widthReduceEnterActive,
      exit: styles.widthReduceExit,
      exitActive: styles.widthReduceExitActive,
    };
  }, [isMobile, isTablet, enableTabletMode]);

  const customWidth = typeof width === 'number' ? `${width}px` : width;

  return (
    <CSSTransition
      in={inProp}
      timeout={timeout}
      classNames={animationClassNames}
      nodeRef={nodeRef}
      unmountOnExit={unmountOnExit}
      onExited={onExited}
    >
      <div
        ref={nodeRef}
        className={classNames(styles.root, {
          [styles.tabletModeOff]: !enableTabletMode,
        })}
        style={{ '--custom-panel-width': customWidth } as React.CSSProperties}
      >
        <div className={styles.container}>{children}</div>
      </div>
    </CSSTransition>
  );
};

import styles from './ChangeSeatsCheckout.module.scss';
import { useEffect, useRef, useState } from 'react';
import { AppearingFromRight } from 'src/animations/AppearingFromRight';
import { X } from '@phosphor-icons/react';
import { APPLE_PAYMENT_ERROR_TEXT, SVG_SIZE_M } from 'src/constants';
import {
  getAvailableSeatsCount,
  getNextBillingCycleDate,
  getRoundedPriceWithCurrency,
} from 'src/utils';
import { SummaryRow } from 'src/pages/PaymentBetaPage/components/SummaryRow';
import { Button } from 'src/v2/commonComponents/Button';
import { SelectSimple } from 'src/components/SelectSimple';
import { PaymentsSubscriptionInfo, SeatsOptions } from 'src/types';
import {
  useSelectedNumberOfSeats,
  useSession,
  useSubscriptionInfo,
  useSubscriptionPlans,
  useTeamInfo,
} from 'src/hooks';
import { useChangePlanMutation } from 'src/store/services';
import log from 'src/utils/logger';
import { toast } from 'react-toastify';
import { ProgressBar } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/ProgressBar';

type ChangeSeatsCheckoutProps = {
  isOpen: boolean;
  onClose: () => void;
  subscriptionInfo?: PaymentsSubscriptionInfo;
};

const options = [
  { value: SeatsOptions.ADD, label: 'Add seats' },
  { value: SeatsOptions.REMOVE, label: 'Remove seats' },
];

const errorRemoveText =
  'To downgrade to this seat count you must first remove active and/or pending users from your workspace';

export const ChangeSeatsCheckout = ({
  isOpen,
  onClose,
  subscriptionInfo,
}: ChangeSeatsCheckoutProps) => {
  const { appUser } = useSession();

  const { selectedNumberOfSeatsToBuy } = useSelectedNumberOfSeats();

  const { teamInfo, isOwner } = useTeamInfo();
  const checkoutContentRef = useRef<HTMLDivElement>(null);
  const [inputValue, changeInputValue] = useState<string>('0');
  const [numberOfSeats, changeNumberOfSeats] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [changeSeatsOption, setChangeSeatsOption] = useState<SeatsOptions>(
    SeatsOptions.ADD,
  );
  const [isDisabledSubmit, setDisabledSubmit] = useState<boolean>(false);

  const [changePlan, { isLoading }] = useChangePlanMutation();

  const isRemoving = changeSeatsOption === SeatsOptions.REMOVE;

  const newNumberOfSeats = isRemoving
    ? (teamInfo?.num_seats_total || 1) - numberOfSeats
    : (teamInfo?.num_seats_total || 1) + numberOfSeats;

  const { plans, isPlansLoading } = useSubscriptionPlans({
    group: 'teams',
    seat_count: !isRemoving
      ? numberOfSeats + (teamInfo?.num_seats_total || 1)
      : 1,
  });

  const { isAppleOrAmpPayment } = useSubscriptionInfo();

  const selectedPlan = plans
    ? plans.filter(
        (item) =>
          item.plan_tier === (appUser.tier_id as string) &&
          item.period === subscriptionInfo?.interval,
      )[0]
    : undefined;

  const isDisabled =
    numberOfSeats <= 0 ||
    !!error ||
    isLoading ||
    (isOwner &&
      (subscriptionInfo?.has_scheduled_downgrade ||
        subscriptionInfo?.is_cancelled ||
        subscriptionInfo?.has_scheduled_plan_change ||
        subscriptionInfo?.processing_plan_change)) ||
    !isOwner ||
    isPlansLoading ||
    isAppleOrAmpPayment;

  const numberOfAvailableSeats = getAvailableSeatsCount(teamInfo);

  useEffect(() => {
    if (selectedNumberOfSeatsToBuy && selectedNumberOfSeatsToBuy > 0) {
      changeNumberOfSeats(selectedNumberOfSeatsToBuy);
    }
    if (!selectedNumberOfSeatsToBuy) {
      changeNumberOfSeats(0);
    }
  }, [selectedNumberOfSeatsToBuy]);

  const handleConfirmAndPayClick = async () => {
    setDisabledSubmit(true);
    try {
      if (!!selectedPlan) {
        const data = await changePlan({
          user_id: appUser.user_id,
          product_key: selectedPlan.metadata?.stripe?.lookup_key || '',
          price_timestamp: selectedPlan.timestamp,
          seat_count: newNumberOfSeats,
        }).unwrap();

        onClose();

        const redirectUrl = data?.approve_url;

        if (!!redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          toast.success('Seat change successful');
        }
      }
    } catch (e) {
      toast.error('Seat change failed');
      onClose();
      log.error();
    } finally {
      setDisabledSubmit(false);
    }
  };

  const handleChangeSeats = (value: SeatsOptions) => {
    setChangeSeatsOption(value);
    const isChangedToRemoval = value === SeatsOptions.REMOVE;
    if (isChangedToRemoval && numberOfSeats > numberOfAvailableSeats) {
      setError(errorRemoveText);
    }
    if (!isChangedToRemoval && !!error) {
      setError(null);
    }
  };

  const handelOnBlurNumberOfSeats = () => {
    if (inputValue === '') {
      changeInputValue('0');
      changeNumberOfSeats(0);
    }
  };

  const handleChangeNumberOfSeats = (value: string) => {
    if (!!error) {
      setError(null);
    }
    const inputValue = Number(value);
    if (inputValue >= 0) {
      changeInputValue(value);
      if (value.length > 0) {
        changeNumberOfSeats(Number(value));
      }
      if (Number(value) > numberOfAvailableSeats && isRemoving) {
        setError(errorRemoveText);
      }
    }
  };

  const isNullValue = isRemoving || numberOfSeats === 0;

  return (
    <AppearingFromRight
      width={320}
      inProp={isOpen}
      nodeRef={checkoutContentRef}
      enableTabletMode={false}
    >
      <div className={styles.root}>
        <div className={styles.backButtonRow}>
          <button type="button" onClick={onClose} className={styles.backButton}>
            <span>
              {selectedNumberOfSeatsToBuy ? 'Confirm order' : 'Change seats'}
            </span>
            <X size={SVG_SIZE_M} />
          </button>
        </div>

        <div className={styles.mainContainer}>
          {!selectedNumberOfSeatsToBuy ? (
            <div className={styles.inputRow}>
              <SelectSimple
                aria-label="seats-change"
                options={options}
                className={styles.select}
                onChange={handleChangeSeats}
                value={changeSeatsOption}
              />
              <input
                className={styles.seatsInput}
                type="number"
                step={1}
                min={1}
                value={inputValue}
                onChange={(e) => handleChangeNumberOfSeats(e.target.value)}
                onBlur={handelOnBlurNumberOfSeats}
              />
            </div>
          ) : (
            <div className={styles.addSeatsRow}>
              <div className={styles.checkoutResultSectionRow}>
                <span>Add {numberOfSeats} seats</span>

                <span>
                  {getRoundedPriceWithCurrency(
                    selectedPlan?.total_due_amount || 0,
                  )}
                </span>
              </div>
            </div>
          )}
          {!!error && <div className={styles.error}>{error}</div>}
          <hr className={styles.divider} />
          <div className={styles.paymentContainer}>
            <SummaryRow
              label="Subtotal"
              value={getRoundedPriceWithCurrency(
                isNullValue ? 0 : selectedPlan?.total_recurring_amount || 0,
              )}
              isLoading={isPlansLoading}
            />
            <SummaryRow
              label="Prorated discount"
              value={`-${getRoundedPriceWithCurrency(
                isNullValue ? 0 : selectedPlan?.prorated_discount_amount || 0,
              )}`}
              isLoading={isPlansLoading}
            />
            <SummaryRow
              label="Tax & fees"
              value={getRoundedPriceWithCurrency(0)}
              isLoading={isPlansLoading}
            />
            <div className={styles.checkoutResultSectionRow}>
              <span>Due Today (USD)</span>

              {isPlansLoading ? (
                <ProgressBar
                  progressLineClassName={styles.priceLoaderLine}
                  className={styles.priceLoader}
                />
              ) : (
                <span>
                  {getRoundedPriceWithCurrency(
                    isNullValue ? 0 : selectedPlan?.total_due_amount || 0,
                  )}
                </span>
              )}
            </div>
            {!isDisabled &&
              (isPlansLoading ? (
                <ProgressBar
                  progressLineClassName={styles.descriptionLoaderLine}
                  className={styles.descriptionLoader}
                />
              ) : (
                <div className={styles.description}>
                  Then{' '}
                  {getRoundedPriceWithCurrency(
                    (selectedPlan?.per_seat?.total_recurring_amount || 0) *
                      (newNumberOfSeats || 1),
                  )}{' '}
                  per {selectedPlan?.period === 'monthly' ? 'month' : 'year'}{' '}
                  for {newNumberOfSeats} seats
                  {getNextBillingCycleDate(
                    selectedPlan?.next_billing_cycle_start_timestamp,
                  )}
                </div>
              ))}
          </div>
          <hr className={styles.divider} />
          <div className={styles.paymentButtonWrapper}>
            <Button
              className={styles.paymentButton}
              disabled={isDisabled || isDisabledSubmit}
              onClick={handleConfirmAndPayClick}
              title={isAppleOrAmpPayment ? APPLE_PAYMENT_ERROR_TEXT : ''}
              data-e2e="confirm-and-pay-button"
            >
              Confirm & pay
            </Button>
          </div>
        </div>
      </div>
    </AppearingFromRight>
  );
};

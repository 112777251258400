import { ZendeskTicketForm } from 'src/components/ZendeskSupport';
import React, { useCallback } from 'react';
import { AmpOnboardingPageStep } from 'src/v2/pages/auth/AmpOnboardingPage/common';

interface ContactSupportSectionProps {
  setStep: (step: AmpOnboardingPageStep) => void;
}

export const ContactSupportSection = ({
  setStep,
}: ContactSupportSectionProps) => {
  const onZendeskCancel = useCallback(() => {
    setStep(AmpOnboardingPageStep.ONBOARD);
  }, [setStep]);
  const onZendeskSent = useCallback(() => {
    setStep(AmpOnboardingPageStep.ZENDESK_REQUEST_SENT);
  }, [setStep]);
  return (
    <>
      <h5>
        Unfortunately, we were unable to provision your Account automatically.
        Please contact us via support form.
      </h5>
      <ZendeskTicketForm
        onClose={onZendeskCancel}
        onSuccess={onZendeskSent}
        forceAllow
      />
    </>
  );
};
